.ril__image {
	max-width: none !important;
}
.farmer-information {
	.bottom-menu {
		width: 100%;
		display: block;
		position: fixed;
		bottom: 0px;
		padding: 15px;
		z-index: 9;
		background: #f9f9f9;
		box-shadow: 0px 0px 3px 1px #ccc;
		.custom-button {
			border-radius: 0px;
			padding: 5px;
			font-size: 14px;
			font-weight: 500;
			margin: 5px 0;
			width: 100%;
			background: #f9f9f9;
			transition: all 0.5s ease;
		}
		.custom-button:hover {
			background: #585858;
			color: #fff;
			transition: all 0.5s ease;
		}
	}
	.chip {
		display: inline-block;
		padding: 0 25px;
		font-size: 15px;
		font-weight: 500;
		cursor: pointer;
		margin: 5px;
		line-height: 50px;
		border-radius: 25px;
		transition: all 0.3s ease;
		background-color: honeydew;
	}
	.chip:hover {
		box-shadow: 1px 1px 1px 1px #ccc;
		transition: all 0.3s ease;
	}
	.statement-block {
		background: #f9f9f9;
		padding: 20px;
		color: #000000a8;
		font-size: 20px;
		text-align: center;
		font-weight: 700;
		border: 5px dashed #f1f1f1;
	}
	.farmer-block {
		box-shadow: 1px 1px 2px 1px #ccc;
		background: #fff;
		.text-block {
			margin: 10px 0;
			padding: 10px;
			background: #f9f9f9;
		}
		.farmer-block-row {
			margin: 10px 0;
			padding: 10px;
			font-size: 15px;
			color: #585858;
			transition: all 1s ease;
			border-bottom: 1px solid #f1f1f1;
		}
		.farmer-block-row:hover {
			transition: all 1s ease;
			border-bottom: 1px solid #a1bd59;
			cursor: pointer;
		}
		.farmer-block-img {
			display: inline-block;
			width: 30px;
		}
		.logo {
			width: auto !important;
		}
	}
	.crop-prices {
		.custom-grid {
			width: 25%;
			float: left;
			display: inline-block;
			padding: 3px;
			cursor: pointer;
			figure {
				background: #f1f1f1;
			}
		}
		.image-heading {
			margin: 0px 0px 20px 0;
			font-size: 25px;
			font-weight: 700;
			color: #585858;
		}
		.img-flex {
			width: auto;
			height: 200px;
			display: flex;
			justify-content: center;
			text-align: center;
			align-items: center;
		}
	}
	.non-dimension-report {
		div.cards-container {
			margin: 10px 40px;
			display: flex;
			flex-wrap: wrap;
		}
		div.card {
			width: 30%;
			padding: 20px;
			background: #f8fff8;
			border: 1px solid #e0e0e0;
			border-radius: 4px;
			margin: 8px;
			box-shadow: 1px 1px 2px #e0e0e0;
			// box-shadow: 1px 1px 2px #cee6d0;
		}
		div.card:hover {
			box-shadow: 2px 2px 2px #e0e0e0;
		}
		.card-heading {
			margin-bottom: 5px;
			text-align: center;
		}
		.card-content {
			font-size: 15px;
			font-weight: 500;
			padding: 5px;
		}

		@media all and (max-width: 440px) {
			div.card {
				width: 90%;
			}
		}

		@media all and (max-width: 600px) and (min-width: 440px) {
			div.card {
				width: 45%;
			}
		}

		@media all and (max-width: 1040px) and (min-width: 768px) {
			div.card {
				width: 90%;
			}
		}
	}
}
