@import "colors";
@import "mixins";

.login-form {
    height: 100vh;
    margin: 0 auto;
    width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &_icon {
        // height: 15rem;
        font-size: 10rem;
        text-align: center;
        color: rgba($positive-green, 0.3);

        & > img {
            margin: 0 auto;
        }
    }

    // &_icon > span {
    //     border: 4px solid rgba($positive-green, 0.3);
    //     border-radius: 50%;
    //     border-top: none;
    //     padding: 2rem;
    // }

    &_error {
        font-size: 1.6rem;
        color: red;

        @include breakpoint(mobile) {
            font-size: 1.8rem;
            margin-top: 1rem;
        }
    }

    &_submit-error {
        padding: 1rem;
        margin-top: 2rem;
        text-align: center;
        background-color: rgba(mistyrose, 0.7);
        border-radius: 4px;
        border: 1px solid red;
        color: red;
        font-size: 1.6rem;

        @include breakpoint(mobile) {
            padding: 1.6rem;
        }
    }

    @include breakpoint(largeDesktop) {
        padding-top: 10rem;
    }

    @include breakpoint(desktop) {
        padding-top: 20rem;
    }

    @include breakpoint(mobile) {
        padding-top: 0;
        min-height: 100vh;
        width: 100vw;
    }
}

.login-btn {
    padding: 0.7rem 2rem;
    margin-top: 2rem;
    background-color: rgba($positive-green, 0.08);
    border: 1px solid $positive-green;
    color: $positive-green;
    font-size: 2rem;
    text-transform: uppercase;
    float: right;
    transition: 200ms;
    outline: none;

    &:hover {
        border-radius: 4px;
        box-shadow: 0px 0px 2px 2px rgba($positive-green, 0.2);
    }

    @include breakpoint(mobile) {
        margin-top: 3rem;
        padding: 1.6rem 2rem;
        border-radius: 4px;
    }

    &--special {
        margin-top: 1rem;
        width: 100%;
        float: none;
        text-transform: none;
    }
}

.show-password {
    font-size: 2rem;
    text-align: center;
    background-color: white;
    color: #777;
    padding: 1rem 0;
    width: 10%;
    cursor: pointer;

    @include breakpoint(mobile) {
        font-size: 3rem;
    }
}

.login-form--password {
    width: 90% !important;
    border-radius-right-bottom: 0;
    margin: 0;
}

.password__container {
    display: flex;
    margin: 3rem 0 0 0;
}
