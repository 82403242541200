@import "mixins";
@import "colors";

.mg-village_edit {
    background-color: #f4f4f8;
    min-height: 100vh;
    padding: 110px 40px 20px 40px;
    @media (max-width: 500px) {
        padding: 86px 10px 20px 10px;
    }
    &-section {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        height: 100%;
        border: 1px solid #ccc;
        @media (max-width: 400px) {
            padding: 20px 10px;
        }
    }
    &-profile {
        position: relative;
    }
}

.mg-village_edit-profile-hero {
    margin: -20px;
    @media (max-width: 400px) {
        margin: -20px -10px;
    }
    border-radius: 5px 5px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    //height: 220px;
    img {
        max-height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.mg-village_edit-profile-picture {
    //height: 150px;
    width: 150px;
    position: relative;
    background-color: white;
    padding: 5px;
    border-radius: 2px;
    left: 25px;
    top: -60px;
    border-radius: 50%;
    @media (max-width: 450px) {
        left: 106px;
    }
    @media (max-width: 400px) {
        left: 92px;
    }
    @media (max-width: 350px) {
        left: 66px;
    }

    img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.mg-village_edit-profile-overview {
    margin-left: 40px;
    margin-top: -60px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
        font-size: 16px;
        font-weight: 500;
    }
    @media (max-width: 480px) {
        margin-left: 10px;
        text-align: center;
    }
}
.mg-village-festivals_edit {
    padding-top: 20px;
}
.mg-village-festival_edit {
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
    &-image {
        height: 300px;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 4px;
        }
    }
    &:last-of-type {
        border-bottom: none;
    }

    &-description {
        font-size: 14px;
        margin: 12px 0;
        font-family: "PT Sans Caption", sans-serif;
    }
    &-name {
        padding: 10px 0px;
    }
    &-date {
        font-size: 12px;
        font-weight: 500;
    }
}
.mg-village-festival_edit-group {
    margin: 15px 0;
    padding: 20px 0;
    border-top: 1px solid #ccc;
}
.mg-village-festival_edit-group-name {
    color: #9fbc59;
    margin: 0;
    display: inline-block;
    width: auto;
    padding-right: 50px;
    padding-left: 20px;
    border: none;
    font-weight: bold;
    font-size: 32px;
}
#profileImage {
    display: none;
}
#coverImage {
    display: none;
}
.cropperContainer{
    margin: auto;
    min-height: 50vh;
    background-color: #b6dd58f5;
    margin-top: 12px;
}
.profile_image_icon {
    position: absolute;
    height: 30px;
    top: 104px;
    right: 1px;
    border: grey;
    opacity: 0.9;
    border-radius: 50%;
    padding: 5px 6px;
    font-size: 16px;
    background-color: #bfbfbf;
}
.cover_image_icon {
    position: absolute;
    top: 6px;
    right: 10px;
    border: grey;
    opacity: 0.9;
    border-radius: 50%;
    padding: 3px 8px;
    font-size: 22px;
    background-color: white;
}
.crop_image_btn{
    margin-top: 10px;
    border: none;
    background: rgb(51, 122, 183);
    color: white;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 4px;
}
#eventImage {
    font-size: 12px;
    color: transparent;
}
#festivalImage {
    font-size: 12px;
    display: none;
}
#updateEventImage {
    font-size: 12px;
    color: transparent;
}
.file_choose_name {
    padding-top: 6px;
    font-weight: 500;
    font-size: 12px;
    color: #337ab7;
}

.search_Tag_location {
    font-size: 12px;
}
.create_event_admin {
    background-color: #a3bd60;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    transition: all 0.3s linear;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    summary:focus,
    &:focus {
        outline: none;
    }
    &[open] summary {
        margin: 0 -10px 10px -10px;
        border-bottom: 1px solid #ccc;
        padding: 0 10px 5px 10px;
    }
    summary {
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;
        color: white;
        text-align: center;
    }
    &-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 15px;
        @media (max-width: 767px) {
            display: block;
        }
    }
    label {
        font-size: 12px;
        margin-bottom: 5px !important;
        color: white;
    }
}
.create_event_admin_button {
    background-color: #3c763df2;
    font-size: 12px;
    font-weight: 700;
    color: white;
    padding: 5px 10px;
    float: right;
    margin-top: -6px;
    border-radius: 3px;
    border: none;
}
.edit_description_admin_button {
    background-color: #3c763df2;
    font-size: 14px;
    color: white;
    padding: 5px 10px;
    float: right;
    margin-top: -6px;
    border-radius: 3px;
    border: none;
}
.edit_event_admin_button {
    background-color: #3b70b3;
    font-size: 14px;
    font-weight: 500;
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    border: none;
}
.delete_event_admin_button {
    background-color: #d9534f;
    font-size: 14px;
    font-weight: 500;
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
}
.village-description {
    font-size: 11px;
    white-space: pre-wrap;
}
.image_remove_btn{
    background: transparent;
    border: none;
    color: #b90909;
    font-size: 16px;
}
.image_remove_btn_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}