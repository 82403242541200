@font-face {
    font-family: Aileron;
    src: url(../fonts/Aileron-Heavy.otf);
}
@import "colors.scss";
@import "mixins";

@mixin fontProps() {
    color: $text-tertiary;
    font-weight: 300;
}

.heading-primary {
    font-size: 4rem;
    font-family: Aileron;
    color: $text-primary;
}

.heading-secondary {
    font-size: 3.2rem;
    font-family: Aileron;
    color: $text-primary;
}

.heading-tertiary {
    font-size: 2.6rem;
    font-family: Aileron;
    color: $text-primary;
}

.heading-quad {
    font-size: 2rem;
    font-family: Aileron;
    color: $text-primary;
}

.text-lg {
    font-size: 1.8rem;
    @include fontProps();

    @include breakpoint(mobile) {
        font-size: 2.4rem;
    }
}

.text-md {
    font-size: 1.6rem;
    font-weight: lighter;
    @include fontProps();

    @include breakpoint(mobile) {
        font-size: 2rem;
    }
    @include breakpoint(hdLaptop) {
        font-size: 1.8rem;
    }
}

.text-sm {
    font-size: 1.6rem;
    @include fontProps();
}

.text-xs {
    font-size: 1.2rem;
    @include fontProps();
}

.heading-dark {
    color: #444;
    font-weight: 700;
    font-family: Aileron;

    & > p {
        display: inline-block;
    }
}

.text-dark {
    color: #444;
    font-weight: 500;
}
