@charset "UTF-8";
@import "../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
@font-face {
  font-family: Aileron;
  src: url(../fonts/Aileron-Heavy.otf);
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
}
@media (max-width: 1100px) {
  html,
  body {
    font-size: 8px;
  }
}
@media (max-width: 900px) {
  html,
  body {
    font-size: 6px;
  }
}
@media (max-width: 447px) {
  html,
  body {
    font-size: 7px;
  }
}

body.ReactModal__Body--open {
  overflow: hidden hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}

div,
header,
footer,
section,
nav,
aside {
  position: relative;
}

img {
  display: block;
  max-width: 100%;
}

button {
  position: relative;
}
button:focus {
  outline: none;
}

button:focus,
input:focus,
select:focus {
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
}

input[type=radio] {
  -webkit-appearance: radio;
}

.text-center {
  text-align: center;
}

.block-center {
  display: block;
  margin: 0 auto;
}

.center-text {
  text-align: center;
}

#kn-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  color: #000000;
  z-index: 201;
  font-size: 1.5em;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
#kn-header .menu-btn {
  display: none;
}
#kn-header .logo-container img {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
#kn-header a.active {
  border-bottom: 2px solid forestgreen;
  color: black;
}
#kn-header .link-container {
  display: block;
}
#kn-header .link-container ul.main-list {
  display: table;
  margin: 0;
  padding: 0;
  float: right;
}
@media (max-width: 667px) {
  #kn-header .link-container ul.main-list {
    display: block;
  }
}
@media (max-width: 447px) {
  #kn-header .link-container ul.main-list {
    display: block;
  }
}
#kn-header .link-container ul.main-list li {
  display: table-cell;
  font-size: 14px;
  color: #888;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease 0s;
  border-bottom: 1px solid transparent;
  font-weight: 400;
}
@media (max-width: 667px) {
  #kn-header .link-container ul.main-list li {
    display: block;
  }
}
@media (max-width: 447px) {
  #kn-header .link-container ul.main-list li {
    display: block;
  }
}
#kn-header .link-container ul.main-list li:hover {
  color: #000000;
}
#kn-header .link-container ul.main-list li .dropdown-card-list {
  display: block;
  width: 200px;
  position: absolute;
  background: rgb(247, 245, 245);
  top: 45px;
  right: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
#kn-header .link-container ul.main-list li .dropdown-card-list li {
  display: block;
  padding: 8px 12px;
  user-select: none;
  -webkit-user-select: none;
  text-align: left;
}
#kn-header .link-container ul.main-list li .dropdown-card-list li:first-child {
  border-bottom: 1px solid #ccc;
}
#kn-header .link-container ul.main-list li .dropdown-card-list li:hover {
  background: #836100;
  color: #ffffff;
  border-bottom: 1px solid #836100;
}
#kn-header .link-container ul.main-list li .dropdown-card-list li a:hover {
  color: white;
}
#kn-header .link-container ul.main-list li .dropdown-card-list::after {
  content: "";
  position: absolute;
  top: -20px;
  right: 20px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent rgb(247, 245, 245) transparent;
}
#kn-header .link-container ul.main-list li.active {
  border-bottom: 1px solid black;
}

.scroll-before {
  background: #ffffff;
  padding: 20px 20px;
  color: #000000;
  font-size: 1.5em;
}
.scroll-before .logo-container img {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  max-width: 250px;
}
.scroll-before .link-container ul li {
  font-size: 14px;
  padding: 10px 10px;
}
.scroll-before .link-container ul li.active {
  border-bottom: 1px solid black;
}

.header-scroll {
  background: white;
  padding: 15px 20px;
  color: #ffffff;
  z-index: 100;
  font-size: 1.5em;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}
.header-scroll .logo-container img {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  max-width: 180px;
}
.header-scroll .link-container ul li {
  padding: 5px 10px;
}
.header-scroll .link-container ul li.active {
  border-bottom: 1px solid black;
}

.vis {
  display: block;
}

.hide {
  display: block;
}

@media (max-width: 992px) {
  #kn-header .logo-container {
    max-width: 170px;
  }
  #kn-header .logo-container img {
    max-width: 100%;
  }
  #kn-header .menu-btn {
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    color: #836100;
    background-color: transparent;
    border: none;
    font-size: 2rem;
    z-index: 100;
  }
  #kn-header .link-container ul {
    width: 100%;
    margin: 30px 0 0 0;
    padding: 0;
    table-layout: fixed;
    float: none;
  }
  #kn-header .link-container ul li {
    display: block;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    padding: 15px 10px;
    border-bottom: 1px solid transparent;
  }
  #kn-header .link-container ul li:hover {
    border-bottom: 1px solid transparent;
    color: #000000;
    background: white;
  }
  #kn-header .link-container ul li.active {
    border-bottom: 1px solid black;
  }
  .vis {
    display: block;
  }
  .hid {
    display: none !important;
  }
}
.theme-btn {
  padding: 10px 30px 10px 30px;
  border-radius: 50px 50px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.theme-btn i {
  opacity: 0;
  position: absolute;
}
.theme-btn i.right-align {
  margin-left: 5px;
}
.theme-btn i.left-align {
  margin-right: 5px;
}
.theme-btn:hover i {
  position: relative;
  opacity: 1;
}

.theme-btn--green {
  background: rgb(159, 188, 89);
  border: 1px solid rgb(159, 188, 89);
  color: #ffffff;
}
.theme-btn--green:hover {
  background: #ffffff;
  color: #000000;
}

.theme-btn--brown {
  background: #836100;
  color: #ffffff;
  border: 1px solid #836100;
}
.theme-btn--brown:hover {
  background: #ffffff;
  color: #000000;
}

.kn-btn {
  font-size: 1.2rem;
  padding: 1rem 5rem;
  transition: all 200ms;
}
@media (max-width: 900px) {
  .kn-btn {
    font-size: 1.8rem;
    padding: 1.5rem 6rem;
  }
}
@media (max-width: 667px) {
  .kn-btn {
    font-size: 1.8rem;
    padding: 1.5rem 6rem;
  }
}
@media (max-width: 447px) {
  .kn-btn {
    font-size: 2rem;
    padding: 2rem 7rem;
  }
}
.kn-btn--brown {
  border-radius: 4px;
  color: #7f6127;
  background-color: #ffffff;
  text-transform: uppercase;
  border: 1px solid transparent;
}
.kn-btn:hover {
  box-shadow: 5px 8px 25px 0 rgba(46, 61, 73, 0.2);
  border-radius: 0;
  transform: translateY(-1px);
  border: 1px solid #ffffff;
  color: #7f6127;
}

@media (max-width: 445px) {
  .theme-btn {
    padding: 5px 10px;
    border-radius: 2px;
  }
}
.page-footer {
  border-top: 1px solid rgb(243, 243, 243);
  background: #ffffff;
  padding: 5rem 10rem;
  font-weight: 300;
}
@media (max-width: 900px) {
  .page-footer {
    padding: 5rem 5rem;
  }
}
@media (max-width: 447px) {
  .page-footer {
    padding: 3rem 3rem;
  }
}
.page-footer img {
  height: 5rem;
  margin: 0 0 1rem -0.5rem;
}
.page-footer .kn-heading {
  margin-bottom: 1rem;
}
.page-footer table tr td {
  font-size: 1.6rem;
  padding: 0.5rem 0;
}
.page-footer ul {
  list-style-type: none;
}
.page-footer ul li {
  font-size: 1.6rem;
  padding: 0.5rem 0;
  cursor: pointer;
}
.page-footer ul li a {
  text-decoration: none;
}
.page-footer ul li a:hover {
  color: rgb(159, 188, 89);
}
.page-footer ul li.bold {
  font-weight: 700;
}

.copyright-text {
  display: inline-block;
}

.page-post-footer {
  padding: 2rem 10rem;
  background: rgb(247, 246, 246);
}
.page-post-footer p {
  vertical-align: middle;
}
.page-post-footer .follow-icons-container {
  float: right;
}
@media (max-width: 447px) {
  .page-post-footer .follow-icons-container {
    float: left;
  }
}
.page-post-footer .follow-icons-container i {
  font-size: 2.4rem;
  cursor: pointer;
  margin-right: 5px;
}

.careers::after {
  content: "We are hiring :)";
  display: inline-block;
  background-color: #836100;
  color: #ffffff;
  padding: 2px 8px;
  margin-left: 1rem;
  border-radius: 4px;
  font-style: italic;
}

@media (max-width: 900px) {
  .homepage .heading-special::after {
    display: none;
  }
}

.hero-section {
  background-color: white;
  height: 100vh;
  min-height: 700px;
  background-size: cover;
  margin-top: -18rem;
  background-image: url("../images/banner-img-image.png");
  background-size: cover;
  overflow: hidden !important;
  max-height: 1024px;
  background-position: 0 0;
}
@media (min-width: 2000px) {
  .hero-section {
    min-height: 1400px;
  }
}
@media (min-width: 1610px) {
  .hero-section {
    background-position: 250px 0;
  }
}
@media (max-width: 900px) {
  .hero-section {
    height: 70vh;
    background-position: 0 0;
  }
}
@media (max-width: 447px) {
  .hero-section {
    background-size: cover;
    background-position: -350px 0;
  }
}
.hero-section .curve {
  position: absolute;
  top: 80px;
  z-index: 39;
  display: flex;
  align-items: center;
  left: 0;
  background-color: white;
  height: calc(100% - 90px);
  width: 100vw;
  opacity: 0.95;
  clip-path: ellipse(48% 68% at -6% 29%);
}
@media (max-width: 1100px) {
  .hero-section .curve {
    -webkit-clip-path: polygon(0 0, 27% 0, 58% 50%, 55% 57%, 0 91%);
    clip-path: ellipse(48% 68% at -6% 29%);
  }
}
@media (max-width: 900px) {
  .hero-section .curve {
    -webkit-clip-path: ellipse(56% 62% at -6% 29%);
    clip-path: ellipse(56% 62% at -6% 29%);
  }
}
@media (max-width: 667px) {
  .hero-section .curve {
    background: transparent;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@media (max-width: 447px) {
  .hero-section .curve {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    background: transparent;
  }
}
.hero-section .heading-container {
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  max-width: 41%;
  justify-content: space-evenly;
}
.hero-section .heading-container a {
  width: 150px;
  font-size: 1.5rem;
}
@media (max-width: 900px) {
  .hero-section .heading-container {
    padding: 0 15px;
    max-width: 46%;
  }
}
@media (max-width: 667px) {
  .hero-section .heading-container {
    padding: 0 15px;
    max-width: 100%;
    height: 250px;
  }
  .hero-section .heading-container a {
    width: auto;
    max-width: 120px;
    font-weight: bold;
    text-align: center;
  }
  .hero-section .heading-container h1 {
    font-weight: bold;
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  }
}
.hero-section .hero-btn {
  background-color: rgb(34, 139, 34);
  border-color: rgb(34, 139, 34);
}
.hero-section .hero-btn:hover {
  opacity: 0.8;
  color: white;
}
.hero-section .hero-text-highlight {
  margin: 130px auto 0 auto;
  color: black;
  text-align: center;
  z-index: 200;
}
.hero-section .hero-text-highlight h3 {
  font-size: 18px;
  font-weight: 900;
}
.hero-section .hero-text-highlight .hero-content-box {
  display: inline-block;
  max-width: 80%;
  background: #dcf0dc;
  border-radius: 4px;
  padding: 12px;
  margin-top: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.hero-section .hero-text-highlight .hero-content-box h1 {
  font-size: 18px;
  font-weight: 500;
  color: #388e3c;
}
.hero-section .hero-text-highlight .hero-content-box h1 a {
  color: rgb(52, 165, 209);
}

.homepage-numbers {
  position: relative;
  z-index: 43;
  height: 250px;
  margin-top: -18rem;
  background-color: #f7f7f7;
}
.homepage-numbers .number-wrapper {
  max-width: 1400px;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.homepage-numbers .number-wrapper div {
  width: 180px;
  height: 180px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homepage-numbers .number-wrapper div img {
  margin: 25px 0 10px 0;
  width: 30px;
  height: 30px;
}
.homepage-numbers .number-wrapper div h3 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.homepage-numbers .number-wrapper div h1 {
  text-align: center;
  position: relative;
  font-size: 2.5rem;
  font-weight: normal !important;
}
.homepage-numbers .number-wrapper div .number::after {
  display: block;
  position: absolute;
  content: "";
  right: 15%;
  bottom: -8px;
  height: 5px;
  width: 50%;
  background-color: forestgreen;
}
@media (max-width: 900px) {
  .homepage-numbers .number-wrapper div {
    width: 140px;
    height: 140px;
  }
  .homepage-numbers .number-wrapper div img {
    margin: 15px 0 8px 0;
  }
  .homepage-numbers .number-wrapper div h3 {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .homepage-numbers .number-wrapper div h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 447px) {
  .homepage-numbers {
    padding: 40px 0;
    height: auto;
  }
  .homepage-numbers .number-wrapper {
    flex-direction: column;
    justify-content: space-evenly;
    height: 700px;
  }
  .homepage-numbers .number-wrapper div {
    height: 150px;
    width: 150px;
    align-items: center;
  }
  .homepage-numbers .number-wrapper div img {
    margin-top: 20px;
  }
  .homepage-numbers .number-wrapper div h3 {
    margin-top: 2rem;
  }
}

.feature-section {
  position: relative;
  overflow-x: hidden;
  min-height: 700px;
  margin-top: -6rem;
}
@media (min-width: 1610px) {
  .feature-section {
    margin-top: 4rem;
  }
}
.feature-section .feature-wrapper {
  margin-top: 8rem;
}
@media (min-width: 1610px) {
  .feature-section .feature-wrapper {
    max-width: 85%;
    margin: 8rem auto auto auto;
  }
}
.feature-section .feature-text {
  display: grid;
  grid-template: 150px 150px/1fr 1fr;
  grid-gap: 20px 30px;
  width: 45%;
  padding: 70px 0 0 10%;
}
.feature-section .feature-text .heading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.feature-section .feature-text .heading img {
  height: 30px;
  width: 30px;
  margin: 0 5px 0 0;
}
.feature-section .feature-image {
  position: absolute;
  height: 100%;
  top: 0;
  width: auto;
  right: -15vw;
}
.feature-section .feature-image img {
  height: 120%;
}
.feature-section .feature-image .mobile-img {
  position: absolute;
  height: 90%;
  width: auto;
  top: 35%;
  left: -5%;
}
@media (min-width: 1610px) {
  .feature-section .feature-image {
    right: 0;
    width: 50%;
  }
}
@media (min-width: 2000px) {
  .feature-section .feature-image {
    right: 0;
  }
}
@media (min-width: 1610px) {
  .feature-section {
    right: 0;
  }
}
@media (max-width: 1600px) {
  .feature-section .feature-text {
    padding-left: 10%;
  }
  .feature-section .feature-image {
    right: -12vw;
    width: 55%;
  }
  .feature-section .feature-image img {
    height: 120%;
  }
}
@media (max-width: 1100px) {
  .feature-section .mobile-img {
    display: none;
  }
  .feature-section .feature-image {
    height: 90%;
    right: -15vw;
    width: 50%;
  }
}
@media (max-width: 900px) {
  .feature-section {
    margin-bottom: -2rem;
  }
  .feature-section .feature-section {
    min-height: auto;
    position: static;
  }
  .feature-section .feature-wrapper {
    margin-top: 3rem;
  }
  .feature-section .feature-text {
    padding-left: 0;
    margin: auto;
    justify-content: center;
    grid-template: 150px 150px/200px 200px;
  }
  .feature-section .feature-image {
    position: static;
    width: 90%;
    margin: auto;
  }
  .feature-section .feature-image img {
    height: auto;
  }
  .feature-section .feature-image .mobile-img {
    display: block;
    height: 80%;
    left: 5%;
    height: 30%;
    top: 70%;
  }
}
@media (max-width: 447px) {
  .feature-section .feature-text {
    width: 80%;
    padding: 40px 0 0 0;
    margin: auto;
    grid-template: repeat(4, auto)/1fr;
    grid-row-gap: 35px;
    margin-bottom: 35px;
  }
  .feature-section .feature-text .heading img {
    height: 15px;
    width: 15px;
    margin-right: 15px;
  }
  .feature-section .feature-image .mobile-img {
    height: 20%;
    top: 80%;
  }
}

.process-section {
  margin-top: -12rem;
}
@media (min-width: 1610px) {
  .process-section {
    margin-top: -2rem;
  }
}
.process-section p.text-lg {
  max-width: 60%;
  margin: 20px auto 0 auto;
}
.process-section .process-stepper {
  display: grid;
  grid-template: auto/repeat(6, 1fr);
  grid-column-gap: 3%;
  max-width: 90%;
  margin: auto;
  padding-top: 100px;
}
.process-section .step-wrapper {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.process-section .step-wrapper p.text-lg {
  margin-top: 5px;
}
.process-section .step-wrapper .icon-wrapper {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
}
.process-section .step-wrapper .icon-wrapper img {
  width: 40px;
  height: 40px;
}
.process-section .step-wrapper::after, .process-section .step-wrapper::before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: #ccc;
  opacity: 0.9;
  top: 50%;
  border-radius: 50%;
  right: -20%;
}
.process-section .step-wrapper::before {
  right: 0%;
}
.process-section .step-wrapper:last-child::after, .process-section .step-wrapper:last-child::before {
  display: none;
}
.process-section .step-wrapper:hover .icon-wrapper {
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.process-section .step-wrapper .step-description {
  pointer-events: none;
  position: absolute;
  top: -120%;
  box-shadow: 0 15px 10px -8px rgba(0, 0, 0, 0.2);
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  width: 250px;
  border-radius: 5px;
  z-index: 50;
  background-color: forestgreen;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.process-section .step-wrapper .step-description p {
  color: white;
  font-weight: bold;
  text-align: center;
}
.process-section .step-wrapper .step-description::after {
  position: absolute;
  bottom: -15px;
  content: "";
  left: 50%;
  transform: rotate(45deg) translateX(-50%);
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: forestgreen;
}
@media (max-width: 900px) {
  .process-section {
    margin-top: inherit;
  }
  .process-section p.text-lg {
    max-width: 75%;
  }
  .process-section .process-stepper {
    align-items: center;
    grid-template: auto auto/repeat(3, 2fr);
    grid-row-gap: 80px;
    padding-top: 50px;
    grid-template-areas: "sourcing  sorting grading" "delivering dispatching packaging";
  }
  .process-section .process-stepper .sourcing-step {
    grid-area: sourcing;
  }
  .process-section .process-stepper .sorting-step {
    grid-area: sorting;
  }
  .process-section .process-stepper .grading-step {
    grid-area: grading;
  }
  .process-section .process-stepper .packaging-step {
    grid-area: packaging;
  }
  .process-section .process-stepper .dispatch-step {
    grid-area: dispatching;
  }
  .process-section .process-stepper .delivery-step {
    grid-area: delivering;
  }
  .process-section .step-wrapper {
    padding: 0;
  }
  .process-section .step-wrapper::after {
    top: 35%;
    right: 10%;
  }
  .process-section .step-wrapper::before {
    top: 35%;
    right: -10%;
  }
  .process-section .step-wrapper:nth-child(3)::after, .process-section .step-wrapper:nth-child(3)::before {
    display: none;
  }
  .process-section .step-wrapper:last-child::after, .process-section .step-wrapper:last-child::before {
    display: block;
  }
  .process-section .step-wrapper:nth-child(4)::after {
    top: -45%;
    right: 45%;
  }
  .process-section .step-wrapper:nth-child(4)::before {
    top: -20%;
    right: 45%;
  }
  .process-section .step-wrapper .step-description {
    top: -75%;
    width: 200px;
    padding-bottom: 15px;
  }
  .process-section .step-wrapper .step-description::after {
    position: absolute;
    bottom: -10px;
    z-index: 4;
    transform: rotate(45deg) translateX(-50%);
  }
}
@media (max-width: 447px) {
  .process-section h1.heading-secondary {
    max-width: 90%;
    margin: auto;
  }
  .process-section h1.heading-secondary::after {
    display: none;
  }
  .process-section p.text-lg {
    max-width: 90%;
  }
  .process-section .process-stepper {
    display: block;
  }
  .process-section .step-wrapper {
    margin-bottom: 55px;
  }
  .process-section .step-wrapper::after, .process-section .step-wrapper::before {
    display: none !important;
  }
  .process-section .step-wrapper .icon-wrapper {
    width: 200px;
    height: 200px;
  }
  .process-section .step-wrapper .icon-wrapper img {
    height: 75px;
    width: 75px;
  }
  .process-section .step-wrapper p.text-lg {
    font-size: 3rem;
    margin-top: 20px;
  }
  .process-section .step-wrapper .step-description {
    top: -65%;
    width: 300px;
  }
  .process-section .step-wrapper .step-description p {
    font-size: 16px;
  }
}
.process-section .read-more-link {
  margin-top: 30px;
}

a.read-more-link {
  font-size: 1.5rem;
  display: inline-block;
  margin-top: 30px;
  font-size: 1.5rem;
}
a.read-more-link i {
  color: forestgreen;
  display: inline-block;
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
}
a.read-more-link:hover i {
  margin-left: 10px;
}

.customer-section {
  margin-top: -6rem;
}
@media (min-width: 1610px) {
  .customer-section {
    margin-top: 1rem;
  }
}
.customer-section .wrapper {
  max-width: 80%;
  margin: auto;
  margin-top: 5rem;
  display: grid;
  grid-template: auto/0.5fr 0.5fr;
  align-items: center;
  grid-column-gap: 5%;
}
@media (min-width: 1610px) {
  .customer-section .wrapper {
    width: 70%;
  }
}
@media (max-width: 900px) {
  .customer-section .wrapper {
    max-width: 90%;
  }
}
@media (max-width: 1100px) {
  .customer-section .wrapper {
    max-width: 90%;
  }
}
@media (max-width: 447px) {
  .customer-section .wrapper {
    max-width: 90%;
    grid-template: auto auto/1fr;
    grid-row-gap: 50px;
  }
}
.customer-section .farmer-section {
  margin-bottom: 6rem;
}
.customer-section .farmer-section .wrapper {
  grid-template-areas: "text image";
}
@media (max-width: 447px) {
  .customer-section .farmer-section .wrapper {
    grid-template-areas: "image" "text";
  }
}
.customer-section .buyer-section .wrapper {
  grid-template-areas: "image text";
}
@media (max-width: 447px) {
  .customer-section .buyer-section .wrapper {
    grid-template-areas: "image" "text";
  }
}
.customer-section .reason-wrapper {
  grid-area: text;
}
.customer-section .reason-wrapper .reason-text {
  display: grid;
  grid-template: auto/70px auto;
  align-items: center;
  padding: 15px;
  margin-bottom: 25px;
  max-width: 80%;
  grid-column-gap: 15px;
  transition: box-shadow 0.2s ease-in-out;
  border-radius: 5px;
}
.customer-section .reason-wrapper .reason-text:hover {
  box-shadow: 0 3px 15px -2px rgba(0, 0, 0, 0.2);
}
@media (max-width: 900px) {
  .customer-section .reason-wrapper .reason-text {
    max-width: 90%;
    grid-template: auto/50px auto;
    grid-column-gap: 8px;
  }
}
@media (max-width: 1100px) {
  .customer-section .reason-wrapper .reason-text {
    max-width: 90%;
  }
}
@media (max-width: 447px) {
  .customer-section .reason-wrapper .reason-text {
    max-width: 100%;
  }
}
.customer-section .reason-wrapper .icon-wrapper {
  display: flex;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f7f7f7;
}
.customer-section .reason-wrapper .icon-wrapper img {
  height: 40px;
  width: 40px;
}
@media (max-width: 900px) {
  .customer-section .reason-wrapper .icon-wrapper {
    height: 45px;
    width: 45px;
  }
  .customer-section .reason-wrapper .icon-wrapper img {
    height: 35px;
    width: 35px;
  }
}
.customer-section .reason-wrapper .heading h4 {
  margin-bottom: 10px;
}
@media (max-width: 900px) {
  .customer-section .reason-wrapper .heading h4 {
    margin-bottom: 7px;
  }
}
.customer-section .illustration-wrapper {
  grid-area: image;
}
.customer-section .illustration-wrapper h3 {
  align-self: center;
  max-width: 80%;
}
.customer-section .illustration-wrapper img {
  height: 70%;
  width: 70%;
}
@media (max-width: 447px) {
  .customer-section .illustration-wrapper {
    align-items: center;
  }
  .customer-section .illustration-wrapper h3 {
    font-size: 2.5rem;
    max-width: 100%;
  }
  .customer-section .illustration-wrapper img {
    margin: 0 auto;
  }
}
.customer-section .buyer-section .illustration-wrapper img {
  align-self: flex-start;
}
@media (max-width: 447px) {
  .customer-section .buyer-section .illustration-wrapper img {
    align-self: center;
  }
}
.customer-section .buyer-section .reason-wrapper {
  align-self: end;
}

.testimonial-section {
  margin-top: -4rem;
}
@media (min-width: 1610px) {
  .testimonial-section {
    margin-top: 4rem;
  }
}
@media (max-width: 900px) {
  .testimonial-section {
    margin-top: 0rem;
  }
}
.testimonial-section .testimonial-wrapper {
  margin: auto auto auto auto;
}
@media (min-width: 2000px) {
  .testimonial-section .testimonial-wrapper {
    width: 60%;
    margin: auto auto auto auto;
  }
}
@media (min-width: 1610px) {
  .testimonial-section .testimonial-wrapper {
    width: 75%;
  }
}
.testimonial-section .testimonial-container {
  position: relative;
  width: 90%;
  height: 450px;
  margin: auto;
  margin-top: 4rem;
}
@media (max-width: 447px) {
  .testimonial-section .testimonial-container {
    position: static;
    height: auto;
    min-height: 320px;
    max-height: auto;
  }
}
@media (max-width: 900px) {
  .testimonial-section .testimonial-container {
    height: auto;
    min-height: 300px;
  }
}
.testimonial-section .testimonial-container .first-card,
.testimonial-section .testimonial-container .second-card,
.testimonial-section .testimonial-container .third-card {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  z-index: 5;
}
.testimonial-section .testimonial-container .first-card,
.testimonial-section .testimonial-container .third-card {
  height: 350px;
  top: 25px;
  width: 24%;
  opacity: 0.5;
  padding: 25px 15px 15px 15px;
}
.testimonial-section .testimonial-container .first-card img.quoteIcon,
.testimonial-section .testimonial-container .third-card img.quoteIcon {
  width: 30px;
  height: 30px;
}
.testimonial-section .testimonial-container .first-card .testimonial-text,
.testimonial-section .testimonial-container .third-card .testimonial-text {
  margin-top: 50px;
  max-height: 40%;
  overflow: hidden;
}
.testimonial-section .testimonial-container .first-card .info-wrapper,
.testimonial-section .testimonial-container .third-card .info-wrapper {
  margin-top: 40px;
  display: grid;
  grid-template: 50px/40px auto;
  grid-column-gap: 20px;
}
.testimonial-section .testimonial-container .first-card .info-wrapper img,
.testimonial-section .testimonial-container .third-card .info-wrapper img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
@media (max-width: 1100px) {
  .testimonial-section .testimonial-container .first-card,
  .testimonial-section .testimonial-container .third-card {
    height: auto;
  }
  .testimonial-section .testimonial-container .first-card .testimonial-text,
  .testimonial-section .testimonial-container .third-card .testimonial-text {
    margin-top: 15px;
    max-height: 130px;
    overflow: hidden;
  }
  .testimonial-section .testimonial-container .first-card .info-wrapper,
  .testimonial-section .testimonial-container .third-card .info-wrapper {
    margin-top: 15px;
  }
}
@media (max-width: 667px) {
  .testimonial-section .testimonial-container .first-card,
  .testimonial-section .testimonial-container .third-card {
    display: none;
  }
}
@media (max-width: 900px) {
  .testimonial-section .testimonial-container .first-card,
  .testimonial-section .testimonial-container .third-card {
    height: auto;
  }
  .testimonial-section .testimonial-container .first-card .testimonial-text,
  .testimonial-section .testimonial-container .third-card .testimonial-text {
    max-height: 80px;
    overflow: hidden;
  }
  .testimonial-section .testimonial-container .first-card .info-wrapper,
  .testimonial-section .testimonial-container .third-card .info-wrapper {
    grid-column-gap: 0;
  }
  .testimonial-section .testimonial-container .first-card .info-wrapper img,
  .testimonial-section .testimonial-container .third-card .info-wrapper img {
    height: 20px;
    width: 20px;
  }
  .testimonial-section .testimonial-container .first-card .info-wrapper .name p,
  .testimonial-section .testimonial-container .third-card .info-wrapper .name p {
    font-size: 8px;
  }
}
.testimonial-section .testimonial-container .third-card {
  right: 0;
}
.testimonial-section .testimonial-container .active-card {
  height: 380px;
  width: 60%;
  left: 50%;
  z-index: 6;
  transform: translateX(-50%);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2);
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.testimonial-section .testimonial-container .active-card .quoteIcon {
  height: 50px;
  width: 50px;
  margin-bottom: 2rem;
}
.testimonial-section .testimonial-container .active-card p.testimonial-text {
  font-size: 1.8rem;
}
.testimonial-section .testimonial-container .active-card .info-wrapper {
  display: grid;
  margin-top: 0px;
  grid-template: 50px/65px auto;
  align-items: center;
  grid-column-gap: 3%;
}
.testimonial-section .testimonial-container .active-card img {
  border-radius: 50%;
  height: 65px;
  width: 65px;
}
.testimonial-section .testimonial-container .active-card div.name h5 {
  font-size: 2rem;
}
.testimonial-section .testimonial-container .active-card div.name p {
  font-size: 1.5rem;
}
@media (max-width: 1100px) {
  .testimonial-section .testimonial-container .active-card {
    padding: 20px 30px;
    height: 330px;
  }
  .testimonial-section .testimonial-container .active-card .quoteIcon {
    height: 40px;
    width: 40px;
  }
  .testimonial-section .testimonial-container .active-card .info-wrapper {
    margin-top: 40px;
  }
  .testimonial-section .testimonial-container .active-card .info-wrapper img {
    height: 45px;
    width: 45px;
  }
}
@media (max-width: 667px) {
  .testimonial-section .testimonial-container .active-card {
    width: 95%;
    margin: auto;
    height: auto;
    padding: 15px;
  }
  .testimonial-section .testimonial-container .active-card .quoteIcon {
    height: 30px;
    width: 30px;
    display: inline-block;
    margin-bottom: 15px;
  }
  .testimonial-section .testimonial-container .active-card .info-wrapper {
    margin-top: 0;
  }
  .testimonial-section .testimonial-container .active-card div.name h5 {
    font-size: 1.2rem;
  }
}
@media (max-width: 900px) {
  .testimonial-section .testimonial-container .active-card {
    height: 290px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
}
.testimonial-section .testimonial-btns .wrapper {
  margin: auto;
  width: 100px;
  display: flex;
  justify-content: space-between;
}
.testimonial-section .testimonial-btns .wrapper .arrow-btn {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 40px;
  width: 40px;
  background-color: forestgreen;
  border-radius: 50%;
  box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.3);
  transition: all 0.2s linear;
  font-size: 16px;
  cursor: pointer;
}
.testimonial-section .testimonial-btns .wrapper .arrow-btn:active {
  box-shadow: none;
}
@media (max-width: 447px) {
  .testimonial-section .testimonial-btns .wrapper {
    width: 100px;
  }
}
@media (max-width: 900px) {
  .testimonial-section .background--1 {
    height: 70rem;
    top: 0rem;
  }
}
@media (max-width: 447px) {
  .testimonial-section .background--1 {
    display: none;
  }
}

.animate-card * {
  animation: fadeInCard 0.6s ease-in-out 0s 1 normal forwards;
}

@keyframes fadeInCard {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.news-section {
  overflow: hidden;
  margin-top: -4rem;
}
@media (min-width: 1610px) {
  .news-section {
    margin-top: 4rem;
  }
}
@media (max-width: 900px) {
  .news-section {
    margin-top: 0rem;
  }
}
.news-section .wrapper {
  margin-top: 5rem;
}
.news-section .news-content {
  cursor: pointer;
  width: 60%;
  margin: auto;
  display: grid;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px 35px;
  min-height: 350px;
  grid-template: 90px 50px 70px auto/1fr;
}
.news-section .news-content .logo {
  height: 100%;
  justify-self: center;
}
.news-section .news-content .quote-icon {
  height: 40px;
  width: 40px;
}
.news-section .news-content a.read-more-content {
  color: forestgreen;
  font-weight: bold;
}
@media (min-width: 2000px) {
  .news-section .news-content {
    width: 75%;
  }
}
@media (min-width: 1610px) {
  .news-section .news-content {
    width: 75%;
  }
}
@media (max-width: 1100px) {
  .news-section .news-content {
    grid-template: 90px auto auto auto/1fr;
    width: 75%;
  }
}
@media (max-width: 900px) {
  .news-section .news-content {
    min-height: auto;
    width: 95%;
    margin: auto;
    grid-template: 90px 40px 60px auto/1fr;
    grid-column-gap: 0;
  }
  .news-section .news-content .quote-icon {
    width: 30px;
    height: 30px;
  }
  .news-section .news-content .article-heading {
    font-size: 3rem;
  }
}
@media (max-width: 447px) {
  .news-section .news-content {
    grid-template: 40px auto auto auto/1fr;
    width: 95%;
    padding: 15px;
    min-height: 300px;
    grid-row-gap: 15px;
  }
  .news-section .news-content .quote-icon {
    width: 25px;
    height: 25px;
  }
  .news-section .news-content h3.article-heading {
    font-size: 18px;
  }
  .news-section .news-content p.article-content {
    font-size: 14px;
  }
}
.news-section .animate-in {
  animation: fadeIn 0.4s linear 0s 1 normal forwards;
}
.news-section .logo-container {
  margin-top: 10rem;
  display: grid;
  grid-template: 80px/repeat(5, 1fr);
  grid-column-gap: 5%;
  align-items: center;
  transition: all 0.6s ease-in-out;
}
.news-section .logo-container img {
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.news-section .logo-container .active {
  padding-bottom: 15px;
  border-bottom: 5px solid forestgreen;
}
@media (max-width: 447px) {
  .news-section .logo-container {
    grid-template: 100px/repeat(5, 25%);
    overflow: hide;
    position: relative;
    margin-top: 4rem;
    grid-column-gap: 10%;
  }
}
.news-section .as-seen-on-container {
  margin-top: 10rem;
}
.news-section .as-seen-on-container .wrapper {
  display: grid;
  grid-template: 70px/repeat(5, 1fr);
  justify-content: space-around;
  grid-column-gap: 40px;
  align-items: center;
}
@media (max-width: 447px) {
  .news-section .as-seen-on-container {
    margin-top: 4rem;
  }
  .news-section .as-seen-on-container .wrapper {
    grid-template: 60px 60px/1fr 1fr;
  }
}
@media (max-width: 447px) {
  .news-section .as-seen-on-container .wrapper {
    grid-template: 70px/repeat(5, 25%);
    animation: scrolling 5s linear 0s infinite alternate forwards;
  }
  .news-section .as-seen-on-container .wrapper:hover {
    animation-play-state: paused;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes scrolling {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(-50%);
  }
}
.image-grid {
  background-color: rgba(245, 240, 245, 0.6);
  padding: 100px 0;
  margin-bottom: -9rem;
  margin-top: -6rem;
}
@media (min-width: 1610px) {
  .image-grid {
    margin-top: 4rem;
  }
}
.image-grid .wrapper {
  display: grid;
  max-width: 80%;
  margin: auto;
  grid-gap: 5px;
  grid-template: 190px/repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 290px;
  grid-auto-flow: dense;
}
.image-grid .wrapper img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.image-grid .wrapper .vertical {
  grid-row: span 2;
}
.image-grid .wrapper .landscape {
  grid-column: span 2;
}
.image-grid .wrapper .hero {
  grid-row: span 2;
  grid-column: span 2;
}
@media (max-width: 1100px) {
  .image-grid .wrapper .hero {
    grid-row: span 1;
  }
}
@media (max-width: 900px) {
  .image-grid .wrapper .hero {
    grid-row: span 1;
  }
}
@media (max-width: 447px) {
  .image-grid .wrapper .hero {
    grid-row: span 1;
  }
}
@media (min-width: 1610px) {
  .image-grid .wrapper {
    grid-template: 230px/repeat(auto-fit, minmax(500px, 1fr));
    grid-auto-rows: 290px;
  }
}
@media (min-width: 2000px) {
  .image-grid .wrapper {
    grid-template: 270px/repeat(auto-fit, minmax(500px, 1fr));
    grid-auto-rows: 270px;
  }
}
@media (max-width: 447px) {
  .image-grid .wrapper {
    grid-template: auto/1fr;
    grid-auto-rows: auto;
  }
  .image-grid .wrapper .vertical,
  .image-grid .wrapper .hero {
    grid-row: auto;
  }
  .image-grid .wrapper .hero {
    grid-column: span 1;
  }
}
.image-grid .image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 4000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-grid .image-modal img {
  max-width: 80%;
  height: 80%;
}
@media (max-width: 447px) {
  .image-grid .image-modal img {
    width: 100%;
    height: auto;
  }
}
.image-grid .image-modal .fa {
  position: fixed;
  top: 3%;
  font-size: 45px;
  color: white;
  right: 5%;
  cursor: pointer;
}

@font-face {
  font-family: Aileron;
  src: url(../fonts/Aileron-Heavy.otf);
}
.heading-primary {
  font-size: 4rem;
  font-family: Aileron;
  color: #444444;
}

.heading-secondary {
  font-size: 3.2rem;
  font-family: Aileron;
  color: #444444;
}

.heading-tertiary {
  font-size: 2.6rem;
  font-family: Aileron;
  color: #444444;
}

.heading-quad {
  font-size: 2rem;
  font-family: Aileron;
  color: #444444;
}

.text-lg {
  font-size: 1.8rem;
  color: #888;
  font-weight: 300;
}
@media (max-width: 447px) {
  .text-lg {
    font-size: 2.4rem;
  }
}

.text-md {
  font-size: 1.6rem;
  font-weight: lighter;
  color: #888;
  font-weight: 300;
}
@media (max-width: 447px) {
  .text-md {
    font-size: 2rem;
  }
}
@media (min-width: 2000px) {
  .text-md {
    font-size: 1.8rem;
  }
}

.text-sm {
  font-size: 1.6rem;
  color: #888;
  font-weight: 300;
}

.text-xs {
  font-size: 1.2rem;
  color: #888;
  font-weight: 300;
}

.heading-dark {
  color: #444;
  font-weight: 700;
  font-family: Aileron;
}
.heading-dark > p {
  display: inline-block;
}

.text-dark {
  color: #444;
  font-weight: 500;
}

.container {
  margin: 8.9rem auto 0 auto;
  min-height: 100vh;
}

.section-container {
  padding: 2rem;
  margin: 7rem 0;
}
@media (max-width: 900px) {
  .section-container {
    padding: 3rem 2rem 2rem 2rem;
    margin: 3rem 0;
  }
}
@media (max-width: 447px) {
  .section-container {
    padding: 2rem 1rem 1rem 1rem;
    margin: 3rem 0;
  }
}

@media (min-width: 1610px) {
  .homepage .section-container {
    padding: 2rem 1rem 1rem 1rem;
    margin: 3rem 0;
  }
}

.divider--sm {
  margin: 10px 0;
}

.divider--md {
  margin: 20px 0;
}
@media (max-width: 447px) {
  .divider--md {
    margin: 15px 0;
  }
}

.divider--lg {
  margin: 30px 0;
}
@media (max-width: 447px) {
  .divider--lg {
    margin: 20px 0;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-italic {
  font-style: italic;
}

.padding-15 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.padding-20 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.padding-25 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.has-border {
  border: 1px solid black;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.center {
  display: block;
  margin: 0 auto;
}

@media (max-width: 1600px) {
  .hide-on-desktop {
    display: none;
  }
}

@media (max-width: 1100px) {
  .hide-on-laptop {
    display: none;
  }
}

@media (max-width: 900px) {
  .hide-on-tablet {
    display: none;
  }
}

@media (max-width: 667px) {
  .hide-on-phablet {
    display: none;
  }
}

@media (max-width: 447px) {
  .hide-on-mobile {
    display: none;
  }
}

.dropdown-card-list {
  display: block;
  width: 200px;
  position: absolute;
  background: rgb(247, 245, 245);
  top: 45px;
  right: 0;
  z-index: 10;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.dropdown-card-list li {
  display: block;
  padding: 8px 12px;
  font-size: 1.6rem;
  color: black;
  user-select: none;
  -webkit-user-select: none;
  text-align: left;
}
.dropdown-card-list li:first-child {
  border-bottom: 1px solid #ccc;
}
.dropdown-card-list li:hover {
  background: #836100;
  color: #ffffff;
  border-bottom: 1px solid #836100;
}
.dropdown-card-list li a:hover {
  color: white;
}
.dropdown-card-list::after {
  content: "";
  position: absolute;
  top: -20px;
  right: 20px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent rgb(247, 245, 245) transparent;
}
.dropdown-card-list .dropdown-card-list--custom {
  width: 100%;
}

.kn-divider::after {
  content: "";
  clear: both;
  display: table;
}

.important-link {
  color: #333;
}

.careers-hiring {
  color: rgb(56, 142, 60);
  font-weight: 500;
  background-color: rgb(220, 240, 220);
  padding: 4px 8px;
  border-radius: 4px;
  border: none !important;
}
.careers-hiring:hover {
  color: #444;
}
.loader {
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader img {
  width: 50px;
  height: 50px;
}
.loader h1 {
  margin-top: 15px;
  font-size: 2rem;
}
@media (min-width: 900px) {
  .loader img {
    height: auto;
    width: auto;
  }
}

.main-container--heading {
  margin: 0 auto;
  width: 65rem;
  text-align: center;
  font-weight: bold;
}
@media (max-width: 1100px) {
  .main-container--heading {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .main-container--heading {
    width: 100%;
  }
}
@media (max-width: 667px) {
  .main-container--heading {
    width: 100%;
  }
}
@media (max-width: 447px) {
  .main-container--heading {
    width: 100%;
  }
}
.main-container--text {
  margin: 3rem auto 0 auto;
  width: 100rem;
  text-align: center;
  line-height: 2.8rem;
}
@media (max-width: 900px) {
  .main-container--text {
    width: 100%;
  }
}
@media (max-width: 447px) {
  .main-container--text {
    width: 100%;
  }
}
@media (max-width: 447px) {
  .main-container--heading {
    margin-top: 2rem;
  }
}

.vision-section {
  padding: 0 5rem;
}
@media (max-width: 900px) {
  .vision-section {
    padding: 0 7rem;
    text-align: left;
  }
}
@media (max-width: 667px) {
  .vision-section {
    padding: 0 5rem;
    text-align: left;
  }
}
@media (max-width: 447px) {
  .vision-section {
    padding: 0;
    text-align: left;
  }
}

@media (max-width: 900px) {
  .main-heading {
    padding: 0 7rem;
    text-align: left;
  }
}
@media (max-width: 667px) {
  .main-heading {
    padding: 0;
    text-align: left;
  }
}
@media (max-width: 447px) {
  .main-heading {
    padding: 0;
    text-align: left;
  }
}

@media (max-width: 900px) {
  .main-text {
    padding: 0 7rem;
    text-align: left;
  }
}
@media (max-width: 667px) {
  .main-text {
    padding: 0;
    text-align: left;
  }
}
@media (max-width: 447px) {
  .main-text {
    padding: 0;
    text-align: left;
  }
}

.numbers-container .numbers--img-1 {
  z-index: 10;
  width: 100vw;
  margin-top: -22rem;
}
.numbers-container .numbers--img-2 {
  z-index: 10;
  width: 200%;
  height: 200%;
  margin-top: -20rem;
}
@media (max-width: 900px) {
  .numbers-container .numbers--img-1 {
    z-index: 10;
    width: 200%;
    height: 200%;
    margin-top: 0rem;
  }
  .numbers-container .numbers--img-2 {
    z-index: 10;
    width: 200%;
    height: 200%;
    margin-top: -15rem;
  }
}
@media (max-width: 447px) {
  .numbers-container .numbers--img-1 {
    z-index: 10;
    width: 200%;
    height: 200%;
    margin-top: 0rem;
  }
  .numbers-container .numbers--img-2 {
    z-index: 10;
    width: 200%;
    height: 200%;
    margin-top: -10rem;
  }
}
.numbers-container .numbers-wrapper {
  display: flex;
  margin-top: 5rem;
  justify-content: space-around;
  color: rgb(159, 188, 89);
}
.numbers-container .numbers-wrapper--item {
  text-align: center;
}
.numbers-container .numbers-wrapper--item > h1 {
  font-size: 7rem;
}
@media (max-width: 1100px) {
  .numbers-container .numbers-wrapper--item > h1 {
    font-size: 5rem;
  }
}
@media (max-width: 900px) {
  .numbers-container .numbers-wrapper--item > h1 {
    font-size: 5rem;
  }
}
@media (max-width: 667px) {
  .numbers-container .numbers-wrapper--item > h1 {
    font-size: 4rem;
  }
}
@media (max-width: 447px) {
  .numbers-container .numbers-wrapper--item > h1 {
    font-size: 7rem;
  }
}
.numbers-container .numbers-wrapper--item > p {
  font-size: 2rem;
}
@media (max-width: 447px) {
  .numbers-container .numbers-wrapper--item {
    margin-bottom: 3rem;
  }
}
@media (max-width: 447px) {
  .numbers-container .numbers-wrapper {
    flex-direction: column;
  }
}

.numbers-container--img-2 {
  z-index: 5;
  margin-top: -10rem;
  width: 200%;
  transform: scale(2);
}

.farmer {
  padding: 0 6rem 6rem 6rem;
  display: flex;
  align-items: center;
}
@media (max-width: 900px) {
  .farmer {
    padding: 4rem;
  }
}
@media (max-width: 667px) {
  .farmer {
    flex-direction: column;
    padding: 4rem 0 4rem 0;
  }
}
@media (max-width: 447px) {
  .farmer {
    flex-direction: column;
    padding: 0 0 4rem 0;
  }
}
.farmer-info {
  text-align: left;
  width: 50%;
  padding-right: 6rem;
  float: left;
}
.farmer-info--text {
  margin-top: 1rem;
  line-height: 2.4rem;
}
@media (max-width: 900px) {
  .farmer-info {
    width: 100%;
    clear: both;
    text-align: left;
  }
}
@media (max-width: 447px) {
  .farmer-info {
    width: 100%;
    padding-right: 0;
    clear: both;
    text-align: left;
  }
}
.farmer-images {
  width: 50%;
  float: right;
  position: relative;
  padding-left: 6rem;
}
@media (max-width: 900px) {
  .farmer-images {
    width: 100%;
    clear: both;
    display: flex;
    padding-left: 0;
    margin-top: 3rem;
  }
}
@media (max-width: 447px) {
  .farmer-images {
    width: 100%;
    clear: both;
    display: flex;
    padding-left: 0;
  }
}
.farmer-img {
  height: 55rem;
  width: 60rem;
  background: url(../images/FarmerAppUse2.jpg);
  background-position: center;
  background-size: cover;
  margin-right: -20rem;
}
@media (max-width: 900px) {
  .farmer-img {
    height: 25rem;
    width: 40rem;
    margin-right: 0;
  }
}
@media (max-width: 667px) {
  .farmer-img {
    height: 35rem;
    width: 100%;
    margin: 2rem 0 0 0;
  }
}
@media (max-width: 447px) {
  .farmer-img {
    height: 25rem;
    width: 100%;
    margin: 2rem 0 0 0;
  }
}
.farmer-img-1 {
  height: 46rem;
  margin-left: auto;
  margin-right: -20rem;
}

.buyer {
  padding: 6rem 6rem 0 6rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media (max-width: 900px) {
  .buyer {
    padding: 4rem;
  }
}
@media (max-width: 667px) {
  .buyer {
    flex-direction: column;
    padding: 0;
  }
}
@media (max-width: 447px) {
  .buyer {
    flex-direction: column;
    padding: 0;
    margin-bottom: 3rem;
  }
}
.buyer-info {
  width: 50%;
  padding-left: 6rem;
  float: right;
}
.buyer-info--text {
  margin-top: 1rem;
  line-height: 2.4rem;
}
@media (max-width: 900px) {
  .buyer-info {
    float: left;
    width: 100%;
    clear: both;
  }
}
@media (max-width: 667px) {
  .buyer-info {
    padding-left: 0;
  }
}
@media (max-width: 447px) {
  .buyer-info {
    padding-left: 0;
  }
}
.buyer-images {
  width: 50%;
  float: left;
  position: relative;
  padding-right: 6rem;
}
@media (max-width: 900px) {
  .buyer-images {
    width: 100%;
    clear: both;
    display: flex;
    padding-right: 0;
    margin-top: 3rem;
  }
}
@media (max-width: 447px) {
  .buyer-images {
    width: 100%;
    clear: both;
    display: flex;
    padding-right: 0;
  }
}
.buyer-img {
  transition: all 200ms;
  height: 55rem;
  width: 60rem;
  background: url(../images/buyer_7.jpeg);
  background-position: center;
  background-size: cover;
  margin-left: -12rem;
}
@media (max-width: 900px) {
  .buyer-img {
    height: 25rem;
    width: 40rem;
    margin-left: 0;
  }
}
@media (max-width: 667px) {
  .buyer-img {
    height: 35rem;
    width: 100%;
    margin: 2rem 0 0 0;
  }
}
@media (max-width: 447px) {
  .buyer-img {
    height: 25rem;
    width: 100%;
    margin: 2rem 0 0 0;
  }
}

.investors {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 447px) {
  .investors {
    flex-direction: column;
  }
}
.investors-logo {
  width: 16rem;
  height: 16rem;
  filter: grayscale(2);
  transition: 200ms;
}
.investors-logo:hover {
  filter: none;
}
.investors-card {
  width: 24rem;
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  -moz-box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  -o-box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  -ms-box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  overflow: hidden;
  transition: all 150ms;
  cursor: pointer;
}
@media (max-width: 667px) {
  .investors-card {
    width: 20rem;
  }
}
@media (max-width: 447px) {
  .investors-card {
    margin: 2rem auto;
  }
}
.investors-card-logo {
  width: 24rem;
  height: 22rem;
  transition: 200ms;
}
.investors-card-logo:hover {
  filter: none;
}
.investors-card-name {
  padding: 1rem 2rem;
}
.investors-card:hover {
  -webkit-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  -moz-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  -o-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  -ms-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
}
.investors-link {
  transition: all 200ms;
  color: #008bed;
}
.investors-link:hover {
  color: #003bff;
}

.media .slick-track {
  display: flex;
}
.media .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}
.media-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 667px) {
  .media-container {
    flex-direction: column;
  }
}
@media (max-width: 447px) {
  .media-container {
    flex-direction: column;
  }
}
.media-container-others-up {
  margin-top: 7rem;
  animation: showMoreMedia 400ms ease-in;
}
.media-container-others-down {
  margin-top: 7rem;
  animation: hideMoreMedia 200ms ease-in;
}
.media-container-showMore {
  margin-top: 7rem;
}
.media-container-showMore-enter {
  margin-top: 7rem;
  opacity: 0.01;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.media-container-showMore-enter-active {
  margin-top: 7rem;
  opacity: 1;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
  transition: all 300ms ease-out;
}
.media-container-showMore-exit {
  margin-top: 7rem;
  opacity: 1;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}
.media-container-showMore-exit-active {
  margin-top: 7rem;
  opacity: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 300ms ease-out;
}
.media-logo {
  width: 16rem;
  margin: 0 auto;
}
@media (max-width: 900px) {
  .media-logo {
    width: 14rem;
  }
}
@media (max-width: 667px) {
  .media-logo {
    width: 22rem;
    margin: 2rem auto;
  }
}
@media (max-width: 447px) {
  .media-logo {
    margin: 2rem auto;
    width: 18rem;
  }
}
.media-more-btn {
  transition: all 200ms;
  color: rgb(56, 142, 60);
  background-color: transparent;
  border: none;
}
.media-more-btn:hover {
  color: rgb(159, 188, 89);
}

.more-media {
  margin-top: 7rem;
}

.arrow-more {
  margin-left: 0.5rem;
}

.background--2 {
  border-radius: 4px;
  height: 130rem;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: 5rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
  -webkit-clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
  -moz-clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
  background-color: rgba(245, 240, 245, 0.6);
}
@media (max-width: 900px) {
  .background--2 {
    height: 88rem;
    margin-top: -7rem;
    clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
    -webkit-clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
    -moz-clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
  }
}
@media (max-width: 667px) {
  .background--2 {
    height: 130rem;
    margin-top: -5rem;
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
    -webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
    -moz-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
  }
}
@media (max-width: 447px) {
  .background--2 {
    height: 115rem;
    margin-top: -5rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

.background--1 {
  border-radius: 4px;
  height: 75rem;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -15rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
  -webkit-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
  -moz-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
  background-color: rgba(245, 240, 245, 0.6);
}
@media (max-width: 900px) {
  .background--1 {
    height: 55rem;
    margin-top: -4rem;
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
    -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
    -moz-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
  }
}
@media (max-width: 667px) {
  .background--1 {
    height: 50rem;
    margin-top: -3rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@media (max-width: 447px) {
  .background--1 {
    height: 120rem;
    margin-top: 0rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

.hiring-banner {
  background: #7f6127;
  padding: 12rem 0;
  font-family: Roboto;
}
.hiring-banner > h1 {
  font-family: serif;
  font-size: 4.5rem;
}
.hiring-banner .careers-text {
  padding: 0 45rem;
  color: #f7f1e5;
  font-weight: lighter;
}
@media (max-width: 1600px) {
  .hiring-banner .careers-text {
    padding: 0 15rem;
  }
}
@media (max-width: 447px) {
  .hiring-banner .careers-text {
    padding: 0 5rem;
  }
}
.hiring-banner button {
  margin: 2rem auto 0 auto;
  border: none;
  background-color: transparent;
}

.heading-special {
  display: inline-block;
  position: relative;
}
.heading-special::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 6rem;
  height: 0.4rem;
  color: #ffffff;
  background-color: rgb(56, 142, 60);
  right: 0;
  bottom: 0;
  z-index: -10;
}

.culture-img {
  height: 30rem;
}
.culture-img-container {
  padding: 1rem;
}
.culture-img-1 {
  background: url(../images/oc_1.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.culture-img-2 {
  background: url(../images/oc_2.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.culture-img-3 {
  background: url(../images/FarmerMeet.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.culture-img-4 {
  background: url(../images/carousel1.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.culture-img-5 {
  background: url(../images/oc_3.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.culture-img-6 {
  background: url(../images/oc_6.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.core-values-box {
  background: rgb(56, 142, 60);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  height: 50rem;
  padding: 0 50rem;
  text-align: center;
}
.core-values-box h1 {
  font-size: 10rem;
  font-family: serif;
  font-weight: 300;
  letter-spacing: 0.1rem;
}
.core-values-box p {
  margin: 2rem 0 0 0;
  font-size: 1.8rem;
}
.core-values-box .carousel-btn {
  position: absolute;
  cursor: pointer;
  top: 50%;
  font-size: 2.4rem;
}
.core-values-box .carousel-btn--left {
  left: 2rem;
}
.core-values-box .carousel-btn--right {
  right: 2rem;
}
@media (max-width: 1600px) {
  .core-values-box {
    height: 35rem;
    padding: 0 30rem;
  }
  .core-values-box h1 {
    font-size: 8rem;
  }
}
@media (max-width: 667px) {
  .core-values-box {
    padding: 0 20rem;
  }
  .core-values-box h1 {
    font-size: 6rem;
  }
}
@media (max-width: 447px) {
  .core-values-box {
    padding: 0 5rem;
  }
  .core-values-box h1 {
    font-size: 4rem;
  }
}

.core-value-list {
  display: table;
  margin: 0 auto 0 auto;
}
@media (max-width: 447px) {
  .core-value-list {
    display: none;
  }
}
.core-value-list li {
  display: table-cell;
  font-size: 1.5rem;
  padding: 0.4rem 1rem;
  cursor: pointer;
  color: #888;
}
.core-value-list li.active {
  border-bottom: 2px solid rgb(56, 142, 60);
  font-weight: 700;
}

.animate-on-enter {
  opacity: 0;
  animation: animate-on-enter 3000ms ease-out forwards;
}

@keyframes animate-on-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.core-values--animation-enter {
  margin-top: 7rem;
  opacity: 0.01;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.core-values--animation-enter-active {
  margin-top: 7rem;
  opacity: 1;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
  transition: all 300ms ease-out;
}
.core-values--animation-exit {
  margin-top: 7rem;
  opacity: 1;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}
.core-values--animation-exit-active {
  margin-top: 7rem;
  opacity: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 300ms ease-out;
}

.mapouter {
  text-align: right;
  height: 20rem;
  width: 40rem;
}
@media (max-width: 447px) {
  .mapouter {
    margin: 1rem 0;
  }
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 20rem;
  width: 40rem;
}

.careers-footer > li {
  width: 20rem;
}
@media (max-width: 447px) {
  .careers-footer > li {
    width: 40rem;
  }
}
.careers-footer > li:nth-child(1)::after {
  content: "New";
  display: inline-block;
  background-color: #836100;
  color: #ffffff;
  padding: 2px 8px;
  margin-left: 1rem;
  border-radius: 4px;
}

@media (max-width: 900px) {
  .heading-container--heading {
    text-align: center;
  }
}
@media (max-width: 667px) {
  .heading-container--heading {
    padding: 4rem 4rem 0 4rem;
  }
}
@media (max-width: 447px) {
  .heading-container--heading {
    text-align: left;
    padding: 0;
  }
}
.heading-container--text {
  text-align: left;
  padding: 4rem 4rem 0 4rem;
}
@media (max-width: 900px) {
  .heading-container--text {
    padding: 4rem 4rem 0 4rem;
  }
}
@media (max-width: 667px) {
  .heading-container--text {
    padding: 3rem 0 0 0;
  }
}
@media (max-width: 447px) {
  .heading-container--text {
    padding: 2rem 0 0 0;
  }
}

.department-container {
  padding: 4rem;
}
.department-card {
  min-height: 25rem;
  text-align: center;
  background-color: #ffffff;
  padding: 4rem;
  border-radius: 1rem;
  -webkit-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  -moz-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  -o-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  -ms-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  transition: 200ms;
  cursor: pointer;
  border-top-style: solid;
  border-top-width: 3px;
}
.department-card:hover {
  -webkit-box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  -moz-box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  -o-box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  -ms-box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  transform: translateY(-2px);
}
.department-card:hover .department-icon {
  animation: icon-animation 1400ms ease-in infinite forwards;
}
.department-card > p {
  margin-top: 2rem;
}
.department-icon {
  font-size: 2.4rem;
  margin-top: 1rem;
  transition: 300ms;
  color: #444444;
}
.department-engineering {
  border-top-color: orangered;
}
.department-operations {
  border-top-color: forestgreen;
}
.department-sales {
  border-top-color: yellowgreen;
}
.department-misc {
  border-top-color: midnightblue;
}

.background--3 {
  border-radius: 4px;
  height: 100rem;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -18rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
  -webkit-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
  -moz-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
  background-color: rgba(245, 240, 245, 0.6);
}
@media (max-width: 900px) {
  .background--3 {
    height: 150rem;
    margin-top: -10rem;
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
    -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
    -moz-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
  }
}
@media (max-width: 667px) {
  .background--3 {
    height: 150rem;
    margin-top: -8rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@media (max-width: 447px) {
  .background--3 {
    height: 300rem;
    margin-top: -8rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

.background--4 {
  border-radius: 4px;
  height: 85rem;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -15rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);
  -webkit-clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);
  -moz-clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);
  background-color: rgba(245, 240, 245, 0.6);
}
@media (max-width: 900px) {
  .background--4 {
    height: 60rem;
    margin-top: -3rem;
    clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
    -webkit-clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
    -moz-clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
  }
}
@media (max-width: 667px) {
  .background--4 {
    height: 60rem;
    margin-top: -5rem;
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
    -webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
    -moz-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
  }
}
@media (max-width: 447px) {
  .background--4 {
    height: 85rem;
    margin-top: -5rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

@keyframes icon-animation {
  0% {
    transform: translateX(-10px);
    opacity: 1;
  }
  80% {
    transform: translateX(10px);
    opacity: 0;
  }
  100% {
    transform: translateX(10px);
    opacity: 0;
  }
}
.icon-container {
  padding: 2rem 4rem;
}

.tech-icon {
  width: 6rem;
  height: 6rem;
  transition: 150ms;
  cursor: pointer;
  align-self: center;
}
.tech-icon-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 3rem;
}
.tech-icon:hover {
  filter: grayscale(10);
}
.tech-icon-name {
  margin-top: 1rem;
}

.jobs {
  list-style: none;
}
.jobs > li {
  background-color: #ffffff;
  padding: 2.4rem 4rem;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
}
@media (max-width: 447px) {
  .jobs > li {
    padding: 2.4rem 2rem;
    width: 95%;
  }
}
.jobs > li > span {
  display: flex;
  flex-direction: column;
}
.jobs > li > a {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: bold;
  border-radius: 2px;
  padding: 0.5rem 2rem;
  color: #ffffff;
  background-color: rgb(56, 142, 60);
  border: none;
  text-transform: uppercase;
  transition: all 200ms;
}
.jobs > li > a:hover {
  transform: translateY(-1px);
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.2);
}

.screenshot {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 667px) {
  .screenshot {
    flex-direction: column;
  }
}
@media (max-width: 447px) {
  .screenshot {
    flex-direction: column;
  }
}
.screenshot-text-container {
  height: 100%;
  padding: 8rem 8rem 0 0;
}
@media (max-width: 900px) {
  .screenshot-text-container {
    padding: 0 8rem;
  }
}
@media (max-width: 667px) {
  .screenshot-text-container {
    padding: 0 3rem;
  }
}
@media (max-width: 447px) {
  .screenshot-text-container {
    padding: 0 1rem;
  }
}
.screenshot-img-container {
  position: relative;
  max-width: 100%;
}
.screenshot-img-browser {
  width: 75rem;
  height: 50rem;
  background: url(../images/laptop.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
}
@media (max-width: 900px) {
  .screenshot-img-browser {
    width: 60rem;
    height: 50rem;
  }
}
@media (max-width: 667px) {
  .screenshot-img-browser {
    width: 55rem;
    height: 40rem;
  }
}
@media (max-width: 447px) {
  .screenshot-img-browser {
    width: 40rem;
    height: 30rem;
  }
}
.screenshot-img-phone {
  position: absolute;
  bottom: 2rem;
  left: -4rem;
  width: 20rem;
  height: 30rem;
  background: url(../images/android.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 900px) {
  .screenshot-img-phone {
    left: 5%;
  }
}
@media (max-width: 667px) {
  .screenshot-img-phone {
    width: 20rem;
    height: 25rem;
  }
}
@media (max-width: 447px) {
  .screenshot-img-phone {
    width: 10rem;
    height: 16rem;
  }
}

.hire-steps {
  width: 75%;
  margin: 0 auto;
  padding: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 447px) {
  .hire-steps {
    width: 100%;
  }
}
.hire-steps .step {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.hire-steps .step-circle {
  background-color: rgb(56, 142, 60);
  height: 6rem;
  width: 6rem;
  padding: 1rem 2rem;
  border-radius: 50%;
  font-size: 3.2rem;
  text-align: center;
  font-weight: bolder;
  color: #eee;
}
.hire-steps .step-name {
  display: inline-block;
  background-color: rgb(56, 142, 60);
  margin-top: 2rem;
  border-radius: 4px;
  color: #eee;
  padding: 1rem;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: bolder;
  text-align: center;
  vertical-align: middle;
}
.hire-steps .step-name::after {
  content: "";
  position: absolute;
  background-color: rgb(56, 142, 60);
  width: 1.5rem;
  height: 1.5rem;
  top: -10%;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  border-radius: 4px;
}
.hire-steps > .step-join {
  display: block;
  border-top: 4px dotted #999;
  width: 20rem;
}

@media (max-width: 667px) {
  .values {
    flex-direction: column;
  }
}
@media (max-width: 447px) {
  .values {
    flex-direction: column;
  }
}
.values-text {
  margin-top: 1rem;
}
.values-rev {
  flex-direction: row-reverse;
  margin-top: 8rem;
}
@media (max-width: 667px) {
  .values-rev {
    flex-direction: column;
    margin-top: 6rem;
  }
}
@media (max-width: 447px) {
  .values-rev {
    flex-direction: column;
    margin-top: 3rem;
  }
}
.values-text-container {
  padding: 4rem;
}
@media (max-width: 667px) {
  .values-text-container {
    padding: 3rem;
  }
}
@media (max-width: 447px) {
  .values-text-container {
    padding: 2rem 1rem;
  }
}
.values-img-container {
  padding: 2rem 4rem;
}
@media (max-width: 667px) {
  .values-img-container {
    padding: 2rem 2rem;
  }
}
@media (max-width: 447px) {
  .values-img-container {
    padding: 2rem 0;
  }
}
.values-img {
  height: 25rem;
}
@media (max-width: 447px) {
  .values-img {
    height: 30rem;
  }
}

.features {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}
@media (max-width: 667px) {
  .features {
    padding: 5rem 0;
  }
}
@media (max-width: 447px) {
  .features {
    padding: 0;
  }
}
.features-button {
  height: 5rem;
  width: 10rem;
  font-size: 2rem;
  border: none;
  box-shadow: 2px 2px 40px 10px rgba(230, 230, 230, 0.6);
  border-radius: 50%;
  transition: 200ms;
  color: #aaa;
}
.features-button:hover {
  box-shadow: 2px 2px 40px 10px rgb(220, 220, 220);
  color: #333;
}
.features-card {
  align-items: center;
  margin: 3rem 2rem 0 2rem;
  transition: 200ms;
  padding: 2rem;
  cursor: pointer;
  perspective: 10rem;
  border-radius: 10px;
  border: 1px solid transparent;
}
@media (max-width: 900px) {
  .features-card {
    flex-direction: column;
    height: auto;
  }
}
@media (max-width: 667px) {
  .features-card {
    flex-direction: column;
    margin: 0;
    padding: 2rem;
  }
}
@media (max-width: 447px) {
  .features-card {
    flex-direction: column;
    margin: 0;
    padding: 1rem;
  }
}
.features-card-details {
  padding: 6rem 4rem;
}
@media (max-width: 900px) {
  .features-card-details {
    padding: 3rem 10rem;
  }
}
@media (max-width: 667px) {
  .features-card-details {
    padding: 1rem 6rem;
  }
}
@media (max-width: 447px) {
  .features-card-details {
    padding: 0 15px;
  }
}
.features-card-image-container {
  position: relative;
}
@media (max-width: 900px) {
  .features-card-image-container {
    height: auto;
    padding: 3rem 10rem;
  }
}
@media (max-width: 667px) {
  .features-card-image-container {
    padding: 3rem 6rem;
    height: auto;
  }
}
@media (max-width: 447px) {
  .features-card-image-container {
    padding: 2rem 15px;
  }
}
.features-card-image {
  position: absolute;
  top: 4rem;
  left: 0;
  width: 60rem;
  height: 60rem;
}
@media (max-width: 900px) {
  .features-card-image {
    width: 60rem;
    height: 50rem;
  }
}
@media (max-width: 667px) {
  .features-card-image {
    width: 40rem;
    height: 40rem;
  }
}
@media (max-width: 447px) {
  .features-card-image {
    width: 30rem;
    height: 40rem;
  }
}
.features-card-image-1 {
  background: url(../images/androidOffline.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.features-card-image-2 {
  background: url(../images/androidPaper.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.features-card-image-3 {
  top: 0;
  left: -5rem;
  background: url(../images/laptopIP.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.features-card-special-img {
  display: none;
  margin: 1rem auto 0 auto;
  background: url(../images/laptopIP.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 667px) {
  .features-card-special-img {
    display: block;
    height: 30rem;
    width: 65rem;
  }
}
@media (max-width: 447px) {
  .features-card-special-img {
    display: block;
    height: 25rem;
    width: 45rem;
  }
}
.features-card-text {
  margin-top: 1rem;
}
.features-card:hover {
  box-shadow: 2px 2px 40px 10px rgba(230, 240, 230, 0.6);
}
@media (max-width: 667px) {
  .features-card:hover {
    box-shadow: none;
  }
}
@media (max-width: 447px) {
  .features-card:hover {
    box-shadow: none;
  }
}
.features-card-active {
  background-color: rgba(220, 240, 220, 0.4);
  border: 1px solid rgb(220, 240, 220);
}
@media (max-width: 667px) {
  .features-card-active {
    background-color: transparent;
    border: 1px solid transparent;
  }
}
@media (max-width: 447px) {
  .features-card-active {
    background-color: transparent;
    border: 1px solid transparent;
  }
}
.features-card-image-active {
  animation: featureIn 600ms ease-in-out 1 forwards;
}
.features-list {
  list-style: none;
  display: flex;
  font-size: 1.8rem;
  justify-content: center;
  width: 70%;
  margin: 4rem auto 0 auto;
}
@media (max-width: 667px) {
  .features-list {
    width: 90%;
    margin: 3rem auto 0 auto;
  }
}
@media (max-width: 447px) {
  .features-list {
    width: 90%;
    margin: 3rem auto 0 auto;
  }
}
.features-list li {
  margin: 0 3rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
@media (max-width: 667px) {
  .features-list li {
    margin: 0 2rem;
  }
}
@media (max-width: 447px) {
  .features-list li {
    margin: 0 1rem;
  }
}
.features-list li:hover {
  color: forestgreen;
}
.features-list li.features-list-item-active {
  border-bottom: 2px solid forestgreen;
}

@keyframes featureIn {
  0% {
    transform: translate(10rem);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
.background--5 {
  border-radius: 4px;
  height: 95rem;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -10rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 85%);
  -webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 85%);
  -moz-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 85%);
  background-color: rgba(245, 240, 245, 0.6);
}
@media (max-width: 900px) {
  .background--5 {
    height: 80rem;
    margin-top: -5rem;
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
    -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
    -moz-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
  }
}
@media (max-width: 667px) {
  .background--5 {
    height: 90rem;
    margin-top: -5rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@media (max-width: 447px) {
  .background--5 {
    height: 100rem;
    margin-top: -2rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

.job {
  padding: 8rem 0 0 0;
}
.job .back-button {
  font-size: 1.6rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
  margin-bottom: 2rem;
  padding: 1rem 1rem 0 1rem;
}
.job .back-button > i {
  margin-left: -5px;
  margin-right: 5px;
  transition: 200ms;
}
.job .back-button:hover > i {
  margin-left: -10px;
  margin-right: 10px;
}
.job .back-button:hover {
  border-bottom: 1px solid #ccc;
  color: #444;
}
.job-title {
  position: sticky;
  background-color: #ffffff;
  margin-top: 2rem;
  z-index: 10;
}
.job-description {
  padding: 2rem 4rem 4rem 4rem;
}
@media (max-width: 667px) {
  .job-description {
    padding: 4rem;
  }
}
@media (max-width: 447px) {
  .job-description {
    padding: 2rem;
  }
}
.job-form {
  padding: 4rem;
  background-color: rgba(242, 245, 242, 0.6);
  border-radius: 5px;
}
@media (max-width: 667px) {
  .job-form {
    padding: 4rem;
  }
}
@media (max-width: 447px) {
  .job-form {
    margin: 2rem;
  }
}
.job-input {
  margin: 3rem 0 0 0;
  font-size: 1.6rem;
  padding: 1rem 1.5rem;
  border: none;
  outline: none;
  border-radius: 4px;
  width: 100%;
  border: 1px solid transparent;
  background-color: #ffffff;
}
.job-input:placeholder-shown > label {
  opacity: 0;
}
.job-input::placeholder {
  color: #aaa;
}
.job-input:focus > label {
  opacity: 0;
}
@media (max-width: 447px) {
  .job-input {
    font-size: 2.6rem;
  }
}
.job-input.error {
  border: 1px solid crimson;
}
.job .resume {
  display: none;
}
.job .resume-label {
  color: #aaa;
  font-weight: 100;
  cursor: pointer;
}
.job .filename {
  float: left;
  font-weight: 600;
  color: #666;
}
.job textarea.job-input {
  resize: vertical;
}
.job-section {
  padding: 2rem;
  background-color: rgba(242, 245, 242, 0.6);
  border-radius: 5px;
  margin-bottom: 2rem;
}
.job-section ul {
  margin-top: 2rem;
  padding: 0 2rem;
}

.select-city {
  -webkit-appearance: none;
  color: #333;
  font-weight: 300;
  background-color: #ffffff;
}

.submit-application-btn {
  padding: 1rem 3rem;
  margin-top: 2rem;
  background-color: forestgreen;
  color: #ffffff;
  font-size: 2rem;
  text-transform: uppercase;
  float: right;
  transition: 200ms;
  border: none;
  outline: none;
}
.submit-application-btn:hover {
  border-radius: 4px;
  transform: translateY(-2px);
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.2);
}

.mandatory::after {
  content: "*";
  display: inline;
  color: crimson;
  position: absolute;
  right: -10px;
  top: 2.4rem;
  font-size: 2rem;
}

.error-label {
  font-size: 1.2rem;
  color: crimson;
  font-style: italic;
  padding: 2rem 0 0 1rem;
}

.spin {
  -webkit-animation-name: spinner;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.stop-scrolling {
  overflow: hidden;
}

.alert-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(240, 240, 240, 0.8);
  z-index: 1000;
}
.alert-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8rem 16rem;
  background-color: rgba(220, 240, 220, 0.2);
  border-radius: 1rem;
  z-index: 100000;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  animation: modalIn 800ms ease-in-out 1 forwards;
}
.alert-icon {
  font-size: 6rem;
  color: forestgreen;
  text-align: center;
}
.alert-close {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 3rem;
  background-color: transparent;
  border: none;
  transition: 150ms;
}
.alert-close:hover {
  transform: translateY(-1px);
  color: crimson;
}

@keyframes modalIn {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
.offer-text {
  padding: 4rem 15rem 0 15rem;
}
@media (max-width: 900px) {
  .offer-text {
    padding: 4rem 10rem 0 10rem;
  }
}
@media (max-width: 667px) {
  .offer-text {
    padding: 4rem 7rem 0 7rem;
  }
}
@media (max-width: 447px) {
  .offer-text {
    padding: 4rem 0 0 0;
  }
}
.offer-box {
  padding: 1rem 6rem;
}
@media (max-width: 900px) {
  .offer-box {
    padding: 1rem 6rem;
  }
}
@media (max-width: 667px) {
  .offer-box {
    padding: 1rem 4rem;
  }
}
@media (max-width: 447px) {
  .offer-box {
    padding: 1rem 2rem;
  }
}
.offer-icon {
  width: 4rem;
  height: 4rem;
  display: inline-block;
  margin-left: 1rem;
}

.success {
  height: 100vh;
}
.success-message {
  text-align: center;
  padding: 15rem;
}
@media (max-width: 900px) {
  .success-message {
    padding: 12rem;
  }
}
@media (max-width: 667px) {
  .success-message {
    padding: 8rem;
  }
}
@media (max-width: 447px) {
  .success-message {
    padding: 5rem;
  }
}
.success-message-heading {
  font-size: 5rem;
}
.success-message-text {
  margin-top: 3rem;
}

.not-found {
  font-size: 10rem;
}
.not-found-container {
  margin: 8.9rem auto 0 auto;
  height: 70vh;
}
.not-found-2 {
  transform: rotate(20deg);
  color: crimson;
}

.spaceship {
  position: absolute;
  animation: shakeSpaceship 200ms ease-in infinite;
}

@keyframes shakeSpaceship {
  0% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
.subscript {
  float: right;
}

@media (max-width: 900px) {
  .homepage .heading-special::after {
    display: none;
  }
}

.hero-section {
  background-color: white;
  height: 100vh;
  min-height: 700px;
  background-size: cover;
  margin-top: -18rem;
  background-image: url("../images/banner-img-image.png");
  background-size: cover;
  overflow: hidden !important;
  max-height: 1024px;
  background-position: 0 0;
}
@media (min-width: 2000px) {
  .hero-section {
    min-height: 1400px;
  }
}
@media (min-width: 1610px) {
  .hero-section {
    background-position: 250px 0;
  }
}
@media (max-width: 900px) {
  .hero-section {
    height: 70vh;
    background-position: 0 0;
  }
}
@media (max-width: 447px) {
  .hero-section {
    background-size: cover;
    background-position: -350px 0;
  }
}
.hero-section .curve {
  position: absolute;
  top: 80px;
  z-index: 39;
  display: flex;
  align-items: center;
  left: 0;
  background-color: white;
  height: calc(100% - 90px);
  width: 100vw;
  opacity: 0.95;
  clip-path: ellipse(48% 68% at -6% 29%);
}
@media (max-width: 1100px) {
  .hero-section .curve {
    -webkit-clip-path: polygon(0 0, 27% 0, 58% 50%, 55% 57%, 0 91%);
    clip-path: ellipse(48% 68% at -6% 29%);
  }
}
@media (max-width: 900px) {
  .hero-section .curve {
    -webkit-clip-path: ellipse(56% 62% at -6% 29%);
    clip-path: ellipse(56% 62% at -6% 29%);
  }
}
@media (max-width: 667px) {
  .hero-section .curve {
    background: transparent;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@media (max-width: 447px) {
  .hero-section .curve {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    background: transparent;
  }
}
.hero-section .heading-container {
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  max-width: 41%;
  justify-content: space-evenly;
}
.hero-section .heading-container a {
  width: 150px;
  font-size: 1.5rem;
}
@media (max-width: 900px) {
  .hero-section .heading-container {
    padding: 0 15px;
    max-width: 46%;
  }
}
@media (max-width: 667px) {
  .hero-section .heading-container {
    padding: 0 15px;
    max-width: 100%;
    height: 250px;
  }
  .hero-section .heading-container a {
    width: auto;
    max-width: 120px;
    font-weight: bold;
    text-align: center;
  }
  .hero-section .heading-container h1 {
    font-weight: bold;
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  }
}
.hero-section .hero-btn {
  background-color: rgb(34, 139, 34);
  border-color: rgb(34, 139, 34);
}
.hero-section .hero-btn:hover {
  opacity: 0.8;
  color: white;
}
.hero-section .hero-text-highlight {
  margin: 130px auto 0 auto;
  color: black;
  text-align: center;
  z-index: 200;
}
.hero-section .hero-text-highlight h3 {
  font-size: 18px;
  font-weight: 900;
}
.hero-section .hero-text-highlight .hero-content-box {
  display: inline-block;
  max-width: 80%;
  background: #dcf0dc;
  border-radius: 4px;
  padding: 12px;
  margin-top: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.hero-section .hero-text-highlight .hero-content-box h1 {
  font-size: 18px;
  font-weight: 500;
  color: #388e3c;
}
.hero-section .hero-text-highlight .hero-content-box h1 a {
  color: rgb(52, 165, 209);
}

.homepage-numbers {
  position: relative;
  z-index: 43;
  height: 250px;
  margin-top: -18rem;
  background-color: #f7f7f7;
}
.homepage-numbers .number-wrapper {
  max-width: 1400px;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.homepage-numbers .number-wrapper div {
  width: 180px;
  height: 180px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homepage-numbers .number-wrapper div img {
  margin: 25px 0 10px 0;
  width: 30px;
  height: 30px;
}
.homepage-numbers .number-wrapper div h3 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.homepage-numbers .number-wrapper div h1 {
  text-align: center;
  position: relative;
  font-size: 2.5rem;
  font-weight: normal !important;
}
.homepage-numbers .number-wrapper div .number::after {
  display: block;
  position: absolute;
  content: "";
  right: 15%;
  bottom: -8px;
  height: 5px;
  width: 50%;
  background-color: forestgreen;
}
@media (max-width: 900px) {
  .homepage-numbers .number-wrapper div {
    width: 140px;
    height: 140px;
  }
  .homepage-numbers .number-wrapper div img {
    margin: 15px 0 8px 0;
  }
  .homepage-numbers .number-wrapper div h3 {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .homepage-numbers .number-wrapper div h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 447px) {
  .homepage-numbers {
    padding: 40px 0;
    height: auto;
  }
  .homepage-numbers .number-wrapper {
    flex-direction: column;
    justify-content: space-evenly;
    height: 700px;
  }
  .homepage-numbers .number-wrapper div {
    height: 150px;
    width: 150px;
    align-items: center;
  }
  .homepage-numbers .number-wrapper div img {
    margin-top: 20px;
  }
  .homepage-numbers .number-wrapper div h3 {
    margin-top: 2rem;
  }
}

.feature-section {
  position: relative;
  overflow-x: hidden;
  min-height: 700px;
  margin-top: -6rem;
}
@media (min-width: 1610px) {
  .feature-section {
    margin-top: 4rem;
  }
}
.feature-section .feature-wrapper {
  margin-top: 8rem;
}
@media (min-width: 1610px) {
  .feature-section .feature-wrapper {
    max-width: 85%;
    margin: 8rem auto auto auto;
  }
}
.feature-section .feature-text {
  display: grid;
  grid-template: 150px 150px/1fr 1fr;
  grid-gap: 20px 30px;
  width: 45%;
  padding: 70px 0 0 10%;
}
.feature-section .feature-text .heading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.feature-section .feature-text .heading img {
  height: 30px;
  width: 30px;
  margin: 0 5px 0 0;
}
.feature-section .feature-image {
  position: absolute;
  height: 100%;
  top: 0;
  width: auto;
  right: -15vw;
}
.feature-section .feature-image img {
  height: 120%;
}
.feature-section .feature-image .mobile-img {
  position: absolute;
  height: 90%;
  width: auto;
  top: 35%;
  left: -5%;
}
@media (min-width: 1610px) {
  .feature-section .feature-image {
    right: 0;
    width: 50%;
  }
}
@media (min-width: 2000px) {
  .feature-section .feature-image {
    right: 0;
  }
}
@media (min-width: 1610px) {
  .feature-section {
    right: 0;
  }
}
@media (max-width: 1600px) {
  .feature-section .feature-text {
    padding-left: 10%;
  }
  .feature-section .feature-image {
    right: -12vw;
    width: 55%;
  }
  .feature-section .feature-image img {
    height: 120%;
  }
}
@media (max-width: 1100px) {
  .feature-section .mobile-img {
    display: none;
  }
  .feature-section .feature-image {
    height: 90%;
    right: -15vw;
    width: 50%;
  }
}
@media (max-width: 900px) {
  .feature-section {
    margin-bottom: -2rem;
  }
  .feature-section .feature-section {
    min-height: auto;
    position: static;
  }
  .feature-section .feature-wrapper {
    margin-top: 3rem;
  }
  .feature-section .feature-text {
    padding-left: 0;
    margin: auto;
    justify-content: center;
    grid-template: 150px 150px/200px 200px;
  }
  .feature-section .feature-image {
    position: static;
    width: 90%;
    margin: auto;
  }
  .feature-section .feature-image img {
    height: auto;
  }
  .feature-section .feature-image .mobile-img {
    display: block;
    height: 80%;
    left: 5%;
    height: 30%;
    top: 70%;
  }
}
@media (max-width: 447px) {
  .feature-section .feature-text {
    width: 80%;
    padding: 40px 0 0 0;
    margin: auto;
    grid-template: repeat(4, auto)/1fr;
    grid-row-gap: 35px;
    margin-bottom: 35px;
  }
  .feature-section .feature-text .heading img {
    height: 15px;
    width: 15px;
    margin-right: 15px;
  }
  .feature-section .feature-image .mobile-img {
    height: 20%;
    top: 80%;
  }
}

.process-section {
  margin-top: -12rem;
}
@media (min-width: 1610px) {
  .process-section {
    margin-top: -2rem;
  }
}
.process-section p.text-lg {
  max-width: 60%;
  margin: 20px auto 0 auto;
}
.process-section .process-stepper {
  display: grid;
  grid-template: auto/repeat(6, 1fr);
  grid-column-gap: 3%;
  max-width: 90%;
  margin: auto;
  padding-top: 100px;
}
.process-section .step-wrapper {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.process-section .step-wrapper p.text-lg {
  margin-top: 5px;
}
.process-section .step-wrapper .icon-wrapper {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
}
.process-section .step-wrapper .icon-wrapper img {
  width: 40px;
  height: 40px;
}
.process-section .step-wrapper::after, .process-section .step-wrapper::before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: #ccc;
  opacity: 0.9;
  top: 50%;
  border-radius: 50%;
  right: -20%;
}
.process-section .step-wrapper::before {
  right: 0%;
}
.process-section .step-wrapper:last-child::after, .process-section .step-wrapper:last-child::before {
  display: none;
}
.process-section .step-wrapper:hover .icon-wrapper {
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.process-section .step-wrapper .step-description {
  pointer-events: none;
  position: absolute;
  top: -120%;
  box-shadow: 0 15px 10px -8px rgba(0, 0, 0, 0.2);
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  width: 250px;
  border-radius: 5px;
  z-index: 50;
  background-color: forestgreen;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.process-section .step-wrapper .step-description p {
  color: white;
  font-weight: bold;
  text-align: center;
}
.process-section .step-wrapper .step-description::after {
  position: absolute;
  bottom: -15px;
  content: "";
  left: 50%;
  transform: rotate(45deg) translateX(-50%);
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: forestgreen;
}
@media (max-width: 900px) {
  .process-section {
    margin-top: inherit;
  }
  .process-section p.text-lg {
    max-width: 75%;
  }
  .process-section .process-stepper {
    align-items: center;
    grid-template: auto auto/repeat(3, 2fr);
    grid-row-gap: 80px;
    padding-top: 50px;
    grid-template-areas: "sourcing  sorting grading" "delivering dispatching packaging";
  }
  .process-section .process-stepper .sourcing-step {
    grid-area: sourcing;
  }
  .process-section .process-stepper .sorting-step {
    grid-area: sorting;
  }
  .process-section .process-stepper .grading-step {
    grid-area: grading;
  }
  .process-section .process-stepper .packaging-step {
    grid-area: packaging;
  }
  .process-section .process-stepper .dispatch-step {
    grid-area: dispatching;
  }
  .process-section .process-stepper .delivery-step {
    grid-area: delivering;
  }
  .process-section .step-wrapper {
    padding: 0;
  }
  .process-section .step-wrapper::after {
    top: 35%;
    right: 10%;
  }
  .process-section .step-wrapper::before {
    top: 35%;
    right: -10%;
  }
  .process-section .step-wrapper:nth-child(3)::after, .process-section .step-wrapper:nth-child(3)::before {
    display: none;
  }
  .process-section .step-wrapper:last-child::after, .process-section .step-wrapper:last-child::before {
    display: block;
  }
  .process-section .step-wrapper:nth-child(4)::after {
    top: -45%;
    right: 45%;
  }
  .process-section .step-wrapper:nth-child(4)::before {
    top: -20%;
    right: 45%;
  }
  .process-section .step-wrapper .step-description {
    top: -75%;
    width: 200px;
    padding-bottom: 15px;
  }
  .process-section .step-wrapper .step-description::after {
    position: absolute;
    bottom: -10px;
    z-index: 4;
    transform: rotate(45deg) translateX(-50%);
  }
}
@media (max-width: 447px) {
  .process-section h1.heading-secondary {
    max-width: 90%;
    margin: auto;
  }
  .process-section h1.heading-secondary::after {
    display: none;
  }
  .process-section p.text-lg {
    max-width: 90%;
  }
  .process-section .process-stepper {
    display: block;
  }
  .process-section .step-wrapper {
    margin-bottom: 55px;
  }
  .process-section .step-wrapper::after, .process-section .step-wrapper::before {
    display: none !important;
  }
  .process-section .step-wrapper .icon-wrapper {
    width: 200px;
    height: 200px;
  }
  .process-section .step-wrapper .icon-wrapper img {
    height: 75px;
    width: 75px;
  }
  .process-section .step-wrapper p.text-lg {
    font-size: 3rem;
    margin-top: 20px;
  }
  .process-section .step-wrapper .step-description {
    top: -65%;
    width: 300px;
  }
  .process-section .step-wrapper .step-description p {
    font-size: 16px;
  }
}
.process-section .read-more-link {
  margin-top: 30px;
}

a.read-more-link {
  font-size: 1.5rem;
  display: inline-block;
  margin-top: 30px;
  font-size: 1.5rem;
}
a.read-more-link i {
  color: forestgreen;
  display: inline-block;
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
}
a.read-more-link:hover i {
  margin-left: 10px;
}

.customer-section {
  margin-top: -6rem;
}
@media (min-width: 1610px) {
  .customer-section {
    margin-top: 1rem;
  }
}
.customer-section .wrapper {
  max-width: 80%;
  margin: auto;
  margin-top: 5rem;
  display: grid;
  grid-template: auto/0.5fr 0.5fr;
  align-items: center;
  grid-column-gap: 5%;
}
@media (min-width: 1610px) {
  .customer-section .wrapper {
    width: 70%;
  }
}
@media (max-width: 900px) {
  .customer-section .wrapper {
    max-width: 90%;
  }
}
@media (max-width: 1100px) {
  .customer-section .wrapper {
    max-width: 90%;
  }
}
@media (max-width: 447px) {
  .customer-section .wrapper {
    max-width: 90%;
    grid-template: auto auto/1fr;
    grid-row-gap: 50px;
  }
}
.customer-section .farmer-section {
  margin-bottom: 6rem;
}
.customer-section .farmer-section .wrapper {
  grid-template-areas: "text image";
}
@media (max-width: 447px) {
  .customer-section .farmer-section .wrapper {
    grid-template-areas: "image" "text";
  }
}
.customer-section .buyer-section .wrapper {
  grid-template-areas: "image text";
}
@media (max-width: 447px) {
  .customer-section .buyer-section .wrapper {
    grid-template-areas: "image" "text";
  }
}
.customer-section .reason-wrapper {
  grid-area: text;
}
.customer-section .reason-wrapper .reason-text {
  display: grid;
  grid-template: auto/70px auto;
  align-items: center;
  padding: 15px;
  margin-bottom: 25px;
  max-width: 80%;
  grid-column-gap: 15px;
  transition: box-shadow 0.2s ease-in-out;
  border-radius: 5px;
}
.customer-section .reason-wrapper .reason-text:hover {
  box-shadow: 0 3px 15px -2px rgba(0, 0, 0, 0.2);
}
@media (max-width: 900px) {
  .customer-section .reason-wrapper .reason-text {
    max-width: 90%;
    grid-template: auto/50px auto;
    grid-column-gap: 8px;
  }
}
@media (max-width: 1100px) {
  .customer-section .reason-wrapper .reason-text {
    max-width: 90%;
  }
}
@media (max-width: 447px) {
  .customer-section .reason-wrapper .reason-text {
    max-width: 100%;
  }
}
.customer-section .reason-wrapper .icon-wrapper {
  display: flex;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f7f7f7;
}
.customer-section .reason-wrapper .icon-wrapper img {
  height: 40px;
  width: 40px;
}
@media (max-width: 900px) {
  .customer-section .reason-wrapper .icon-wrapper {
    height: 45px;
    width: 45px;
  }
  .customer-section .reason-wrapper .icon-wrapper img {
    height: 35px;
    width: 35px;
  }
}
.customer-section .reason-wrapper .heading h4 {
  margin-bottom: 10px;
}
@media (max-width: 900px) {
  .customer-section .reason-wrapper .heading h4 {
    margin-bottom: 7px;
  }
}
.customer-section .illustration-wrapper {
  grid-area: image;
}
.customer-section .illustration-wrapper h3 {
  align-self: center;
  max-width: 80%;
}
.customer-section .illustration-wrapper img {
  height: 70%;
  width: 70%;
}
@media (max-width: 447px) {
  .customer-section .illustration-wrapper {
    align-items: center;
  }
  .customer-section .illustration-wrapper h3 {
    font-size: 2.5rem;
    max-width: 100%;
  }
  .customer-section .illustration-wrapper img {
    margin: 0 auto;
  }
}
.customer-section .buyer-section .illustration-wrapper img {
  align-self: flex-start;
}
@media (max-width: 447px) {
  .customer-section .buyer-section .illustration-wrapper img {
    align-self: center;
  }
}
.customer-section .buyer-section .reason-wrapper {
  align-self: end;
}

.testimonial-section {
  margin-top: -4rem;
}
@media (min-width: 1610px) {
  .testimonial-section {
    margin-top: 4rem;
  }
}
@media (max-width: 900px) {
  .testimonial-section {
    margin-top: 0rem;
  }
}
.testimonial-section .testimonial-wrapper {
  margin: auto auto auto auto;
}
@media (min-width: 2000px) {
  .testimonial-section .testimonial-wrapper {
    width: 60%;
    margin: auto auto auto auto;
  }
}
@media (min-width: 1610px) {
  .testimonial-section .testimonial-wrapper {
    width: 75%;
  }
}
.testimonial-section .testimonial-container {
  position: relative;
  width: 90%;
  height: 450px;
  margin: auto;
  margin-top: 4rem;
}
@media (max-width: 447px) {
  .testimonial-section .testimonial-container {
    position: static;
    height: auto;
    min-height: 320px;
    max-height: auto;
  }
}
@media (max-width: 900px) {
  .testimonial-section .testimonial-container {
    height: auto;
    min-height: 300px;
  }
}
.testimonial-section .testimonial-container .first-card,
.testimonial-section .testimonial-container .second-card,
.testimonial-section .testimonial-container .third-card {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  z-index: 5;
}
.testimonial-section .testimonial-container .first-card,
.testimonial-section .testimonial-container .third-card {
  height: 350px;
  top: 25px;
  width: 24%;
  opacity: 0.5;
  padding: 25px 15px 15px 15px;
}
.testimonial-section .testimonial-container .first-card img.quoteIcon,
.testimonial-section .testimonial-container .third-card img.quoteIcon {
  width: 30px;
  height: 30px;
}
.testimonial-section .testimonial-container .first-card .testimonial-text,
.testimonial-section .testimonial-container .third-card .testimonial-text {
  margin-top: 50px;
  max-height: 40%;
  overflow: hidden;
}
.testimonial-section .testimonial-container .first-card .info-wrapper,
.testimonial-section .testimonial-container .third-card .info-wrapper {
  margin-top: 40px;
  display: grid;
  grid-template: 50px/40px auto;
  grid-column-gap: 20px;
}
.testimonial-section .testimonial-container .first-card .info-wrapper img,
.testimonial-section .testimonial-container .third-card .info-wrapper img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
@media (max-width: 1100px) {
  .testimonial-section .testimonial-container .first-card,
  .testimonial-section .testimonial-container .third-card {
    height: auto;
  }
  .testimonial-section .testimonial-container .first-card .testimonial-text,
  .testimonial-section .testimonial-container .third-card .testimonial-text {
    margin-top: 15px;
    max-height: 130px;
    overflow: hidden;
  }
  .testimonial-section .testimonial-container .first-card .info-wrapper,
  .testimonial-section .testimonial-container .third-card .info-wrapper {
    margin-top: 15px;
  }
}
@media (max-width: 667px) {
  .testimonial-section .testimonial-container .first-card,
  .testimonial-section .testimonial-container .third-card {
    display: none;
  }
}
@media (max-width: 900px) {
  .testimonial-section .testimonial-container .first-card,
  .testimonial-section .testimonial-container .third-card {
    height: auto;
  }
  .testimonial-section .testimonial-container .first-card .testimonial-text,
  .testimonial-section .testimonial-container .third-card .testimonial-text {
    max-height: 80px;
    overflow: hidden;
  }
  .testimonial-section .testimonial-container .first-card .info-wrapper,
  .testimonial-section .testimonial-container .third-card .info-wrapper {
    grid-column-gap: 0;
  }
  .testimonial-section .testimonial-container .first-card .info-wrapper img,
  .testimonial-section .testimonial-container .third-card .info-wrapper img {
    height: 20px;
    width: 20px;
  }
  .testimonial-section .testimonial-container .first-card .info-wrapper .name p,
  .testimonial-section .testimonial-container .third-card .info-wrapper .name p {
    font-size: 8px;
  }
}
.testimonial-section .testimonial-container .third-card {
  right: 0;
}
.testimonial-section .testimonial-container .active-card {
  height: 380px;
  width: 60%;
  left: 50%;
  z-index: 6;
  transform: translateX(-50%);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2);
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.testimonial-section .testimonial-container .active-card .quoteIcon {
  height: 50px;
  width: 50px;
  margin-bottom: 2rem;
}
.testimonial-section .testimonial-container .active-card p.testimonial-text {
  font-size: 1.8rem;
}
.testimonial-section .testimonial-container .active-card .info-wrapper {
  display: grid;
  margin-top: 0px;
  grid-template: 50px/65px auto;
  align-items: center;
  grid-column-gap: 3%;
}
.testimonial-section .testimonial-container .active-card img {
  border-radius: 50%;
  height: 65px;
  width: 65px;
}
.testimonial-section .testimonial-container .active-card div.name h5 {
  font-size: 2rem;
}
.testimonial-section .testimonial-container .active-card div.name p {
  font-size: 1.5rem;
}
@media (max-width: 1100px) {
  .testimonial-section .testimonial-container .active-card {
    padding: 20px 30px;
    height: 330px;
  }
  .testimonial-section .testimonial-container .active-card .quoteIcon {
    height: 40px;
    width: 40px;
  }
  .testimonial-section .testimonial-container .active-card .info-wrapper {
    margin-top: 40px;
  }
  .testimonial-section .testimonial-container .active-card .info-wrapper img {
    height: 45px;
    width: 45px;
  }
}
@media (max-width: 667px) {
  .testimonial-section .testimonial-container .active-card {
    width: 95%;
    margin: auto;
    height: auto;
    padding: 15px;
  }
  .testimonial-section .testimonial-container .active-card .quoteIcon {
    height: 30px;
    width: 30px;
    display: inline-block;
    margin-bottom: 15px;
  }
  .testimonial-section .testimonial-container .active-card .info-wrapper {
    margin-top: 0;
  }
  .testimonial-section .testimonial-container .active-card div.name h5 {
    font-size: 1.2rem;
  }
}
@media (max-width: 900px) {
  .testimonial-section .testimonial-container .active-card {
    height: 290px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
}
.testimonial-section .testimonial-btns .wrapper {
  margin: auto;
  width: 100px;
  display: flex;
  justify-content: space-between;
}
.testimonial-section .testimonial-btns .wrapper .arrow-btn {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 40px;
  width: 40px;
  background-color: forestgreen;
  border-radius: 50%;
  box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.3);
  transition: all 0.2s linear;
  font-size: 16px;
  cursor: pointer;
}
.testimonial-section .testimonial-btns .wrapper .arrow-btn:active {
  box-shadow: none;
}
@media (max-width: 447px) {
  .testimonial-section .testimonial-btns .wrapper {
    width: 100px;
  }
}
@media (max-width: 900px) {
  .testimonial-section .background--1 {
    height: 70rem;
    top: 0rem;
  }
}
@media (max-width: 447px) {
  .testimonial-section .background--1 {
    display: none;
  }
}

.animate-card * {
  animation: fadeInCard 0.6s ease-in-out 0s 1 normal forwards;
}

@keyframes fadeInCard {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.news-section {
  overflow: hidden;
  margin-top: -4rem;
}
@media (min-width: 1610px) {
  .news-section {
    margin-top: 4rem;
  }
}
@media (max-width: 900px) {
  .news-section {
    margin-top: 0rem;
  }
}
.news-section .wrapper {
  margin-top: 5rem;
}
.news-section .news-content {
  cursor: pointer;
  width: 60%;
  margin: auto;
  display: grid;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px 35px;
  min-height: 350px;
  grid-template: 90px 50px 70px auto/1fr;
}
.news-section .news-content .logo {
  height: 100%;
  justify-self: center;
}
.news-section .news-content .quote-icon {
  height: 40px;
  width: 40px;
}
.news-section .news-content a.read-more-content {
  color: forestgreen;
  font-weight: bold;
}
@media (min-width: 2000px) {
  .news-section .news-content {
    width: 75%;
  }
}
@media (min-width: 1610px) {
  .news-section .news-content {
    width: 75%;
  }
}
@media (max-width: 1100px) {
  .news-section .news-content {
    grid-template: 90px auto auto auto/1fr;
    width: 75%;
  }
}
@media (max-width: 900px) {
  .news-section .news-content {
    min-height: auto;
    width: 95%;
    margin: auto;
    grid-template: 90px 40px 60px auto/1fr;
    grid-column-gap: 0;
  }
  .news-section .news-content .quote-icon {
    width: 30px;
    height: 30px;
  }
  .news-section .news-content .article-heading {
    font-size: 3rem;
  }
}
@media (max-width: 447px) {
  .news-section .news-content {
    grid-template: 40px auto auto auto/1fr;
    width: 95%;
    padding: 15px;
    min-height: 300px;
    grid-row-gap: 15px;
  }
  .news-section .news-content .quote-icon {
    width: 25px;
    height: 25px;
  }
  .news-section .news-content h3.article-heading {
    font-size: 18px;
  }
  .news-section .news-content p.article-content {
    font-size: 14px;
  }
}
.news-section .animate-in {
  animation: fadeIn 0.4s linear 0s 1 normal forwards;
}
.news-section .logo-container {
  margin-top: 10rem;
  display: grid;
  grid-template: 80px/repeat(5, 1fr);
  grid-column-gap: 5%;
  align-items: center;
  transition: all 0.6s ease-in-out;
}
.news-section .logo-container img {
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.news-section .logo-container .active {
  padding-bottom: 15px;
  border-bottom: 5px solid forestgreen;
}
@media (max-width: 447px) {
  .news-section .logo-container {
    grid-template: 100px/repeat(5, 25%);
    overflow: hide;
    position: relative;
    margin-top: 4rem;
    grid-column-gap: 10%;
  }
}
.news-section .as-seen-on-container {
  margin-top: 10rem;
}
.news-section .as-seen-on-container .wrapper {
  display: grid;
  grid-template: 70px/repeat(5, 1fr);
  justify-content: space-around;
  grid-column-gap: 40px;
  align-items: center;
}
@media (max-width: 447px) {
  .news-section .as-seen-on-container {
    margin-top: 4rem;
  }
  .news-section .as-seen-on-container .wrapper {
    grid-template: 60px 60px/1fr 1fr;
  }
}
@media (max-width: 447px) {
  .news-section .as-seen-on-container .wrapper {
    grid-template: 70px/repeat(5, 25%);
    animation: scrolling 5s linear 0s infinite alternate forwards;
  }
  .news-section .as-seen-on-container .wrapper:hover {
    animation-play-state: paused;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes scrolling {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(-50%);
  }
}
.image-grid {
  background-color: rgba(245, 240, 245, 0.6);
  padding: 100px 0;
  margin-bottom: -9rem;
  margin-top: -6rem;
}
@media (min-width: 1610px) {
  .image-grid {
    margin-top: 4rem;
  }
}
.image-grid .wrapper {
  display: grid;
  max-width: 80%;
  margin: auto;
  grid-gap: 5px;
  grid-template: 190px/repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 290px;
  grid-auto-flow: dense;
}
.image-grid .wrapper img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.image-grid .wrapper .vertical {
  grid-row: span 2;
}
.image-grid .wrapper .landscape {
  grid-column: span 2;
}
.image-grid .wrapper .hero {
  grid-row: span 2;
  grid-column: span 2;
}
@media (max-width: 1100px) {
  .image-grid .wrapper .hero {
    grid-row: span 1;
  }
}
@media (max-width: 900px) {
  .image-grid .wrapper .hero {
    grid-row: span 1;
  }
}
@media (max-width: 447px) {
  .image-grid .wrapper .hero {
    grid-row: span 1;
  }
}
@media (min-width: 1610px) {
  .image-grid .wrapper {
    grid-template: 230px/repeat(auto-fit, minmax(500px, 1fr));
    grid-auto-rows: 290px;
  }
}
@media (min-width: 2000px) {
  .image-grid .wrapper {
    grid-template: 270px/repeat(auto-fit, minmax(500px, 1fr));
    grid-auto-rows: 270px;
  }
}
@media (max-width: 447px) {
  .image-grid .wrapper {
    grid-template: auto/1fr;
    grid-auto-rows: auto;
  }
  .image-grid .wrapper .vertical,
  .image-grid .wrapper .hero {
    grid-row: auto;
  }
  .image-grid .wrapper .hero {
    grid-column: span 1;
  }
}
.image-grid .image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 4000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-grid .image-modal img {
  max-width: 80%;
  height: 80%;
}
@media (max-width: 447px) {
  .image-grid .image-modal img {
    width: 100%;
    height: auto;
  }
}
.image-grid .image-modal .fa {
  position: fixed;
  top: 3%;
  font-size: 45px;
  color: white;
  right: 5%;
  cursor: pointer;
}

.treasure-chest {
  position: absolute;
  left: 67%;
  top: 47vh;
  transform: translate(-50%, -50%);
  height: 20rem;
  z-index: 10;
}
@media (max-width: 1100px) {
  .treasure-chest {
    top: 280px;
  }
}
@media (max-width: 447px) {
  .treasure-chest {
    display: none;
  }
}
@media (max-width: 900px) {
  .treasure-chest {
    top: 25vh;
  }
}

.map-image {
  position: absolute;
  left: 73%;
  top: 48vh;
  transform: translate(-50%, -50%);
  height: 65rem;
  z-index: 5;
}
@media (max-width: 1100px) {
  .map-image {
    top: 23vh;
    top: 300px;
  }
}
@media (max-width: 900px) {
  .map-image {
    top: 25vh;
  }
}
@media (max-width: 447px) {
  .map-image {
    top: 35rem;
    left: 50%;
    opacity: 0.3;
    z-index: 1;
  }
}

.treasure-animation {
  animation: animate-treasure 1000ms ease-out 1 forwards;
}

.move-treasure:hover {
  animation: move-treasure 1400ms ease-in infinite alternate;
}

@keyframes move-treasure {
  0% {
    top: 44%;
  }
  50% {
    top: 45%;
  }
  100% {
    top: 46%;
  }
}
@keyframes animate-treasure {
  0% {
    height: 200px;
    opacity: 1;
  }
  100% {
    height: 1400px;
    opacity: 0;
    z-index: -1;
  }
}
.map-overlay {
  height: 100vh;
  width: 50%;
  z-index: 0;
  position: absolute;
  top: 0;
}
.map-overlay-left {
  background: url("../../src/images/joot2.jpg");
  left: 0%;
  transform-origin: left center;
}
.map-overlay-right {
  background: url("../../src/images/joot2.jpg");
  left: 50%;
  transform-origin: right center;
}
@media (max-width: 900px) {
  .map-overlay {
    max-height: 500px;
  }
}
@media (max-width: 447px) {
  .map-overlay {
    min-height: 700px;
  }
}

.animate-panel-right {
  animation: animate-right-panel 2000ms linear 1 forwards;
}

.animate-panel-left {
  animation: animate-left-panel 2000ms linear 1 forwards;
}

@keyframes animate-right-panel {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(145deg);
    visibility: hidden;
  }
}
@keyframes animate-left-panel {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(-145deg);
    visibility: hidden;
  }
}
.treasure-text {
  position: absolute;
  display: inline-block;
  top: 20vh;
  left: 10%;
  width: auto;
  max-width: 40rem;
  line-height: 6rem;
}
.treasure-text > .heading-special {
  font-size: 4rem;
}
@media (min-width: 1610px) {
  .treasure-text {
    font-size: 5rem;
  }
}
@media (max-width: 900px) {
  .treasure-text {
    top: 10vh;
    z-index: 4;
    text-align: center;
    display: block;
    width: 40rem;
    font-size: 2rem;
  }
}
@media (max-width: 447px) {
  .treasure-text {
    top: 20rem;
    z-index: 12;
    text-align: center;
    display: block;
    width: 40rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
  }
}

.open-card-button {
  z-index: 100;
  color: forestgreen;
  transition: 200ms;
  border: none;
  background-color: transparent;
  font-weight: bolder;
  border-bottom: 1px solid transparent;
  font-size: 2rem;
}
.open-card-button:hover {
  top: calc(100vh - 8.1rem);
  border-radius: 5px;
  border-bottom: 1px solid forestgreen;
}

.curl {
  width: 6rem;
  height: 6rem;
  position: absolute;
  top: calc(100vh - 6rem);
  left: 50%;
  cursor: pointer;
  background: linear-gradient(45deg, #fff, #f3f3f3 45%, #ddd 50%, #aaa 50%, #bbb 56%, #ccc 62%, #f3f3f3 80%, #fff 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease;
}
.curl:hover {
  width: 140px;
  height: 140px;
  top: calc(100vh - 140px);
}

.buyer-page {
  min-height: 92vh;
  height: auto;
  margin-top: 80px;
}
@media (max-width: 1100px) {
  .buyer-page {
    min-height: 650px;
  }
}
@media (max-width: 900px) {
  .buyer-page {
    max-height: 500px;
  }
}
@media (max-width: 447px) {
  .buyer-page {
    height: auto;
    min-height: 700px;
  }
}

.map-wrapper.map-uninteractive {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  justify-content: flex-end;
}

.district-group {
  animation: svgfadein 0.5s ease-in-out 0s 1 normal forwards;
}

@keyframes svgfadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.map-wrapper.map-interactive {
  display: flex;
  justify-content: center;
  transition: 200ms;
}

.state {
  fill-opacity: 1;
  stroke: black;
  stroke-opacity: 1;
  stroke-width: 1;
}
.state-selected {
  fill: rgba(34, 139, 34, 0.6);
}
.state:hover {
  cursor: pointer;
  fill: rgba(34, 139, 34, 0.8);
}
.state-title-wrapper {
  display: none;
  background-color: white;
  font-size: 16px;
  border-radius: 4px;
  color: #333;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 4px 4rem;
  z-index: 1;
}
.state-title {
  z-index: 10;
}
.state-title-wrapper::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 90%;
  left: 50%;
  background-color: white;
  z-index: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(45deg);
}

.district-path {
  stroke: rgba(0, 0, 0, 0.3);
  fill-opacity: 1;
  stroke-opacity: 1;
  stroke-width: 0.2;
}
.district-path:hover {
  cursor: pointer;
  fill: rgba(34, 139, 34, 0.8);
}

.buyer-form-wrapper {
  padding: 3rem 2rem;
  margin-top: 10rem;
}

.buyer-form {
  width: 40rem;
}
.buyer-form-control {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  border: none;
  font-size: 14px;
  transition: 200ms;
  transform-origin: center;
}
.buyer-form-control:hover {
  transform: scale(1.1);
}
.buyer-form .verify-options {
  display: flex;
  margin-top: 0.8rem;
  align-items: center;
  justify-content: space-between;
}
.buyer-form .verify-options a {
  font-weight: bold;
  font-size: 1.6rem;
  text-decoration: underline;
}
.buyer-input {
  margin: 1rem 0 0 0;
  font-size: 1.6rem;
  padding: 1rem 1.5rem;
  border: none;
  outline: none;
  border-radius: 4px;
  width: 100%;
  border: 1px solid transparent;
  background-color: #fafafa;
}
.buyer-input:placeholder-shown > label {
  opacity: 0;
}
.buyer-input::placeholder {
  color: #aaa;
}
.buyer-input:focus > label {
  opacity: 0;
}
.buyer-input:focus {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.buyer-input-error {
  font-size: 1.6rem;
  color: crimson;
  margin: 0;
}
.buyer-verify-btn {
  padding: 0.5rem 2rem;
  background-color: forestgreen;
  color: #ffffff;
  font-size: 1.6rem;
  text-transform: uppercase;
  transition: 200ms;
  border: none;
  outline: none;
}
.buyer-verify-btn:hover {
  border-radius: 4px;
  transform: translateY(-2px);
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.2);
}
.buyer .slick-track {
  display: flex;
}
.buyer .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}
.buyer-form-details {
  position: absolute;
  top: 30vh;
  left: 10%;
  border-radius: 5px;
  z-index: 100;
}
.buyer-form-details-show {
  margin-top: 1rem;
}
.buyer-form-details-show-enter {
  opacity: 0.01;
  -webkit-transform: translate(-50%, -40%);
  -moz-transform: translate(-50%, -40%);
  -ms-transform: translate(-50%, -40%);
  -o-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
}
.buyer-form-details-show-enter-active {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 300ms ease-out;
}
.buyer-form-details-show-exit {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.buyer-form-details-show-exit-active {
  opacity: 0;
  -webkit-transform: translate(-50%, -40%);
  -moz-transform: translate(-50%, -40%);
  -ms-transform: translate(-50%, -40%);
  -o-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
  transition: all 300ms ease-out;
}
@media (max-width: 900px) {
  .buyer-form-details {
    top: 18vh;
  }
}
@media (max-width: 447px) {
  .buyer-form-details {
    top: 28rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.phone-number-wrapper {
  display: grid;
  grid-template: auto/100px auto;
  grid-column-gap: 5px;
}
.phone-number-wrapper select {
  height: 50px;
}
@media (max-width: 447px) {
  .phone-number-wrapper select {
    height: 35px;
  }
}

.buyer-enquiry {
  font-size: 1.6rem;
}

.crop-list-dropdown {
  position: absolute;
  padding: 10px;
  background-color: white;
  top: 10%;
  font-size: 1.6rem;
  right: 15%;
  z-index: 99;
}
.crop-list-dropdown select {
  margin-top: 10px;
  height: 30px;
  outline: none;
  min-width: 150px;
  border: 1px solid #333;
  font-size: 1.6rem;
  background-color: #fafafa;
}

div[role=toolbar] {
  background-color: transparent !important;
  border: 1px solid #aaa;
  top: 20px !important;
}
div[role=toolbar] > button:hover {
  color: #999 !important;
}
div[role=toolbar] > button[title="Zoom in"] {
  display: none !important;
}
div[role=toolbar] > button[title="Zoom out"] {
  display: none !important;
}
div[role=toolbar] > button[title="Fit to viewer"] {
  display: none !important;
}
@media (max-width: 447px) {
  div[role=toolbar] {
    transform: translate(0, 0) !important;
    left: 20% !important;
  }
}

.crop-image {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

@media (max-width: 900px) {
  .buyer-page {
    min-height: 500px;
    max-height: 500px;
  }
}
@media (max-width: 1100px) {
  .buyer-page {
    max-height: 500px;
  }
}
.buyer-page .sidebar {
  height: calc(100vh - 60px);
  padding: 2rem;
  overflow: scroll;
  width: 35%;
  border-right: 1px solid #eee;
  background-color: whitesmoke;
  transition: all 0.4s ease-in-out;
  transform: translateX(0);
}
@media (max-width: 1100px) {
  .buyer-page .sidebar {
    padding: 15px;
    width: 45%;
    height: 700px;
  }
  .buyer-page .sidebar form > div > input {
    border: 1px solid #ccc;
  }
}
@media (max-width: 900px) {
  .buyer-page .sidebar {
    height: 500px;
    width: 45%;
  }
}
@media (max-width: 447px) {
  .buyer-page .sidebar {
    z-index: 400;
    max-height: 610px;
    overflow: scroll;
    padding-bottom: 100px;
    position: absolute;
    width: 85%;
  }
}
.buyer-page .sidebar::-webkit-scrollbar {
  width: 0 !important;
}
.buyer-page .crop-region-wrapper {
  padding: 10px 0;
  font-size: 1.6rem;
  position: relative;
}
.buyer-page .crop-region-wrapper .user-details {
  position: fixed;
}
.buyer-page .crop-region-wrapper label {
  font-weight: normal;
  display: block;
}
.buyer-page .crop-region-wrapper > div {
  margin-bottom: 15px;
}
.buyer-page .crop-region-wrapper select {
  height: 35px;
  background-color: white;
  width: 100%;
  border-radius: 0;
  border: none;
}
.buyer-page .crop-region-wrapper label.map-select {
  padding: 7px 5px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
}
.buyer-page .crop-region-wrapper .cart-items {
  margin-top: 3.5rem;
}
.buyer-page .crop-region-wrapper .cart-items-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buyer-page .crop-region-wrapper .cart-items-header button {
  background-color: transparent;
  padding: 0.3rem 0.7rem;
  font-size: 1.6rem;
  border: 1px solid black;
}
.buyer-page .crop-region-wrapper .cart-items-header button .fa {
  position: static;
}
.buyer-page .crop-region-wrapper .cart-items .cart-item-card {
  position: relative;
  margin-top: 2rem;
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 15px 10px 15px 20px;
  border-radius: 3px;
  display: grid;
  grid-template: auto/80% 10%;
  grid-gap: 10px 1%;
}
.buyer-page .crop-region-wrapper .cart-items .cart-item-card p {
  font-size: 1.6rem;
}
.buyer-page .crop-region-wrapper .cart-items .cart-item-card--info > div {
  margin: 0.5rem 0;
  font-size: 1.6rem;
}
.buyer-page .crop-region-wrapper .cart-items label {
  margin-bottom: 1rem;
  font-weight: bold;
}
.buyer-page .crop-region-wrapper .cart-items input[type=text] {
  width: 70%;
  font-size: 1.6rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #cccccc;
}
.buyer-page .crop-region-wrapper .cart-items .no-borders {
  border: none !important;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.buyer-page .crop-region-wrapper .cart-items .date input {
  width: 100% !important;
}
.buyer-page .crop-region-wrapper .cart-items span {
  font-size: 1.6rem;
}
.buyer-page .crop-region-wrapper .cart-items .fa {
  position: absolute;
  top: 7%;
  font-size: 1.8rem;
  cursor: pointer;
}
.buyer-page .crop-region-wrapper .cart-items .fa.fa-trash {
  color: #ff3366;
  right: 5%;
}
.buyer-page .crop-region-wrapper .cart-items .fa.fa-edit {
  color: forestgreen;
  right: 10%;
  cursor: pointer;
}
.buyer-page .crop-region-wrapper .cart-items .save-edits {
  z-index: 5;
  position: absolute;
  right: 5%;
  top: 7%;
  background-color: transparent;
  border: 1px solid forestgreen;
  color: forestgreen;
  font-size: 1.6rem;
  padding: 3px 8px;
  border-radius: 5px;
}
.buyer-page .crop-region-wrapper .cart-items .save-edits:hover {
  box-shadow: 0px 0px 5px 0px rgba(34, 139, 34, 0.6);
}
.buyer-page .crop-region-wrapper .cart-items .react-date-picker {
  width: 75%;
  padding: 5px 0;
}
.buyer-page .crop-region-wrapper .cart-items .react-date-picker .react-date-picker__inputGroup__year {
  width: 2rem;
}
@media (max-width: 447px) {
  .buyer-page .crop-region-wrapper .cart-items .react-date-picker {
    font-size: 1.6rem;
  }
  .buyer-page .crop-region-wrapper .cart-items .react-date-picker .react-date-picker__button svg {
    height: 10px;
    width: 10px;
  }
}
.buyer-page .crop-region-wrapper .cart-items .react-date-picker input.react-date-picker__inputGroup__input {
  width: auto !important;
  min-width: 0.75rem !important;
}

.carousel.carousel-slider {
  height: inherit;
}

.buyer-enquiry-data-wrapper {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}
.buyer-enquiry-data-wrapper .images {
  height: 40%;
  position: relative;
  height: 35rem;
}
.buyer-enquiry-data-wrapper .images > div {
  position: absolute;
  height: 100%;
  width: 100%;
}
.buyer-enquiry-data-wrapper .images .carousel-image {
  height: 30rem;
  max-height: auto;
}
.buyer-enquiry-data-wrapper .images .carousel .slider-wrapper {
  height: 100%;
  background-color: whitesmoke;
}
.buyer-enquiry-data-wrapper .control-dots {
  background-color: whitesmoke;
  margin-bottom: 0;
}
.buyer-enquiry-data-wrapper .carousel .slide {
  background-color: whitesmoke;
}
.buyer-enquiry-data-wrapper .carousel .slide img {
  width: auto;
  height: 35rem;
}
.buyer-enquiry-data-wrapper .carousel .slider-wrapper {
  cursor: pointer;
  background-color: whitesmoke;
}
.buyer-enquiry-data-wrapper .carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 100%;
}
.buyer-enquiry-data-wrapper .info-wrapper {
  padding: 2rem 4rem;
}
.buyer-enquiry-data-wrapper .carousel.carousel-slider .control-arrow {
  background-color: transparent;
  color: black;
}
.buyer-enquiry-data-wrapper .carousel.carousel-slider .control-arrow::before {
  border-right: 8px solid #000;
}
.buyer-enquiry-data-wrapper .carousel.carousel-slider .control-arrow.control-next::before {
  border-right: none;
  border-left: 8px solid #000;
}
.buyer-enquiry-data-wrapper .info {
  font-size: 1.6rem;
}
.buyer-enquiry-data-wrapper .info h2 {
  margin: 1rem 0;
}
.buyer-enquiry-data-wrapper .info-table {
  margin-top: 1rem;
}
.buyer-enquiry-data-wrapper .info-table, .buyer-enquiry-data-wrapper .info-details {
  margin: 1rem 0;
}
.buyer-enquiry-data-wrapper .info-table h3, .buyer-enquiry-data-wrapper .info-details h3 {
  margin-bottom: 1rem;
  color: forestgreen;
}
.buyer-enquiry-data-wrapper button.close-btn {
  z-index: 500;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 3rem;
  height: 3rem;
  background-color: whitesmoke;
  border-radius: 50%;
  border: none;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
  transition: 150ms;
  font-size: 1.6rem;
}
.buyer-enquiry-data-wrapper button.close-btn:hover {
  transform: translateY(-1px);
}
.buyer-enquiry-data-wrapper button.cart-interested-btn {
  padding: 0.5rem 2rem;
  font-size: 1.6rem;
  border: 1px solid transparent;
  background-color: forestgreen;
  transition: 200ms;
  color: white;
}
.buyer-enquiry-data-wrapper button.cart-interested-btn:hover {
  border: 1px solid forestgreen;
  transform: translateY(-1px);
  box-shadow: 1px 1px 10px 0px rgba(34, 139, 34, 0.5);
}
@media (max-width: 1100px) {
  .buyer-enquiry-data-wrapper button.cart-interested-btn {
    padding: 5px 10px;
  }
}

.buyer-enquiry-form-wrapper {
  padding: 15px 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 3rem;
  padding: 3rem 2rem;
  position: relative;
}
.buyer-enquiry-form-wrapper .react-date-picker > div {
  border: 1px solid #ccc;
}
.buyer-enquiry-form-wrapper .more-info {
  overflow: hidden;
  font-size: 1.6rem;
  height: 0;
  transition: all 0.3s ease-in-out;
}
.buyer-enquiry-form-wrapper .more-info.expand {
  overflow: visible;
  height: 12rem;
}
.buyer-enquiry-form-wrapper .extra-crop {
  margin-top: 20px;
}
.buyer-enquiry-form-wrapper .extra-crop label {
  margin-bottom: 4px;
}
.buyer-enquiry-form-wrapper .extra-crop-list > div {
  font-weight: bold;
  font-style: italic;
  display: inline-block;
  padding: 5px 0;
  margin-right: 15px;
}
.buyer-enquiry-form-wrapper form {
  box-sizing: border-box;
  font-size: 1.6rem;
  border-radius: 5px;
  margin-top: 1rem;
  background-color: white;
}
.buyer-enquiry-form-wrapper form > div {
  margin-bottom: 15px;
}
.buyer-enquiry-form-wrapper form .react-date-picker__wrapper,
.buyer-enquiry-form-wrapper form .react-date-picker {
  width: 100%;
}
.buyer-enquiry-form-wrapper form label {
  font-size: 1.6rem;
  display: block;
  margin-bottom: 8px;
}
.buyer-enquiry-form-wrapper form label span {
  color: red;
}
.buyer-enquiry-form-wrapper form p {
  margin-bottom: 15px;
}
.buyer-enquiry-form-wrapper form > div > input {
  padding: 5px;
  height: 35px;
  width: 100%;
  border: 1px solid #ccc;
}
.buyer-enquiry-form-wrapper button.cart-add-btn {
  font-size: 1.6rem;
  border: none;
  background-color: forestgreen;
  transition: 200ms;
  color: white;
}
.buyer-enquiry-form-wrapper button.cart-add-btn:hover {
  transform: translateY(-2px);
  color: white;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1100px) {
  .buyer-enquiry-form-wrapper button.cart-add-btn {
    padding: 5px 10px;
  }
}
.buyer-enquiry-form-wrapper button.cart-remove-btn {
  font-size: 1.6rem;
  border: 1px solid crimson;
  background-color: white;
  transition: 200ms;
  color: crimson;
  margin-left: 2rem;
}
.buyer-enquiry-form-wrapper button.cart-remove-btn:hover {
  transform: translateY(-2px);
  color: red;
  border: 1px solid red;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1100px) {
  .buyer-enquiry-form-wrapper button.cart-remove-btn {
    padding: 5px 10px;
  }
}

.cart-conatainer {
  display: flex;
  justify-content: space-between;
}
.cart-conatainer p {
  font-size: 2rem;
  max-width: 220px;
  font-weight: bold;
}
@media (max-width: 900px) {
  .cart-conatainer p {
    font-size: 1.5rem;
  }
}
.cart-conatainer .sidebar-menu-icon {
  font-size: 2.2rem;
  cursor: pointer;
}
@media (max-width: 900px) {
  .cart-conatainer .sidebar-menu-icon {
    margin-left: 0;
  }
}
.cart-conatainer .sidebar-menu-container {
  position: relative;
}
.cart-conatainer .sidebar-menu-container div {
  position: absolute;
  background-color: white;
  left: 1rem;
  z-index: 5;
  padding: 0.5rem;
  width: 15rem;
  top: 3rem;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.cart-conatainer .sidebar-menu-container div button {
  outline: none;
  border: none;
  background-color: transparent;
  margin-top: 5px;
  transition: 150ms;
  width: 100%;
  text-align: left;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}
.cart-conatainer .sidebar-menu-container div button:hover {
  background-color: rgb(245, 245, 245);
}

.date-picker > div {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1px;
  width: 100%;
  background-color: transparent !important;
}

.date-picker--special > div {
  border: none;
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  margin-top: 0.5rem;
}

.react-date-picker--disabled {
  background-color: transparent !important;
}

.cart-badge {
  font-size: 1.6rem;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: crimson;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.react-svg-zoom-animation {
  animation: svg-animation 600ms linear 1 forwards;
}

.react-svg-zoom-in {
  left: 50%;
  transform-origin: center;
  animation: svg-animation-in 1200ms linear 1 forwards;
}

@keyframes svg-animation {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes svg-animation-in {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.checkout-btn {
  font-size: 1.6rem;
  border: none;
  background-color: forestgreen;
  transition: 200ms;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 2.5rem;
  margin-right: 2rem;
}
.checkout-btn:hover {
  transform: translateY(-1px);
  color: white;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
}
@media (max-width: 900px) {
  .checkout-btn {
    font-size: 1.6rem;
    padding: 5px;
  }
}
@media (max-width: 447px) {
  .checkout-btn {
    padding: 0.5rem 3rem;
  }
}

.submit-otp-btn {
  background-color: forestgreen;
}
.submit-otp-btn:hover {
  background-color: forestgreen;
  opacity: 0.8;
}

.otp-modal input {
  margin: 1rem 0 0 0;
  font-size: 1.6rem;
  padding: 1rem 1.5rem;
  border: none;
  outline: none;
  border-radius: 4px;
  width: 100%;
  border: 1px solid transparent;
  background-color: #fafafa;
}
.otp-modal input:focus {
  outline: none;
}

.menu-btn {
  border: none;
  background-color: transparent;
}

.sweet-alert h2 {
  font-size: 2.8rem;
}
.sweet-alert .text-muted {
  padding: 1rem;
  font-size: 1.8rem;
}

.map-description {
  position: absolute;
  right: 9%;
  top: 82vh;
  font-size: 1.6rem;
  max-width: 400px;
  font-weight: bold;
}

.OTPmodal {
  position: fixed;
  width: 40%;
  min-width: 400px;
  height: 40%;
  left: 50%;
  min-height: 350px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 2500;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 5rem;
  justify-content: space-between;
  font-size: 1.6rem;
}
.OTPmodal > div {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.OTPmodal .resent-otp {
  margin-right: 2rem;
}
.OTPmodal .fa {
  position: relative;
  right: -55%;
  top: 0%;
  font-size: 2rem;
  cursor: pointer;
}
@media (max-width: 1100px) {
  .OTPmodal {
    width: 50%;
    height: 280px;
  }
}
@media (max-width: 900px) {
  .OTPmodal {
    height: 280px;
    width: 60%;
  }
  .OTPmodal > div {
    width: 75%;
  }
}
@media (max-width: 447px) {
  .OTPmodal {
    min-height: 280px;
    height: auto;
    min-width: auto;
    width: 90%;
    padding: 10px 15px 30px 15px;
  }
  .OTPmodal > div {
    width: 80%;
  }
  .OTPmodal .fa {
    right: -45%;
    top: 5%;
    font-size: 3rem;
  }
}

.otp-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 120;
}

.mapWrapper {
  overflow: hidden;
  position: relative;
  width: 65%;
  height: calc(100vh - 60px);
}
@media (max-width: 1100px) {
  .mapWrapper {
    width: 100%;
    margin: auto;
    height: 500px;
  }
}
@media (max-width: 900px) {
  .mapWrapper {
    width: 100%;
    margin: auto;
    height: 500px;
  }
}
.mapWrapper .sidebar-toggle-btn {
  display: none;
  position: absolute;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 447px) {
  .mapWrapper .sidebar-toggle-btn {
    display: flex;
    height: 60px;
    border-radius: 0 5px 5px 0;
    z-index: 400;
    left: 0;
    width: 20px;
    background-color: forestgreen;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
  }
}

p.buyer-register-error {
  font-size: 1.6rem !important;
  padding: 4px 0;
  margin: 1rem 0;
  font-weight: bold;
}
@media (max-width: 447px) {
  p.buyer-register-error {
    font-weight: normal;
    margin: 0;
    padding: 0;
  }
}

.crop-select {
  background-color: white;
  overflow: auto;
  max-height: 70rem;
  width: 95%;
  position: absolute;
  z-index: 1;
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.2);
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin-bottom: 0;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 2px solid #ccc;
}
.styled-checkbox:hover + label:before {
  background: rgba(34, 139, 34, 0.6);
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.styled-checkbox:checked + label:before {
  background: forestgreen;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 15px;
  top: 19px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.state-info-tooltip {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 234567;
  width: 350px;
  min-height: 200px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: ghostwhite;
}
@media (max-width: 447px) {
  .state-info-tooltip {
    height: 180px;
    width: 200px;
  }
}
.state-info-tooltip > div {
  position: relative;
  padding: 2rem;
  z-index: 1;
}
.state-info-tooltip .population {
  margin: 1rem 0;
}
.state-info-tooltip label {
  display: block;
}
.state-info-tooltip .major-crops {
  word-break: break-all;
  font-weight: normal;
  letter-spacing: 0.09rem;
  font-size: 1.2rem;
}
.state-info-tooltip .offer2Sale-crops {
  margin-top: 1rem;
  font-size: 1.6rem;
  letter-spacing: 0.03rem;
}
.state-info-tooltip .arrow {
  position: absolute;
  height: 30px;
  width: 30px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  background-color: ghostwhite;
  z-index: 0;
}

.theme-table {
  width: 100%;
}
.theme-table tr th {
  margin: 15px;
}

.stylish-loader {
  padding: 20px;
}

.loader-effect {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 20px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
.buyer-loader {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  z-index: 2500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.buyer-loader img {
  width: 100px;
  animation: loadingAnim 1s linear 0s infinite normal forwards;
}

@keyframes loadingAnim {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.instruction-container {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 3px;
}
.instruction-container ul {
  margin: 2rem 0;
  padding-left: 1.5rem;
  letter-spacing: 0.08rem;
  line-height: 3rem;
}
.instruction-container form {
  display: flex;
  align-items: center;
}
.instruction-container form input,
.instruction-container form label {
  margin: 0;
}
.instruction-container form input {
  margin-right: 1rem;
}

.buyer-page-image-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buyer-page-image-modal img {
  max-width: 90%;
  max-height: 90%;
}
.buyer-page-image-modal .fa.fa-times {
  position: absolute;
  font-size: 4rem;
  right: 5%;
  top: 5%;
  cursor: pointer;
  color: white;
}

.email-agree {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}
.email-agree input,
.email-agree label {
  margin: 0;
}
.email-agree input {
  margin-right: 1rem;
}

.crop-region-wrapper .toggle-mode {
  height: 60px;
  display: flex;
  padding: 1rem 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.crop-region-wrapper .toggle-mode input {
  margin-right: 0.5rem;
}

.offer-label {
  display: flex;
  align-items: center;
  color: #388e3c;
}
.offer-label span {
  cursor: pointer;
}
.offer-label img {
  cursor: pointer;
  height: 40px;
  margin-right: 8px;
}
@media (max-width: 447px) {
  .offer-label img {
    height: 15px;
  }
}

.offer-info {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.offer-info img {
  height: 45px;
  margin-right: 3rem;
}

.offer-text-span,
.state-title span {
  background-color: #388e3c;
  color: white;
  padding: 3px 10px;
  cursor: pointer;
  display: inline-block;
  border-radius: 0.5rem;
}

.state-title span {
  font-size: 1.2rem;
  font-weight: normal;
  padding: 2px 5px;
}

.offer-text {
  color: white;
  font-size: 14px;
}

@keyframes offerBlink {
  0%, 100% {
    border-color: red;
    color: red;
  }
  50% {
    border-color: black;
    color: yellow;
  }
}
.offer-crop-tooltip {
  position: absolute;
}

.mandi-tooltip {
  height: 250px;
  width: 250px;
  background-color: red;
  position: absolute;
  left: 50%;
  top: 10%;
  display: none;
  z-index: 400;
}

.mandi-info-tooltip {
  position: fixed;
  left: 50%;
  top: 50%;
  padding: 2rem 1rem;
  padding-bottom: 3rem;
  z-index: 234567;
  width: 350px;
  font-size: 1.6rem;
  min-height: 200px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: white;
}
@media (max-width: 447px) {
  .mandi-info-tooltip {
    height: 180px;
    width: 200px;
  }
}
.mandi-info-tooltip h3 {
  letter-spacing: 0.02rem;
  margin-bottom: 1rem;
}
.mandi-info-tooltip .price-container {
  padding: 2rem 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;
}
.mandi-info-tooltip .price-container label {
  text-align: center;
  font-size: 1.6rem;
  display: block;
  font-weight: normal;
}
.mandi-info-tooltip .price-container p {
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
}
.mandi-info-tooltip .unitofprice {
  font-size: 1rem;
}
.mandi-info-tooltip .arrow {
  position: absolute;
  height: 30px;
  width: 30px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  background-color: white;
  z-index: 0;
}

.weather-page {
  min-height: 740px;
  max-width: 100%;
  display: grid;
  height: auto;
  box-sizing: border-box;
}

.app-download-btn {
  outline: none;
  border: none;
  background-color: rgb(56, 142, 60);
  font-size: 1.6rem;
  border-radius: 4px;
  font-weight: bold;
  color: white;
}

.weather {
  background-image: linear-gradient(to bottom, #ffffff 5%, rgb(139, 183, 159), rgb(56, 142, 60));
  padding: 15px 0 15px 0;
  color: white;
  overflow: hidden;
}
.weather header {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.weather header img {
  width: 70%;
}
.weather header button {
  padding: 5px;
  font-size: 1.6rem;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0.5s 2 both;
}
@media (min-width: 767px) {
  .weather header {
    display: block;
    text-align: center;
  }
  .weather header img {
    width: 70%;
    margin: auto;
  }
  .weather header button {
    display: none;
  }
}
.weather .farmer-location {
  margin: 15px 0;
  display: flex;
  color: #333;
  justify-content: center;
  align-items: center;
}
.weather .farmer-location img {
  width: 20px;
  height: 20px;
}
.weather .weather-day {
  color: #333;
  font-size: 1.6rem;
}
.weather .weather-data {
  margin-top: 30px;
}
.weather .weather-data img {
  height: 90px;
  width: 90px;
  display: inline-block;
}
.weather .weather-data .temprature-value h2 {
  font-size: 5rem;
}
.weather .weather-data .temprature-value span {
  font-size: 1.6rem;
}
.weather .weather-data div.rain-info {
  max-width: 250px;
  margin: auto;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.weather .weather-data div.rain-info img {
  height: 80px;
  width: 80px;
}
.weather .weather-data div.rain-info span {
  font-size: 1.6rem;
  font-weight: bold;
}
.weather .weather-description {
  margin: 10px 0 15px 0;
}
.weather .weather-description .short {
  margin-bottom: 1rem;
}
.weather .weather-description .long {
  font-size: 1.6rem;
  max-width: 90%;
  margin: auto;
}
.weather .statics-wrapper {
  display: flex;
  max-width: 60%;
  margin: auto;
  height: 100px;
  justify-content: space-between;
}
.weather .statics-wrapper img {
  height: 50px;
  width: 50px;
}
.weather .statics-wrapper div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.weather .card-wrapper {
  padding: 0 15px;
}
.weather .card-wrapper .card {
  display: grid;
  color: black;
  align-items: center;
  grid-template: 70px/0.6fr 0.2fr 0.2fr;
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.weather .card-wrapper .card img {
  height: 40px;
  width: 40px;
}
.weather .card-wrapper .card:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}
.weather .card-wrapper div.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.6rem;
  padding-left: 25px;
}
.weather .card-wrapper .temprature,
.weather .card-wrapper .rain {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 1.6rem;
}
.weather .card-wrapper .rain img {
  height: 70px;
  width: 70px;
  margin-top: -10px;
}
.weather .card-wrapper .rain p {
  margin-top: -15px;
}
.weather .card-wrapper .card.active {
  box-shadow: none;
  background-image: linear-gradient(to bottom, #ffffff 5%, rgba(139, 183, 159, 0.5), rgba(159, 188, 89, 0.5));
}
.weather .card-wrapper .card.active .text {
  font-weight: bold;
}
@media (min-width: 767px) {
  .weather {
    max-height: 100vh;
    position: fixed;
    left: 0;
    height: 100vh;
    width: 400px;
    z-index: 5;
  }
  .weather .card-wrapper {
    padding: 5px 15px;
    max-height: calc(100vh - 380px);
    overflow: scroll;
  }
  .weather .card-wrapper::-webkit-scrollbar {
    width: 0 !important;
  }
}

.crop-prices {
  background-color: white;
  padding: 30px 20px 0 20px;
}
.crop-prices .heading-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.crop-prices .heading-container button {
  padding: 8px 10px;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0.5s 2 both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@media (max-width: 767px) {
  .crop-prices .heading-container button {
    box-shadow: none;
  }
}
.crop-prices .crops-name {
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  flex-direction: row;
}
.crop-prices .crops-name div {
  text-align: center;
  padding: 8px 24px;
  cursor: pointer;
}
.crop-prices .crops-name div.active {
  background-color: red;
  border-radius: 25px;
  color: white;
  font-weight: bold;
  background-image: linear-gradient(to bottom right, #ffffff 5%, rgb(139, 183, 159), rgb(56, 142, 60));
}
@media (min-width: 900px) {
  .crop-prices .crops-name {
    max-width: 500px;
  }
}
.crop-prices .mandi-cards {
  display: grid;
  grid-template: auto/1fr;
  grid-auto-rows: 200px;
  grid-row-gap: 25px;
}
.crop-prices .mandi-cards > div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-image: linear-gradient(to bottom right, white 0% 90%, rgb(139, 183, 159), rgb(56, 142, 60));
}
.crop-prices .mandi-cards p {
  font-size: 1.6rem;
}
.crop-prices .mandi-cards h3 {
  opacity: 0.8;
}
@media (min-width: 767px) {
  .crop-prices {
    padding: 30px 40px 20px 40px;
    position: relative;
    left: 400px;
    width: calc(100% - 400px);
  }
  .crop-prices .mandi-cards {
    margin-top: 50px;
    grid-template: 250px/repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 250px;
    grid-gap: 50px 5%;
  }
}

@media (min-width: 767px) {
  .weather-page {
    max-height: 100vh;
    height: 100vh;
    display: block;
  }
}
.weather-page-error {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.weather-page-error .error-icon {
  height: 50px;
  width: 50px;
  margin-bottom: 24px;
}
.weather-page-error .logo {
  max-width: 80%;
  margin: 10px 0 25px 0;
}
.weather-page-error p {
  text-align: center;
  font-size: 1.6rem;
}
@media (min-width: 767px) {
  .weather-page-error .logo {
    margin-top: 0;
    width: 30%;
  }
}

.weather-footer {
  width: 100%;
  bottom: 5px;
  padding: 50px 0 5px 0;
}
.weather-footer .img {
  grid-area: button;
  padding: 25px 20px;
}
.weather-footer .img img {
  display: block;
  max-width: 250px;
  height: auto;
  margin: auto;
}
.weather-footer .footer-text {
  grid-area: img;
  font-size: 1.8rem;
  text-align: center;
}
.weather-footer .footer-text button {
  outline: none;
  border: none;
  background-color: rgb(56, 142, 60);
  padding: 10px 15px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  font-weight: bold;
  color: white;
  display: inline-block;
  margin: 15px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.weather-footer .footer-text button:hover {
  opacity: 0.9;
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.2);
}
.weather-footer .footer-text img {
  display: inline;
}
@media (min-width: 767px) {
  .weather-footer {
    left: 400px;
    width: calc(100% - 400px);
    display: grid;
    grid-template: auto/1fr 1fr;
    grid-template-areas: "img button";
  }
  .weather-footer .footer-text {
    grid-area: button;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .weather-footer .footer-text button {
    margin-bottom: 50px;
  }
  .weather-footer .img {
    grid-area: img;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.weather-page-error .weather-footer {
  left: 0;
  width: 100%;
}
@media (min-width: 767px) {
  .weather-page-error .weather-footer {
    max-width: 65%;
  }
}

.ril__image {
  max-width: none !important;
}

.farmer-information .bottom-menu {
  width: 100%;
  display: block;
  position: fixed;
  bottom: 0px;
  padding: 15px;
  z-index: 9;
  background: #f9f9f9;
  box-shadow: 0px 0px 3px 1px #ccc;
}
.farmer-information .bottom-menu .custom-button {
  border-radius: 0px;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0;
  width: 100%;
  background: #f9f9f9;
  transition: all 0.5s ease;
}
.farmer-information .bottom-menu .custom-button:hover {
  background: #585858;
  color: #fff;
  transition: all 0.5s ease;
}
.farmer-information .chip {
  display: inline-block;
  padding: 0 25px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  margin: 5px;
  line-height: 50px;
  border-radius: 25px;
  transition: all 0.3s ease;
  background-color: honeydew;
}
.farmer-information .chip:hover {
  box-shadow: 1px 1px 1px 1px #ccc;
  transition: all 0.3s ease;
}
.farmer-information .statement-block {
  background: #f9f9f9;
  padding: 20px;
  color: rgba(0, 0, 0, 0.6588235294);
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  border: 5px dashed #f1f1f1;
}
.farmer-information .farmer-block {
  box-shadow: 1px 1px 2px 1px #ccc;
  background: #fff;
}
.farmer-information .farmer-block .text-block {
  margin: 10px 0;
  padding: 10px;
  background: #f9f9f9;
}
.farmer-information .farmer-block .farmer-block-row {
  margin: 10px 0;
  padding: 10px;
  font-size: 15px;
  color: #585858;
  transition: all 1s ease;
  border-bottom: 1px solid #f1f1f1;
}
.farmer-information .farmer-block .farmer-block-row:hover {
  transition: all 1s ease;
  border-bottom: 1px solid #a1bd59;
  cursor: pointer;
}
.farmer-information .farmer-block .farmer-block-img {
  display: inline-block;
  width: 30px;
}
.farmer-information .farmer-block .logo {
  width: auto !important;
}
.farmer-information .crop-prices .custom-grid {
  width: 25%;
  float: left;
  display: inline-block;
  padding: 3px;
  cursor: pointer;
}
.farmer-information .crop-prices .custom-grid figure {
  background: #f1f1f1;
}
.farmer-information .crop-prices .image-heading {
  margin: 0px 0px 20px 0;
  font-size: 25px;
  font-weight: 700;
  color: #585858;
}
.farmer-information .crop-prices .img-flex {
  width: auto;
  height: 200px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.farmer-information .non-dimension-report div.cards-container {
  margin: 10px 40px;
  display: flex;
  flex-wrap: wrap;
}
.farmer-information .non-dimension-report div.card {
  width: 30%;
  padding: 20px;
  background: #f8fff8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 8px;
  box-shadow: 1px 1px 2px #e0e0e0;
}
.farmer-information .non-dimension-report div.card:hover {
  box-shadow: 2px 2px 2px #e0e0e0;
}
.farmer-information .non-dimension-report .card-heading {
  margin-bottom: 5px;
  text-align: center;
}
.farmer-information .non-dimension-report .card-content {
  font-size: 15px;
  font-weight: 500;
  padding: 5px;
}
@media all and (max-width: 440px) {
  .farmer-information .non-dimension-report div.card {
    width: 90%;
  }
}
@media all and (max-width: 600px) and (min-width: 440px) {
  .farmer-information .non-dimension-report div.card {
    width: 45%;
  }
}
@media all and (max-width: 1040px) and (min-width: 768px) {
  .farmer-information .non-dimension-report div.card {
    width: 90%;
  }
}

.login-form {
  height: 100vh;
  margin: 0 auto;
  width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-form_icon {
  font-size: 10rem;
  text-align: center;
  color: rgba(34, 139, 34, 0.3);
}
.login-form_icon > img {
  margin: 0 auto;
}
.login-form_error {
  font-size: 1.6rem;
  color: red;
}
@media (max-width: 447px) {
  .login-form_error {
    font-size: 1.8rem;
    margin-top: 1rem;
  }
}
.login-form_submit-error {
  padding: 1rem;
  margin-top: 2rem;
  text-align: center;
  background-color: rgba(255, 228, 225, 0.7);
  border-radius: 4px;
  border: 1px solid red;
  color: red;
  font-size: 1.6rem;
}
@media (max-width: 447px) {
  .login-form_submit-error {
    padding: 1.6rem;
  }
}
@media (min-width: 1610px) {
  .login-form {
    padding-top: 10rem;
  }
}
@media (max-width: 1600px) {
  .login-form {
    padding-top: 20rem;
  }
}
@media (max-width: 447px) {
  .login-form {
    padding-top: 0;
    min-height: 100vh;
    width: 100vw;
  }
}

.login-btn {
  padding: 0.7rem 2rem;
  margin-top: 2rem;
  background-color: rgba(34, 139, 34, 0.08);
  border: 1px solid forestgreen;
  color: forestgreen;
  font-size: 2rem;
  text-transform: uppercase;
  float: right;
  transition: 200ms;
  outline: none;
}
.login-btn:hover {
  border-radius: 4px;
  box-shadow: 0px 0px 2px 2px rgba(34, 139, 34, 0.2);
}
@media (max-width: 447px) {
  .login-btn {
    margin-top: 3rem;
    padding: 1.6rem 2rem;
    border-radius: 4px;
  }
}
.login-btn--special {
  margin-top: 1rem;
  width: 100%;
  float: none;
  text-transform: none;
}

.show-password {
  font-size: 2rem;
  text-align: center;
  background-color: white;
  color: #777;
  padding: 1rem 0;
  width: 10%;
  cursor: pointer;
}
@media (max-width: 447px) {
  .show-password {
    font-size: 3rem;
  }
}

.login-form--password {
  width: 90% !important;
  border-radius-right-bottom: 0;
  margin: 0;
}

.password__container {
  display: flex;
  margin: 3rem 0 0 0;
}

.mobile-screen {
  min-height: 100vh;
  margin: 0 auto 0 auto;
  width: 70rem;
  color: #444444;
}
@media (max-width: 667px) {
  .mobile-screen {
    min-height: 100vh;
    width: 100vw;
    margin-top: 4rem;
  }
}
@media (max-width: 447px) {
  .mobile-screen {
    min-height: 100vh;
    width: 100vw;
    margin-top: 4rem;
  }
}
.mobile-screen__heading {
  display: inline-block;
  margin: 3rem;
  font-weight: bold;
}
@media (max-width: 447px) {
  .mobile-screen__heading {
    margin: 0 3rem 4rem 3rem;
  }
}
.mobile-screen__container {
  flex: 1;
  background-color: rgba(34, 139, 34, 0.2);
  border-top-left-radius: 4rem;
  border-top-right-radius: 4rem;
  height: 100%;
  padding: 3rem;
  flex: 1;
}
.mobile-screen__info {
  flex: 1;
  height: 100%;
  padding: 3rem;
  flex: 1;
  border: "1px #ccc solid";
}

.mobile-form {
  display: flex;
  flex-direction: column;
}

.candidate {
  margin-left: 30px;
}
.candidate__name {
  font-size: 2rem;
  font-weight: bold;
}
@media (max-width: 900px) {
  .candidate__name {
    font-size: 3rem;
  }
}
@media (max-width: 447px) {
  .candidate__name {
    font-size: 2.5rem;
  }
}
.candidate__profile {
  font-size: 1.5rem;
}
@media (max-width: 900px) {
  .candidate__profile {
    font-size: 2.1rem;
  }
}
@media (max-width: 447px) {
  .candidate__profile {
    font-size: 1.9rem;
  }
}
.candidate__mobile {
  font-size: 1.5rem;
}
@media (max-width: 900px) {
  .candidate__mobile {
    font-size: 2.1rem;
  }
}
@media (max-width: 447px) {
  .candidate__mobile {
    font-size: 1.9rem;
  }
}

.document__list {
  margin-top: 1rem;
}
.document__list_item {
  font-size: 1.6rem;
  margin: 2rem 0 0 0;
  position: relative;
  background-color: #ffffff;
  padding: 1rem 1.6rem;
  border-radius: 4px;
}
@media (max-width: 900px) {
  .document__list_item {
    font-size: 2.4rem;
  }
}
@media (max-width: 447px) {
  .document__list_item {
    font-size: 2rem;
  }
}
.document__list_item > span {
  font-weight: bold;
}
.document__list_item--delete {
  position: absolute;
  top: -5px;
  right: -5px;
  color: crimson;
  background-color: mistyrose;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 900px) {
  .link {
    font-size: 4.4rem;
  }
}
@media (max-width: 447px) {
  .link {
    padding: 4rem;
  }
  .link h2 {
    font-size: 4rem;
  }
}
.link-broken {
  width: 30rem;
  height: 30rem;
  margin: -15rem auto 0 auto;
}
@media (max-width: 447px) {
  .link-broken {
    margin: -25rem auto 0 auto;
  }
}

.stepper-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 10px;
  margin-bottom: 5px;
}
.stepper-buttons .back-btn {
  width: 100%;
  background-color: #836100;
  padding: 1rem;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 1.6rem;
  margin-top: 2rem;
  transition: 150ms;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}
.stepper-buttons .next-btn {
  width: 100%;
  background-color: forestgreen;
  padding: 1rem;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 1.6rem;
  margin-top: 2rem;
  transition: 150ms;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}
.stepper-buttons .submit-btn {
  width: 100%;
  padding: 1rem;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 1.6rem;
  margin-top: 2rem;
  transition: 150ms;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  background-color: forestgreen;
}

.docs-submit-btn {
  width: 100%;
  background-color: forestgreen;
  padding: 1rem;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 1.6rem;
  margin-top: 2rem;
  transition: 150ms;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}
.docs-submit-btn:hover {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
}
.docs-submit-btn:active {
  transform: translateY(1px);
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}
@media (max-width: 900px) {
  .docs-submit-btn {
    font-size: 3.2rem;
  }
}
@media (max-width: 447px) {
  .docs-submit-btn {
    padding: 2.2rem;
    font-size: 2.8rem;
  }
}

.success-modal {
  position: fixed;
  width: 20%;
  min-width: 15rem;
  height: 24%;
  left: 50%;
  min-height: 15rem;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10000;
}
@media (max-width: 900px) {
  .success-modal {
    height: 45rem;
    width: 70rem;
  }
}
@media (max-width: 447px) {
  .success-modal {
    top: 40%;
    height: 25rem;
    width: 40rem;
  }
}
.success-modal_wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100000;
}
.success-modal > p {
  font-size: 1.6rem;
  margin: 1rem 0 0 0;
}

.done-btn {
  outline: none;
  background-color: forestgreen;
  padding: 0.5rem 2rem;
  width: 10rem;
  color: white;
  font-size: 1.6rem;
  border: none;
  margin-top: 2rem;
}
@media (max-width: 900px) {
  .done-btn {
    padding: 1rem 3rem;
    width: 14rem;
    font-size: 2.8rem;
    margin-top: 3rem;
  }
}
@media (max-width: 447px) {
  .done-btn {
    padding: 1rem 3rem;
    width: 14rem;
    font-size: 2.4rem;
    margin-top: 3rem;
  }
}

.confirm__data {
  -ms-overflow-style: none;
  font-size: 1.8rem;
  overflow: scroll;
  max-height: 90vh;
  padding-bottom: 1rem;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.confirm__data ol {
  counter-reset: item;
}
.confirm__data ol > li {
  counter-increment: item;
}
.confirm__data ol li p,
.confirm__data ul li p {
  display: inline;
}
.confirm__data ol li p > strong,
.confirm__data ul li p > strong {
  display: block;
}
.confirm__data ol ol > li {
  display: block;
}
.confirm__data ol ol > li:before {
  content: counters(item, ".") ". ";
  margin-left: -20px;
}
@media (max-width: 900px) {
  .confirm__data {
    font-size: 2rem;
  }
}
@media (max-width: 447px) {
  .confirm__data {
    font-size: 2rem;
  }
}
.confirm__data ul {
  list-style: inside;
}
.confirm__data--highlight {
  font-size: 1.6rem;
  font-weight: bold;
}
@media (max-width: 900px) {
  .confirm__data--highlight {
    font-size: 2.4rem;
  }
}
@media (max-width: 447px) {
  .confirm__data--highlight {
    font-size: 2.2rem;
  }
}
.confirm__data--support {
  font-size: 1.2rem;
  font-style: italic;
  font-weight: bold;
}
@media (max-width: 900px) {
  .confirm__data--support {
    font-size: 2rem;
  }
}
@media (max-width: 447px) {
  .confirm__data--support {
    font-size: 1.6rem;
  }
}
.confirm__data * {
  margin: 1rem 0;
}
@media (max-width: 900px) {
  .confirm__data * {
    margin: 1.2rem 0;
  }
}
@media (max-width: 447px) {
  .confirm__data * {
    margin: 1.6rem 0;
  }
}
.confirm__data::-webkit-scrollbar {
  display: none;
}
.confirm__btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: forestgreen;
  padding: 1rem;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 1.6rem;
  transition: 150ms;
}
@media (max-width: 900px) {
  .confirm__btn {
    font-size: 3.2rem;
  }
}
@media (max-width: 447px) {
  .confirm__btn {
    padding: 2.2rem;
    font-size: 2.8rem;
  }
}

.details .error--label {
  font-size: 1.5rem;
  color: crimson;
  font-style: italic;
  margin: 0.2rem 0 0 0;
  opacity: 0;
}
.details__control--label {
  font-size: 1.6rem;
  margin: 1rem 0 0 0;
}
@media (max-width: 900px) {
  .details__control--label {
    font-size: 2.4rem;
  }
}
@media (max-width: 447px) {
  .details__control--label {
    font-size: 2rem;
    margin: 2rem 0 0 0;
  }
}
.details__control--input {
  margin: 0.5rem 0 0 0;
  font-size: 1.6rem;
  padding: 1rem 1rem;
  border: none;
  outline: none;
  border-radius: 4px;
  width: 100%;
  border: 1px solid transparent;
  background-color: #ffffff;
}
.details__control--input:placeholder-shown > label {
  opacity: 0;
}
.details__control--input::placeholder {
  color: #aaa;
}
.details__control--input:focus > label {
  opacity: 0;
}
@media (max-width: 900px) {
  .details__control--input {
    font-size: 3.2rem;
  }
}
@media (max-width: 447px) {
  .details__control--input {
    font-size: 2.6rem;
  }
}
.details__control--phone {
  display: flex;
  justify-content: space-between;
}
.details__radio--wrapper {
  display: flex;
  margin: 1rem 0 0 0;
}
.details__radio--btn {
  display: flex;
  font-size: 1.6rem;
  margin: 0 1rem;
}
.details__radio--btn > label {
  margin-left: 1.5rem;
}

.details__container {
  display: block;
  position: relative;
  padding-left: 3rem;
  margin: 0 2rem 1rem 0;
  cursor: pointer;
  font-size: 1.6rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 900px) {
  .details__container {
    font-size: 2.6rem;
  }
}
@media (max-width: 447px) {
  .details__container {
    margin: 0 3rem 1rem 0;
    font-size: 2.2rem;
  }
}

/* Hide the browser's default radio button */
.details__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.details__container--checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
  background-color: #eee;
  border-radius: 50%;
}
@media (max-width: 900px) {
  .details__container--checkmark {
    height: 3.2rem;
    width: 3.2rem;
  }
}
@media (max-width: 447px) {
  .details__container--checkmark {
    height: 2.8rem;
    width: 2.8rem;
  }
}

/* On mouse-over, add a grey background color */
.details__container:hover input ~ .details__container--checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.details__container input:checked ~ .details__container--checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.details__container--checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.details__container input:checked ~ .details__container--checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.details__container .details__container--checkmark:after {
  top: 0.5rem;
  left: 0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: white;
}
@media (max-width: 447px) {
  .details__container .details__container--checkmark:after {
    top: 0.8rem;
    left: 0.8rem;
    width: 1.2rem;
    height: 1.2rem;
  }
}

.add-nominee-btn {
  background-color: transparent;
  padding: 0.5rem 0 0 0;
  outline: none;
  border: none;
  color: #0275db;
  font-size: 1.8rem;
  margin: 1rem;
  transition: 150ms;
  font-weight: bold;
  display: inline-block;
  border-bottom: 2px solid transparent;
}
.add-nominee-btn:hover {
  border-bottom: 2px solid #0275db;
}
@media (max-width: 900px) {
  .add-nominee-btn {
    font-size: 3.2rem;
  }
}
@media (max-width: 447px) {
  .add-nominee-btn {
    padding: 1.5rem;
    font-size: 2.4rem;
  }
}
.add-nominee-btn--blue {
  color: #0275db;
}
.add-nominee-btn--blue:hover {
  border-bottom: 2px solid #0275db;
}
.add-nominee-btn--orange {
  color: orangered;
}
.add-nominee-btn--orange:hover {
  border-bottom: 2px solid orangered;
}

.add-location-btn {
  background-color: rgba(34, 139, 34, 0.1);
  padding: 0.5rem 2rem;
  outline: none;
  border: 1px solid forestgreen;
  border-radius: 4px;
  color: forestgreen;
  font-size: 1.6rem;
  transition: 150ms;
  font-weight: bold;
  display: inline-block;
  margin: 0 0 0.5rem 0;
}
.add-location-btn:hover {
  box-shadow: 0 1px 2px 0px rgba(34, 139, 34, 0.3);
  box-shadow: 0 1px 3px 1px rgba(34, 139, 34, 0.15);
}
.add-location-btn:active {
  box-shadow: 0 1px 2px 0px rgba(34, 139, 34, 0.3);
  box-shadow: 0 1px 3px 1px rgba(34, 139, 34, 0.15);
}
@media (max-width: 900px) {
  .add-location-btn {
    padding: 2rem;
    font-size: 2.8rem;
  }
}
@media (max-width: 447px) {
  .add-location-btn {
    padding: 1.5rem;
    font-size: 2.4rem;
  }
}

.nominee {
  background-color: rgba(255, 69, 0, 0.7);
  padding: 2rem;
  border-radius: 5px;
  font-size: 1.8rem;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin: 1.5rem 0;
  color: white;
}
.nominee__remove {
  position: absolute;
  top: -5px;
  right: -5px;
  color: crimson;
  background-color: white;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  font-size: 1.8rem;
  text-align: center;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
@media (max-width: 447px) {
  .nominee__remove {
    width: 2.8rem;
    height: 2.8rem;
  }
}
.nominee__input {
  background-color: #efefef;
  padding: 0 2rem 2rem 2rem;
  margin: 1rem 0 1rem 0;
  border-radius: 5px;
}

.candidate_signature {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 70rem;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302);
  box-shadow: 0 1px 3px 1px rgba(60, 64, 67, 0.149);
}
.candidate_signature--clear {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 1.6rem;
  border: none;
  outline: none;
  border: 1px solid #777;
  border-radius: 10px;
  transition: 150ms;
}
.candidate_signature--clear:hover {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302);
}
.candidate_signature--cancel {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.6rem;
  border: none;
  outline: none;
  border: 1px solid #777;
  border-radius: 10px;
  transition: 150ms;
}
.candidate_signature--cancel:hover {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302);
}
.candidate_signature > div {
  background-color: #efefef;
  font-size: 2rem;
  text-align: center;
  padding: 1rem;
}
@media (max-width: 900px) {
  .candidate_signature {
    width: 100vw;
  }
}
@media (max-width: 447px) {
  .candidate_signature {
    width: 100vw;
  }
}
.candidate_signature--animate {
  animation: animate-in 500ms ease-in-out forwards;
}

@keyframes animate-in {
  0% {
    transform: translate(-50%, 100%);
  }
  80% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
.location__success--label {
  font-size: 1.8rem;
  margin: 0.5rem 0;
  color: forestgreen;
}
.location__success--label > i {
  margin-right: 1rem;
}

.aadhaar__image {
  display: none !important;
}
.aadhaar__image-label {
  color: #aaa;
  font-weight: 100;
  cursor: pointer;
}
.aadhaar__label {
  cursor: pointer;
  font-weight: normal;
}
.aadhaar__files {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 900px) {
  .aadhaar__files {
    flex-direction: column;
  }
}
@media (max-width: 447px) {
  .aadhaar__files {
    flex-direction: column;
  }
}
.aadhaar__files--file {
  width: 48%;
}
@media (max-width: 447px) {
  .aadhaar__files--file {
    width: 100%;
  }
}
.aadhaar__filename {
  word-break: break-word;
}

.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 0 10rem;
  overflow-y: auto;
}
@media (max-width: 900px) {
  .modal-container {
    padding: 0 3rem;
    overflow: scroll;
    padding: 6rem 3rem 4rem 3rem;
  }
}
@media (max-width: 447px) {
  .modal-container {
    padding: 0 3rem;
    overflow: scroll;
    padding: 8rem 3rem 4rem 3rem;
  }
}

.uploaded__doc {
  padding: 1rem;
  border-radius: 4px;
  font-size: 1.6rem;
  margin: 1rem 0;
  color: forestgreen;
  display: flex;
}
@media (max-width: 900px) {
  .uploaded__doc {
    font-size: 2.6rem;
  }
}
@media (max-width: 447px) {
  .uploaded__doc {
    font-size: 2.2rem;
  }
}
.uploaded__doc > i {
  font-size: 2.4rem;
  margin-right: 1rem;
}
@media (max-width: 900px) {
  .uploaded__doc > i {
    font-size: 3.4rem;
  }
}
@media (max-width: 447px) {
  .uploaded__doc > i {
    font-size: 3.2rem;
  }
}

.m-signature-pad {
  position: absolute;
  width: 100%;
  height: 40rem;
  bottom: 0;
  left: 0;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
  border-radius: 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.m-signature-pad--body {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 60px;
  border: 1px solid #f4f4f4;
}

.m-signature-pad--body canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

.m-signature-pad--footer {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  height: 40px;
}

.m-signature-pad--footer .description {
  color: #C3C3C3;
  text-align: center;
  font-size: 1.2em;
  margin-top: 1.8em;
}

.m-signature-pad--footer .button {
  position: absolute;
  bottom: 0;
}

.m-signature-pad--footer .button.clear {
  left: 0;
}

.m-signature-pad--footer .button.save {
  right: 0;
}

@media screen and (max-width: 1024px) {
  .m-signature-pad {
    width: 100vw;
    height: auto;
    min-width: 250px;
    min-height: 140px;
  }
  #github {
    display: none;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .m-signature-pad {
    margin: 10%;
  }
}
@media screen and (max-height: 320px) {
  .m-signature-pad--body {
    left: 0;
    right: 0;
    top: 0;
    bottom: 32px;
  }
  .m-signature-pad--footer {
    left: 20px;
    right: 20px;
    bottom: 4px;
    height: 28px;
  }
  .m-signature-pad--footer .description {
    font-size: 1em;
    margin-top: 1em;
  }
}
.candidate-page {
  margin-top: 0;
  font-size: 1.6rem;
  padding: 20px 10px 10px 10px;
}
.candidate-page label {
  font-size: 12px;
  margin-bottom: 5px;
}
.candidate-page p {
  font-size: 14px;
}
.candidate-page .form-control {
  border-color: #ccc;
  height: 40px;
  box-shadow: none;
  color: #333;
}

.candidate-heading {
  color: forestgreen;
  font-family: Aileron;
  margin: 10px 0;
}

.candidate-section {
  padding: 10px;
  margin: 15px 0;
  border: 1px solid #ccc;
}

.cn-page-header {
  text-align: center;
  font-weight: 500;
}

.candidate-btn {
  margin: 15px 0;
  width: 100%;
  padding: 10px 10px;
  font-size: 1.6rem;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #333;
}
.candidate-btn.secondary {
  background-color: #836100;
  color: white;
}
.candidate-btn.primary {
  background: rgb(159, 188, 89);
  border: 1px solid rgb(159, 188, 89);
  color: #ffffff;
}
.candidate-btn.sm {
  margin: 5px 0;
  padding: 5px;
  font-size: 1.6rem;
}

.candidate-doc {
  padding: 10px;
  border-radius: 5px;
  margin: 15px 0;
  border: 1px dashed #ccc;
}
.candidate-doc.already-uploaded {
  border: 1px solid #ccc;
}
.candidate-doc p {
  margin-bottom: 5px;
}
.candidate-doc label {
  background-color: #f9f9f9;
  padding: 20px;
  margin: 10px 0;
  border-radius: 5px;
  display: block;
  font-weight: normal;
}

.page-loader .overlay, .page-loader .content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(248, 248, 248, 0.6784313725);
}
.page-loader .overlay {
  z-index: 1000;
}
.page-loader .content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10002;
  margin-top: 10px;
}
.page-loader .wrapper {
  padding: 30px;
  text-align: center;
  animation: imagePulse 1.4s linear infinite;
}
.page-loader img {
  margin: 20px auto;
  height: 100px;
  width: 100px;
}
.page-loader .wrapper-text {
  margin: 10px 0;
  font-weight: bold;
  color: #333;
}
.page-loader p.wrapper-text {
  font-size: 3rem;
  font-weight: bold;
}
.page-loader .taking-longer {
  margin: 15px 0;
}
.page-loader .taking-longer, .page-loader .quote {
  opacity: 0;
}
.page-loader .taking-longer.fade-in, .page-loader .quote.fade-in {
  animation: fadeIn 0.8s ease-in-out 0s forwards 1;
}
.page-loader .loader {
  position: fixed;
  top: 0;
  z-index: 10000;
  height: 4px;
  width: 400px;
  background-color: #9fbc59;
  animation: loaderWidth 1s linear 0s 1 forwards, slowLoaderWidth 10s linear 1s 1 forwards;
}

@keyframes imagePulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loaderWidth {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
@keyframes slowLoaderWidth {
  0% {
    width: 90%;
  }
  100% {
    width: 99%;
  }
}
.spinny-loader {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 5px solid #333;
  position: relative;
}
.spinny-loader::after {
  content: "";
  position: absolute;
  height: 30px;
  width: 4px;
  background-color: green;
  top: 50%;
  transform-origin: top center;
  left: 50%;
  margin-left: -2px;
  animation: spinny 2s linear infinite;
}

@keyframes spinny {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.qna-multimedia-question {
  margin: 40px auto;
  font-size: 1.6rem;
}
.qna-multimedia-question > * {
  margin: 10px 0;
}
.qna-multimedia-question blockquote {
  margin: 25px 0;
}

.qna-media-videos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.qna-media-videos video {
  width: 100%;
}
@media (max-width: 1023px) {
  .qna-media-videos {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767px) {
  .qna-media-videos {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 500px) {
  .qna-media-videos {
    display: block;
  }
  .qna-media-videos video {
    width: 100%;
  }
}

.qna-media-images {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
}
@media (max-width: 500px) {
  .qna-media-images {
    grid-template-columns: repeat(4, 1fr);
  }
}

.qna-media-icon {
  font-size: 5rem;
  padding: 1rem;
  cursor: pointer;
}

.qna-media-icon-container {
  display: flex;
  flex-wrap: wrap;
}

.hhero-btn {
  background-color: #228b22;
  border-color: #228b22;
}
.hhero-btn:hover {
  opacity: 0.8;
  color: rgb(255, 255, 255);
  background-color: #228b22;
}

.hero {
  padding-top: 5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.hero .hero_left {
  text-align: left;
  max-width: 518px;
  min-width: 200px;
}
.hero .hero_left h1 {
  text-align: left !important;
  font-size: 30px;
}
.hero .hero_left p {
  padding: 1rem 2rem 0 0;
}
.hero .hero_right {
  max-width: 560px;
}

.cards {
  padding: 5rem;
  padding-top: 10rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 2rem;
  border-radius: 10px;
  width: 216px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc(100% - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 8px;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}
@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
  .hero {
    display: grid;
    gap: 4rem;
  }
  .hero .hero_left {
    margin: auto;
    text-align: center;
  }
  .hero .hero_left h1 {
    text-align: center !important;
  }
  .hero .hero_right {
    margin: auto;
    max-width: 100%;
  }
  .hero .hero_left div {
    margin: auto;
  }
  .hero .hero_left p {
    padding: 1rem 2rem 10px 0;
  }
}
@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}
/*****Landing page carousal modified css of carousal package*******/
.kopia-page-slider .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}
.kopia-page-slider .carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0) !important;
}

.kopia-farmer-listing {
  min-height: 100vh;
  padding: 11rem 2rem 3rem 0;
  font-size: 1.6rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: 0.26fr 0.8fr;
  font-family: "PT Sans", sans-serif;
  background-color: #f1f3f6;
}
@media (max-width: 767px) {
  .kopia-farmer-listing {
    padding: 13rem 2rem 2rem 2rem;
    display: block;
  }
}
@media (min-width: 767px) and (max-width: 1024px) {
  .kopia-farmer-listing {
    padding: 13rem 2rem 2rem 0;
  }
}

.kopia-locations-filter {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  overflow-x: auto;
  display: flex;
  gap: 4rem;
}
.kopia-locations-filter button {
  background-color: white;
  border-radius: 40px;
  padding: 1rem 1.5rem;
  border: none;
}
.kopia-locations-filter button i {
  margin-right: 1rem;
}
.kopia-locations-filter button.selected-infra {
  font-weight: bold;
  background-color: #9fbc59;
  color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.kopia-sidebar-filters {
  position: sticky;
  margin-left: 16px;
  top: 0;
  height: 100%;
}
.kopia-sidebar-filters-heading {
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
}

.kopia-sidebar-filter {
  margin: 1rem 0;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
}
.kopia-sidebar-filter p {
  margin-bottom: 1rem;
  opacity: 0.9;
}
.kopia-sidebar-filter label {
  font-weight: normal;
  display: block;
}
.kopia-sidebar-filter input {
  margin-right: 1rem;
  background-color: #f5f5f5;
  opacity: 0.8;
  border-radius: 4px;
  padding: 4px 4px 4px 8px;
  border: 1px solid #c4c4c4;
}
.kopia-sidebar-filter button {
  border: none;
  padding: 0.4rem 1.4rem;
  border-radius: 2px;
  background-color: #9fbc59;
  color: white;
  margin-top: 10px;
  font-size: 1.4rem;
}
.kopia-sidebar-filter button:hover {
  opacity: 0.8;
  color: rgb(255, 255, 255);
  background-color: #228b22;
}

.kopia-farmer-listing-container {
  display: grid;
  padding: 2rem 0;
  color: black;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
}
@media (max-width: 1024px) {
  .kopia-farmer-listing-container {
    padding: 2rem 2rem 2rem 0;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .kopia-farmer-listing-container {
    padding: 2rem 0;
    grid-template-columns: 1fr;
  }
}

.kopia-farmer-card {
  padding: 1rem 2rem;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: white;
}
.kopia-farmer-card .heading-tertiary {
  margin: 2rem 0;
}
.kopia-farmer-card h3 {
  margin-bottom: 1rem;
  font-family: "Montserrat", sans-serif;
}
.kopia-farmer-card h2 {
  margin-bottom: 0;
}

.kopia-crop-card-image,
.kopia-farmer-card-image {
  height: 200px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 1.5rem 1%;
  cursor: pointer;
  width: 48%;
  display: inline-block;
}

.kopia-farmer-card-footer {
  display: flex;
  justify-content: space-between;
}
.kopia-farmer-card-footer button {
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 4px;
}
.kopia-farmer-card-footer .kopia-farmer-cta {
  background-color: #9fbc59;
  color: white;
  border-radius: 4px;
}
.kopia-farmer-card-footer .kopia-farmer-cta:hover {
  opacity: 0.8;
  color: rgb(255, 255, 255);
  background-color: #228b22;
}

.kn-ops-theme-btn {
  color: white;
  background-color: #9fbc59;
  border: none;
  padding: 1rem;
  border-radius: 4px;
}

.kopia-farmer-filter-toggler {
  display: none;
  padding: 1rem 2rem;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
}
@media (max-width: 500px) {
  .kopia-farmer-filter-toggler {
    display: inline-block;
  }
}

@media (max-width: 500px) {
  .kopia-farmer-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100vh;
    background-color: white;
    z-index: 4;
    transition: transform 0.3s linear;
    transform: translateX(-100%);
  }
  .kopia-farmer-sidebar.sidebar-open {
    transform: translateX(0);
  }
}

.kopia-sidebar-filters-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}
@media (max-width: 500px) {
  .kopia-sidebar-filters-overlay.overlay-open {
    display: block;
    z-index: 3;
  }
}

.kopia-farmer-listing-header {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.kopia-sidebar-filters-heading {
  display: flex;
  justify-content: space-between;
}
.kopia-sidebar-filters-heading button {
  background-color: #f6f6f6;
  border: 1px solid #ccc;
  display: none;
}
@media (max-width: 500px) {
  .kopia-sidebar-filters-heading button {
    display: inline-block;
  }
}

.kopia-farmer-card-logo {
  height: 200px;
  position: relative;
  margin: 1.5rem auto;
}
.kopia-farmer-card-logo img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  width: 100px;
}

.modal-wrapper {
  background: #b1c155;
  max-width: 400px;
  margin: auto;
  transition: all 0.5s ease;
  z-index: 1;
  position: fixed;
  overflow: hidden;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  max-height: 80vh;
  overflow: auto;
  width: 95%;
}

.Gallerymodal__wrapper {
  background: #b1c155;
  max-width: 95vw;
  margin: auto;
  transition: all 0.5s ease;
  border-radius: 10px;
  z-index: 1;
  position: fixed;
  overflow: hidden;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  max-height: 90vh;
}
@media only screen and (max-width: 640px) {
  .Gallerymodal__wrapper {
    overflow-y: auto;
    width: 95%;
  }
}

.modal__header {
  background: #b1c155;
  color: #e9e9e9;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
}

body.modal-open {
  overflow: hidden;
  height: 100vh;
}

.modal__header h4 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.modal__header span {
  font-size: 2rem;
  cursor: pointer;
  color: #e9e9e9;
}

.modal--content {
  padding: 0rem;
  background-color: #fff;
  width: 100%;
}

.modal-body {
  margin-bottom: 1rem;
  padding: 0 !important;
}

.Gallerymodal-body {
  margin-bottom: 1rem;
  padding: 0 !important;
  display: flex;
}

.modal-body h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.modal-body h4 span {
  font-weight: normal;
}

.modal-body p {
  font-size: 10px;
  color: #333;
  padding: 1rem;
  margin: 1rem 0 2rem 0;
}

.modal-footer button {
  display: block;
  font-size: 1.2vw;
  margin-left: auto;
  padding: 0.5rem 1.8rem;
  background: #da6840;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.back-drop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(16, 16, 16, 0.9098039216);
  z-index: 9999;
}

/**************form css************/
.modal-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal-list li {
  padding: 0.3em;
}

.required-star,
.close-modal-btn {
  font-weight: 700;
  color: #102a43;
  line-height: 35px;
  font-size: 12px;
  text-transform: uppercase;
}

.modal-form input[type=submit] {
  background: #fc4366;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 5px;
  margin-top: 1.3em;
}

.modal-form {
  background-color: #ffffff;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
}

.required-star {
  color: #fc4366;
  font-size: small;
}

.modal-form input,
.modal-form textarea {
  width: 100%;
  padding: 9px 20px;
  border: 1px solid #e1e2eb;
  background-color: #fff;
  color: #102a43;
  box-sizing: border-box;
  font-size: 1.5rem;
  line-height: 2rem;
  box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
  border-radius: 3px;
  line-height: 2rem;
}

.b-detail {
  padding: 8px;
  text-align: center;
}
.b-detail h2 {
  font-size: 22px;
  text-align: left;
}
@media only screen and (min-width: 640px) {
  .b-detail {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.kopia-report-card {
  padding: 1rem 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  min-height: 500px;
  background-color: white;
}
.kopia-report-card img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.kopia-report-card .kopia-report-card-data {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .kopia-report-card {
    display: block;
    padding: 0;
  }
}

.kopia-non-dimension-container {
  min-height: 200px;
  min-width: 300px;
  max-width: 800px;
}
.kopia-non-dimension-container .control-arrow {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

/******* Farmer HomePage Slider CSS ************/
html.modal-open {
  overflow: hidden;
}
html.modal-open body.modal-open {
  height: unset;
}

.farmer_language {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: row-reverse;
  padding: 0px 4px 6px 80px;
}

.farmer_language_button {
  border: none;
  padding: 6px 22px;
  background-color: #e5e5e5;
  font-size: 16px;
  font-family: montserrat;
  font-weight: bold;
}

@media (max-width: 900px) {
  .farmer_language_button {
    padding: 4px 20px;
  }
  .farmer_language {
    margin-top: 85px;
    padding: 0px 0px 6px 80px;
  }
}
@media (max-width: 447px) {
  .farmer_language_button {
    padding: 4px 16px;
  }
  .farmer_language {
    margin-top: 85px;
    padding: 0px 0px 6px 80px;
  }
}
.Farmer-HomePage-hero {
  top: 90px;
  /*****Farmer page carousal modified css of carousal package*******/
}
.Farmer-HomePage-hero .carousel .thumbs-wrapper {
  top: -215px !important;
  padding: 60px 100px !important;
  margin: 0 !important;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #040404 98%) !important;
}
.Farmer-HomePage-hero .carousel .thumb {
  border: none !important;
  width: 180px !important;
  border-radius: 16px !important;
}
.Farmer-HomePage-hero .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
  margin: 12px !important;
}
.Farmer-HomePage-hero .carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0) !important;
}
@media (max-width: 900px) {
  .Farmer-HomePage-hero .carousel .thumbs-wrapper {
    top: -188px !important;
    padding: 60px 60px !important;
    margin: 0 !important;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #040404 98%) !important;
  }
  .Farmer-HomePage-hero .carousel .thumb {
    width: 130px !important;
  }
}
@media (max-width: 447px) {
  .Farmer-HomePage-hero .carousel .thumbs-wrapper {
    top: -60px !important;
    padding: 10px 35px !important;
    margin: 0 !important;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #040404 98%) !important;
  }
  .Farmer-HomePage-hero .carousel .thumb {
    width: 70px !important;
    border-radius: 14px !important;
  }
  .Farmer-HomePage-hero .carousel.carousel-slider .control-arrow {
    margin: -2px !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .Farmer-HomePage-hero {
    top: 76px;
  }
}
.carousal-content h1 {
  position: absolute;
  width: 100%;
  color: #9cc75a;
  justify-content: space-around;
  font-size: 48px;
  font-family: montserrat;
  font-weight: bold;
  padding: 40px;
}
.carousal-content p {
  position: absolute;
  display: flex;
  width: 100%;
  color: white;
  justify-content: space-around;
  font-size: 28px;
  font-family: montserrat;
  font-weight: bold;
  padding: 116px;
}

@media (max-width: 768px) {
  .carousal-content > h1 {
    font-size: 32px;
    padding: 26px;
  }
  .carousal-content > p {
    font-size: 24px;
    padding: 80px;
  }
  .Farmer-HomePage-hero {
    top: 76px;
  }
}
@media (max-width: 500px) {
  .carousal-content > h1 {
    font-size: 24px;
    padding: 16px;
  }
  .carousal-content > p {
    font-size: 12px;
    padding: 53px;
  }
}
/******************* Laughing Farmer section ************/
.laughingfarmer-section {
  padding: 20px 0px 0px 0px;
  margin-top: -110px;
}
.laughingfarmer-section h1 {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  font-family: montserrat;
  font-style: normal;
  opacity: 0.8;
  padding: 60px;
}

@media (max-width: 900px) {
  .laughingfarmer-section > h1 {
    font-size: 32px;
    padding: 48px;
  }
}
@media (max-width: 447px) {
  .laughingfarmer-section {
    padding: 0px 0px 0px 0px;
    margin-top: 48px;
  }
  .laughingfarmer-section > h1 {
    font-size: 28px;
    padding: 10px;
  }
}
.laughingfarmer-background {
  border-radius: 4px;
  height: 84rem;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -8rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: rgba(245, 240, 245, 0.6);
}
@media (max-width: 900px) {
  .laughingfarmer-background {
    height: 108rem;
    margin-top: -18rem;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .laughingfarmer-background {
    height: 130rem;
    margin-top: -16rem;
  }
}
@media (max-width: 447px) {
  .laughingfarmer-background {
    height: 115rem;
    margin-top: -5rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@media (max-width: 400px) {
  .laughingfarmer-background {
    height: 118rem;
    margin-top: -5rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

.laughing-farmer-img {
  height: 380px;
  width: 600px;
  background: url(../images/farmer/laugh_farmer.png);
  background-position: center;
  background-size: cover;
  margin-right: -320px;
}
@media (max-width: 900px) {
  .laughing-farmer-img {
    height: 220px;
    width: 400px;
    margin-right: -40px;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .laughing-farmer-img {
    height: 330px;
    width: 480px;
    margin-right: -40px;
  }
}
@media (max-width: 447px) {
  .laughing-farmer-img {
    height: 280px;
    width: 96vw;
    margin: 2rem 0 0 0;
  }
}

/****** Crop-section *****/
.crop-section {
  padding: 50px 0px 0px 0px;
}
.crop-section h1 {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  font-family: montserrat;
  font-style: normal;
  opacity: 0.8;
  padding: 60px;
}

.crop-section-video {
  width: 600px;
  height: 380px;
}

@media (min-width: 900px) and (max-width: 1280px) {
  .crop-section-video {
    width: 420px;
    height: 300px;
  }
  .crop-section {
    padding: 0px 0px 0px 0px;
  }
  .journey-farmer-info--text {
    font-size: 26px !important;
  }
}
@media (max-width: 900px) {
  .crop-section-video {
    width: 360px;
    height: 240px;
  }
  .crop-section > h1 {
    font-size: 32px;
    padding: 40px;
  }
  .crop-section {
    padding: 5px 0px 0px 0px;
  }
}
@media (max-width: 447px) {
  .crop-section-video {
    width: 95vw;
    height: 220px;
  }
  .crop-section {
    padding: 8px 0px 0px 0px;
  }
  .crop-section > h1 {
    font-size: 28px;
    padding: 10px;
  }
}
/**********Journey *******/
.journey-section {
  padding: 60px 0px 0px 0px;
}

.journey-section-quote {
  background-color: #38853b;
  height: 300px;
  padding: 86px 60px;
}
.journey-section-quote h1 {
  color: white;
  font-weight: bold;
  font-size: 42px;
  font-family: montserrat;
  font-style: normal;
  line-height: 1.3;
}

@media (max-width: 768px) {
  .journey-section-quote {
    height: 240px;
    padding: 55px 70px;
  }
  .journey-section-quote > h1 {
    font-size: 30px;
  }
  .journey-section {
    padding: 50px 0px 0px 0px;
  }
}
@media (max-width: 500px) {
  .journey-section-quote {
    height: 212px;
    padding: 22px 30px;
  }
  .journey-section-quote > h1 {
    font-size: 26px;
  }
  .journey-section {
    padding: 20px 0px 0px 0px;
  }
}
@media (max-width: 400px) {
  .journey-section-quote {
    height: 242px;
    padding: 20px 30px;
  }
  .journey-section-quote > h1 {
    font-size: 26px;
  }
  .journey-section {
    padding: 4px 0px 0px 0px;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .journey-section-quote {
    padding: 46px 60px;
  }
  .journey-section {
    padding: 50px 0px 0px 0px;
  }
}
.journey-farmer {
  padding: 0 96px 96px 96px;
  display: flex;
  align-items: center;
}
@media (max-width: 900px) {
  .journey-farmer {
    padding: 0 55px 96px 36px;
  }
}
@media (max-width: 667px) {
  .journey-farmer {
    flex-direction: column;
    padding: 64px 0 64px 0;
  }
}
@media (max-width: 447px) {
  .journey-farmer {
    flex-direction: column;
    padding: 0 0 64px 0;
  }
}
.journey-farmer-info {
  text-align: left;
  width: 50%;
  padding-right: 64px;
  float: left;
}
.journey-farmer-info--text {
  margin-top: 16px;
  font-size: 26px;
  color: #888;
  font-weight: 400;
  line-height: 1.5;
}
@media (max-width: 900px) {
  .journey-farmer-info {
    width: 60%;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
  }
  .journey-farmer-info .journey-farmer-info--text {
    font-size: 18px !important;
  }
}
@media (max-width: 447px) {
  .journey-farmer-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -24px;
  }
  .journey-farmer-info .journey-farmer-info--text {
    font-size: 18px;
  }
}
.journey-farmer-img {
  height: 600px;
  width: 520px;
  background: url(../images/farmer/mappic.jpg);
  background-position: center;
  background-size: cover;
  margin-right: -320px;
}
@media (max-width: 900px) {
  .journey-farmer-img {
    height: 390px;
    width: 400px;
    margin-right: -40px;
  }
}
@media (max-width: 447px) {
  .journey-farmer-img {
    height: 400px;
    width: 100%;
    margin: 2rem 0 0 0;
  }
}

.distance-numbers {
  position: relative;
  height: 250px;
  background-color: white;
}
.distance-numbers .distance-number-wrapper {
  max-width: 100vw;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f9f6f9;
}
.distance-numbers .distance-number-wrapper div {
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.distance-numbers .distance-number-wrapper div img {
  margin: 25px 0 10px 0;
  width: 30px;
  height: 30px;
}
.distance-numbers .distance-number-wrapper div h3 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.distance-numbers .distance-number-wrapper div h1 {
  text-align: center;
  position: relative;
  font-size: 2.5rem;
  font-weight: normal !important;
}
.distance-numbers .distance-number-wrapper div .number::after {
  display: block;
  position: absolute;
  content: "";
  right: 15%;
  bottom: -8px;
  height: 5px;
  width: 50%;
  background-color: forestgreen;
}
@media (max-width: 900px) {
  .distance-numbers .distance-number-wrapper div {
    width: 160px;
    height: 160px;
  }
  .distance-numbers .distance-number-wrapper div img {
    margin: 15px 0 8px 0;
  }
  .distance-numbers .distance-number-wrapper div h3 {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .distance-numbers .distance-number-wrapper div h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 447px) {
  .distance-numbers {
    height: auto;
  }
  .distance-numbers .distance-number-wrapper {
    flex-direction: column;
    justify-content: space-evenly;
    height: 700px;
  }
  .distance-numbers .distance-number-wrapper div {
    height: 160px;
    width: 160px;
    align-items: center;
  }
  .distance-numbers .distance-number-wrapper div img {
    margin-top: 20px;
  }
  .distance-numbers .distance-number-wrapper div h3 {
    margin-top: 0rem;
  }
}

/********Farmer Mandi section**********/
.farmermandi-section {
  padding: 30px 0px 0px 0px;
}
.farmermandi-section h1 {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  font-family: montserrat;
  font-style: normal;
  opacity: 0.8;
  padding: 60px;
}

@media (max-width: 900px) {
  .farmermandi-section > h1 {
    font-size: 32px;
    padding: 40px;
  }
  .farmermandi-section {
    padding: 5px 0px 0px 0px;
  }
}
@media (max-width: 447px) {
  .farmermandi-section {
    padding: 60px 0px 0px 0px;
  }
  .farmermandi-section > h1 {
    font-size: 28px;
    padding: 10px;
  }
}
.farmermandi_background {
  border-radius: 4px;
  height: 78rem;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -6rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: rgba(245, 240, 245, 0.6);
}
@media (max-width: 900px) {
  .farmermandi_background {
    height: 82rem;
    margin-top: -4rem;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .farmermandi_background {
    height: 90rem;
    margin-top: -4rem;
  }
}
@media (max-width: 447px) {
  .farmermandi_background {
    height: 100rem;
    margin-top: -5rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

.farmermandi-mandi-img {
  height: 380px;
  width: 600px;
  background: url(../images/farmer/mandi3.png);
  background-position: center;
  background-size: cover;
  margin-right: -320px;
}
@media (max-width: 900px) {
  .farmermandi-mandi-img {
    height: 220px;
    width: 400px;
    margin-right: -40px;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .farmermandi-mandi-img {
    height: 330px;
    width: 480px;
    margin-right: -40px;
  }
}
@media (max-width: 447px) {
  .farmermandi-mandi-img {
    height: 280px;
    width: 96vw;
    margin: 2rem 0 0 0;
  }
}

/******* farmer page हमसे जुड़ने के फायदे ! *********/
.benefit-join {
  padding: 40px 0px 75px 0px;
}

.benefit-join-us {
  background-color: #7f6127;
  height: 610px;
  padding: 40px 20px;
}
.benefit-join-us h1 {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 42px;
  font-family: montserrat;
  font-style: normal;
}
.benefit-join-us h1 p {
  text-align: center;
  font-weight: 300;
  font-family: montserrat;
  font-size: 20px;
  color: white;
  padding: 12px;
}

.joining_us_audio-form_section {
  justify-content: space-between;
}

.joining_us_registration_form {
  text-align: left;
  padding: 20px 20px 10px 20px;
}
.joining_us_registration_form button {
  border: none;
  padding: 0.6rem 1rem;
  border-radius: 8px;
}
.joining_us_registration_form .registration-form-cta {
  background-color: rgba(183, 38, 38, 0.9294117647);
  color: white;
  font-size: 18px;
  font-weight: 700;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .benefit-join-us {
    height: 540px;
  }
  .benefit-join {
    padding: 20px 0px 75px 0px;
  }
  .joining_us_audio-form_section {
    padding: 50px 60px 2px 60px;
  }
}
@media (min-width: 800px) and (max-width: 900px) {
  .joining_us_registration_form {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .benefit-join-us {
    height: 750px;
  }
  .benefit-join-us > h1 {
    font-size: 32px;
  }
  .benefit-join-us > h1 p {
    font-size: 16px;
  }
  .benefit-join {
    padding: 0px;
  }
  .joining_us_audio-form_section {
    padding: 16px 32px;
  }
  .joining_us_registration_form {
    text-align: center;
  }
}
@media (max-width: 500px) {
  .benefit-join-us {
    height: 830px;
    padding: 20px 14px;
  }
  .benefit-join-us > h1 {
    font-size: 28px;
  }
  .benefit-join-us > h1 p {
    font-size: 14px;
    padding: 10px 2px;
  }
  .benefit-join {
    padding: 0px;
    top: -10px;
  }
  .joining_us_audio-form_section {
    display: block;
    justify-content: space-between;
    padding: 16px 44px;
  }
  .joining_us_registration_form {
    text-align: center;
  }
}
@media (max-width: 400px) {
  .benefit-join-us {
    height: 780px;
    padding: 20px 14px;
  }
}
.benefit-join_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  align-items: center;
  width: 90vw;
  height: 400px;
  margin-left: 55px;
}

.benefit-join_grid > article {
  background-color: #9cc75a;
  border: none;
  box-shadow: 2px 2px 6px 0px;
  border-radius: 20px;
  text-align: center;
  transition: transform 0.3s;
}

.benefit-join_grid > article:hover {
  transform: translateY(5px);
  box-shadow: 2px 2px 26px 0px;
}

.benefit-join_grid > article img {
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 80%;
}

.benefit-join_grid_text {
  padding: 20px 20px 20px;
}

.benefit-join_grid_text h3 {
  color: white;
  font-size: 20px;
  font-family: montserrat;
  font-weight: 700;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .benefit-join_grid {
    margin-left: 32px;
    height: 330px;
  }
}
@media (max-width: 768px) {
  .benefit-join_grid {
    grid-template-columns: repeat(2, 1fr);
    width: 88vw;
    margin-left: 28px;
  }
}
@media (max-width: 500px) {
  .benefit-join_grid {
    grid-template-columns: repeat(1, 1fr);
    width: 65vw;
    margin-left: 52px;
  }
  .benefit-join_grid > article {
    text-align: center;
  }
  .benefit-join_grid_text {
    padding: 8px 8px 8px;
  }
  .benefit-join_grid_text > h3 {
    font-size: 18px;
  }
}
/********playstore section *******/
.playstore-section {
  padding: 40px 0px 0px 0px;
  margin-top: -100px;
}
.playstore-section h1 {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  font-family: montserrat;
  font-style: normal;
  opacity: 0.8;
  padding: 60px;
}

@media (max-width: 900px) {
  .playstore-section {
    padding: 90px 0px 0px 0px;
  }
  .playstore-section h1 {
    font-size: 32px;
  }
}
@media (max-width: 447px) {
  .playstore-section {
    padding: 1px 0px 0px 0px;
    top: 80px;
  }
  .playstore-section h1 {
    font-size: 28px;
    padding: 60px 18px 10px 18px;
  }
}
.playstore-app-img {
  height: 530px;
  width: 580px;
  background: url(../images/farmer/playstore.png);
  background-position: center;
  background-size: cover;
  margin-right: -320px;
}
@media (max-width: 900px) {
  .playstore-app-img {
    height: 280px;
    width: 310px;
    margin-right: -40px;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .playstore-app-img {
    height: 400px;
    width: 440px;
    margin-right: -40px;
  }
}
@media (max-width: 447px) {
  .playstore-app-img {
    height: 360px;
    width: 92vw;
    margin: 2rem 0 0 0;
  }
}

/**********farmer image grid *******/
.farmergrid {
  top: -50px;
}
.farmergrid h1 {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 42px;
  font-family: montserrat;
  font-style: normal;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .farmergrid {
    top: -60px;
  }
}
@media (max-width: 768px) {
  .farmergrid > h1 {
    font-size: 32px;
  }
  .farmergrid {
    top: -48px;
    padding: 0px 15px;
  }
}
@media (max-width: 500px) {
  .farmergrid > h1 {
    font-size: 28px;
  }
}
.farmer_image-grid {
  background-color: rgba(245, 240, 245, 0.6);
  padding: 100px 0;
  margin-bottom: -9rem;
  margin-top: -10rem;
}
@media (min-width: 1610px) {
  .farmer_image-grid {
    margin-top: 4rem;
  }
}
@media (max-width: 447px) {
  .farmer_image-grid {
    margin-top: 0rem;
  }
}
.farmer_image-grid .farmer_image_wrapper {
  display: grid;
  max-width: 80%;
  margin: auto;
  grid-gap: 5px;
  grid-template: 190px/repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 290px;
  grid-auto-flow: dense;
}
.farmer_image-grid .farmer_image_wrapper img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.farmer_image-grid .farmer_image_wrapper .vertical {
  grid-row: span 2;
}
.farmer_image-grid .farmer_image_wrapper .landscape {
  grid-column: span 2;
}
.farmer_image-grid .farmer_image_wrapper .hero {
  grid-row: span 2;
  grid-column: span 2;
}
@media (max-width: 1100px) {
  .farmer_image-grid .farmer_image_wrapper .hero {
    grid-row: span 1;
  }
}
@media (max-width: 900px) {
  .farmer_image-grid .farmer_image_wrapper .hero {
    grid-row: span 1;
  }
}
@media (max-width: 447px) {
  .farmer_image-grid .farmer_image_wrapper .hero {
    grid-row: span 1;
  }
}
@media (min-width: 1610px) {
  .farmer_image-grid .farmer_image_wrapper {
    grid-template: 230px/repeat(auto-fit, minmax(500px, 1fr));
    grid-auto-rows: 290px;
  }
}
@media (min-width: 2000px) {
  .farmer_image-grid .farmer_image_wrapper {
    grid-template: 270px/repeat(auto-fit, minmax(500px, 1fr));
    grid-auto-rows: 270px;
  }
}
@media (max-width: 447px) {
  .farmer_image-grid .farmer_image_wrapper {
    grid-template: auto/1fr;
    grid-auto-rows: auto;
  }
  .farmer_image-grid .farmer_image_wrapper .vertical,
  .farmer_image-grid .farmer_image_wrapper .hero {
    grid-row: auto;
  }
  .farmer_image-grid .farmer_image_wrapper .hero {
    grid-column: span 1;
  }
}
.farmer_image-grid .farmer_image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 4000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.farmer_image-grid .farmer_image-modal img {
  max-width: 80%;
  height: 80%;
}
@media (max-width: 447px) {
  .farmer_image-grid .farmer_image-modal img {
    width: 100%;
    height: auto;
  }
}
.farmer_image-grid .farmer_image-modal .fa {
  position: fixed;
  top: 3%;
  font-size: 45px;
  color: white;
  right: 5%;
  cursor: pointer;
}

/******KN logo on homepage carousel **********/
.carousal-content-logo img {
  width: 90px !important;
  height: 82px;
  position: absolute;
  justify-content: space-around;
  margin: 26px 50px 0px -254px;
}
@media (min-width: 769px) and (max-width: 899px) {
  .carousal-content-logo img {
    width: 70px !important;
    height: 60px;
    position: absolute;
    justify-content: space-around;
    margin: 34px 50px 0px -220px;
  }
}
@media (max-width: 768px) {
  .carousal-content-logo img {
    width: 70px !important;
    height: 60px;
    position: absolute;
    justify-content: space-around;
    margin: 15px 50px 0px -180px;
  }
}
@media (max-width: 447px) {
  .carousal-content-logo img {
    width: 50px !important;
    height: 45px;
    position: absolute;
    justify-content: space-around;
    margin: 6px 0px 0px -132px;
  }
}

/******************video grid ***********/
.video_grid {
  background-color: #f9f6f9;
}

.KN_video_grid {
  margin: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  height: 220px;
}

.KN_video_grid > article {
  border-radius: 18px;
  text-align: center;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
}

.KN_video_grid > article:hover {
  transition: transform 0.1s ease-out;
  transform: translateY(5px);
}

.KN_video_section {
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .KN_video_grid {
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    height: 440px;
  }
  .video_grid {
    height: 460px;
  }
}
@media (max-width: 500px) {
  .KN_video_grid {
    grid-template-columns: repeat(1, 1fr);
    margin: 0;
    height: 720px;
  }
  .KN_video_grid > article {
    text-align: center;
  }
  .video_grid {
    height: 738px;
    top: -40px;
  }
}
/***********.playstore-section social media button *****************/
/**************facebook***********/
.farmer_page_facebook-button {
  height: 34px;
  padding: 4px;
  display: inline-block;
  margin-left: 12px;
}

@media (max-width: 768px) {
  .farmer_page_facebook-button {
    margin-left: -100px;
  }
}
@media (max-width: 500px) {
  .farmer_page_facebook-button {
    padding: 2px;
    margin-left: 0px;
  }
}
/**************youtube***********/
.farmer_page_youtube-button {
  height: 34px;
  padding: 4px;
  display: inline-block;
}

@media (max-width: 768px) {
  .farmer_page_youtube-button {
    padding: 4px;
  }
}
@media (max-width: 500px) {
  .farmer_page_youtube-button {
    padding: 2px;
  }
}
/*********** playstore *****/
.farmer_page_playstore-button {
  height: 34px;
  padding: 3px;
  display: inline-block;
}

@media (max-width: 768px) {
  .farmer_page_playstore-button {
    padding: 4px;
  }
}
@media (max-width: 500px) {
  .farmer_page_playstore-button {
    padding: 2px;
    height: 36px;
  }
}
/******* farmer registration form Modal **********/
.farmer_registration_modal-wrapper {
  background: #b1c155;
  max-width: 400px;
  margin: auto;
  transition: all 0.5s ease;
  z-index: 1;
  position: fixed;
  overflow: hidden;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  max-height: 80vh;
  overflow: auto;
  width: 95%;
  border-radius: 10px;
  line-height: 26px;
  font-size: 16px;
}

.farmer_registration_modal__header {
  background: #b1c155;
  color: #e9e9e9;
  padding: 0.6rem 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
}
.farmer_registration_modal__header h4 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}
.farmer_registration_modal__header span {
  font-size: 2rem;
  cursor: pointer;
  color: #e9e9e9;
}

.farmer_registration_close-modal-btn {
  font-weight: 700;
  color: #102a43;
  line-height: 35px;
  font-size: 12px;
  text-transform: uppercase;
}

.farmer_registration_modal--content {
  padding: 0rem 0rem 10px 0rem;
  background-color: #fff;
  width: 100%;
}

.farmer_registration_modal-body {
  padding: 0 !important;
  text-align: left !important;
}
.farmer_registration_modal-body h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
.farmer_registration_modal-body span {
  font-weight: normal;
}
.farmer_registration_modal-body p {
  font-size: 10px;
  color: #333;
  padding: 1rem;
  margin: 1rem 0 2rem 0;
}

.farmer_registration_back-drop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9019607843);
  z-index: 9999;
}

/**********farmer Registration form css*************/
.farmer_registration_modal-form {
  background-color: #ffffff;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
}

.farmer_registration_modal-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.farmer_registration_modal-list li {
  padding: 0.2em;
}
.farmer_registration_modal-list li button {
  background: rgba(183, 38, 38, 0.9294117647);
  color: white;
  font-weight: 700;
  font-size: 13px;
  border-radius: 5px;
  margin-top: 8px;
  width: 136px;
  padding: 5px;
  border: none;
}
.farmer_registration_modal-list label {
  font-size: 10px;
  margin-bottom: 0px;
}

.required-star {
  color: #fc4366;
  font-size: small;
}

.farmer_registration_modal-form input,
.farmer_registration_modal-form textarea {
  width: 100%;
  padding: 9px 12px;
  border: 1px solid #e1e2eb;
  background-color: #fff;
  color: #102a43;
  box-sizing: border-box;
  font-size: 1.2rem;
  line-height: 1.3rem;
  box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
  border-radius: 3px;
}

.promotion-post {
  font-family: "PT Sans", sans-serif;
  padding-top: 110px;
  min-height: calc(100vh - 200px);
  background-color: #f7f6f6;
  padding-bottom: 25px;
}
.promotion-post-wrapper {
  width: 600px;
  max-width: 95%;
  margin: auto;
}
.promotion-post-card {
  font-size: 1.6rem;
  background-color: white;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
}
.promotion-post-card--header {
  padding: 25px 10px;
  display: flex;
  align-items: center;
}
.promotion-post-card--header img {
  height: 50px;
  margin-right: 20px;
}
.promotion-post-card--body {
  padding: 15px 0;
  height: 550px;
}
.promotion-post-card--body p {
  line-height: 3.2rem;
  font-size: 1.8rem;
}
.promotion-post-card--footer {
  padding: 15px 10px;
}
.promotion-post-card--footer-tags {
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
}
.promotion-post-card--footer-tags span {
  margin-right: 10px;
  font-weight: bold;
}
.promotion-post-card .slider-card {
  height: 510px;
}
.promotion-post-card .slider-card iframe,
.promotion-post-card .slider-card video {
  height: 510px;
  width: 100%;
}
.promotion-post .promotion-card-fb {
  height: 100%;
}
.promotion-post .promotion-card-fb div {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.promotion-post .promotion-card-kn {
  height: 100%;
}
.promotion-post .promotion-card-kn img {
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.promotion-post .promotion-card-kn embed {
  height: 100%;
  width: 100%;
}
.promotion-post .promotion-card-kn-audio {
  position: relative;
}
.promotion-post .promotion-card-kn audio {
  position: absolute;
  bottom: 10px;
  width: 90%;
  left: 5%;
}
.promotion-post .slick-arrow {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  height: 40px;
  width: 40px;
  z-index: 1;
}
.promotion-post .slick-prev {
  left: 0;
}
.promotion-post .slick-next {
  right: 0;
}

.toaster-container {
  font-size: 18px;
}

.mg-timeline-post {
  padding: 20px 0px;
  width: 100%;
  max-width: 767px;
  margin-bottom: 25px;
  font-size: 16px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}
.mg-timeline-post-header, .mg-timeline-post-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
}
.mg-timeline-post-body {
  margin: 20px 0 28px 0;
  padding: 0px 12px;
}
.mg-timeline-post-body-caption {
  padding: 0 15px 15px 15px;
  color: #337ab7;
}
.mg-timeline-post-body .imYUvI > div:nth-child(2), .mg-timeline-post-body .imYUvI > div:nth-child(3) {
  width: 40px;
  top: 50%;
  height: auto;
  transform: translateY(-50%);
}
.mg-timeline-post-body .imYUvI > div:nth-child(2):hover, .mg-timeline-post-body .imYUvI > div:nth-child(3):hover {
  color: #9fbc59;
}

.mg-timeline-post-header__user {
  display: flex;
  align-items: center;
}
.mg-timeline-post-header__user-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ghostwhite;
}
.mg-timeline-post-header__user-image img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.mg-timeline-post-header__user-info {
  padding: 0 10px;
}
.mg-timeline-post-header__user-info div small {
  display: inline-block;
  margin-right: 7px;
}

.mg-timeline-post-footer {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mg-timeline-post-footer .fa {
  color: #555;
}
.mg-timeline-post-footer button {
  background-color: transparent;
  border: none;
}

.mg-post-share-data {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.mg-post-share-data div {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
}
.mg-post-share-data div span {
  position: absolute;
  background: #9fbc59;
  font-size: 14px;
  top: -33%;
  right: -17px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mg-timeline-post-body-text {
  padding: 50px 30px;
  text-align: center;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 15px;
}

.mg-timeline-post-body-image-video video {
  max-height: 400px;
  max-width: 100%;
  width: 90vw;
  object-fit: cover;
  cursor: pointer;
}
.mg-timeline-post-body-image-video img {
  width: 100%;
  object-fit: cover;
  max-height: 400px;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 447px) {
  .mg-timeline-post-body-image-video video {
    max-height: 320px;
  }
  .mg-timeline-post-body-image-video img {
    max-height: 320px;
  }
}

.mg-timeline-post-body-image-video-single video {
  max-height: 450px;
  max-width: 100%;
  width: 90vw;
  object-fit: cover;
  cursor: pointer;
}
.mg-timeline-post-body-image-video-single img {
  width: 100%;
  object-fit: cover;
  max-height: 450px;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 447px) {
  .mg-timeline-post-body-image-video-single video {
    max-height: 400px;
  }
  .mg-timeline-post-body-image-video-single img {
    max-height: 400px;
  }
}

.mg-timeline-post-comment-body-text {
  padding: 50px 30px;
  text-align: center;
  height: 300px;
  max-height: 320px;
  overflow-y: scroll;
}

.mg-timeline-post-body-document {
  margin: 0 16px;
  height: 300px;
  max-height: 320px;
  overflow-y: scroll;
}

.mg-timeline-comment-audio {
  width: 100%;
  height: 80px;
  padding: 4px 16px 2px 16px;
}

.mg-timeline-comment-post-body-image-video video {
  max-width: 86%;
  width: 84%;
  max-height: 320px;
  margin-left: 16px;
}
.mg-timeline-comment-post-body-image-video img {
  width: 84%;
  margin-left: 16px;
  object-fit: fill;
  max-height: 380px;
}
.mg-timeline-comment-post-body-image-video .slick-track {
  display: flex;
  align-items: center;
}

.mg-timeline-audio {
  padding: 90px 40px;
  margin: 0 -15px;
}

.mg-tagged-users {
  display: flex;
  flex-wrap: nowrap;
}
.mg-tagged-users .mg-tagged-user {
  margin-right: 15px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.mg-tagged-users .mg-tagged-user img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.mgtooltip {
  position: relative;
  display: inline-block;
}
.mgtooltip:hover {
  color: red;
}

.mgtooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #aec673;
  font-weight: 500;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 100;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.mgtooltip:hover .tooltiptext {
  visibility: visible;
  margin-bottom: 4px;
}

.mg-carousel-dots {
  justify-content: center;
  display: flex;
  gap: 8px;
  margin-top: 10px;
}
.mg-carousel-dots span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ccc;
  transition: background-color 0.2s linear;
}
.mg-carousel-dots span.mg-carouse-dot-active {
  background-color: #333;
}

.mg-feed-page {
  background-color: #f6f6f7;
}
.mg-feed-page-wrapper {
  padding: 100px 0 60px 0;
  width: 500px;
  max-width: 95%;
  margin: auto;
}

.mg-user-type {
  color: green;
  font-family: "Aileron";
}

.mg-share-details {
  display: flex;
}
.mg-share-details .fa {
  font-size: 25px;
}
.mg-share-details .mg-share-detail {
  margin-left: 24px;
  position: relative;
}
.mg-share-details .mg-share-detail h4 {
  font-size: 14px;
}
.mg-share-details span {
  position: absolute;
  top: -4px;
  border-radius: 50%;
  color: white;
  height: 26px;
  width: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  left: -56%;
}
.mg-share-details img {
  height: 24px;
}

.mg-commented-user-container {
  padding-left: 16px;
  margin-bottom: -12px;
}

.mg-reply-user-container {
  margin-bottom: -16px;
  margin-left: -16px;
  top: 4px;
}

.mg-commented-user {
  display: flex;
  margin-bottom: 25px;
  margin-top: -4px;
  gap: 6px;
}

.mg-commented-user-image-section {
  width: 10%;
}
.mg-commented-user-image-section img {
  height: 50px;
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.mg-commented-reply-user-image-section {
  width: 10%;
}
.mg-commented-reply-user-image-section img {
  height: 50px;
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.mg_commented_user_details {
  background-color: rgba(237, 248, 235, 0.5294117647);
  border-radius: 10px;
  padding: 4px 10px;
  max-width: 22em;
  min-width: 16em;
}
.mg_commented_user_details h4 {
  font-weight: 700;
  padding: 2px;
  text-transform: capitalize;
  font-size: 18px;
}
.mg_commented_user_details p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  padding: 2px;
  word-wrap: break-word;
}

.mg_commented_reply_user_details {
  background-color: #f5fbf4;
  border-radius: 10px;
  padding: 4px 10px;
  max-width: 22em;
  min-width: 16em;
}
.mg_commented_reply_user_details h4 {
  font-weight: 700;
  padding: 2px;
  text-transform: capitalize;
  font-size: 18px;
}
.mg_commented_reply_user_details p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  padding: 2px;
  word-wrap: break-word;
}

.mg_comment_footer_button {
  border: none;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
}

.mg_comment_footer_like_button {
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
}

.mg_comment_footer {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-left: -14px;
}
.mg_comment_footer p {
  font-size: 14px;
}

.mg_reply_footer {
  top: -24px;
  margin-left: 58px;
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
}

@media (min-width: 320px) and (max-width: 768px) {
  .mg_commented_user_details {
    max-width: 16em;
    min-width: 14em;
  }
  .mg_commented_user_details h4 {
    font-size: 14px;
  }
  .mg_commented_user_details p {
    font-size: 11px;
  }
  .mg-commented-user-image-section {
    width: 16%;
  }
  .mg-commented-reply-user-image-section {
    width: 18%;
  }
  .mg-reply-user-container {
    margin-left: -26px;
  }
  .mg_commented_reply_user_details {
    max-width: 14em;
    min-width: 12em;
  }
  .mg_commented_reply_user_details h4 {
    font-size: 14px;
  }
  .mg_commented_reply_user_details p {
    font-size: 11px;
  }
  .mg_comment_footer_button {
    font-size: 11px;
  }
  .mg_comment_footer {
    gap: 2px;
    margin-left: -2px;
  }
  .mg_comment_footer p {
    font-size: 11px;
  }
  .mg_reply_footer {
    margin-left: 70px;
    gap: 6px;
  }
  .mg-share-details .mg-share-detail {
    margin-left: 22px;
  }
  .mg-share-details .mg-share-detail h4 {
    font-size: 11px;
  }
}
.mg_comment_footer_button_like {
  background: #8fbc43;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 16px;
}

.mg-reply-user {
  display: flex;
  margin-bottom: 25px;
  margin-right: 40px;
  margin-top: 2px;
}

.mg_comment_reply {
  margin-left: 70px;
  top: -24px;
}

.mg_comment_footer_button_like_icon {
  margin-right: 130px;
  top: -10px;
}

.mg-liked-user {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  margin-bottom: 18px;
}
.mg-liked-user img {
  height: 50px;
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.mg-share-user {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  margin-bottom: 12px;
}
.mg-share-user img {
  height: 50px;
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.mg-share-user p {
  font-size: 12px;
  padding: 5px 0px 0px 0px;
  opacity: 0.76;
  font-weight: 300;
}
.mg-share-user h4 {
  font-size: 20px;
}

.mg-share-list-details {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mg-share-list img {
  height: 20px;
  width: 20px;
}

.mg_comment_button {
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  margin-left: 4px;
}

.mg_post_footer_comment_area {
  margin: 24px 8px 0 4px;
  display: flex;
  justify-content: space-between;
}

.mg_post_view_count {
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
  margin-right: 4px;
  color: #454545;
}
.mg_post_view_count i {
  font-size: 18px;
  padding-right: 4px;
}

.poll_post_section {
  border-radius: 6px;
  border: 1px solid #d1cccc;
  padding: 4px 10px;
  margin: 4px 10px;
}
.poll_post_section h4 {
  text-align: left;
  padding: 4px 4px 10px 4px;
  font-size: 18px;
}

.poll_post_option_section {
  padding: 8px 0px;
  cursor: default;
}

.poll_option_before_click {
  background-color: rgba(243, 243, 243, 0.6509803922);
  border: 1px solid #cbd1be;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8509803922);
  width: 60%;
}

.poll_post_section_aftervote {
  padding: 8px 0px;
  cursor: default;
}

.poll_options_aftervote {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.poll_option {
  background-color: rgba(243, 243, 243, 0.6509803922);
  border: 1px solid #cbd1be;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.8509803922);
  width: 60%;
}

.poll_icon {
  height: 26px;
  width: 26px;
  border: 2px solid #91ab55;
  border-radius: 50%;
  font-size: 13px;
  line-height: 24px;
  pointer-events: none;
  text-align: center;
}
.poll_icon-tick {
  color: white;
  background-color: #9fbc59;
}

.poll_post_option_bottom_section {
  display: flex;
  justify-content: space-between;
  padding: 6px 4px;
  align-items: center;
}
.poll_post_option_bottom_section p {
  font-size: 16px;
  color: rgba(52, 58, 64, 0.8784313725);
}
.poll_post_option_bottom_section p button {
  border: none;
  background-color: transparent;
}

.mg-poll-vote-user {
  display: flex;
  text-decoration: none;
  padding-bottom: 20px;
  align-items: center;
  gap: 4px;
}
.mg-poll-vote-user img {
  height: 50px;
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.mg-poll-vote-user-details {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}
.mg-poll-vote-user-details a {
  color: #3d3d3d;
  cursor: pointer;
}
.mg-poll-vote-user-details a:hover {
  color: #337ab7;
}

.poll_option_vote-details {
  display: flex;
  gap: 14px;
  align-items: center;
}
.poll_option_vote-details h4 {
  font-size: 15px;
  padding: 2px 0px 0px 0px;
}

.poll_option_voter-details-btn {
  border: none;
  background-color: transparent;
  color: #4d5257;
}

.mg_feed_location_Ui {
  background: #dce2da;
  border: none;
  padding: 6px 14px 6px 8px;
  border-radius: 14px;
  font-size: 12px;
  cursor: default;
}

.audio-el {
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 50px 1fr 40px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.audio-el button {
  border: none;
  background-color: transparent;
}
.audio-el .fa {
  font-size: 25px;
}
.audio-el-progress {
  position: relative;
  height: 8px;
}
.audio-el-progress-track, .audio-el-progress-slider {
  height: 8px;
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}
.audio-el-progress-slider {
  transition: width 0.1s linear;
  z-index: 2;
  background-color: #9fbc59;
}
.audio-el-progress-timing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 15px;
  font-size: 14px;
}
.audio-el-volume {
  position: relative;
}
.audio-el-volume-slider {
  position: absolute;
  display: none;
  background-color: white;
  width: 100px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  bottom: 220%;
  left: 50%;
  padding: 2px 5px;
  cursor: pointer;
  transform: translateX(-50%) rotate(270deg);
}
.audio-el-volume-slider:hover {
  display: block;
}
.audio-el-volume-slider::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  background-color: white;
  z-index: -14;
  border: 1px solid #ccc;
  top: 8px;
  left: -8px;
  transform: rotate(45deg);
  border-top: none;
  border-right: none;
}

.audio-el-volume-btn:hover + .audio-el-volume-slider {
  display: block;
}

.mg-timeline-post {
  padding: 20px 0px;
  width: 100%;
  max-width: 767px;
  margin-bottom: 25px;
  font-size: 16px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}
.mg-timeline-post-header, .mg-timeline-post-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
}
.mg-timeline-post-body {
  margin: 20px 0 28px 0;
  padding: 0px 12px;
}
.mg-timeline-post-body-caption {
  padding: 0 15px 15px 15px;
  color: #337ab7;
}
.mg-timeline-post-body .imYUvI > div:nth-child(2), .mg-timeline-post-body .imYUvI > div:nth-child(3) {
  width: 40px;
  top: 50%;
  height: auto;
  transform: translateY(-50%);
}
.mg-timeline-post-body .imYUvI > div:nth-child(2):hover, .mg-timeline-post-body .imYUvI > div:nth-child(3):hover {
  color: #9fbc59;
}

.mg-timeline-post-header__user {
  display: flex;
  align-items: center;
}
.mg-timeline-post-header__user-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ghostwhite;
}
.mg-timeline-post-header__user-image img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.mg-timeline-post-header__user-info {
  padding: 0 10px;
}
.mg-timeline-post-header__user-info div small {
  display: inline-block;
  margin-right: 7px;
}

.mg-timeline-post-footer {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mg-timeline-post-footer .fa {
  color: #555;
}
.mg-timeline-post-footer button {
  background-color: transparent;
  border: none;
}

.mg-post-share-data {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.mg-post-share-data div {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
}
.mg-post-share-data div span {
  position: absolute;
  background: #9fbc59;
  font-size: 14px;
  top: -33%;
  right: -17px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mg-timeline-post-body-text {
  padding: 50px 30px;
  text-align: center;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 15px;
}

.mg-timeline-post-body-image-video video {
  max-height: 400px;
  max-width: 100%;
  width: 90vw;
  object-fit: cover;
  cursor: pointer;
}
.mg-timeline-post-body-image-video img {
  width: 100%;
  object-fit: cover;
  max-height: 400px;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 447px) {
  .mg-timeline-post-body-image-video video {
    max-height: 320px;
  }
  .mg-timeline-post-body-image-video img {
    max-height: 320px;
  }
}

.mg-timeline-post-body-image-video-single video {
  max-height: 450px;
  max-width: 100%;
  width: 90vw;
  object-fit: cover;
  cursor: pointer;
}
.mg-timeline-post-body-image-video-single img {
  width: 100%;
  object-fit: cover;
  max-height: 450px;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 447px) {
  .mg-timeline-post-body-image-video-single video {
    max-height: 400px;
  }
  .mg-timeline-post-body-image-video-single img {
    max-height: 400px;
  }
}

.mg-timeline-post-comment-body-text {
  padding: 50px 30px;
  text-align: center;
  height: 300px;
  max-height: 320px;
  overflow-y: scroll;
}

.mg-timeline-post-body-document {
  margin: 0 16px;
  height: 300px;
  max-height: 320px;
  overflow-y: scroll;
}

.mg-timeline-comment-audio {
  width: 100%;
  height: 80px;
  padding: 4px 16px 2px 16px;
}

.mg-timeline-comment-post-body-image-video video {
  max-width: 86%;
  width: 84%;
  max-height: 320px;
  margin-left: 16px;
}
.mg-timeline-comment-post-body-image-video img {
  width: 84%;
  margin-left: 16px;
  object-fit: fill;
  max-height: 380px;
}
.mg-timeline-comment-post-body-image-video .slick-track {
  display: flex;
  align-items: center;
}

.mg-timeline-audio {
  padding: 90px 40px;
  margin: 0 -15px;
}

.mg-tagged-users {
  display: flex;
  flex-wrap: nowrap;
}
.mg-tagged-users .mg-tagged-user {
  margin-right: 15px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.mg-tagged-users .mg-tagged-user img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.mgtooltip {
  position: relative;
  display: inline-block;
}
.mgtooltip:hover {
  color: red;
}

.mgtooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #aec673;
  font-weight: 500;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 100;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.mgtooltip:hover .tooltiptext {
  visibility: visible;
  margin-bottom: 4px;
}

.mg-carousel-dots {
  justify-content: center;
  display: flex;
  gap: 8px;
  margin-top: 10px;
}
.mg-carousel-dots span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ccc;
  transition: background-color 0.2s linear;
}
.mg-carousel-dots span.mg-carouse-dot-active {
  background-color: #333;
}

.mg-feed-page {
  background-color: #f6f6f7;
}
.mg-feed-page-wrapper {
  padding: 100px 0 60px 0;
  width: 500px;
  max-width: 95%;
  margin: auto;
}

.mg-user-type {
  color: green;
  font-family: "Aileron";
}

.mg-share-details {
  display: flex;
}
.mg-share-details .fa {
  font-size: 25px;
}
.mg-share-details .mg-share-detail {
  margin-left: 24px;
  position: relative;
}
.mg-share-details .mg-share-detail h4 {
  font-size: 14px;
}
.mg-share-details span {
  position: absolute;
  top: -4px;
  border-radius: 50%;
  color: white;
  height: 26px;
  width: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  left: -56%;
}
.mg-share-details img {
  height: 24px;
}

.mg-commented-user-container {
  padding-left: 16px;
  margin-bottom: -12px;
}

.mg-reply-user-container {
  margin-bottom: -16px;
  margin-left: -16px;
  top: 4px;
}

.mg-commented-user {
  display: flex;
  margin-bottom: 25px;
  margin-top: -4px;
  gap: 6px;
}

.mg-commented-user-image-section {
  width: 10%;
}
.mg-commented-user-image-section img {
  height: 50px;
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.mg-commented-reply-user-image-section {
  width: 10%;
}
.mg-commented-reply-user-image-section img {
  height: 50px;
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.mg_commented_user_details {
  background-color: rgba(237, 248, 235, 0.5294117647);
  border-radius: 10px;
  padding: 4px 10px;
  max-width: 22em;
  min-width: 16em;
}
.mg_commented_user_details h4 {
  font-weight: 700;
  padding: 2px;
  text-transform: capitalize;
  font-size: 18px;
}
.mg_commented_user_details p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  padding: 2px;
  word-wrap: break-word;
}

.mg_commented_reply_user_details {
  background-color: #f5fbf4;
  border-radius: 10px;
  padding: 4px 10px;
  max-width: 22em;
  min-width: 16em;
}
.mg_commented_reply_user_details h4 {
  font-weight: 700;
  padding: 2px;
  text-transform: capitalize;
  font-size: 18px;
}
.mg_commented_reply_user_details p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  padding: 2px;
  word-wrap: break-word;
}

.mg_comment_footer_button {
  border: none;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
}

.mg_comment_footer_like_button {
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
}

.mg_comment_footer {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-left: -14px;
}
.mg_comment_footer p {
  font-size: 14px;
}

.mg_reply_footer {
  top: -24px;
  margin-left: 58px;
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
}

@media (min-width: 320px) and (max-width: 768px) {
  .mg_commented_user_details {
    max-width: 16em;
    min-width: 14em;
  }
  .mg_commented_user_details h4 {
    font-size: 14px;
  }
  .mg_commented_user_details p {
    font-size: 11px;
  }
  .mg-commented-user-image-section {
    width: 16%;
  }
  .mg-commented-reply-user-image-section {
    width: 18%;
  }
  .mg-reply-user-container {
    margin-left: -26px;
  }
  .mg_commented_reply_user_details {
    max-width: 14em;
    min-width: 12em;
  }
  .mg_commented_reply_user_details h4 {
    font-size: 14px;
  }
  .mg_commented_reply_user_details p {
    font-size: 11px;
  }
  .mg_comment_footer_button {
    font-size: 11px;
  }
  .mg_comment_footer {
    gap: 2px;
    margin-left: -2px;
  }
  .mg_comment_footer p {
    font-size: 11px;
  }
  .mg_reply_footer {
    margin-left: 70px;
    gap: 6px;
  }
  .mg-share-details .mg-share-detail {
    margin-left: 22px;
  }
  .mg-share-details .mg-share-detail h4 {
    font-size: 11px;
  }
}
.mg_comment_footer_button_like {
  background: #8fbc43;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 16px;
}

.mg-reply-user {
  display: flex;
  margin-bottom: 25px;
  margin-right: 40px;
  margin-top: 2px;
}

.mg_comment_reply {
  margin-left: 70px;
  top: -24px;
}

.mg_comment_footer_button_like_icon {
  margin-right: 130px;
  top: -10px;
}

.mg-liked-user {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  margin-bottom: 18px;
}
.mg-liked-user img {
  height: 50px;
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.mg-share-user {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  margin-bottom: 12px;
}
.mg-share-user img {
  height: 50px;
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.mg-share-user p {
  font-size: 12px;
  padding: 5px 0px 0px 0px;
  opacity: 0.76;
  font-weight: 300;
}
.mg-share-user h4 {
  font-size: 20px;
}

.mg-share-list-details {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mg-share-list img {
  height: 20px;
  width: 20px;
}

.mg_comment_button {
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  margin-left: 4px;
}

.mg_post_footer_comment_area {
  margin: 24px 8px 0 4px;
  display: flex;
  justify-content: space-between;
}

.mg_post_view_count {
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
  margin-right: 4px;
  color: #454545;
}
.mg_post_view_count i {
  font-size: 18px;
  padding-right: 4px;
}

.poll_post_section {
  border-radius: 6px;
  border: 1px solid #d1cccc;
  padding: 4px 10px;
  margin: 4px 10px;
}
.poll_post_section h4 {
  text-align: left;
  padding: 4px 4px 10px 4px;
  font-size: 18px;
}

.poll_post_option_section {
  padding: 8px 0px;
  cursor: default;
}

.poll_option_before_click {
  background-color: rgba(243, 243, 243, 0.6509803922);
  border: 1px solid #cbd1be;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8509803922);
  width: 60%;
}

.poll_post_section_aftervote {
  padding: 8px 0px;
  cursor: default;
}

.poll_options_aftervote {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.poll_option {
  background-color: rgba(243, 243, 243, 0.6509803922);
  border: 1px solid #cbd1be;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.8509803922);
  width: 60%;
}

.poll_icon {
  height: 26px;
  width: 26px;
  border: 2px solid #91ab55;
  border-radius: 50%;
  font-size: 13px;
  line-height: 24px;
  pointer-events: none;
  text-align: center;
}
.poll_icon-tick {
  color: white;
  background-color: #9fbc59;
}

.poll_post_option_bottom_section {
  display: flex;
  justify-content: space-between;
  padding: 6px 4px;
  align-items: center;
}
.poll_post_option_bottom_section p {
  font-size: 16px;
  color: rgba(52, 58, 64, 0.8784313725);
}
.poll_post_option_bottom_section p button {
  border: none;
  background-color: transparent;
}

.mg-poll-vote-user {
  display: flex;
  text-decoration: none;
  padding-bottom: 20px;
  align-items: center;
  gap: 4px;
}
.mg-poll-vote-user img {
  height: 50px;
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.mg-poll-vote-user-details {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}
.mg-poll-vote-user-details a {
  color: #3d3d3d;
  cursor: pointer;
}
.mg-poll-vote-user-details a:hover {
  color: #337ab7;
}

.poll_option_vote-details {
  display: flex;
  gap: 14px;
  align-items: center;
}
.poll_option_vote-details h4 {
  font-size: 15px;
  padding: 2px 0px 0px 0px;
}

.poll_option_voter-details-btn {
  border: none;
  background-color: transparent;
  color: #4d5257;
}

.mg_feed_location_Ui {
  background: #dce2da;
  border: none;
  padding: 6px 14px 6px 8px;
  border-radius: 14px;
  font-size: 12px;
  cursor: default;
}

.mg-village_edit {
  background-color: #f4f4f8;
  min-height: 100vh;
  padding: 110px 40px 20px 40px;
}
@media (max-width: 500px) {
  .mg-village_edit {
    padding: 86px 10px 20px 10px;
  }
}
.mg-village_edit-section {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  height: 100%;
  border: 1px solid #ccc;
}
@media (max-width: 400px) {
  .mg-village_edit-section {
    padding: 20px 10px;
  }
}
.mg-village_edit-profile {
  position: relative;
}

.mg-village_edit-profile-hero {
  margin: -20px;
  border-radius: 5px 5px 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 400px) {
  .mg-village_edit-profile-hero {
    margin: -20px -10px;
  }
}
.mg-village_edit-profile-hero img {
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}

.mg-village_edit-profile-picture {
  width: 150px;
  position: relative;
  background-color: white;
  padding: 5px;
  border-radius: 2px;
  left: 25px;
  top: -60px;
  border-radius: 50%;
}
@media (max-width: 450px) {
  .mg-village_edit-profile-picture {
    left: 106px;
  }
}
@media (max-width: 400px) {
  .mg-village_edit-profile-picture {
    left: 92px;
  }
}
@media (max-width: 350px) {
  .mg-village_edit-profile-picture {
    left: 66px;
  }
}
.mg-village_edit-profile-picture img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.mg-village_edit-profile-overview {
  margin-left: 40px;
  margin-top: -60px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mg-village_edit-profile-overview p {
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .mg-village_edit-profile-overview {
    margin-left: 10px;
    text-align: center;
  }
}

.mg-village-festivals_edit {
  padding-top: 20px;
}

.mg-village-festival_edit {
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
}
.mg-village-festival_edit-image {
  height: 300px;
}
.mg-village-festival_edit-image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 4px;
}
.mg-village-festival_edit:last-of-type {
  border-bottom: none;
}
.mg-village-festival_edit-description {
  font-size: 14px;
  margin: 12px 0;
  font-family: "PT Sans Caption", sans-serif;
}
.mg-village-festival_edit-name {
  padding: 10px 0px;
}
.mg-village-festival_edit-date {
  font-size: 12px;
  font-weight: 500;
}

.mg-village-festival_edit-group {
  margin: 15px 0;
  padding: 20px 0;
  border-top: 1px solid #ccc;
}

.mg-village-festival_edit-group-name {
  color: #9fbc59;
  margin: 0;
  display: inline-block;
  width: auto;
  padding-right: 50px;
  padding-left: 20px;
  border: none;
  font-weight: bold;
  font-size: 32px;
}

#profileImage {
  display: none;
}

#coverImage {
  display: none;
}

.cropperContainer {
  margin: auto;
  min-height: 50vh;
  background-color: rgba(182, 221, 88, 0.9607843137);
  margin-top: 12px;
}

.profile_image_icon {
  position: absolute;
  height: 30px;
  top: 104px;
  right: 1px;
  border: grey;
  opacity: 0.9;
  border-radius: 50%;
  padding: 5px 6px;
  font-size: 16px;
  background-color: #bfbfbf;
}

.cover_image_icon {
  position: absolute;
  top: 6px;
  right: 10px;
  border: grey;
  opacity: 0.9;
  border-radius: 50%;
  padding: 3px 8px;
  font-size: 22px;
  background-color: white;
}

.crop_image_btn {
  margin-top: 10px;
  border: none;
  background: rgb(51, 122, 183);
  color: white;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 4px;
}

#eventImage {
  font-size: 12px;
  color: transparent;
}

#festivalImage {
  font-size: 12px;
  display: none;
}

#updateEventImage {
  font-size: 12px;
  color: transparent;
}

.file_choose_name {
  padding-top: 6px;
  font-weight: 500;
  font-size: 12px;
  color: #337ab7;
}

.search_Tag_location {
  font-size: 12px;
}

.create_event_admin {
  background-color: #a3bd60;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  transition: all 0.3s linear;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}
.create_event_admin summary:focus, .create_event_admin:focus {
  outline: none;
}
.create_event_admin[open] summary {
  margin: 0 -10px 10px -10px;
  border-bottom: 1px solid #ccc;
  padding: 0 10px 5px 10px;
}
.create_event_admin summary {
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: white;
  text-align: center;
}
.create_event_admin-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 15px;
}
@media (max-width: 767px) {
  .create_event_admin-container {
    display: block;
  }
}
.create_event_admin label {
  font-size: 12px;
  margin-bottom: 5px !important;
  color: white;
}

.create_event_admin_button {
  background-color: rgba(60, 118, 61, 0.9490196078);
  font-size: 12px;
  font-weight: 700;
  color: white;
  padding: 5px 10px;
  float: right;
  margin-top: -6px;
  border-radius: 3px;
  border: none;
}

.edit_description_admin_button {
  background-color: rgba(60, 118, 61, 0.9490196078);
  font-size: 14px;
  color: white;
  padding: 5px 10px;
  float: right;
  margin-top: -6px;
  border-radius: 3px;
  border: none;
}

.edit_event_admin_button {
  background-color: #3b70b3;
  font-size: 14px;
  font-weight: 500;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
}

.delete_event_admin_button {
  background-color: #d9534f;
  font-size: 14px;
  font-weight: 500;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
}

.village-description {
  font-size: 11px;
  white-space: pre-wrap;
}

.image_remove_btn {
  background: transparent;
  border: none;
  color: #b90909;
  font-size: 16px;
}

.image_remove_btn_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kn-web-container {
  padding: 80px 20px 20px 20px;
  font-size: 1.6rem;
}

.enam-page {
  padding-top: 120px;
  max-width: 400px;
  margin: auto;
  font-family: PT Sans Caption;
}

.enam-logos {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 250px;
  margin: auto;
}
.enam-logos img {
  max-width: 70px;
  max-height: 70px;
}

.enam-bid-list-container {
  padding: 30px 0;
}

.enam-bid-card {
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 15px 15px 0 15px;
}
.enam-bid-card-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  margin-bottom: 12px;
}
.enam-bid-card-body {
  padding: 15px 0;
}
.enam-bid-card-body-price {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}
.enam-bid-card-body p {
  line-height: 35px;
}
.enam-bid-card-footer-images {
  display: flex;
}
.enam-bid-card-footer-images img {
  height: 80px;
  object-fit: cover;
  width: 80px;
  border-radius: 5px;
  margin-right: 15px;
}
.enam-bid-card-footer a {
  background-color: #9fbc59;
  display: block;
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.enam-seller-section {
  font-size: 16px;
  margin: 20px 0 15px 0;
  display: flex;
}
.enam-seller-section-avatar {
  height: 70px;
  width: 70px;
  margin-right: 25px;
  background-color: #f6f6f6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
.enam-seller-section p {
  line-height: 30px;
}

.enam-bid-success {
  background-color: #f6f6f6;
  padding: 25px;
  border-radius: 5px;
  text-align: center;
}

.bid-create-group {
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 20px 15px;
}

.enam-bid-status {
  padding: 5px 18px;
  border-radius: 15px;
  display: inline-block;
  background-color: lightgoldenrodyellow;
  color: darkgoldenrod;
  font-size: 14px;
}
.enam-bid-status.accepted {
  background-color: rgba(0, 255, 0, 0.2);
  color: darkgreen;
}
.enam-bid-status.rejected {
  background-color: rgba(255, 0, 0, 0.2);
  color: darkred;
}

html.modal_question-open {
  overflow: hidden;
}
html.modal_question-open body.modal_question-open {
  height: unset;
}

.farmer_asp_language {
  width: 100%;
  margin-top: 14px;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 32px;
  position: absolute;
  z-index: 1;
}

.farmer_asp_language_button {
  border: none;
  padding: 6px 16px;
  background-color: #dcf0dc;
  color: #57a05a;
  font-size: 16px;
  font-family: montserrat;
  font-weight: 500;
  border-radius: 16px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .farmer_asp_language_button {
    padding: 4px 14px;
    font-size: 14px;
  }
  .farmer_asp_language {
    margin-top: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 667px) {
  .farmer_asp_language_button {
    padding: 4px 12px;
    font-size: 12px;
  }
  .farmer_asp_language {
    margin-top: 6px;
    padding-right: 8px;
  }
}
@media (max-width: 447px) {
  .farmer_asp_language_button {
    padding: 4px 8px;
    font-size: 1.5rem;
  }
  .farmer_asp_language {
    padding: 6px 8px 6px 0px;
    position: relative;
    z-index: 0;
  }
}
.asp_hero {
  margin-top: 90px;
}
@media (max-width: 900px) {
  .asp_hero {
    margin-top: 78px;
  }
}
@media (max-width: 500px) {
  .asp_hero {
    margin-top: 76px;
  }
}

.asp_hero_image {
  width: 100vw;
}
.asp_hero_image img {
  width: 100%;
  height: 680px;
  opacity: 0.97;
}
@media (min-width: 800px) and (max-width: 1024px) {
  .asp_hero_image img {
    height: 480px;
  }
}
@media (max-width: 768px) {
  .asp_hero_image img {
    height: 380px;
  }
}
@media (max-width: 500px) {
  .asp_hero_image img {
    height: 220px;
  }
}
@media (max-width: 400px) {
  .asp_hero_image img {
    height: 220px;
  }
}

.hhero_ask-btn {
  background-color: #337ab7;
  border-color: #337ab7;
  border: none;
}
.hhero_ask-btn:hover {
  color: rgb(255, 255, 255);
  background-color: #b51a27;
}

.hhero_know-btn {
  background-color: #9fbb59;
  border-color: #9fbb59;
}
.hhero_know-btn:hover {
  opacity: 0.9;
  color: rgb(255, 255, 255);
  background-color: #228b22;
  border-color: #228b22;
}

.hero_div {
  position: absolute;
  margin-top: -100px;
  margin-left: 24px;
  padding: 38px 40px;
}
.hero_div h2 {
  text-align: left !important;
  font-size: 30px;
}
.hero_div .button_area {
  display: flex;
  gap: 20px;
}
@media (min-width: 800px) and (max-width: 1024px) {
  .hero_div {
    margin-top: -90px;
    margin-left: 24px;
    padding: 38px 40px;
  }
}
@media (max-width: 768px) {
  .hero_div {
    margin-top: -66px;
    margin-left: 2px;
    padding: 24px 36px;
  }
}
@media (max-width: 500px) {
  .hero_div {
    margin-top: -62px;
    margin-left: -12px;
  }
  .hero_div .button_area {
    gap: 20px;
  }
}
@media (max-width: 400px) {
  .hero_div {
    margin-top: -56px;
    margin-left: -12px;
  }
  .hero_div .button_area {
    gap: 20px;
  }
}

.hero_quote_section {
  padding: 0px 0px 0px 0px;
}

.hero_section_quote {
  background-color: white;
  padding: 60px 36px 48px 36px;
  opacity: 0.9;
}
.hero_section_quote h1 {
  color: rgba(0, 0, 0, 0.7882352941);
  font-weight: 500;
  text-align: center;
  font-size: 26px;
  font-family: montserrat;
  font-style: normal;
  line-height: 1.3;
}

.hero_section_quote2 h1 {
  color: rgba(0, 0, 0, 0.7882352941);
  font-weight: 500;
  text-align: center;
  font-size: 26px;
  font-family: montserrat;
  font-style: normal;
  line-height: 1.3;
}

@media (max-width: 768px) {
  .hero_section_quote {
    padding: 30px 20px 40px 20px;
  }
  .hero_section_quote > h1 {
    font-size: 22px;
  }
  .hero_section_quote2 > h1 {
    font-size: 22px;
  }
  .hero_quote_section {
    padding: 18px 0px 0px 0px;
    top: -16px;
  }
}
@media (max-width: 500px) {
  .hero_section_quote {
    padding: 32px 18px 48px 18px;
  }
  .hero_section_quote > h1 {
    font-size: 24px;
  }
  .hero_section_quote2 > h1 {
    font-size: 24px;
  }
  .hero_quote_section {
    padding: 0px 0px 0px 0px;
    top: 1px;
  }
}
@media (max-width: 400px) {
  .hero_section_quote {
    padding: 24px 18px 48px 18px;
  }
  .hero_section_quote > h1 {
    font-size: 22px;
  }
  .hero_section_quote2 > h1 {
    font-size: 22px;
  }
  .hero_quote_section {
    padding: 0px 0px 0px 0px;
    top: 1px;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .hero_section_quote {
    padding: 40px 40px 48px 40px;
  }
  .hero_quote_section {
    padding: 1px 0px 0px 0px;
    top: 0px;
  }
}
.message-numbers {
  position: relative;
  height: 240px;
  background-color: white;
}
.message-numbers .message-number-wrapper {
  max-width: 100vw;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 180px;
  align-items: center;
  background-color: #f9f6f9;
}
.message-numbers .message-number-wrapper div {
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 14%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.message-numbers .message-number-wrapper div img {
  margin: 25px 0 14px 0;
  width: 50px;
  height: 50px;
}
.message-numbers .message-number-wrapper div h3 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.message-numbers .message-number-wrapper div h1 {
  text-align: center;
  position: relative;
  font-size: 2.5rem;
  font-weight: normal !important;
}
.message-numbers .message-number-wrapper div .number::after {
  display: block;
  position: absolute;
  content: "";
  right: 15%;
  bottom: -8px;
  height: 5px;
  width: 50%;
  background-color: #aec673;
}
@media (max-width: 900px) {
  .message-numbers .message-number-wrapper div {
    width: 160px;
    height: 160px;
  }
  .message-numbers .message-number-wrapper div img {
    margin: 15px 0 8px 0;
  }
  .message-numbers .message-number-wrapper div h3 {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .message-numbers .message-number-wrapper div h1 {
    font-size: 2.5rem;
  }
}
@media (min-width: 447px) and (max-width: 600px) {
  .message-numbers .message-number-wrapper div {
    width: 160px;
    height: 160px;
  }
}
@media (min-width: 447px) and (max-width: 600px) {
  .message-numbers .message-number-wrapper {
    grid-gap: 80px;
  }
}
@media (max-width: 447px) {
  .message-numbers {
    height: auto;
  }
  .message-numbers .message-number-wrapper {
    flex-direction: column;
    justify-content: space-evenly;
    height: 440px;
    gap: 10px;
  }
  .message-numbers .message-number-wrapper div {
    height: 170px;
    width: 170px;
    align-items: center;
  }
  .message-numbers .message-number-wrapper div img {
    margin-top: 20px;
  }
  .message-numbers .message-number-wrapper div h3 {
    margin-top: 0rem;
  }
}

/**********FarmerinMap css *******/
.farmer_in_map-section {
  padding: 1px 0px 0px 0px;
}

.farmer_in_map-section-quote {
  background-color: #aec673;
  padding: 60px;
}
.farmer_in_map-section-quote h1 {
  color: white;
  font-weight: 500;
  text-align: center;
  font-size: 38px;
  font-family: serif;
  line-height: 1.3;
}

.ask_question_quote {
  text-align: center;
}
.ask_question_quote button {
  margin: 2rem auto 0;
  border: none;
  background-color: white;
  color: #aec673;
  padding: 10px 30px;
  font-size: 14px;
  border-radius: 4px;
}
.ask_question_quote:hover {
  border-radius: 0;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  color: #aec673;
}

@media (max-width: 768px) {
  .farmer_in_map-section-quote {
    padding: 64px 70px;
  }
  .farmer_in_map-section-quote > h1 {
    font-size: 30px;
  }
  .farmer_in_map-section {
    padding: 18px 0px 0px 0px;
    top: -40px;
  }
}
@media (max-width: 500px) {
  .farmer_in_map-section-quote {
    padding: 40px 30px;
  }
  .farmer_in_map-section-quote > h1 {
    font-size: 26px;
  }
  .farmer_in_map-section {
    padding: 1px 0px 0px 0px;
    top: -24px;
  }
}
@media (max-width: 400px) {
  .farmer_in_map-section-quote {
    padding: 40px 30px;
  }
  .farmer_in_map-section-quote > h1 {
    font-size: 25px;
  }
  .farmer_in_map-section {
    padding: 0px;
    top: -22px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .farmer_in_map-section-quote {
    padding: 80px 60px;
  }
  .farmer_in_map-section {
    padding: 1px 0px 0px 0px;
    top: -30px;
  }
}
.farmer_in_map-farmer {
  padding: 40px 96px 70px 96px;
  display: flex;
  align-items: center;
  gap: 60px;
}
@media (min-width: 900px) and (max-width: 1024px) {
  .farmer_in_map-farmer {
    padding: 40px 96px 20px 96px;
  }
}
@media (max-width: 900px) {
  .farmer_in_map-farmer {
    padding: 0px 55px 20px 36px;
  }
}
@media (min-width: 447px) and (max-width: 767px) {
  .farmer_in_map-farmer {
    flex-direction: column;
    padding: 4px 0 64px 0;
    gap: 20px;
  }
}
@media (max-width: 447px) {
  .farmer_in_map-farmer {
    flex-direction: column;
    padding: 0 0 48px 0;
    gap: 0px;
  }
}
.farmer_in_map-farmer-info {
  text-align: left;
  width: 50%;
  padding-right: 64px;
  float: left;
}
.farmer_in_map-farmer-info-text {
  color: rgba(0, 0, 0, 0.7882352941);
  font-weight: 500;
  font-size: 30px;
  padding-bottom: 12px;
}
.farmer_in_map-farmer-info--text {
  margin-top: 10px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.7882352941);
  font-weight: 400;
  line-height: 1.5;
}
@media (min-width: 800px) and (max-width: 1024px) {
  .farmer_in_map-farmer-info .farmer_in_map-farmer-info-text {
    font-size: 30px;
  }
}
@media (max-width: 900px) {
  .farmer_in_map-farmer-info {
    width: 60%;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
  }
  .farmer_in_map-farmer-info .farmer_in_map-farmer-info--text {
    font-size: 18px !important;
  }
  .farmer_in_map-farmer-info .farmer_in_map-farmer-info-text {
    font-size: 26px;
  }
}
@media (min-width: 447px) and (max-width: 767px) {
  .farmer_in_map-farmer-info {
    width: 100%;
    clear: both;
    padding: 0px 14px 0px 14px;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
  }
  .farmer_in_map-farmer-info .farmer_in_map-farmer-info--text {
    font-size: 18px !important;
  }
  .farmer_in_map-farmer-info .farmer_in_map-farmer-info-text {
    font-size: 26px;
  }
}
@media (max-width: 447px) {
  .farmer_in_map-farmer-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -16px;
  }
  .farmer_in_map-farmer-info .farmer_in_map-farmer-info--text {
    font-size: 18px;
  }
  .farmer_in_map-farmer-info .farmer_in_map-farmer-info-text {
    font-size: 26px;
  }
}
.farmer_in_map-farmer-img {
  height: 590px;
  width: 510px;
  background-position: center;
  background-size: cover;
  margin-right: -320px;
}
@media (max-width: 1200px) {
  .farmer_in_map-farmer-img {
    height: 414px;
    width: 420px;
    margin-right: -40px;
  }
}
@media (max-width: 500px) {
  .farmer_in_map-farmer-img {
    height: auto;
    width: 360px;
    margin-right: -10px;
  }
}
@media (max-width: 375px) {
  .farmer_in_map-farmer-img {
    height: 390px;
    width: 340px;
    margin-right: -16px;
  }
}

/********Quiz promotion section**********/
.quizpromotion-section {
  padding: 30px 0px 20px 0px;
  top: 0px;
  background-color: white;
}
.quizpromotion-section h1 {
  color: rgba(0, 0, 0, 0.7882352941);
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  font-family: montserrat;
  font-style: normal;
  padding: 20px 60px;
}

.quiz_button {
  color: white;
  font-size: 16px;
  border: none;
  background-color: #aec673;
  padding: 10px 14px;
  border-radius: 24px;
}
.quiz_button:hover {
  opacity: 0.8;
  color: rgb(255, 255, 255);
  background-color: #268f1c;
}

.quiz_button_div {
  padding-top: 14px;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .quizpromotion-section {
    padding: 40px 40px 60px 40px;
  }
}
@media (min-width: 447px) and (max-width: 1200px) {
  .asp_number_promotion-section > h1 {
    font-size: 30px;
    padding: 10px 10px 2px 10px;
  }
  .asp_number_promotion-section {
    top: -20px;
  }
  .asp_number_promotion_background-section {
    padding: 5px 0px 0px 0px;
    top: 0px;
  }
}
@media (min-width: 447px) and (max-width: 1200px) {
  .quizpromotion-section > h1 {
    font-size: 30px;
    padding: 10px 10px 2px 10px;
  }
  .asp_number_promotion-section {
    top: -20px;
  }
  .asp_number_promotion_background-section {
    padding: 5px 0px 0px 0px;
    top: 0px;
  }
  .quiz_button {
    color: white;
    font-size: 12px;
    padding: 6px 14px;
  }
}
@media (max-width: 447px) {
  .quizpromotion-section {
    padding: 6px 0px 0px 0px;
    top: -18px;
  }
  .quizpromotion-section > h1 {
    font-size: 28px;
    padding: 10px;
  }
  .quiz_button {
    color: white;
    font-size: 12px;
    padding: 6px 14px;
  }
}
.quizpromotion_background {
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -4rem;
}

.quizpromotion-img {
  width: 50%;
  background-position: center;
  background-size: cover;
  margin-right: -320px;
}
@media (max-width: 900px) {
  .quizpromotion-img {
    width: 580px;
    margin-right: -40px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .quizpromotion-img {
    width: 50%;
    margin-right: -40px;
  }
}
@media (min-width: 447px) and (max-width: 767px) {
  .quizpromotion-img {
    width: 90vw;
    margin: 4rem 0 0 0;
    height: auto;
  }
}
@media (max-width: 500px) {
  .quizpromotion-img {
    width: 94vw;
    margin: 2rem 0 0 0;
  }
}

/**********FarmerinMap css *******/
.quiz-section {
  padding: 1px 0px 0px 0px;
}

.quiz-section-quote {
  background-color: #aec673;
  padding: 60px;
}
.quiz-section-quote h1 {
  color: white;
  font-weight: 500;
  text-align: center;
  font-size: 38px;
  font-family: serif;
  line-height: 1.3;
}

.ask_question_quote {
  text-align: center;
}
.ask_question_quote button {
  margin: 2rem auto 0;
  border: none;
  background-color: white;
  color: #aec673;
  padding: 10px 30px;
  font-size: 14px;
  border-radius: 4px;
}
.ask_question_quote:hover {
  border-radius: 0;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  color: #aec673;
}

@media (max-width: 768px) {
  .quiz-section-quote {
    padding: 64px 70px;
  }
  .quiz-section-quote > h1 {
    font-size: 30px;
  }
  .quiz-section {
    padding: 18px 0px 0px 0px;
    top: -40px;
  }
}
@media (max-width: 500px) {
  .quiz-section-quote {
    padding: 40px 30px;
  }
  .quiz-section-quote > h1 {
    font-size: 26px;
  }
  .quiz-section {
    padding: 1px 0px 0px 0px;
    top: -24px;
  }
}
@media (max-width: 400px) {
  .quiz-section-quote {
    padding: 40px 30px;
  }
  .quiz-section-quote > h1 {
    font-size: 25px;
  }
  .quiz-section {
    padding: 0px;
    top: -22px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .quiz-section-quote {
    padding: 80px 60px;
  }
  .quiz-section {
    padding: 1px 0px 0px 0px;
    top: -30px;
  }
}
.quiz-farmer {
  padding: 40px 96px 70px 96px;
  display: flex;
  align-items: center;
  gap: 60px;
}
@media (min-width: 900px) and (max-width: 1024px) {
  .quiz-farmer {
    padding: 40px 55px 20px 36px;
  }
}
@media (max-width: 900px) {
  .quiz-farmer {
    padding: 40px 55px 20px 36px;
  }
}
@media (min-width: 447px) and (max-width: 767px) {
  .quiz-farmer {
    flex-direction: column;
    padding: 4px 0 64px 0;
    gap: 20px;
  }
}
@media (max-width: 447px) {
  .quiz-farmer {
    flex-direction: column;
    padding: 0 0 48px 0;
    gap: 0px;
  }
}
.quiz-farmer-info {
  text-align: left;
  width: 50%;
  padding-right: 64px;
  float: left;
}
.quiz-farmer-info-text {
  color: rgba(0, 0, 0, 0.7882352941);
  font-weight: 500;
  font-size: 30px;
  padding-bottom: 12px;
}
.quiz-farmer-info--text {
  margin-top: 10px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.7882352941);
  font-weight: 400;
  line-height: 1.5;
}
@media (min-width: 800px) and (max-width: 1024px) {
  .quiz-farmer-info .quiz-farmer-info-text {
    font-size: 30px;
  }
}
@media (max-width: 900px) {
  .quiz-farmer-info {
    width: 50%;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
  }
  .quiz-farmer-info .quiz-farmer-info--text {
    font-size: 18px !important;
  }
  .quiz-farmer-info .quiz-farmer-info-text {
    font-size: 26px;
  }
}
@media (min-width: 447px) and (max-width: 767px) {
  .quiz-farmer-info {
    width: 100%;
    clear: both;
    padding: 0px 14px 0px 14px;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
  }
  .quiz-farmer-info .quiz-farmer-info--text {
    font-size: 18px !important;
  }
  .quiz-farmer-info .quiz-farmer-info-text {
    font-size: 26px;
  }
}
@media (max-width: 447px) {
  .quiz-farmer-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -16px;
  }
  .quiz-farmer-info .quiz-farmer-info--text {
    font-size: 18px;
  }
  .quiz-farmer-info .quiz-farmer-info-text {
    font-size: 26px;
  }
}
.quiz-farmer-img {
  height: 590px;
  width: 510px;
  background-position: center;
  background-size: cover;
  margin-right: -320px;
}
@media (max-width: 1200px) {
  .quiz-farmer-img {
    height: 414px;
    width: 420px;
    margin-right: -40px;
  }
}
@media (max-width: 500px) {
  .quiz-farmer-img {
    height: auto;
    width: 360px;
    margin-right: -10px;
  }
}
@media (max-width: 375px) {
  .quiz-farmer-img {
    height: 390px;
    width: 340px;
    margin-right: -16px;
  }
}

.available_on_whatsapp_background {
  border-radius: 4px;
  height: 90rem;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -6rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: rgba(245, 240, 245, 0.6);
}
@media (max-width: 900px) {
  .available_on_whatsapp_background {
    height: 92rem;
    margin-top: -2rem;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .available_on_whatsapp_background {
    height: 118rem;
  }
}
@media (max-width: 447px) {
  .available_on_whatsapp_background {
    height: 130rem;
  }
}
@media (max-width: 400px) {
  .available_on_whatsapp_background {
    height: 142rem;
  }
}

.available_on_whatsapp-section {
  padding: 40px 0px 0px 0px;
  margin-top: 60px;
}
.available_on_whatsapp-section h1 {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  font-family: montserrat;
  font-style: normal;
  opacity: 0.8;
  padding: 60px;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .available_on_whatsapp-section {
    margin-top: -40px;
  }
}
@media (max-width: 900px) {
  .available_on_whatsapp-section {
    padding: 20px 0px 0px 0px;
    margin-top: -80px;
  }
  .available_on_whatsapp-section h1 {
    font-size: 32px;
  }
}
@media (max-width: 447px) {
  .available_on_whatsapp-section {
    padding: 1px 0px 0px 0px;
    top: 8px;
  }
  .available_on_whatsapp-section h1 {
    font-size: 28px;
    padding: 60px 18px 10px 18px;
  }
}
.available_on_whatsapp-img {
  height: 530px;
  width: 580px;
  background-position: center;
  background-size: cover;
  margin-right: -320px;
}
@media (max-width: 900px) {
  .available_on_whatsapp-img {
    height: 280px;
    width: 310px;
    margin-right: -40px;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .available_on_whatsapp-img {
    height: 400px;
    width: 440px;
    margin-right: -40px;
  }
}
@media (max-width: 447px) {
  .available_on_whatsapp-img {
    height: 360px;
    width: 92vw;
    margin: 2rem 0 0 0;
  }
}

.available_on_whatsapp-info {
  text-align: left;
  width: 50%;
  padding-right: 64px;
  float: left;
  top: -100px;
}
.available_on_whatsapp-info--text {
  margin-top: 16px;
  font-size: 26px;
  color: #888;
  font-weight: 400;
  line-height: 1.5;
}
@media (max-width: 900px) {
  .available_on_whatsapp-info {
    width: 60%;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
    top: -60px;
  }
  .available_on_whatsapp-info .available_on_whatsapp-info--text {
    font-size: 18px !important;
  }
}
@media (max-width: 447px) {
  .available_on_whatsapp-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -24px;
  }
  .available_on_whatsapp-info .available_on_whatsapp-info--text {
    font-size: 18px;
  }
}

.step-join {
  padding: 4px 0px 75px 0px;
  top: 0px;
}

.step-join-us {
  background-color: #7f6127;
  padding: 50px 20px 50px 20px;
}
.step-join-us h1 {
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  font-family: montserrat;
  font-style: normal;
}
.step-join-us h1 p {
  text-align: center;
  font-weight: 300;
  font-family: montserrat;
  font-size: 20px;
  color: white;
  padding: 4px 12px 12px 4px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .step-join {
    padding: 20px 0px 75px 0px;
    top: -30px;
  }
}
@media (max-width: 767px) {
  .step-join-us > h1 {
    font-size: 32px;
  }
  .step-join-us > h1 p {
    font-size: 16px;
  }
  .step-join {
    padding: 0px;
    top: 0px;
  }
}
@media (max-width: 500px) {
  .step-join {
    padding: 0px;
    top: -30px;
  }
  .step-join-us > h1 {
    font-size: 28px;
  }
  .step-join-us > h1 p {
    font-size: 14px;
    padding: 10px 2px;
  }
}
@media (max-width: 400px) {
  .step-join {
    padding: 0px;
    top: -40px;
  }
}
.card_container {
  display: flex;
  justify-content: space-around;
}

.container_card {
  background: white;
  width: 270px;
  margin: 10px;
  padding: 36px 24px 30px 24px;
  border-radius: 20px;
}

.container_card_top {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.container_card_icon {
  width: 54px;
  height: 54px;
}

.container_card_icon:hover {
  color: red;
}

.container_card_number {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 72px;
  line-height: 52px;
  align-items: center;
  text-align: center;
  color: #aec673;
  opacity: 0.8;
}

.container_card h2 {
  padding: 20px 0px 10px 0px;
  font-size: 23px;
  font-weight: 500;
  font-family: Montserrat;
  font-style: normal;
}

.container_card_text {
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.7882352941);
}

.container_card:hover {
  background-color: rgba(173, 164, 164, 0.4901960784);
  cursor: pointer;
  transition: all 0.2s ease-out;
  border-radius: 6px;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  color: #aec673;
}
.container_card:hover .container_card_text {
  opacity: 0.9;
  color: white;
}

@media (max-width: 680px) {
  .card_container {
    flex-wrap: wrap;
  }
}
.farmer_question_modal-wrapper {
  background: #b1c155;
  max-width: 400px;
  margin: auto;
  transition: all 0.5s ease;
  z-index: 1;
  position: fixed;
  overflow: hidden;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  max-height: 80vh;
  overflow: auto;
  width: 95%;
  border-radius: 10px;
  line-height: 26px;
  font-size: 16px;
}

.farmer_question_modal__header {
  background: #b1c155;
  color: #e9e9e9;
  padding: 0.6rem 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
}
.farmer_question_modal__header h4 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}
.farmer_question_modal__header span {
  font-size: 2rem;
  cursor: pointer;
  color: #e9e9e9;
}

.farmer_question_close-modal-btn {
  font-weight: 700;
  color: #102a43;
  line-height: 35px;
  font-size: 12px;
  text-transform: uppercase;
}

.farmer_question_modal--content {
  padding: 0rem 0rem 10px 0rem;
  background-color: #fff;
  width: 100%;
}

.farmer_question_modal-body {
  padding: 0 !important;
  text-align: left !important;
}
.farmer_question_modal-body h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
.farmer_question_modal-body span {
  font-weight: normal;
}
.farmer_question_modal-body p {
  font-size: 10px;
  color: #333;
  padding: 1rem;
  margin: 1rem 0 2rem 0;
}

.farmer_question_modal_back-drop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9019607843);
  z-index: 9999;
}

/**********farmer question form css*************/
.farmer_question_modal-form {
  background-color: #ffffff;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
}

.farmer_question_modal-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.farmer_question_modal-list li {
  padding: 0.2em;
}
.farmer_question_modal-list li button {
  background: rgba(183, 38, 38, 0.9294117647);
  color: white;
  font-weight: 700;
  font-size: 13px;
  border-radius: 5px;
  margin-top: 8px;
  width: 136px;
  padding: 5px;
  border: none;
}
.farmer_question_modal-list label {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: 500;
}
@media (max-width: 900px) {
  .farmer_question_modal-list label {
    font-size: 12px;
  }
}

.required-star {
  color: #fc4366;
  font-size: small;
}

.farmer_question_modal-form input,
.farmer_question_modal-form textarea {
  margin-top: 4px;
  width: 100%;
  padding: 9px 12px;
  border: 1px solid #e1e2eb;
  background-color: #fff;
  color: #102a43;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.3rem;
  box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
  border-radius: 3px;
}
@media (max-width: 900px) {
  .farmer_question_modal-form input,
  .farmer_question_modal-form textarea {
    font-size: 12px;
  }
}

.asp_number_area {
  padding: 24px 96px 60px 96px;
  display: flex;
  align-items: center;
}
@media (min-width: 900px) and (max-width: 1200px) {
  .asp_number_area {
    padding: 24px 96px 60px 96px;
    flex-direction: column;
  }
}
@media (max-width: 900px) {
  .asp_number_area {
    padding: 0px 55px 40px 36px;
    flex-direction: column;
  }
}
@media (min-width: 447px) and (max-width: 767px) {
  .asp_number_area {
    flex-direction: column;
    padding: 0px 0 64px 0;
  }
}
@media (max-width: 447px) {
  .asp_number_area {
    flex-direction: column;
    padding: 0 0 20px 0;
  }
}
@media (max-width: 400px) {
  .asp_number_area {
    padding: 0 0 24px 0;
  }
}
.asp_number_area-info {
  text-align: left;
  width: 50%;
  padding-right: 64px;
  float: left;
}
.asp_number_area-info--text {
  margin-top: 16px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.7882352941);
  font-weight: 400;
  line-height: 1.5;
}
@media (min-width: 447px) and (max-width: 1200px) {
  .asp_number_area-info {
    width: 90vw;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 0px;
    line-height: 26px;
  }
  .asp_number_area-info .asp_number_area-info--text {
    font-size: 20px !important;
  }
}
@media (max-width: 447px) {
  .asp_number_area-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -24px;
  }
  .asp_number_area-info .asp_number_area-info--text {
    font-size: 18px;
  }
}

.asp_number_promotion-section {
  padding: 10px 0px 0px 0px;
  background-color: #f9f6f9;
}
.asp_number_promotion-section h1 {
  color: rgba(0, 0, 0, 0.7882352941);
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  font-family: montserrat;
  font-style: normal;
  padding: 40px 40px 20px 40px;
  background-color: #f9f6f9;
}

@media (min-width: 447px) and (max-width: 1200px) {
  .asp_number_promotion-section > h1 {
    font-size: 30px;
    padding: 10px 10px 2px 10px;
  }
  .asp_number_promotion-section {
    top: -20px;
  }
  .asp_number_promotion_background-section {
    padding: 5px 0px 0px 0px;
    top: 0px;
  }
}
@media (max-width: 447px) {
  .asp_number_promotion_background-section {
    padding: 60px 0px 0px 0px;
  }
  .asp_number_promotion-section {
    padding: 10px 0px 40px 0px;
  }
  .asp_number_promotion-section > h1 {
    font-size: 26px;
    padding: 16px;
  }
}
.asp_number_promotion_background {
  height: 100rem;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: rgba(245, 240, 245, 0.6);
}
@media (max-width: 900px) {
  .asp_number_promotion_background {
    height: 148rem;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .asp_number_promotion_background {
    height: 200rem;
  }
}
@media (max-width: 447px) {
  .asp_number_promotion_background {
    height: 134rem;
    margin-top: -5rem;
  }
}

.asp_number_promotion-img {
  height: 313px;
  width: 50%;
  background-position: center;
  background-size: cover;
  margin-right: -320px;
}
@media (min-width: 700px) and (max-width: 1200px) {
  .asp_number_promotion-img {
    width: 660px;
    margin: 4rem 0 0 0;
    height: auto;
  }
}
@media (min-width: 447px) and (max-width: 700px) {
  .asp_number_promotion-img {
    width: 90vw;
    margin: 4rem 0 0 0;
    height: auto;
  }
}
@media (max-width: 447px) {
  .asp_number_promotion-img {
    width: 94vw;
    margin: 1rem 0 0 0;
    height: auto;
  }
}
@media (max-width: 375px) {
  .asp_number_promotion-img {
    height: 170px;
    width: 94vw;
    margin: 2rem 0 0 0;
  }
}

.asp_video_modal-wrapper {
  background: #b1c155;
  max-width: 600px;
  margin: auto;
  transition: all 0.5s ease;
  z-index: 1;
  position: fixed;
  overflow: hidden;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  max-height: 80vh;
  overflow: auto;
  width: 95%;
  border-radius: 10px;
}
@media (max-width: 900px) {
  .asp_video_modal-wrapper {
    max-width: 360px;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .asp_video_modal-wrapper {
    max-width: 420px;
  }
}
@media (max-width: 500px) {
  .asp_video_modal-wrapper {
    max-width: 95vw;
  }
}

.asp_video_modal_header {
  background: #b1c155;
  color: #e9e9e9;
  padding: 0.6rem 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
}
.asp_video_modal_header h4 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}
.asp_video_modal_header span {
  font-size: 2rem;
  cursor: pointer;
  color: #e9e9e9;
}

.asp_video_modal_close-modal-btn {
  font-weight: 700;
  color: #102a43;
  line-height: 35px;
  font-size: 12px;
  text-transform: uppercase;
}

.asp_video_modal--content {
  padding: 0rem 0rem 10px 0rem;
  background-color: #fff;
  width: 100%;
}

.asp_video_modal-body {
  padding: 0 !important;
  text-align: left !important;
}
.asp_video_modal-body h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
.asp_video_modal-body span {
  font-weight: normal;
}
.asp_video_modal-body p {
  font-size: 10px;
  color: #333;
  padding: 1rem;
  margin: 1rem 0 2rem 0;
}

.asp_video_modal_back-drop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9019607843);
  z-index: 9999;
}

.asp_video {
  width: 600px;
  height: 380px;
}

@media (min-width: 900px) and (max-width: 1280px) {
  .asp_video {
    width: 420px;
    height: 300px;
  }
}
@media (max-width: 900px) {
  .asp_video {
    width: 360px;
    height: 240px;
  }
}
@media (max-width: 447px) {
  .asp_video {
    width: 95vw;
    height: 220px;
  }
}
.ask_question_form_background {
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -4rem;
}

.ask_question_form_section {
  padding: 28px 30px;
  margin-top: -80px;
  background-color: #f9f6f9;
}
.ask_question_form_section h1 {
  color: #363536;
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  font-family: montserrat;
  font-style: normal;
  padding: 22px 60px 10px 60px;
}
.ask_question_form_section h4 {
  color: #363536;
  text-align: center;
  font-size: 20px;
  font-family: montserrat;
  font-style: normal;
  padding-bottom: 32px;
  font-weight: 400;
}

@media (min-width: 900px) and (max-width: 1024px) {
  .ask_question_form_section {
    margin-top: -106px;
  }
}
@media (max-width: 900px) {
  .ask_question_form_section {
    margin-top: -106px;
  }
  .ask_question_form_section h1 {
    font-size: 32px;
    padding: 42px 60px 14px 60px;
  }
  .ask_question_form_section h4 {
    font-size: 20px;
  }
}
@media (min-width: 300px) and (max-width: 765px) {
  .ask_question_form_section {
    margin-top: 0px;
  }
}
@media (max-width: 447px) {
  .ask_question_form_section {
    padding: 1px 0px 0px 0px;
    top: -40px;
  }
  .ask_question_form_section h1 {
    font-size: 28px;
    padding: 32px 18px 10px 18px;
  }
  .ask_question_form_section h4 {
    font-size: 16px;
  }
}
.ask_question_form {
  background-color: #ffffff;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
}

.ask_question_form-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ask_question_form-list li {
  padding: 0.4em;
}
.ask_question_form-list li button {
  background: #aec673;
  color: white;
  font-weight: 700;
  font-size: 13px;
  border-radius: 5px;
  margin-top: 8px;
  padding: 6px 16px;
  border: none;
}
.ask_question_form-list label {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: 500;
}
@media (max-width: 900px) {
  .ask_question_form-list label {
    font-size: 12px;
  }
}

.ask_question_form input,
.ask_question_form textarea {
  margin-top: 6px;
  width: 100%;
  padding: 9px 12px;
  border: 1px solid #e1e2eb;
  background-color: #fff;
  color: #102a43;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.3rem;
  box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
  border-radius: 3px;
}
@media (max-width: 900px) {
  .ask_question_form input,
  .ask_question_form textarea {
    font-size: 12px;
  }
}

.ask_question_form_detail {
  margin: auto;
  width: 640px;
  padding-bottom: 40px;
}

@media (max-width: 900px) {
  .ask_question_form_detail {
    width: 50vw;
  }
}
@media (min-width: 448px) and (max-width: 767px) {
  .ask_question_form_detail {
    width: 70vw;
  }
}
@media (max-width: 447px) {
  .ask_question_form_detail {
    width: 90vw;
  }
}
.asp_video-section {
  padding: 10px 0px 14px 0px;
  top: 4px;
}
.asp_video-section h1 {
  color: rgba(0, 0, 0, 0.7882352941);
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  font-family: montserrat;
  font-style: normal;
  padding: 40px;
}

.asp_video-section-video {
  width: 600px;
  height: 380px;
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .asp_video-section {
    padding: 0px 0px 180px 0px;
    top: 150px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .asp_video-section-video {
    width: 420px;
    height: 300px;
  }
  .asp_video-section {
    padding: 0px 0px 0px 0px;
    top: 40px;
  }
  .journey-farmer-info--text {
    font-size: 26px !important;
  }
}
@media (max-width: 900px) {
  .asp_video-section-video {
    width: 360px;
    height: 240px;
  }
  .asp_video-section > h1 {
    font-size: 32px;
    padding: 30px;
  }
  .asp_video-section {
    padding: 5px 0px 0px 0px;
    top: 56px;
  }
}
@media (max-width: 447px) {
  .asp_video-section-video {
    width: 95vw;
    height: 220px;
  }
  .asp_video-section {
    padding: 26px 0px 30px 0px;
    top: 48px;
  }
  .asp_video-section > h1 {
    font-size: 26px;
    padding: 16px;
  }
}
.asp_video_background {
  border-radius: 4px;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
}
@media (max-width: 447px) {
  .asp_video_background {
    margin-top: 0rem;
  }
}
@media (max-width: 400px) {
  .asp_video_background {
    margin-top: 0rem;
  }
}

.join_others_background {
  border-radius: 4px;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -6rem;
}

.join_others-section {
  padding: 14px 0px 0px 0px;
  top: 10px;
  background-color: #f9f6f9;
}
.join_others-section h1 {
  color: rgba(0, 0, 0, 0.7882352941);
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  font-family: montserrat;
  font-style: normal;
  padding: 40px;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .join_others-section {
    margin-top: -24px;
    padding: 20px 0px 40px 0px;
  }
}
@media (min-width: 447px) and (max-width: 1200px) {
  .join_others-section > h1 {
    font-size: 30px;
    padding: 10px 10px 2px 10px;
  }
  .join_others-section {
    top: -20px;
  }
}
@media (max-width: 447px) {
  .join_others-section {
    padding: 1px 0px 30px 0px;
    top: -20px;
  }
  .join_others-section h1 {
    font-size: 26px;
    padding: 16px;
  }
}
.join_others-img {
  width: 50%;
  background-position: center;
  background-size: cover;
  margin-right: -320px;
}
@media (max-width: 900px) {
  .join_others-img {
    width: 580px;
    margin-right: -40px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .join_others-img {
    width: 50%;
    margin-right: -40px;
  }
}
@media (min-width: 447px) and (max-width: 767px) {
  .join_others-img {
    width: 90vw;
    margin: 4rem 0 0 0;
    height: auto;
  }
}
@media (max-width: 500px) {
  .join_others-img {
    width: 94vw;
    margin: 2rem 0 0 0;
  }
}

.join_others-info {
  text-align: left;
  width: 50%;
  padding-right: 64px;
  float: left;
  top: -100px;
}
.join_others-info--text {
  margin-top: 16px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.7882352941);
  font-weight: 400;
  line-height: 1.5;
}
@media (max-width: 900px) {
  .join_others-info {
    width: 60%;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
    top: -60px;
  }
  .join_others-info .join_others-info--text {
    font-size: 18px !important;
  }
}
@media (max-width: 447px) {
  .join_others-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -24px;
  }
  .join_others-info .join_others-info--text {
    font-size: 18px;
  }
}

.join_others-farmer {
  padding: 24px 96px 96px 96px;
  display: flex;
  align-items: center;
}
@media (min-width: 900px) and (max-width: 1280px) {
  .join_others-farmer {
    padding: 24px 55px 60px 36px;
  }
}
@media (max-width: 900px) {
  .join_others-farmer {
    padding: 24px 55px 42px 36px;
  }
}
@media (min-width: 447px) and (max-width: 767px) {
  .join_others-farmer {
    flex-direction: column;
    padding: 4px 0 40px 0;
    gap: 20px;
  }
}
@media (max-width: 447px) {
  .join_others-farmer {
    flex-direction: column;
    padding: 0 0 40px 0;
  }
}
.join_others-farmer-info {
  text-align: left;
  width: 50%;
  padding-right: 64px;
  float: left;
}
.join_others-farmer-info--text {
  margin-top: 16px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.7882352941);
  font-weight: 400;
  line-height: 1.5;
}
@media (max-width: 900px) {
  .join_others-farmer-info {
    width: 60%;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
  }
  .join_others-farmer-info .join_others-farmer-info--text {
    font-size: 18px !important;
  }
}
@media (min-width: 447px) and (max-width: 767px) {
  .join_others-farmer-info {
    width: 100%;
    clear: both;
    padding: 0px 14px 0px 14px;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
  }
}
@media (max-width: 447px) {
  .join_others-farmer-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -24px;
  }
  .join_others-farmer-info .join_others-farmer-info--text {
    font-size: 18px;
  }
}

.asp_language_url_section {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 30px;
  position: absolute;
  z-index: 1;
}

.asp_language_url_button_section {
  display: flex;
  justify-content: space-between;
}

.asp_language_url_section_buttoun {
  border: none;
  padding: 6px 16px;
  background-color: #dcf0dc;
  color: #47974a;
  font-size: 16px;
  font-family: montserrat;
  font-weight: 500;
  border-radius: 16px;
  cursor: pointer;
}

.ask_question_form_url_section {
  padding: 1px 0px 100px 0px;
  margin-top: 100px;
}
.ask_question_form_url_section h1 {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 44px;
  font-family: montserrat;
  font-style: normal;
  opacity: 0.8;
  padding: 22px 60px 14px 60px;
}
.ask_question_form_url_section h4 {
  color: black;
  text-align: center;
  font-size: 20px;
  font-family: montserrat;
  font-style: normal;
  opacity: 0.8;
  padding-bottom: 32px;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .ask_question_form_url_section {
    margin-top: 100px;
    padding: 1px 0px 60px 0px;
  }
}
@media (max-width: 900px) {
  .ask_question_form_url_section {
    margin-top: 60px;
    padding: 1px 0px 60px 0px;
  }
  .ask_question_form_url_section h1 {
    font-size: 32px;
    padding: 42px 60px 14px 60px;
  }
  .ask_question_form_url_section h4 {
    font-size: 20px;
    padding: 1px 60px 20px 60px;
  }
  .asp_language_url_section {
    margin-top: 20px;
  }
}
@media (max-width: 447px) {
  .ask_question_form_url_section {
    padding: 1px 0px 112px 0px;
    top: 30px;
  }
  .ask_question_form_url_section h1 {
    font-size: 28px;
    padding: 32px 18px 10px 18px;
  }
  .ask_question_form_url_section h4 {
    font-size: 14px;
    padding: 1px 24px 20px 24px;
  }
  .asp_language_url_section {
    margin-top: -8px;
  }
  .asp_language_url_section_buttoun {
    padding: 4px 12px;
    font-size: 14px;
  }
}
@media (max-width: 375px) {
  .asp_language_url_button_section {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.ask_question_form_url_section_background {
  border-radius: 4px;
  height: 120rem;
  width: 100vw;
  z-index: -10;
  position: absolute;
  left: 50%;
  margin-top: -4rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: rgba(245, 240, 245, 0.6);
}
@media (max-width: 900px) {
  .ask_question_form_url_section_background {
    height: 160rem;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .ask_question_form_url_section_background {
    height: 120rem;
  }
}
@media (max-width: 447px) {
  .ask_question_form_url_section_background {
    height: 138rem;
  }
}

.journey-farmer {
  padding: 0 96px 96px 96px;
  display: flex;
  align-items: center;
}
@media (max-width: 900px) {
  .journey-farmer {
    padding: 0 55px 96px 36px;
  }
}
@media (max-width: 667px) {
  .journey-farmer {
    flex-direction: column;
    padding: 64px 0 64px 0;
  }
}
@media (max-width: 447px) {
  .journey-farmer {
    flex-direction: column;
    padding: 0 0 64px 0;
  }
}
.journey-farmer-info {
  text-align: left;
  width: 50%;
  padding-right: 64px;
  float: left;
}
.journey-farmer-info--text {
  margin-top: 16px;
  font-size: 26px;
  color: #888;
  font-weight: 400;
  line-height: 1.5;
}
@media (max-width: 900px) {
  .journey-farmer-info {
    width: 60%;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
  }
  .journey-farmer-info .journey-farmer-info--text {
    font-size: 18px !important;
  }
}
@media (max-width: 447px) {
  .journey-farmer-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -24px;
  }
  .journey-farmer-info .journey-farmer-info--text {
    font-size: 18px;
  }
}

.not-found {
  font-size: 10rem;
}
.not-found-container {
  margin: 2rem auto 0 auto;
  height: 80vh;
}
.not-found-2 {
  transform: rotate(20deg);
  color: crimson;
}

.success {
  height: 100vh;
}
.success-message {
  text-align: center;
  padding: 14rem 18rem 4rem 18rem;
}
@media (max-width: 900px) {
  .success-message {
    padding: 12rem;
  }
}
@media (max-width: 667px) {
  .success-message {
    padding: 8rem;
  }
}
@media (max-width: 447px) {
  .success-message {
    padding: 5rem;
  }
}

/******* MeraGaon webpage CSS ************/
.meraGaon_language {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 12px;
  position: absolute;
  z-index: 1;
}

.meraGaon_language_button {
  border: none;
  padding: 6px 16px;
  background-color: #a6c065;
  color: #745d1a;
  font-size: 16px;
  font-family: montserrat;
  font-weight: 500;
  border-radius: 16px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .meraGaon_language_button {
    padding: 4px 20px;
  }
  .meraGaon_language {
    margin-top: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 667px) {
  .meraGaon_language_button {
    padding: 4px 8px;
    font-size: 12px;
  }
  .meraGaon_language {
    padding: 6px 8px 6px 0px;
    position: absolute;
    z-index: 1;
    margin-top: 1px;
  }
}
@media (max-width: 447px) {
  .meraGaon_language_button {
    padding: 4px 8px;
    font-size: 12px;
  }
  .meraGaon_language {
    padding: 6px 8px 6px 0px;
    position: absolute;
    z-index: 1;
    margin-top: 1px;
  }
}
.meraGaon_hero {
  margin-top: 90px;
}
.meraGaon_hero h1 {
  position: absolute;
  width: 100%;
  color: #9fbc59;
  justify-content: space-around;
  font-size: 48px;
  font-family: montserrat;
  font-weight: bold;
  padding: 16px;
  text-align: center;
}
.meraGaon_hero p {
  position: absolute;
  display: flex;
  width: 100%;
  color: #5a5448;
  justify-content: space-around;
  font-size: 28px;
  font-family: montserrat;
  font-weight: 500;
  top: 74px;
}
@media (min-width: 800px) and (max-width: 1024px) {
  .meraGaon_hero h1 {
    font-size: 38px;
    padding: 26px 26px 4px 26px;
  }
  .meraGaon_hero p {
    font-size: 24px;
    padding: 4px 8px;
    top: 68px;
  }
}
@media (max-width: 900px) {
  .meraGaon_hero {
    margin-top: 72px;
  }
  .meraGaon_hero h1 {
    font-size: 32px;
    padding: 26px 26px 4px 26px;
  }
  .meraGaon_hero p {
    font-size: 22px;
    padding: 4px 8px;
    top: 60px;
  }
}
@media (max-width: 500px) {
  .meraGaon_hero {
    margin-top: 76px;
  }
  .meraGaon_hero h1 {
    font-size: 28px;
    padding: 16px;
  }
  .meraGaon_hero p {
    font-size: 14px;
    padding: 2px 30px 4px 30px;
    top: 50px;
  }
}
@media (max-width: 400px) {
  .meraGaon_hero {
    margin-top: 76px;
  }
  .meraGaon_hero p {
    font-size: 14px;
    padding: 2px 60px 4px 60px;
    top: 54px;
    text-align: center;
    font-weight: 500;
  }
}

.search_village_buton_area {
  top: 140px;
  margin-right: 500px;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .search_village_buton_area {
    margin-right: 400px;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .search_village_buton_area {
    margin-right: 300px;
  }
}
@media (max-width: 768px) {
  .search_village_buton_area {
    margin-right: 180px;
    top: 110px;
  }
}
@media (max-width: 500px) {
  .search_village_buton_area {
    margin-right: 54px;
    top: 72px;
  }
}
@media (max-width: 400px) {
  .search_village_buton_area {
    margin-right: 28px;
    top: 74px;
  }
}

.meraGaon_hero_image {
  width: 100vw;
}
.meraGaon_hero_image img {
  width: 100%;
  height: 650px;
}
@media (min-width: 800px) and (max-width: 1024px) {
  .meraGaon_hero_image .search_village_buton_area {
    margin-right: 400px;
  }
  .meraGaon_hero_image img {
    height: 480px;
  }
}
@media (max-width: 768px) {
  .meraGaon_hero_image img {
    height: 380px;
  }
}
@media (max-width: 500px) {
  .meraGaon_hero_image img {
    height: 220px;
  }
}
@media (max-width: 400px) {
  .meraGaon_hero_image img {
    height: 220px;
  }
}

/*****use MG  page carousal modified css of carousal package*******/
.use-mg-page-slider {
  max-width: 50%;
}
.use-mg-page-slider .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}
.use-mg-page-slider .carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0) !important;
}
@media (max-width: 900px) {
  .use-mg-page-slider {
    max-width: 100%;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .use-mg-page-slider {
    max-width: 80%;
  }
}

/********use meragaon  section**********/
.usemeragaon-section {
  padding: 40px 0px 0px 0px;
}
.usemeragaon-section h1 {
  color: black;
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  font-family: montserrat;
  font-style: normal;
  opacity: 0.8;
  padding: 10px 10px 2px 10px;
}

@media (max-width: 900px) {
  .usemeragaon-section > h1 {
    font-size: 28px;
    padding: 32px 32px 4px 32px;
  }
  .usemeragaon-section {
    padding: 5px 0px 0px 0px;
  }
  .buton_area {
    margin-right: 160px;
  }
}
@media (max-width: 667px) {
  .usemeragaon-section {
    padding: 14px 10px 0px 10px;
  }
}
@media (max-width: 447px) {
  .usemeragaon-section {
    padding: 24px 10px 0px 10px;
  }
  .usemeragaon-section > h1 {
    font-size: 26px;
    padding: 10px 10px 6px 10px;
  }
}
.usemeragaon-section-details {
  padding: 20px 96px 60px 96px;
  display: flex;
  align-items: center;
}
@media (min-width: 900px) and (max-width: 1200px) {
  .usemeragaon-section-details {
    flex-direction: column;
    padding: 8px 0 64px 0;
    flex-wrap: wrap;
  }
}
@media (max-width: 900px) {
  .usemeragaon-section-details {
    padding: 0 55px 48px 36px;
    flex-wrap: wrap;
  }
}
@media (max-width: 667px) {
  .usemeragaon-section-details {
    flex-direction: column;
    padding: 14px 0 40px 0;
    flex-wrap: wrap;
  }
}
@media (max-width: 447px) {
  .usemeragaon-section-details {
    flex-direction: column;
    padding: 0 0 40px 0;
  }
}
.usemeragaon-section-details-info {
  text-align: left;
  padding-right: 64px;
  float: left;
}
.usemeragaon-section-details-info--text {
  margin-top: 4px;
  font-size: 22px;
  color: #333333;
  font-weight: 400;
  line-height: 1.5;
}
@media (min-width: 900px) and (max-width: 1280px) {
  .usemeragaon-section-details-info {
    width: 80%;
    clear: both;
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .usemeragaon-section-details-info .usemeragaon-section-details-info--text {
    font-size: 22px !important;
  }
}
@media (max-width: 900px) {
  .usemeragaon-section-details-info {
    width: 100%;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 0px;
    line-height: 26px;
    padding-bottom: 20px;
  }
  .usemeragaon-section-details-info .usemeragaon-section-details-info--text {
    font-size: 18px !important;
  }
}
@media (max-width: 447px) {
  .usemeragaon-section-details-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -24px;
  }
  .usemeragaon-section-details-info .usemeragaon-section-details-info--text {
    font-size: 18px;
  }
}

.useBusinessmeragaon-section {
  padding: 40px 0px 0px 0px;
}
.useBusinessmeragaon-section h1 {
  color: black;
  text-align: center;
  font-weight: 500;
  font-size: 38px;
  font-family: montserrat;
  font-style: normal;
  opacity: 0.8;
  padding: 20px 10px 2px 10px;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .useBusinessmeragaon-section {
    padding: 4px;
  }
  .useBusinessmeragaon-section h1 {
    font-size: 30px;
  }
}
@media (max-width: 900px) {
  .useBusinessmeragaon-section {
    padding: 4px;
  }
  .useBusinessmeragaon-section > h1 {
    font-size: 26px;
    padding: 24px 32px 4px 32px;
  }
  .useBusinessmeragaon-section {
    padding: 10px 0px 0px 0px;
  }
}
@media (min-width: 448px) and (max-width: 700px) {
  .useBusinessmeragaon-section {
    padding: 24px 12px;
  }
  .useBusinessmeragaon-section > h1 {
    font-size: 26px;
    padding: 14px;
  }
}
@media (min-width: 448px) and (max-width: 700px) {
  .useBusinessmeragaon-section {
    padding: 24px 12px;
  }
}
@media (max-width: 447px) {
  .useBusinessmeragaon-section {
    padding: 24px 12px;
  }
  .useBusinessmeragaon-section > h1 {
    font-size: 26px;
    padding: 14px;
  }
}
.useBusinessmeragaon-section-details {
  padding: 60px 96px 60px 96px;
  display: flex;
  align-items: center;
}
@media (min-width: 900px) and (max-width: 1200px) {
  .useBusinessmeragaon-section-details {
    flex-direction: column;
    padding: 8px 0 64px 0;
    flex-wrap: wrap;
  }
}
@media (max-width: 900px) {
  .useBusinessmeragaon-section-details {
    padding: 0 50px 48px 50px;
    flex-wrap: wrap;
  }
}
@media (max-width: 667px) {
  .useBusinessmeragaon-section-details {
    flex-direction: column;
    padding: 1px 0 40px 0;
    flex-wrap: wrap;
  }
}
@media (max-width: 447px) {
  .useBusinessmeragaon-section-details {
    flex-direction: column;
    padding: 0 0 40px 0;
  }
}
.useBusinessmeragaon-section-details-info {
  text-align: left;
  width: 50%;
  padding-right: 64px;
  float: left;
}
.useBusinessmeragaon-section-details-info--text {
  margin-top: 4px;
  font-size: 22px;
  color: #333;
  font-weight: 400;
  line-height: 1.5;
}
.useBusinessmeragaon-section-details-info--text2 {
  margin-top: 4px;
  font-size: 22px;
  color: #333;
  font-weight: 500;
  line-height: 1.5;
}
@media (min-width: 769px) and (max-width: 1200px) {
  .useBusinessmeragaon-section-details-info {
    width: 100%;
    text-align: center;
    padding-right: 10px;
  }
  .useBusinessmeragaon-section-details-info .useBusinessmeragaon-section-details-info--text {
    font-size: 20px !important;
    padding: 0px 50px 24px 50px;
  }
}
@media (max-width: 768px) {
  .useBusinessmeragaon-section-details-info {
    width: 100%;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
  }
  .useBusinessmeragaon-section-details-info--text {
    font-size: 18px !important;
  }
  .useBusinessmeragaon-section-details-info--text2 {
    font-size: 18px !important;
  }
}
@media (max-width: 447px) {
  .useBusinessmeragaon-section-details-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -10px;
  }
  .useBusinessmeragaon-section-details-info--text {
    font-size: 18px;
  }
  .useBusinessmeragaon-section-details-info--text2 {
    font-size: 18px;
  }
}

.mg-event {
  padding: 0px 0px 0px 0px;
}

.mg-event-us {
  background-color: rgba(245, 240, 245, 0.6);
  padding: 40px 60px 80px 60px;
}
.mg-event-us h1 {
  text-align: center;
  font-weight: 500;
  font-size: 38px;
  font-family: montserrat;
  font-style: normal;
}
.mg-event-us h1 p {
  text-align: center;
  font-weight: 300;
  font-family: montserrat;
  font-size: 20px;
  color: white;
  padding: 12px;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .mg-event {
    padding: 4px 0px 20px 0px;
  }
  .mg-event-us {
    padding: 40px 60px 50px 60px;
  }
}
@media (max-width: 768px) {
  .mg-event-us {
    padding: 40px 40px 20px 40px;
  }
  .mg-event-us > h1 {
    font-size: 28px;
  }
  .mg-event-us > h1 p {
    font-size: 16px;
  }
  .mg-event {
    padding: 0px;
  }
}
@media (max-width: 500px) {
  .mg-event-us > h1 {
    font-size: 28px;
  }
  .mg-event-us > h1 p {
    font-size: 14px;
    padding: 10px 2px;
  }
  .mg-event {
    padding: 0px;
  }
}
@media (max-width: 400px) {
  .mg-event {
    padding: 0px;
  }
}
.event_card_container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 40px;
}

.event_container_card {
  background: white;
  cursor: default;
  width: 360px;
  margin: 10px;
  padding: 16px 20px 20px 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.event_container_card h2 {
  padding: 24px 0px 10px 0px;
  font-size: 23px;
  font-weight: 500;
  font-family: Montserrat;
  font-style: normal;
  text-align: center;
}

.event_container_card_text {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 0px 14px;
}

.event_container_card:hover {
  background-color: #a2bd5e;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.event_container_card:hover .container_card_text {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .event_card_container {
    padding-top: 20px;
  }
}
@media (max-width: 500px) {
  .event_card_container {
    flex-wrap: wrap;
  }
  .event_card_container {
    padding-top: 10px;
  }
}
.get_mg_app_section {
  background-color: #7f6127;
  padding: 40px 20px;
}
.get_mg_app_section h4 {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  color: white;
}
@media (max-width: 900px) {
  .get_mg_app_section h4 {
    font-size: 26px;
  }
}
@media (max-width: 667px) {
  .get_mg_app_section h4 {
    font-size: 24px;
  }
}
@media (max-width: 447px) {
  .get_mg_app_section {
    padding: 40px 20px;
  }
  .get_mg_app_section h4 {
    font-size: 20px;
  }
}

.get_mg_app {
  text-align: center;
  margin-right: 22px;
}
.get_mg_app button {
  background: transparent;
  padding: 8px 12px 2px 12px;
  border: none;
}
.get_mg_app img {
  width: 140px;
  max-width: 150px;
  display: inline-block;
}
@media (max-width: 447px) {
  .get_mg_app {
    margin-right: 2px;
  }
  .get_mg_app button {
    padding: 20px 12px 2px 12px;
  }
}

.get_mg_app_input_section {
  text-align: center;
  padding: 18px 12px 12px 12px;
}
.get_mg_app_input_section input {
  padding: 12px;
  width: 190px;
  border: none;
  font-size: 12px;
  border-radius: 10px 0px 0px 10px;
}
.get_mg_app_input_section button {
  background: #215523;
  border-radius: 0px 10px 10px 0px;
  border: none;
  color: white;
  padding: 8px 12px;
  font-size: 18px;
}
@media (max-width: 447px) {
  .get_mg_app_input_section {
    padding: 20px 6px 2px 6px;
  }
  .get_mg_app_input_section input {
    padding: 9px;
    width: 180px;
    font-size: 12px;
  }
  .get_mg_app_input_section button {
    padding: 7px 8px;
    font-size: 14px;
  }
}

.mg-village {
  background-color: #f4f4f8;
  min-height: 100vh;
  padding: 110px 40px 20px 40px;
}
@media (max-width: 500px) {
  .mg-village {
    padding: 86px 10px 20px 10px;
  }
}
.mg-village-section {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  height: 100%;
  border: 1px solid #ccc;
}
@media (max-width: 400px) {
  .mg-village-section {
    padding: 20px 10px;
  }
}
.mg-village-profile {
  position: relative;
}

.mg-village_cover-picture {
  margin: -20px;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 280px;
}
@media (max-width: 400px) {
  .mg-village_cover-picture {
    margin: -20px -10px;
  }
}
.mg-village_cover-picture img {
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}

.mg-village_profile-picture {
  height: 194px;
  width: 196px;
  margin: auto;
  position: relative;
  cursor: pointer;
  background-color: white;
  padding: 5px;
  border-radius: 2px;
  top: -75px;
  border-radius: 50%;
}
.mg-village_profile-picture img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.mg-village_profile-overview {
  margin-left: 40px;
  margin-top: -60px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mg-village_profile-overview p {
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .mg-village_profile-overview {
    margin-left: 10px;
    text-align: center;
  }
}

.see_village_section {
  margin: 60px 0px;
  padding-top: 30px;
  padding-bottom: 16px;
}
.see_village_section h4 {
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 24px;
}
@media (max-width: 900px) {
  .see_village_section {
    padding: 12rem;
  }
}
@media (max-width: 667px) {
  .see_village_section {
    padding: 8rem;
  }
}
@media (max-width: 447px) {
  .see_village_section {
    padding: 1rem;
    margin: 40px 0px;
  }
  .see_village_section h4 {
    font-size: 26px;
    padding-bottom: 16px;
  }
}

.see_village_section_search {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin: auto;
  width: 470px;
}
.see_village_section_search button {
  background: #337ab7;
  padding: 6px 18px;
  font-size: 18px;
  border: none;
  border-radius: 26px;
  color: white;
  font-weight: 500;
}

.mg_village_result_area {
  margin: 80px 280px;
}

.search_village_input_text {
  width: 300px;
  font-size: 16px;
}

@media (max-width: 447px) {
  .see_village_section_search {
    width: 360px;
    padding: 4px 20px;
    gap: 8px;
  }
  .see_village_section_search button {
    padding: 4px 14px;
    font-size: 14px;
  }
  .search_village_input_text {
    font-size: 13px;
  }
  .mg_village_result_area {
    margin: 12px 10px;
  }
}
.hhero_see-btn {
  background-color: #337ab7;
  border-color: #337ab7;
  border: none;
}
.hhero_see-btn:hover {
  color: rgb(255, 255, 255);
  background-color: #4f8bbf;
}

.green_section_quote {
  background-color: #a2bd5e;
  padding: 60px;
}
.green_section_quote h1 {
  color: #fff;
  font-weight: 500;
  font-size: 38px;
  font-family: montserrat;
  font-style: normal;
  line-height: 1.3;
  text-align: center;
}

.mg_busines_section {
  padding: 14px 0px 0px 0px;
  top: 10px;
}
.mg_busines_section h1 {
  color: rgba(0, 0, 0, 0.7882352941);
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  font-family: montserrat;
  font-style: normal;
  padding: 40px;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .mg_busines_section {
    margin-top: -24px;
  }
  .green_section_quote > h1 {
    font-size: 30px;
  }
}
@media (max-width: 900px) {
  .mg_busines_section {
    padding: 20px 0px 0px 0px;
  }
  .mg_busines_section h1 {
    font-size: 32px;
    padding: 24px;
  }
  .green_section_quote {
    padding: 40px 20px;
  }
  .green_section_quote > h1 {
    font-size: 32px;
  }
}
@media (max-width: 447px) {
  .mg_busines_section {
    padding: 1px 0px 0px 0px;
    top: 14px;
  }
  .mg_busines_section h1 {
    font-size: 26px;
    padding: 16px;
  }
  .green_section_quote > h1 {
    font-size: 28px;
  }
  .green_section_quote {
    padding: 30px 20px;
  }
}
.mg_busines_section-img {
  width: 50%;
  background-position: center;
  background-size: cover;
}
@media (min-width: 769px) and (max-width: 1200px) {
  .mg_busines_section-img {
    width: 90vw;
  }
}
@media (max-width: 768px) {
  .mg_busines_section-img {
    width: 95vw;
    margin: 3rem 0 0 0;
  }
}
@media (max-width: 500px) {
  .mg_busines_section-img {
    width: 94vw;
    margin: 2rem 0 0 0;
  }
}
@media (max-width: 375px) {
  .mg_busines_section-img {
    height: 166px;
    width: 94vw;
    margin: 2rem 0 0 0;
  }
}

.mg_busines_section-info {
  text-align: left;
  width: 50%;
  padding-right: 64px;
  float: left;
  top: -100px;
}
.mg_busines_section-info--text {
  margin-top: 16px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.7882352941);
  font-weight: 400;
  line-height: 1.5;
}
@media (max-width: 900px) {
  .mg_busines_section-info {
    width: 60%;
    clear: both;
    text-align: center;
    font-size: 16px;
    padding-right: 30px;
    line-height: 26px;
    top: -60px;
  }
  .mg_busines_section-info .mg_busines_section-info--text {
    font-size: 18px !important;
  }
}
@media (max-width: 447px) {
  .mg_busines_section-info {
    width: 100%;
    padding: 14px;
    clear: both;
    text-align: center;
    font-size: 18px;
    top: -24px;
  }
  .mg_busines_section-info .mg_busines_section-info--text {
    font-size: 18px;
  }
}

.feature {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}
@media (max-width: 667px) {
  .feature {
    padding: 5rem 0;
  }
}
@media (max-width: 447px) {
  .feature {
    padding: 0;
  }
}
.feature-button {
  height: 5rem;
  width: 10rem;
  font-size: 2rem;
  border: none;
  box-shadow: 2px 2px 40px 10px rgba(230, 230, 230, 0.6);
  border-radius: 50%;
  transition: 200ms;
  color: #aaa;
}
.feature-button:hover {
  box-shadow: 2px 2px 40px 10px rgba(217, 221, 217, 0.6);
  color: #333;
}
.feature-card {
  align-items: center;
  margin: 3rem 2rem 0 2rem;
  transition: 200ms;
  padding: 2rem;
  cursor: pointer;
  perspective: 10rem;
  border-radius: 10px;
  border: 1px solid transparent;
}
@media (max-width: 900px) {
  .feature-card {
    flex-direction: column;
    height: auto;
  }
}
@media (max-width: 667px) {
  .feature-card {
    flex-direction: column;
    margin: 0;
    padding: 2rem;
  }
}
@media (max-width: 447px) {
  .feature-card {
    flex-direction: column;
    margin: 0 0 14px 0;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.0509803922);
    border-radius: 8px;
  }
}
.feature-card-details {
  padding: 6rem 4rem;
}
@media (max-width: 900px) {
  .feature-card-details {
    padding: 3rem 10rem;
  }
}
@media (max-width: 667px) {
  .feature-card-details {
    padding: 1rem 6rem;
  }
}
@media (max-width: 447px) {
  .feature-card-details {
    padding: 0 15px;
  }
}
.feature-card-image-container {
  position: relative;
}
@media (max-width: 900px) {
  .feature-card-image-container {
    height: auto;
    padding: 3rem 10rem;
  }
}
@media (max-width: 667px) {
  .feature-card-image-container {
    padding: 3rem 6rem;
    height: auto;
  }
}
@media (max-width: 447px) {
  .feature-card-image-container {
    padding: 2rem 15px;
  }
}
.feature-card-image {
  position: absolute;
  top: 4rem;
  left: 0;
  width: 42rem;
  height: 52rem;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .feature-card-image {
    width: 36rem;
    height: 50rem;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .feature-card-image {
    width: 34rem;
    height: 48rem;
  }
}
@media (max-width: 900px) {
  .feature-card-image {
    width: 38rem;
    height: 50rem;
    top: 4rem;
  }
}
@media (max-width: 667px) {
  .feature-card-image {
    width: 40rem;
    height: 40rem;
  }
}
@media (max-width: 447px) {
  .feature-card-image {
    width: 38rem;
    height: 46rem;
  }
}
.feature-card-image-1 {
  background: url(../images/post.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.feature-card-image-2 {
  background: url(../images/offer.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.feature-card-image-3 {
  background: url(../images/anouncement.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.feature-card-image-4 {
  background: url(../images/asp.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.feature-card-image-5 {
  background: url(../images/event.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.feature-card-image-6 {
  background: url(../images/csoon.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.feature-card-special-img {
  display: none;
  margin: 4rem auto 0 auto;
  background: url(../images/csoon.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 667px) {
  .feature-card-special-img {
    display: block;
    height: 30rem;
    width: 60rem;
  }
}
@media (max-width: 447px) {
  .feature-card-special-img {
    display: block;
    height: 38rem;
    width: 40rem;
  }
}
.feature-card-text {
  margin-top: 1rem;
}
.feature-card:hover {
  box-shadow: 2px 2px 40px 10px rgba(230, 240, 230, 0.6);
}
@media (max-width: 667px) {
  .feature-card:hover {
    box-shadow: none;
  }
}
@media (max-width: 447px) {
  .feature-card:hover {
    box-shadow: none;
  }
}
.feature-card-active {
  background-color: #edf1e4;
  box-shadow: 2px 2px 40px 10px rgba(230, 240, 230, 0.6);
  border: 1px solid rgb(220, 240, 220);
}
@media (max-width: 667px) {
  .feature-card-active {
    background-color: #edf1e4;
    border: 1px solid transparent;
  }
}
@media (max-width: 447px) {
  .feature-card-active {
    background-color: #edf1e4;
    border: 1px solid rgb(220, 240, 220);
  }
}
.feature-list {
  list-style: none;
  display: flex;
  font-size: 1.8rem;
  justify-content: center;
  width: 70%;
  margin: 4rem auto 0 auto;
}
@media (max-width: 667px) {
  .feature-list {
    width: 90%;
    margin: 3rem auto 0 auto;
  }
}
@media (max-width: 447px) {
  .feature-list {
    width: 90%;
    margin: 3rem auto 0 auto;
  }
}
.feature-list li {
  margin: 0 3rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
@media (max-width: 667px) {
  .feature-list li {
    margin: 0 2rem;
  }
}
@media (max-width: 447px) {
  .feature-list li {
    margin: 0 1rem;
  }
}
.feature-list li:hover {
  color: forestgreen;
}
.feature-list li.features-list-item-active {
  border-bottom: 2px solid forestgreen;
}

.renderImage {
  display: none;
}

.renderImageCard {
  display: block;
}
@media (max-width: 767px) {
  .renderImageCard {
    display: none;
  }
}

/***** modified css of carousal package for Mg feature*******/
.use-mg-feature-slider {
  display: none;
}
.use-mg-feature-slider .carousel .slider.animated {
  transition: none;
}
.use-mg-feature-slider .carousel .slide {
  background: transparent;
}
.use-mg-feature-slider .carousel .slide img {
  width: 50%;
}
@media (min-width: 426px) and (max-width: 767px) {
  .use-mg-feature-slider .carousel .slide img {
    width: 40%;
  }
}
.use-mg-feature-slider .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
  height: 40px;
  border-radius: 4px;
  align-items: center;
  top: 150px;
  background: transparent;
}
@media (min-width: 447px) and (max-width: 767px) {
  .use-mg-feature-slider .carousel.carousel-slider .control-arrow {
    margin: 0px 60px;
    top: 200px;
  }
}
.use-mg-feature-slider .carousel.carousel-slider .control-prev {
  color: red;
}
.use-mg-feature-slider .carousel.carousel-slider .control-prev::before {
  border-right: 8px solid #a2bd5e;
}
.use-mg-feature-slider .carousel.carousel-slider .control-next {
  color: red;
}
.use-mg-feature-slider .carousel.carousel-slider .control-next::before {
  border-left: 8px solid #a2bd5e;
}
.use-mg-feature-slider .carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}
@media (max-width: 767px) {
  .use-mg-feature-slider {
    max-width: 100%;
    display: block;
  }
}
.use-mg-feature-slider h4 {
  font-size: 24px;
  padding: 24px 10px 10px 10px;
  color: #333;
}
.use-mg-feature-slider p {
  font-size: 16px;
  color: #333;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}