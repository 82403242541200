.promotion-post {
    font-family: "PT Sans", sans-serif;

    padding-top: 110px;
    min-height: calc(100vh - 200px);
    background-color: #f7f6f6;
    padding-bottom: 25px;
    &-wrapper {
        width: 600px;
        max-width: 95%;
        margin: auto;
    }
    &-card {
        font-size: 1.6rem;
        background-color: white;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
        margin: 20px 0;
        &--header {
            padding: 25px 10px;

            display: flex;
            align-items: center;
            img {
                height: 50px;
                margin-right: 20px;
            }
        }
        &--body {
            padding: 15px 0;
            height: 550px;
            p {
                line-height: 3.2rem;
                font-size: 1.8rem;
            }
        }
        &--footer {
            padding: 15px 10px;
        }
        &--footer-tags {
            padding: 15px 0;
            display: flex;
            flex-wrap: wrap;
            span {
                margin-right: 10px;
                font-weight: bold;
            }
        }
        .slider-card {
            height: 510px;
            iframe,
            video {
                height: 510px;
                width: 100%;
            }
        }
    }
    .promotion-card-fb {
        height: 100%;
        div {
            height: 100%;
            background-repeat: no-repeat;

            background-position: center;
            background-size: contain;
        }
    }
    .promotion-card-kn {
        height: 100%;
        img {
            margin: auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        embed {
            height: 100%;
            width: 100%;
        }
        &-audio {
            position: relative;
        }
        audio {
            position: absolute;
            bottom: 10px;
            width: 90%;
            left: 5%;
        }
    }
    .slick-arrow {
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        height: 40px;
        width: 40px;
        z-index: 1;
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
}
