@mixin easing($duration, $timing, $delay) {
  -webkit-transition: all $duration $timing $delay;
  -moz-transition: all $duration $timing $delay;
  -ms-transition: all $duration $timing $delay;
  -o-transition: all $duration $timing $delay;
  transition: all $duration $timing $delay;
}

@mixin shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  -o-box-shadow: $value;
  -ms-box-shadow: $value;
  box-shadow: $value;
}

@mixin trans($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin breakpoint($point) {
  @if $point == largeDesktop {
    @media (min-width: 1610px) {
      @content;
    }
  } @else if $point == desktop {
    @media (max-width: 1600px) {
      @content;
    }
  } @else if $point == laptop {
    @media (max-width: 1100px) {
      @content;
    }
  } @else if $point == tablet {
    @media (max-width: 900px) {
      @content;
    }
  } @else if $point == phablet {
    @media (max-width: 667px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 447px) {
      @content;
    }
  }
  @else if $point==hdLaptop {
      @media (min-width: 2000px){
          @content;
      }
  }
}
