@import "colors";
@import "mixins";

#kn-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    color: $dark;
    z-index: 201;
    font-size: 1.5em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    @include easing(0.4s, ease, 0s);
    //border-bottom: 1px solid rgb(209, 209, 209);
    .menu-btn {
        display: none;
    }
    .logo-container {
        img {
            @include easing(0.4s, ease, 0s);
        }
    }
    a.active {
        border-bottom: 2px solid $positive-green;
        color: black;
    }
    .link-container {
        display: block;
        ul.main-list {
            display: table;
            //width: 100%;
            margin: 0;
            padding: 0;
            //table-layout: fixed;
            float: right;
            //border: 1px solid black;
            @include breakpoint(phablet) {
                display: block;
            }
            @include breakpoint(mobile) {
                display: block;
            }
            li {
                display: table-cell;
                @include breakpoint(phablet) {
                    display: block;
                }
                @include breakpoint(mobile) {
                    display: block;
                }
                font-size: 14px;
                color: $text-tertiary;
                cursor: pointer;
                text-align: center;
                //border: 1px solid black;
                transition: all 0.2s ease 0s;
                border-bottom: 1px solid transparent;
                font-weight: 400;
                &:hover {
                    //border-bottom: 1px solid black;
                    color: $dark;
                }
                .dropdown-card-list {
                    display: block;
                    width: 200px;
                    position: absolute;
                    background: rgb(247, 245, 245);
                    top: 45px;
                    right: 0;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                    li {
                        display: block;
                        padding: 8px 12px;
                        user-select: none;
                        -webkit-user-select: none;
                        text-align: left;
                        &:first-child {
                            border-bottom: 1px solid #ccc;
                        }
                        &:hover {
                            background: $primary-brown;
                            color: $light;
                            border-bottom: 1px solid $primary-brown;
                        }
                        a {
                            &:hover {
                                color: white;
                            }
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: -20px;
                        right: 20px;
                        border-style: solid;
                        border-width: 10px;
                        border-color: transparent transparent rgb(247, 245, 245) transparent;
                    }
                }
            }
            li.active {
                border-bottom: 1px solid black;
            }
        }
    }
}

.scroll-before {
    //background: #FAFFFF;
    background: #ffffff;
    padding: 20px 20px;
    color: $dark;
    font-size: 1.5em;
    .logo-container {
        img {
            @include easing(0.4s, ease, 0s);
            max-width: 250px;
        }
    }
    .link-container {
        ul {
            li {
                font-size: 14px;
                padding: 10px 10px;
            }
            li.active {
                border-bottom: 1px solid black;
            }
        }
    }
}

.header-scroll {
    background: white;
    padding: 15px 20px;
    color: $light;
    z-index: 100;
    font-size: 1.5em;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    .logo-container {
        img {
            @include easing(0.4s, ease, 0s);
            max-width: 180px;
        }
    }
    .link-container {
        ul {
            li {
                padding: 5px 10px;
                //border: 1px solid black;
            }
            li.active {
                border-bottom: 1px solid black;
            }
        }
    }
}

.vis {
    display: block;
}

.hide {
    display: block;
}

@media (max-width: 992px) {
    #kn-header {
        .logo-container {
            max-width: 170px;
            img {
                max-width: 100%;
            }
        }
        .menu-btn {
            display: block;
            position: absolute;
            right: 10px;
            top: 0;
            color: $primary-brown;
            background-color: transparent;
            border: none;
            font-size: 2rem;
            z-index: 100;
        }
        .link-container {
            ul {
                width: 100%;
                margin: 30px 0 0 0;
                padding: 0;
                table-layout: fixed;
                float: none;
                li {
                    display: block;
                    font-size: 14px;
                    cursor: pointer;
                    text-align: center;
                    padding: 15px 10px;
                    border-bottom: 1px solid transparent;
                    &:hover {
                        border-bottom: 1px solid transparent;
                        color: $dark;
                        background: white;
                    }
                }
                li.active {
                    border-bottom: 1px solid black;
                }
            }
        }
    }
    .vis {
        display: block;
    }
    .hid {
        display: none !important;
    }
}
