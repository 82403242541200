@import "mixins";
@import "colors";

.container {
    margin: 8.9rem auto 0 auto;
    min-height: 100vh;
}

.section-container {
    padding: 2rem;
    margin: 7rem 0;

    @include breakpoint(tablet) {
        padding: 3rem 2rem 2rem 2rem;
        margin: 3rem 0;
    }

    @include breakpoint(mobile) {
        padding: 2rem 1rem 1rem 1rem;
        margin: 3rem 0;
    }
}
.homepage .section-container {
    @include breakpoint(largeDesktop) {
        padding: 2rem 1rem 1rem 1rem;
        margin: 3rem 0;
    }
}

.divider--sm {
    margin: 10px 0;
}

.divider--md {
    margin: 20px 0;

    // @include breakpoint(tablet) {
    //     padding: 3rem 2rem 2rem 2rem;
    //     margin: 3rem 0;
    // }

    @include breakpoint(mobile) {
        margin: 15px 0;
    }
}

.divider--lg {
    margin: 30px 0;

    // @include breakpoint(tablet) {
    //     padding: 3rem 2rem 2rem 2rem;
    //     margin: 3rem 0;
    // }

    @include breakpoint(mobile) {
        margin: 20px 0;
    }
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-italic {
    font-style: italic;
    // border-bottom: .2rem solid $theme-brown-text;
}

.padding-15 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.padding-20 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.padding-25 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.has-border {
    border: 1px solid black;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.center {
    display: block;
    margin: 0 auto;
}

.hide-on-desktop {
    @include breakpoint(desktop) {
        display: none;
    }
}

.hide-on-laptop {
    @include breakpoint(laptop) {
        display: none;
    }
}

.hide-on-tablet {
    @include breakpoint(tablet) {
        display: none;
    }
}

.hide-on-phablet {
    @include breakpoint(phablet) {
        display: none;
    }
}

.hide-on-mobile {
    @include breakpoint(mobile) {
        display: none;
    }
}

.dropdown-card-list {
    display: block;
    width: 200px;
    position: absolute;
    background: rgb(247, 245, 245);
    top: 45px;
    right: 0;
    z-index: 10;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    li {
        display: block;
        padding: 8px 12px;
        font-size: 1.6rem;
        color: black;
        user-select: none;
        -webkit-user-select: none;
        text-align: left;
        &:first-child {
            border-bottom: 1px solid #ccc;
        }
        &:hover {
            background: $primary-brown;
            color: $light;
            border-bottom: 1px solid $primary-brown;
        }
        a {
            &:hover {
                color: white;
            }
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: -20px;
        right: 20px;
        border-style: solid;
        border-width: 10px;
        border-color: transparent transparent rgb(247, 245, 245) transparent;
    }
    .dropdown-card-list--custom {
        width: 100%;
    }
}

.kn-divider::after {
    content: "";
    clear: both;
    display: table;
}

.important-link {
    color: #333;
}

.careers-hiring {
    color: $theme-green;
    font-weight: 500;
    background-color: rgba(220, 240, 220, 1);
    padding: 4px 8px;
    border-radius: 4px;
    border: none !important; //to prevent extra border when it is active,
    &:hover {
        color: #444;
    }

    &:after {
        // content: "";
        // display: inline-block;
        // position: absolute;
        // height: 2rem;
        // width: 12rem;
        // right: -5px;
        // z-index: -10;
        // background-color: rgba(220, 240, 220, 1);
        // border-radius: 4px;
    }
}

.loader {
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 50px;
        height: 50px;
    }
    h1 {
        margin-top: 15px;
        font-size: 2rem;
    }
    @media (min-width: 900px) {
        img {
            height: auto;
            width: auto;
        }
    }
}
