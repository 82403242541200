@import "colors";
@import "mixins";

//// @Author Suman kumar, 11th Oct, 2021.
html.modal_question-open {
    overflow: hidden;
    body.modal_question-open {
        height: unset;
    }
}
/////language buton
.farmer_asp_language {
    width: 100%;
    margin-top: 14px;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 32px;
    position: absolute;
    z-index: 1;
}
.farmer_asp_language_button {
    border: none;
    padding: 6px 16px;
    background-color: #dcf0dc;
    color: #57a05a;
    font-size: 16px;
    font-family: montserrat;
    font-weight: 500;
    border-radius: 16px;
    cursor: pointer;
}
@include breakpoint(tablet) {
    .farmer_asp_language_button {
        padding: 4px 14px;
        font-size: 14px;
    }
    .farmer_asp_language {
        margin-top: 10px;
        padding-right: 10px;
    }
}
@include breakpoint(phablet) {
    .farmer_asp_language_button {
        padding: 4px 12px;
        font-size: 12px;
    }
    .farmer_asp_language {
        margin-top: 6px;
        padding-right: 8px;
    }
}

@include breakpoint(mobile) {
    .farmer_asp_language_button {
        padding: 4px 8px;
        font-size: 1.5rem;
    }
    .farmer_asp_language {
        padding: 6px 8px 6px 0px;
        position: relative;
        z-index: 0;
    }
}

//hero section /////
.asp_hero {
    margin-top: 90px;
    @include breakpoint(tablet) {
        margin-top: 78px;
    }
    @media (max-width: 500px) {
        margin-top: 76px;
    }
}
.asp_hero_image {
    width: 100vw;
    img {
        width: 100%;
        height: 680px;
        opacity: 0.97;
    }
    @media (min-width: 800px) and (max-width: 1024px) {
        img {
            height: 480px;
        }
    }
    @media (max-width: 768px) {
        img {
            height: 380px;
        }
    }
    @media (max-width: 500px) {
        img {
            height: 220px;
        }
    }
    @media (max-width: 400px) {
        img {
            height: 220px;
        }
    }
}
.hhero_ask-btn {
    background-color: #337ab7;
    border-color: #337ab7;
    border: none;
    &:hover {
        color: rgb(255, 255, 255);
        background-color: #b51a27;
    }
}
.hhero_know-btn {
    background-color: #9fbb59;
    border-color: #9fbb59;
    &:hover {
        opacity: 0.9;
        color: rgb(255, 255, 255);
        background-color: #228b22;
        border-color: #228b22;
    }
}
.hero_div {
    position: absolute;
    margin-top: -100px;
    margin-left: 24px;
    padding: 38px 40px;
    h2 {
        text-align: left !important;
        font-size: 30px;
    }
    .button_area {
        display: flex;
        gap: 20px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        margin-top: -90px;
        margin-left: 24px;
        padding: 38px 40px;
    }
    @media (max-width: 768px) {
        margin-top: -66px;
        margin-left: 2px;
        padding: 24px 36px;
    }
    @media (max-width: 500px) {
        margin-top: -62px;
        margin-left: -12px;
        .button_area {
            gap: 20px;
        }
    }
    @media (max-width: 400px) {
        margin-top: -56px;
        margin-left: -12px;
        .button_area {
            gap: 20px;
        }
    }
}
///////Hero text
.hero_quote_section {
    padding: 0px 0px 0px 0px;
}
.hero_section_quote {
    background-color: white;
    padding: 60px 36px 48px 36px;
    opacity: 0.9;
    h1 {
        color: #000000c9;
        font-weight: 500;
        text-align: center;
        font-size: 26px;
        font-family: montserrat;
        font-style: normal;
        line-height: 1.3;
    }
}
.hero_section_quote2 {
    h1 {
        color: #000000c9;
        font-weight: 500;
        text-align: center;
        font-size: 26px;
        font-family: montserrat;
        font-style: normal;
        line-height: 1.3;
    }
}
@media (max-width: 768px) {
    .hero_section_quote {
        //@at-root height: 200px;
        padding: 30px 20px 40px 20px;
    }
    .hero_section_quote > h1 {
        font-size: 22px;
    }
    .hero_section_quote2 > h1 {
        font-size: 22px;
    }
    .hero_quote_section {
        padding: 18px 0px 0px 0px;
        top: -16px;
    }
}
@media (max-width: 500px) {
    .hero_section_quote {
        padding: 32px 18px 48px 18px;
    }
    .hero_section_quote > h1 {
        font-size: 24px;
    }
    .hero_section_quote2 > h1 {
        font-size: 24px;
    }
    .hero_quote_section {
        padding: 0px 0px 0px 0px;
        top: 1px;
    }
}
@media (max-width: 400px) {
    .hero_section_quote {
        padding: 24px 18px 48px 18px;
    }
    .hero_section_quote > h1 {
        font-size: 22px;
    }
    .hero_section_quote2 > h1 {
        font-size: 22px;
    }
    .hero_quote_section {
        padding: 0px 0px 0px 0px;
        top: 1px;
    }
}

@media (min-width: 800px) and (max-width: 1024px) {
    .hero_section_quote {
        padding: 40px 40px 48px 40px;
    }
    .hero_quote_section {
        padding: 1px 0px 0px 0px;
        top: 0px;
    }
}
///////Counter css ///////
.message-numbers {
    position: relative;
    height: 240px;
    background-color: white;

    .message-number-wrapper {
        max-width: 100vw;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        grid-gap: 180px;
        align-items: center;
        background-color: #f9f6f9;
        div {
            width: 200px;
            height: 200px;
            background-color: white;
            border-radius: 14%;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                margin: 25px 0 14px 0;
                width: 50px;
                height: 50px;
            }
            h3 {
                font-size: 18px;
                text-align: center;
                margin-bottom: 15px;
            }
            h1 {
                text-align: center;
                position: relative;
                font-size: 2.5rem;
                font-weight: normal !important;
            }
            .number::after {
                display: block;
                position: absolute;
                content: "";
                right: 15%;
                bottom: -8px;
                height: 5px;
                width: 50%;
                background-color: #aec673;
            }
            @include breakpoint(tablet) {
                width: 160px;
                height: 160px;
                img {
                    margin: 15px 0 8px 0;
                }
                h3 {
                    font-size: 15px;
                    margin-bottom: 5px;
                }
                h1 {
                    font-size: 2.5rem;
                }
            }
            @media (min-width: 447px) and (max-width: 600px) {
                width: 160px;
                height: 160px;
            }
        }
        @media (min-width: 447px) and (max-width: 600px) {
            grid-gap: 80px;
        }
    }

    @include breakpoint(mobile) {
        height: auto;
        .message-number-wrapper {
            flex-direction: column;
            justify-content: space-evenly;
            height: 440px;
            gap: 10px;
            div {
                height: 170px;
                width: 170px;
                align-items: center;
                img {
                    margin-top: 20px;
                }
                h3 {
                    margin-top: 0rem;
                }
            }
        }
    }
}
/**********FarmerinMap css *******/
.farmer_in_map-section {
    padding: 1px 0px 0px 0px;
}
.farmer_in_map-section-quote {
    background-color: #aec673;
    padding: 60px;
    h1 {
        color: white;
        font-weight: 500;
        text-align: center;
        font-size: 38px;
        font-family: serif;
        line-height: 1.3;
    }
}
.ask_question_quote {
    text-align: center;
    button {
        margin: 2rem auto 0;
        border: none;
        background-color: white;
        color: #aec673;
        padding: 10px 30px;
        font-size: 14px;
        border-radius: 4px;
    }
    &:hover {
        border-radius: 0;
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        color: #aec673;
    }
}
@media (max-width: 768px) {
    .farmer_in_map-section-quote {
        padding: 64px 70px;
    }
    .farmer_in_map-section-quote > h1 {
        font-size: 30px;
    }
    .farmer_in_map-section {
        padding: 18px 0px 0px 0px;
        top: -40px;
    }
}
@media (max-width: 500px) {
    .farmer_in_map-section-quote {
        padding: 40px 30px;
    }
    .farmer_in_map-section-quote > h1 {
        font-size: 26px;
    }
    .farmer_in_map-section {
        padding: 1px 0px 0px 0px;
        top: -24px;
    }
}
@media (max-width: 400px) {
    .farmer_in_map-section-quote {
        padding: 40px 30px;
    }
    .farmer_in_map-section-quote > h1 {
        font-size: 25px;
    }
    .farmer_in_map-section {
        padding: 0px;
        top: -22px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .farmer_in_map-section-quote {
        padding: 80px 60px;
    }
    .farmer_in_map-section {
        padding: 1px 0px 0px 0px;
        top: -30px;
    }
}
.farmer_in_map-farmer {
    padding: 40px 96px 70px 96px;
    display: flex;
    align-items: center;
    gap: 60px;

    @media (min-width: 900px) and (max-width: 1024px) {
        padding: 40px 96px 20px 96px;
    }
    @include breakpoint(tablet) {
        padding: 0px 55px 20px 36px;
    }

    @media (min-width: 447px) and (max-width: 767px) {
        flex-direction: column;
        padding: 4px 0 64px 0;
        gap: 20px;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        padding: 0 0 48px 0;
        gap: 0px;
    }
    &-info {
        text-align: left;
        width: 50%;
        padding-right: 64px;
        float: left;
        &-text {
            color: #000000c9;
            font-weight: 500;
            font-size: 30px;
            padding-bottom: 12px;
        }
        &--text {
            margin-top: 10px;
            font-size: 22px;
            color: #000000c9;
            font-weight: 400;
            line-height: 1.5;
        }
        @media (min-width: 800px) and (max-width: 1024px) {
            .farmer_in_map-farmer-info-text {
                font-size: 30px;
            }
        }
        @include breakpoint(tablet) {
            .farmer_in_map-farmer-info--text {
                font-size: 18px !important;
            }
            .farmer_in_map-farmer-info-text {
                font-size: 26px;
            }
            width: 60%;
            clear: both;
            text-align: center;
            font-size: 16px;
            padding-right: 30px;
            line-height: 26px;
        }
        @media (min-width: 447px) and (max-width: 767px) {
            .farmer_in_map-farmer-info--text {
                font-size: 18px !important;
            }
            .farmer_in_map-farmer-info-text {
                font-size: 26px;
            }
            width: 100%;
            clear: both;
            padding: 0px 14px 0px 14px;
            text-align: center;
            font-size: 16px;
            padding-right: 30px;
            line-height: 26px;
        }
        @include breakpoint(mobile) {
            .farmer_in_map-farmer-info--text {
                font-size: 18px;
            }
            .farmer_in_map-farmer-info-text {
                font-size: 26px;
            }
            width: 100%;
            padding: 14px;
            clear: both;
            text-align: center;
            font-size: 18px;
            top: -16px;
        }
    }

    &-img {
        height: 590px;
        width: 510px;
        background-position: center;
        background-size: cover;
        margin-right: -320px;
        @media (max-width: 1200px) {
            height: 414px;
            width: 420px;
            margin-right: -40px;
        }
        @media (max-width: 500px) {
            height: auto;
            width: 360px;
            margin-right: -10px;
        }
        @media (max-width: 375px) {
            height: 390px;
            width: 340px;
            margin-right: -16px;
        }
    }
}
/********Quiz promotion section**********/
.quizpromotion-section {
    padding: 30px 0px 20px 0px;
    top: 0px;
    background-color: white;

    h1 {
        color: #000000c9;
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        font-family: montserrat;
        font-style: normal;
        padding: 20px 60px;
    }
}
.quiz_button {
    color: white;
    font-size: 16px;
    border: none;
    background-color: #aec673;
    padding: 10px 14px;
    border-radius: 24px;
    &:hover {
        opacity: 0.8;
        color: rgb(255, 255, 255);
        background-color: #268f1c;
    }
}
.quiz_button_div {
    padding-top: 14px;
}
@media (min-width: 800px) and (max-width: 1024px) {
    .quizpromotion-section {
        padding: 40px 40px 60px 40px;
    }
}
@media (min-width: 447px) and (max-width: 1200px) {
    .asp_number_promotion-section > h1 {
        font-size: 30px;
        padding: 10px 10px 2px 10px;
    }
    .asp_number_promotion-section {
        top: -20px;
    }
    .asp_number_promotion_background-section {
        padding: 5px 0px 0px 0px;
        top: 0px;
    }
}
@media (min-width: 447px) and (max-width: 1200px) {
    .quizpromotion-section > h1 {
        font-size: 30px;
        padding: 10px 10px 2px 10px;
    }
    .asp_number_promotion-section {
        top: -20px;
    }
    .asp_number_promotion_background-section {
        padding: 5px 0px 0px 0px;
        top: 0px;
    }
    .quiz_button {
        color: white;
        font-size: 12px;
        padding: 6px 14px;
    }
}

@include breakpoint(mobile) {
    .quizpromotion-section {
        padding: 6px 0px 0px 0px;
        top: -18px;
    }
    .quizpromotion-section > h1 {
        font-size: 28px;
        padding: 10px;
    }
    .quiz_button {
        color: white;
        font-size: 12px;
        padding: 6px 14px;
    }
}

.quizpromotion_background {
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -4rem;
}

.quizpromotion-img {
    width: 50%;
    background-position: center;
    background-size: cover;
    margin-right: -320px;
    @include breakpoint(tablet) {
        width: 580px;
        margin-right: -40px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        width: 50%;
        margin-right: -40px;
    }
    @media (min-width: 447px) and (max-width: 767px) {
        width: 90vw;
        margin: 4rem 0 0 0;
        height: auto;
    }
    @media (max-width: 500px) {
        width: 94vw;
        margin: 2rem 0 0 0;
    }
}
/**********FarmerinMap css *******/
.quiz-section {
    padding: 1px 0px 0px 0px;
}
.quiz-section-quote {
    background-color: #aec673;
    padding: 60px;
    h1 {
        color: white;
        font-weight: 500;
        text-align: center;
        font-size: 38px;
        font-family: serif;
        line-height: 1.3;
    }
}
.ask_question_quote {
    text-align: center;
    button {
        margin: 2rem auto 0;
        border: none;
        background-color: white;
        color: #aec673;
        padding: 10px 30px;
        font-size: 14px;
        border-radius: 4px;
    }
    &:hover {
        border-radius: 0;
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        color: #aec673;
    }
}
@media (max-width: 768px) {
    .quiz-section-quote {
        padding: 64px 70px;
    }
    .quiz-section-quote > h1 {
        font-size: 30px;
    }
    .quiz-section {
        padding: 18px 0px 0px 0px;
        top: -40px;
    }
}
@media (max-width: 500px) {
    .quiz-section-quote {
        padding: 40px 30px;
    }
    .quiz-section-quote > h1 {
        font-size: 26px;
    }
    .quiz-section {
        padding: 1px 0px 0px 0px;
        top: -24px;
    }
}
@media (max-width: 400px) {
    .quiz-section-quote {
        padding: 40px 30px;
    }
    .quiz-section-quote > h1 {
        font-size: 25px;
    }
    .quiz-section {
        padding: 0px;
        top: -22px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .quiz-section-quote {
        padding: 80px 60px;
    }
    .quiz-section {
        padding: 1px 0px 0px 0px;
        top: -30px;
    }
}
.quiz-farmer {
    padding: 40px 96px 70px 96px;
    display: flex;
    align-items: center;
    gap: 60px;

    @media (min-width: 900px) and (max-width: 1024px) {
        padding: 40px 55px 20px 36px;
    }
    @include breakpoint(tablet) {
        padding: 40px 55px 20px 36px;
    }

    @media (min-width: 447px) and (max-width: 767px) {
        flex-direction: column;
        padding: 4px 0 64px 0;
        gap: 20px;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        padding: 0 0 48px 0;
        gap: 0px;
    }
    &-info {
        text-align: left;
        width: 50%;
        padding-right: 64px;
        float: left;
        &-text {
            color: #000000c9;
            font-weight: 500;
            font-size: 30px;
            padding-bottom: 12px;
        }
        &--text {
            margin-top: 10px;
            font-size: 22px;
            color: #000000c9;
            font-weight: 400;
            line-height: 1.5;
        }
        @media (min-width: 800px) and (max-width: 1024px) {
            .quiz-farmer-info-text {
                font-size: 30px;
            }
        }
        @include breakpoint(tablet) {
            .quiz-farmer-info--text {
                font-size: 18px !important;
            }
            .quiz-farmer-info-text {
                font-size: 26px;
            }
            width: 50%;
            clear: both;
            text-align: center;
            font-size: 16px;
            padding-right: 30px;
            line-height: 26px;
        }
        @media (min-width: 447px) and (max-width: 767px) {
            .quiz-farmer-info--text {
                font-size: 18px !important;
            }
            .quiz-farmer-info-text {
                font-size: 26px;
            }
            width: 100%;
            clear: both;
            padding: 0px 14px 0px 14px;
            text-align: center;
            font-size: 16px;
            padding-right: 30px;
            line-height: 26px;
        }
        @include breakpoint(mobile) {
            .quiz-farmer-info--text {
                font-size: 18px;
            }
            .quiz-farmer-info-text {
                font-size: 26px;
            }
            width: 100%;
            padding: 14px;
            clear: both;
            text-align: center;
            font-size: 18px;
            top: -16px;
        }
    }

    &-img {
        height: 590px;
        width: 510px;
        background-position: center;
        background-size: cover;
        margin-right: -320px;
        @media (max-width: 1200px) {
            height: 414px;
            width: 420px;
            margin-right: -40px;
        }
        @media (max-width: 500px) {
            height: auto;
            width: 360px;
            margin-right: -10px;
        }
        @media (max-width: 375px) {
            height: 390px;
            width: 340px;
            margin-right: -16px;
        }
    }
}
///////Available on Whatsapp//////
.available_on_whatsapp_background {
    border-radius: 4px;
    height: 90rem;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -6rem;
    @include trans(translateX(-50%));
    background-color: rgba(245, 240, 245, 0.6);

    @include breakpoint(tablet) {
        height: 92rem;
        margin-top: -2rem;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        height: 118rem;
    }

    @include breakpoint(mobile) {
        height: 130rem;
    }
    @media (max-width: 400px) {
        height: 142rem;
    }
}

.available_on_whatsapp-section {
    padding: 40px 0px 0px 0px;
    margin-top: 60px;
    h1 {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 44px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding: 60px;
    }
}
@media (min-width: 800px) and (max-width: 1024px) {
    .available_on_whatsapp-section {
        margin-top: -40px;
    }
}
@include breakpoint(tablet) {
    .available_on_whatsapp-section {
        padding: 20px 0px 0px 0px;
        margin-top: -80px;
        h1 {
            font-size: 32px;
        }
    }
}
@include breakpoint(mobile) {
    .available_on_whatsapp-section {
        padding: 1px 0px 0px 0px;
        top: 8px;
        h1 {
            font-size: 28px;
            padding: 60px 18px 10px 18px;
        }
    }
}
.available_on_whatsapp-img {
    height: 530px;
    width: 580px;
    background-position: center;
    background-size: cover;
    margin-right: -320px;
    @include breakpoint(tablet) {
        height: 280px;
        width: 310px;
        margin-right: -40px;
    }
    @media (min-width: 800px) and (max-width: 1024px) {
        height: 400px;
        width: 440px;
        margin-right: -40px;
    }
    @include breakpoint(mobile) {
        height: 360px;
        width: 92vw;
        margin: 2rem 0 0 0;
    }
}
.available_on_whatsapp-info {
    text-align: left;
    width: 50%;
    padding-right: 64px;
    float: left;
    top: -100px;
    &--text {
        margin-top: 16px;
        font-size: 26px;
        color: #888;
        font-weight: 400;
        line-height: 1.5;
    }

    @include breakpoint(tablet) {
        .available_on_whatsapp-info--text {
            font-size: 18px !important;
        }
        width: 60%;
        clear: both;
        text-align: center;
        font-size: 16px;
        padding-right: 30px;
        line-height: 26px;
        top: -60px;
    }
    @include breakpoint(mobile) {
        .available_on_whatsapp-info--text {
            font-size: 18px;
        }
        width: 100%;
        padding: 14px;
        clear: both;
        text-align: center;
        font-size: 18px;
        top: -24px;
    }
}
////////reg step //////
.step-join {
    padding: 4px 0px 75px 0px;
    top: 0px;
}
.step-join-us {
    background-color: #7f6127;
    //height: 540px;
    padding: 50px 20px 50px 20px;
    h1 {
        color: white;
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        font-family: montserrat;
        font-style: normal;
        p {
            text-align: center;
            font-weight: 300;
            font-family: montserrat;
            font-size: 20px;
            color: white;
            padding: 4px 12px 12px 4px;
        }
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .step-join {
        padding: 20px 0px 75px 0px;
        top: -30px;
    }
}
@media (max-width: 767px) {
    .step-join-us > h1 {
        font-size: 32px;
        p {
            font-size: 16px;
        }
    }
    .step-join {
        padding: 0px;
        top: 0px;
    }
}
@media (max-width: 500px) {
    .step-join {
        padding: 0px;
        top: -30px;
    }
    .step-join-us > h1 {
        font-size: 28px;
        p {
            font-size: 14px;
            padding: 10px 2px;
        }
    }
}
@media (max-width: 400px) {
    .step-join {
        padding: 0px;
        top: -40px;
    }
}
.card_container {
    display: flex;
    justify-content: space-around;
}
.container_card {
    background: white;
    width: 270px;
    margin: 10px;
    padding: 36px 24px 30px 24px;
    border-radius: 20px;
}

.container_card_top {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.container_card_icon {
    width: 54px;
    height: 54px;
}
.container_card_icon:hover {
    color: red;
}
.container_card_number {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 72px;
    line-height: 52px;
    align-items: center;
    text-align: center;
    color: #aec673;
    opacity: 0.8;
}
.container_card h2 {
    padding: 20px 0px 10px 0px;
    font-size: 23px;
    font-weight: 500;
    font-family: Montserrat;
    font-style: normal;
}
.container_card_text {
    font-size: 15px;
    line-height: 22px;
    color: #000000c9;
}

.container_card:hover {
    background-color: #ada4a47d;
    //color: white;
    cursor: pointer;
    transition: all 0.2s ease-out;
    border-radius: 6px;
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    color: #aec673;
    .container_card_text {
        opacity: 0.9;
        color: white;
    }
}
@media (max-width: 680px) {
    .card_container {
        flex-wrap: wrap;
    }
}
//////// ASk question modal //////
.farmer_question_modal-wrapper {
    background: #b1c155;
    max-width: 400px;
    margin: auto;
    transition: all 0.5s ease;
    z-index: 1;
    position: fixed;
    overflow: hidden;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-height: 80vh;
    overflow: auto;
    width: 95%;
    border-radius: 10px;
    line-height: 26px;
    font-size: 16px;
}
.farmer_question_modal__header {
    background: #b1c155;
    color: #e9e9e9;
    padding: 0.6rem 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
    h4 {
        font-size: 20px;
        color: #fff;
        font-weight: bold;
    }
    span {
        font-size: 2rem;
        cursor: pointer;
        color: #e9e9e9;
    }
}

.farmer_question_close-modal-btn {
    font-weight: 700;
    color: #102a43;
    line-height: 35px;
    font-size: 12px;
    text-transform: uppercase;
}

.farmer_question_modal--content {
    padding: 0rem 0rem 10px 0rem;
    background-color: #fff;
    width: 100%;
}
.farmer_question_modal-body {
    padding: 0 !important;
    text-align: left !important;
    h4 {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
    }
    span {
        font-weight: normal;
    }
    p {
        font-size: 10px;
        color: #333;
        padding: 1rem;
        margin: 1rem 0 2rem 0;
    }
}

.farmer_question_modal_back-drop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000e6;
    z-index: 9999;
}

/**********farmer question form css*************/

.farmer_question_modal-form {
    background-color: #ffffff;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
}

.farmer_question_modal-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        padding: 0.2em;
        button {
            background: #b72626ed;
            color: white;
            font-weight: 700;
            font-size: 13px;
            border-radius: 5px;
            margin-top: 8px;
            width: 136px;
            padding: 5px;
            border: none;
        }
    }
    label {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 500;
        @include breakpoint(tablet) {
            font-size: 12px;
        }
    }
}

.required-star {
    color: #fc4366;
    font-size: small;
}

.farmer_question_modal-form input,
.farmer_question_modal-form textarea {
    margin-top: 4px;
    width: 100%;
    padding: 9px 12px;
    border: 1px solid #e1e2eb;
    background-color: #fff;
    color: #102a43;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.3rem;
    box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
    border-radius: 3px;
    @include breakpoint(tablet) {
        font-size: 12px;
    }
}
/////// ASP number ////////
.asp_number_area {
    padding: 24px 96px 60px 96px;
    display: flex;
    align-items: center;
    @media (min-width: 900px) and (max-width: 1200px) {
        padding: 24px 96px 60px 96px;
        flex-direction: column;
    }

    @include breakpoint(tablet) {
        padding: 0px 55px 40px 36px;
        flex-direction: column;
    }

    @media (min-width: 447px) and (max-width: 767px) {
        flex-direction: column;
        padding: 0px 0 64px 0;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        padding: 0 0 20px 0;
    }
    @media (max-width: 400px) {
        padding: 0 0 24px 0;
    }
    &-info {
        text-align: left;
        width: 50%;
        padding-right: 64px;
        float: left;

        &--text {
            margin-top: 16px;
            font-size: 22px;
            color: #000000c9;
            font-weight: 400;
            line-height: 1.5;
        }
        // @include breakpoint(tablet) {
        //     .asp_number_area-info--text {
        //         font-size: 18px !important;
        //     }
        //     width: 60%;
        //     clear: both;
        //     text-align: center;
        //     font-size: 16px;
        //     padding-right: 30px;
        //     line-height: 26px;
        // }
        @media (min-width: 447px) and (max-width: 1200px) {
            .asp_number_area-info--text {
                font-size: 20px !important;
            }
            width: 90vw;
            clear: both;
            text-align: center;
            font-size: 16px;
            padding-right: 0px;
            line-height: 26px;
        }
        @include breakpoint(mobile) {
            .asp_number_area-info--text {
                font-size: 18px;
            }
            width: 100%;
            padding: 14px;
            clear: both;
            text-align: center;
            font-size: 18px;
            top: -24px;
        }
    }
}
.asp_number_promotion-section {
    padding: 10px 0px 0px 0px;
    background-color: #f9f6f9;

    h1 {
        color: #000000c9;
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        font-family: montserrat;
        font-style: normal;
        padding: 40px 40px 20px 40px;
        background-color: #f9f6f9;
    }
}
// @include breakpoint(tablet) {
//     .asp_number_promotion-section > h1 {
//         font-size: 32px;
//         padding: 30px;
//     }
//     .asp_number_promotion-section {
//         top: -24px;
//     }
//     .asp_number_promotion_background-section {
//         padding: 5px 0px 0px 0px;
//         top: 0px;
//     }
// }
@media (min-width: 447px) and (max-width: 1200px) {
    .asp_number_promotion-section > h1 {
        font-size: 30px;
        padding: 10px 10px 2px 10px;
    }
    .asp_number_promotion-section {
        top: -20px;
    }
    .asp_number_promotion_background-section {
        padding: 5px 0px 0px 0px;
        top: 0px;
    }
}
@include breakpoint(mobile) {
    .asp_number_promotion_background-section {
        padding: 60px 0px 0px 0px;
    }
    .asp_number_promotion-section {
        padding: 10px 0px 40px 0px;
    }

    .asp_number_promotion-section > h1 {
        font-size: 26px;
        padding: 16px;
    }
}

.asp_number_promotion_background {
    height: 100rem;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    @include trans(translateX(-50%));
    background-color: rgba(245, 240, 245, 0.6);

    @include breakpoint(tablet) {
        height: 148rem;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        height: 200rem;
    }

    @include breakpoint(mobile) {
        height: 134rem;
        margin-top: -5rem;
    }
}

.asp_number_promotion-img {
    height: 313px;
    width: 50%;
    background-position: center;
    background-size: cover;
    margin-right: -320px;
    //cursor: pointer;
    // @media (min-width: 800px) and (max-width: 1024px) {
    //     width: 570px;
    //     margin-right: -40px;
    // }
    // @include breakpoint(tablet) {
    //     width: 580px;
    //     margin-right: -40px;
    // }
    @media (min-width: 700px) and (max-width: 1200px) {
        width: 660px;
        margin: 4rem 0 0 0;
        height: auto;
    }
    @media (min-width: 447px) and (max-width: 700px) {
        width: 90vw;
        margin: 4rem 0 0 0;
        height: auto;
    }
    @media (max-width: 447px) {
        width: 94vw;
        margin: 1rem 0 0 0;
        height: auto;
    }
    @media (max-width: 375px) {
        height: 170px;
        width: 94vw;
        margin: 2rem 0 0 0;
    }
}
////// video modal

.asp_video_modal-wrapper {
    background: #b1c155;
    max-width: 600px;
    margin: auto;
    transition: all 0.5s ease;
    z-index: 1;
    position: fixed;
    overflow: hidden;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-height: 80vh;
    overflow: auto;
    width: 95%;
    border-radius: 10px;

    @include breakpoint(tablet) {
        max-width: 360px;
    }
    @media (min-width: 800px) and (max-width: 1024px) {
        max-width: 420px;
    }
    @media (max-width: 500px) {
        max-width: 95vw;
    }
}
.asp_video_modal_header {
    background: #b1c155;
    color: #e9e9e9;
    padding: 0.6rem 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
    h4 {
        font-size: 20px;
        color: #fff;
        font-weight: bold;
    }
    span {
        font-size: 2rem;
        cursor: pointer;
        color: #e9e9e9;
    }
}

.asp_video_modal_close-modal-btn {
    font-weight: 700;
    color: #102a43;
    line-height: 35px;
    font-size: 12px;
    text-transform: uppercase;
}

.asp_video_modal--content {
    padding: 0rem 0rem 10px 0rem;
    background-color: #fff;
    width: 100%;
}
.asp_video_modal-body {
    padding: 0 !important;
    text-align: left !important;
    h4 {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
    }
    span {
        font-weight: normal;
    }
    p {
        font-size: 10px;
        color: #333;
        padding: 1rem;
        margin: 1rem 0 2rem 0;
    }
}

.asp_video_modal_back-drop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000e6;
    z-index: 9999;
}
.asp_video {
    width: 600px;
    height: 380px;
}
@media (min-width: 900px) and (max-width: 1280px) {
    .asp_video {
        width: 420px;
        height: 300px;
    }
}
@include breakpoint(tablet) {
    .asp_video {
        width: 360px;
        height: 240px;
    }
}
@include breakpoint(mobile) {
    .asp_video {
        width: 95vw;
        height: 220px;
    }
}
.ask_question_form_background {
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -4rem;
}
.ask_question_form_section {
    padding: 28px 30px;
    margin-top: -80px;
    background-color: #f9f6f9;
    h1 {
        color: #363536;
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        font-family: montserrat;
        font-style: normal;
        padding: 22px 60px 10px 60px;
    }
    h4 {
        color: #363536;
        text-align: center;
        font-size: 20px;
        font-family: montserrat;
        font-style: normal;
        padding-bottom: 32px;
        font-weight: 400;
    }
}
@media (min-width: 900px) and (max-width: 1024px) {
    .ask_question_form_section {
        margin-top: -106px;
    }
}
@include breakpoint(tablet) {
    .ask_question_form_section {
        margin-top: -106px;
        h1 {
            font-size: 32px;
            padding: 42px 60px 14px 60px;
        }
        h4 {
            font-size: 20px;
        }
    }
}
@media (min-width: 300px) and (max-width: 765px) {
    .ask_question_form_section {
        margin-top: 0px;
    }
}
@include breakpoint(mobile) {
    .ask_question_form_section {
        padding: 1px 0px 0px 0px;
        top: -40px;
        h1 {
            font-size: 28px;
            padding: 32px 18px 10px 18px;
        }
        h4 {
            font-size: 16px;
        }
    }
}
.ask_question_form {
    background-color: #ffffff;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
}
.ask_question_form-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        padding: 0.4em;
        button {
            background: #aec673;
            color: white;
            font-weight: 700;
            font-size: 13px;
            border-radius: 5px;
            margin-top: 8px;
            padding: 6px 16px;
            border: none;
        }
    }
    label {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 500;
        @include breakpoint(tablet) {
            font-size: 12px;
        }
    }
}
.ask_question_form input,
.ask_question_form textarea {
    margin-top: 6px;
    width: 100%;
    padding: 9px 12px;
    border: 1px solid #e1e2eb;
    background-color: #fff;
    color: #102a43;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.3rem;
    box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
    border-radius: 3px;
    @include breakpoint(tablet) {
        font-size: 12px;
    }
}
.ask_question_form_detail {
    margin: auto;
    width: 640px;
    padding-bottom: 40px;
}
@include breakpoint(tablet) {
    .ask_question_form_detail {
        width: 50vw;
    }
}
@media (min-width:448px) and (max-width: 767px) {
    .ask_question_form_detail {
    width: 70vw;}
}
@include breakpoint(mobile) {
    .ask_question_form_detail {
        width: 90vw;
    }
}
//////// ASP video
.asp_video-section {
    padding: 10px 0px 14px 0px;
    top: 4px;
    h1 {
        color: #000000c9;
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        font-family: montserrat;
        font-style: normal;
        padding: 40px;
    }
}
.asp_video-section-video {
    width: 600px;
    height: 380px;
}
@media (min-width: 1025px) and (max-width: 1280px) {
    .asp_video-section {
        padding: 0px 0px 180px 0px;
        top: 150px;
    }
}
@media (min-width: 900px) and (max-width: 1024px) {
    .asp_video-section-video {
        width: 420px;
        height: 300px;
    }
    .asp_video-section {
        padding: 0px 0px 0px 0px;
        top: 40px;
    }
    .journey-farmer-info--text {
        font-size: 26px !important;
    }
}
@include breakpoint(tablet) {
    .asp_video-section-video {
        width: 360px;
        height: 240px;
    }
    .asp_video-section > h1 {
        font-size: 32px;
        padding: 30px;
    }
    .asp_video-section {
        padding: 5px 0px 0px 0px;
        top: 56px;
    }
}
@include breakpoint(mobile) {
    .asp_video-section-video {
        width: 95vw;
        height: 220px;
    }
    .asp_video-section {
        padding: 26px 0px 30px 0px;
        top: 48px;
    }
    .asp_video-section > h1 {
        font-size: 26px;
        padding: 16px;
    }
}

.asp_video_background {
    border-radius: 4px;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    @include breakpoint(mobile) {
        margin-top: 0rem;
    }
    @media (max-width: 400px) {
        margin-top: 0rem;
    }
}

///////join_others//////
.join_others_background {
    border-radius: 4px;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -6rem;
}

.join_others-section {
    padding: 14px 0px 0px 0px;
    top: 10px;
    background-color: #f9f6f9;

    h1 {
        color: #000000c9;
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        font-family: montserrat;
        font-style: normal;
        padding: 40px;
    }
}
@media (min-width: 800px) and (max-width: 1024px) {
    .join_others-section {
        margin-top: -24px;
        padding: 20px 0px 40px 0px;
    }
}
@media (min-width: 447px) and (max-width: 1200px) {
    .join_others-section > h1 {
        font-size: 30px;
        padding: 10px 10px 2px 10px;
    }
    .join_others-section {
        top: -20px;
    }
    
}
@include breakpoint(mobile) {
    .join_others-section {
        padding: 1px 0px 30px 0px;
        top: -20px;
        h1 {
            font-size: 26px;
            padding: 16px;
        }
    }
}
.join_others-img {
    width: 50%;
    background-position: center;
    background-size: cover;
    margin-right: -320px;
    @include breakpoint(tablet) {
        width: 580px;
        margin-right: -40px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        width: 50%;
        margin-right: -40px;
    }
    @media (min-width: 447px) and (max-width: 767px) {
        width: 90vw;
        margin: 4rem 0 0 0;
        height: auto;
    }
    @media (max-width: 500px) {
        width: 94vw;
        margin: 2rem 0 0 0;
    }
}
.join_others-info {
    text-align: left;
    width: 50%;
    padding-right: 64px;
    float: left;
    top: -100px;
    &--text {
        margin-top: 16px;
        font-size: 22px;
        color: #000000c9;
        font-weight: 400;
        line-height: 1.5;
    }

    @include breakpoint(tablet) {
        .join_others-info--text {
            font-size: 18px !important;
        }
        width: 60%;
        clear: both;
        text-align: center;
        font-size: 16px;
        padding-right: 30px;
        line-height: 26px;
        top: -60px;
    }
    @include breakpoint(mobile) {
        .join_others-info--text {
            font-size: 18px;
        }
        width: 100%;
        padding: 14px;
        clear: both;
        text-align: center;
        font-size: 18px;
        top: -24px;
    }
}
.join_others-farmer {
    padding: 24px 96px 96px 96px;
    display: flex;
    align-items: center;

    @media (min-width: 900px) and (max-width: 1280px) {
        padding: 24px 55px 60px 36px;
    }
    @include breakpoint(tablet) {
        padding: 24px 55px 42px 36px;
    }

    @media (min-width: 447px) and (max-width: 767px) {
        flex-direction: column;
        padding: 4px 0 40px 0;
        gap: 20px;
    }
    @include breakpoint(mobile) {
        flex-direction: column;
        padding: 0 0 40px 0;
    }
    &-info {
        text-align: left;
        width: 50%;
        padding-right: 64px;
        float: left;

        &--text {
            margin-top: 16px;
            font-size: 22px;
            color: #000000c9;
            font-weight: 400;
            line-height: 1.5;
        }

        @include breakpoint(tablet) {
            .join_others-farmer-info--text {
                font-size: 18px !important;
            }
            width: 60%;
            clear: both;
            text-align: center;
            font-size: 16px;
            padding-right: 30px;
            line-height: 26px;
        }
        @media (min-width: 447px) and (max-width: 767px) {
            width: 100%;
            clear: both;
            padding: 0px 14px 0px 14px;
            text-align: center;
            font-size: 16px;
            padding-right: 30px;
            line-height: 26px;
        }
        @include breakpoint(mobile) {
            .join_others-farmer-info--text {
                font-size: 18px;
            }
            width: 100%;
            padding: 14px;
            clear: both;
            text-align: center;
            font-size: 18px;
            top: -24px;
        }
    }
}
//////////ask question form_url_section
.asp_language_url_section {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 30px;
    position: absolute;
    z-index: 1;
}
.asp_language_url_button_section {
    display: flex;
    justify-content: space-between;
}

.asp_language_url_section_buttoun {
    border: none;
    padding: 6px 16px;
    background-color: #dcf0dc;
    color: #47974a;
    font-size: 16px;
    font-family: montserrat;
    font-weight: 500;
    border-radius: 16px;
    cursor: pointer;
}
.ask_question_form_url_section {
    padding: 1px 0px 100px 0px;
    margin-top: 100px;
    h1 {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 44px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding: 22px 60px 14px 60px;
    }
    h4 {
        color: black;
        text-align: center;
        font-size: 20px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding-bottom: 32px;
    }
}
@media (min-width: 800px) and (max-width: 1024px) {
    .ask_question_form_url_section {
        margin-top: 100px;
        padding: 1px 0px 60px 0px;
    }
}
@include breakpoint(tablet) {
    .ask_question_form_url_section {
        margin-top: 60px;
        padding: 1px 0px 60px 0px;
        h1 {
            font-size: 32px;
            padding: 42px 60px 14px 60px;
        }
        h4 {
            font-size: 20px;
            padding: 1px 60px 20px 60px;
        }
    }
    .asp_language_url_section {
        margin-top: 20px;
    }
}
@include breakpoint(mobile) {
    .ask_question_form_url_section {
        padding: 1px 0px 112px 0px;
        top: 30px;
        h1 {
            font-size: 28px;
            padding: 32px 18px 10px 18px;
        }
        h4 {
            font-size: 14px;
            padding: 1px 24px 20px 24px;
        }
    }
    .asp_language_url_section {
        margin-top: -8px;
    }
    .asp_language_url_section_buttoun {
        padding: 4px 12px;
        font-size: 14px;
    }
}
@media (max-width: 375px) {
    .asp_language_url_button_section {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}
.ask_question_form_url_section_background {
    border-radius: 4px;
    height: 120rem;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -4rem;
    @include trans(translateX(-50%));
    background-color: rgba(245, 240, 245, 0.6);

    @include breakpoint(tablet) {
        height: 160rem;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        height: 120rem;
    }

    @include breakpoint(mobile) {
        height: 138rem;
    }
}
.journey-farmer {
    padding: 0 96px 96px 96px;
    display: flex;
    align-items: center;

    @include breakpoint(tablet) {
        padding: 0 55px 96px 36px;
    }

    @include breakpoint(phablet) {
        flex-direction: column;
        padding: 64px 0 64px 0;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        padding: 0 0 64px 0;
    }
    &-info {
        text-align: left;
        width: 50%;
        padding-right: 64px;
        float: left;

        &--text {
            margin-top: 16px;
            font-size: 26px;
            color: #888;
            font-weight: 400;
            line-height: 1.5;
        }

        @include breakpoint(tablet) {
            .journey-farmer-info--text {
                font-size: 18px !important;
            }
            width: 60%;
            clear: both;
            text-align: center;
            font-size: 16px;
            padding-right: 30px;
            line-height: 26px;
        }
        @include breakpoint(mobile) {
            .journey-farmer-info--text {
                font-size: 18px;
            }
            width: 100%;
            padding: 14px;
            clear: both;
            text-align: center;
            font-size: 18px;
            top: -24px;
        }
    }
}
/////////////@
.not-found {
    font-size: 10rem;

    &-container {
        margin: 2rem auto 0 auto;
        height: 80vh;
    }

    &-2 {
        transform: rotate(20deg);
        color: crimson;
    }
}
.success {
    height: 100vh;
    // background-color: red;

    &-message {
        text-align: center;
        padding: 14rem 18rem 4rem 18rem;

        @include breakpoint(tablet) {
            padding: 12rem;
        }

        @include breakpoint(phablet) {
            padding: 8rem;
        }

        @include breakpoint(mobile) {
            padding: 5rem;
        }
    }
}
