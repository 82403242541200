.mobile-screen {
    min-height: 100vh;
    margin: 0 auto 0 auto;
    width: 70rem;
    color: $text-primary;
    // background-color: red;
    // padding: 3rem;

    @include breakpoint(largeDesktop) {
        // padding: 3rem;
    }

    @include breakpoint(desktop) {
        // padding: 3rem;
    }

    @include breakpoint(phablet) {
        min-height: 100vh;
        width: 100vw;
        margin-top: 4rem;
    }

    @include breakpoint(mobile) {
        min-height: 100vh;
        width: 100vw;
        margin-top: 4rem;
    }

    &__heading {
        display: inline-block;
        margin: 3rem;
        font-weight: bold;

        @include breakpoint(mobile) {
            margin: 0 3rem 4rem 3rem;
        }
    }

    &__container {
        flex: 1;
        background-color: rgba($positive-green, 0.2);
        border-top-left-radius: 4rem;
        border-top-right-radius: 4rem;
        height: 100%;
        padding: 3rem;
        flex: 1;
        // box-shadow: 0px 0px 2px 4px rgba($positive-green, 0.2);

        // @include breakpoint(mobile){
        //     padding: 5rem;
        // }
    }
    &__info {
        flex: 1;
        height: 100%;
        padding: 3rem;
        flex: 1;
        border: "1px #ccc solid"
        // box-shadow: 0px 0px 2px 4px rgba($positive-green, 0.2);

        // @include breakpoint(mobile){
        //     padding: 5rem;
        // }
    }
}

.mobile-form {
    display: flex;
    flex-direction: column;
}

.candidate {
    margin-left: 30px;
    &__name {
        font-size: 2rem;
        font-weight: bold;

        @include breakpoint(tablet) {
            font-size: 3rem;
        }

        @include breakpoint(mobile) {
            font-size: 2.5rem;
        }
    }

    &__profile {
        font-size: 1.5rem;

        @include breakpoint(tablet) {
            font-size: 2.1rem;
        }

        @include breakpoint(mobile) {
            font-size: 1.9rem;
        }
    }

    &__mobile {
        font-size: 1.5rem;

        @include breakpoint(tablet) {
            font-size: 2.1rem;
        }

        @include breakpoint(mobile) {
            font-size: 1.9rem;
        }
    }
}

.document__list {
    margin-top: 1rem;

    &_item {
        font-size: 1.6rem;
        margin: 2rem 0 0 0;
        position: relative;
        background-color: $white;
        padding: 1rem 1.6rem;
        border-radius: 4px;

        @include breakpoint(tablet) {
            font-size: 2.4rem;
        }

        @include breakpoint(mobile) {
            font-size: 2rem;
        }

        & > span {
            font-weight: bold;
        }

        &--delete {
            position: absolute;
            top: -5px;
            right: -5px;
            color: crimson;
            background-color: mistyrose;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            text-align: center;
            box-shadow: 0px 0px 10px 2px rgba($black, 0.1);
            cursor: pointer;
        }
    }
}

.link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include breakpoint(tablet) {
        font-size: 4.4rem;
    }

    @include breakpoint(mobile) {
        padding: 4rem;

        h2 {
            font-size: 4rem;
        }
    }

    &-broken {
        width: 30rem;
        height: 30rem;
        margin: -15rem auto 0 auto;

        @include breakpoint(mobile) {
            margin: -25rem auto 0 auto;
        }
    }
}

.stepper-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 10px;
    margin-bottom: 5px;
    .back-btn {
        width: 100%;
        background-color: $primary-brown;
        padding: 1rem;
        outline: none;
        border: none;
        color: $white;
        font-size: 1.6rem;
        margin-top: 2rem;
        transition: 150ms;
        box-shadow: 0px 0px 4px 2px rgba($black, 0.1);
    }
    .next-btn {
        width: 100%;
        background-color: $positive-green;
        padding: 1rem;
        outline: none;
        border: none;
        color: $white;
        font-size: 1.6rem;
        margin-top: 2rem;
        transition: 150ms;
        box-shadow: 0px 0px 4px 2px rgba($black, 0.1);
    }
    .submit-btn {
        width: 100%;
        padding: 1rem;
        outline: none;
        border: none;
        color: $white;
        font-size: 1.6rem;
        margin-top: 2rem;
        transition: 150ms;
        box-shadow: 0px 0px 4px 2px rgba($black, 0.1);
        background-color: $positive-green;
    }
}

.docs-submit-btn {
    width: 100%;
    background-color: $positive-green;
    padding: 1rem;
    outline: none;
    border: none;
    color: $white;
    font-size: 1.6rem;
    margin-top: 2rem;
    transition: 150ms;
    box-shadow: 0px 0px 4px 2px rgba($black, 0.1);

    &:hover {
        box-shadow: 0px 0px 4px 2px rgba($black, 0.25);
    }

    &:active {
        transform: translateY(1px);
        box-shadow: 0px 0px 4px 2px rgba($black, 0.1);
    }

    @include breakpoint(tablet) {
        font-size: 3.2rem;
    }

    @include breakpoint(mobile) {
        padding: 2.2rem;
        font-size: 2.8rem;
    }
}

.success-modal {
    position: fixed;
    width: 20%;
    min-width: 15rem;
    height: 24%;
    left: 50%;
    min-height: 15rem;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 4px rgba($black, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 10000;

    @include breakpoint(tablet) {
        height: 45rem;
        width: 70rem;
    }

    @include breakpoint(mobile) {
        top: 40%;
        height: 25rem;
        width: 40rem;
    }

    &_wrapper {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vh;
        background-color: rgba($black, 0.6);
        z-index: 100000;
    }

    & > p {
        font-size: 1.6rem;
        margin: 1rem 0 0 0;
    }
}

.done-btn {
    outline: none;
    background-color: $positive-green;
    padding: 0.5rem 2rem;
    width: 10rem;
    color: white;
    font-size: 1.6rem;
    border: none;
    margin-top: 2rem;

    @include breakpoint(tablet) {
        padding: 1rem 3rem;
        width: 14rem;
        font-size: 2.8rem;
        margin-top: 3rem;
    }

    @include breakpoint(mobile) {
        padding: 1rem 3rem;
        width: 14rem;
        font-size: 2.4rem;
        margin-top: 3rem;
    }
}

.confirm {
    &__data {
        -ms-overflow-style: none;
        font-size: 1.8rem;
        overflow: scroll;
        max-height: 90vh;
        padding-bottom: 1rem;

        // to disable selection
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;

        ol {
            counter-reset: item;
        }

        ol > li {
            counter-increment: item;
        }

        ol li p,
        ul li p {
            display: inline;
        }

        ol li p > strong,
        ul li p > strong {
            display: block;
        }

        ol ol > li {
            display: block;
        }

        ol ol > li:before {
            content: counters(item, ".") ". ";
            margin-left: -20px;
        }

        @include breakpoint(tablet) {
            font-size: 2rem;
        }

        @include breakpoint(mobile) {
            font-size: 2rem;
        }

        & ul {
            list-style: inside;
        }

        &--highlight {
            font-size: 1.6rem;
            font-weight: bold;

            @include breakpoint(tablet) {
                font-size: 2.4rem;
            }

            @include breakpoint(mobile) {
                font-size: 2.2rem;
            }
        }

        &--support {
            font-size: 1.2rem;
            font-style: italic;
            font-weight: bold;

            @include breakpoint(tablet) {
                font-size: 2rem;
            }

            @include breakpoint(mobile) {
                font-size: 1.6rem;
            }
        }

        & * {
            margin: 1rem 0;

            @include breakpoint(tablet) {
                margin: 1.2rem 0;
            }

            @include breakpoint(mobile) {
                margin: 1.6rem 0;
            }
        }
    }

    &__data::-webkit-scrollbar {
        display: none;
    }

    &__btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $positive-green;
        padding: 1rem;
        outline: none;
        border: none;
        color: $white;
        font-size: 1.6rem;
        // margin: 2rem;
        transition: 150ms;

        @include breakpoint(tablet) {
            font-size: 3.2rem;
        }

        @include breakpoint(mobile) {
            padding: 2.2rem;
            font-size: 2.8rem;
        }
    }
}

.details {
    .error--label {
        font-size: 1.5rem;
        color: crimson;
        font-style: italic;
        margin: 0.2rem 0 0 0;
        opacity: 0;
    }

    &__control {
        &--label {
            font-size: 1.6rem;
            margin: 1rem 0 0 0;

            @include breakpoint(tablet) {
                font-size: 2.4rem;
            }

            @include breakpoint(mobile) {
                font-size: 2rem;
                margin: 2rem 0 0 0;
            }
        }

        &--input {
            margin: 0.5rem 0 0 0;
            font-size: 1.6rem;
            padding: 1rem 1rem;
            border: none;
            outline: none;
            border-radius: 4px;
            width: 100%;
            border: 1px solid transparent;
            background-color: $white;

            &:placeholder-shown > label {
                opacity: 0;
            }

            &::placeholder {
                color: #aaa;
            }

            &:focus > label {
                opacity: 0;
            }

            @include breakpoint(tablet) {
                font-size: 3.2rem;
            }

            @include breakpoint(mobile) {
                font-size: 2.6rem;
            }
        }

        &--phone {
            display: flex;
            justify-content: space-between;
        }
    }

    &__radio {
        &--wrapper {
            display: flex;
            margin: 1rem 0 0 0;
        }

        &--btn {
            display: flex;
            font-size: 1.6rem;
            margin: 0 1rem;

            & > label {
                margin-left: 1.5rem;
            }
        }
    }
}

.details__container {
    display: block;
    position: relative;
    padding-left: 3rem;
    margin: 0 2rem 1rem 0;
    cursor: pointer;
    font-size: 1.6rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include breakpoint(tablet) {
        font-size: 2.6rem;
    }

    @include breakpoint(mobile) {
        margin: 0 3rem 1rem 0;
        font-size: 2.2rem;
    }
}

/* Hide the browser's default radio button */
.details__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.details__container--checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: #eee;
    border-radius: 50%;

    @include breakpoint(tablet) {
        height: 3.2rem;
        width: 3.2rem;
    }

    @include breakpoint(mobile) {
        height: 2.8rem;
        width: 2.8rem;
    }
}

/* On mouse-over, add a grey background color */
.details__container:hover input ~ .details__container--checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.details__container input:checked ~ .details__container--checkmark {
    background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.details__container--checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.details__container input:checked ~ .details__container--checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.details__container .details__container--checkmark:after {
    top: 0.5rem;
    left: 0.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: white;

    @include breakpoint(mobile) {
        top: 0.8rem;
        left: 0.8rem;
        width: 1.2rem;
        height: 1.2rem;
    }
}

.add-nominee-btn {
    background-color: transparent;
    padding: 0.5rem 0 0 0;
    outline: none;
    border: none;
    color: #0275db;
    font-size: 1.8rem;
    margin: 1rem;
    transition: 150ms;
    font-weight: bold;
    display: inline-block;
    border-bottom: 2px solid transparent;

    &:hover {
        border-bottom: 2px solid #0275db;
    }

    @include breakpoint(tablet) {
        font-size: 3.2rem;
    }

    @include breakpoint(mobile) {
        padding: 1.5rem;
        font-size: 2.4rem;
    }

    &--blue {
        color: #0275db;

        &:hover {
            border-bottom: 2px solid #0275db;
        }
    }

    &--orange {
        color: orangered;

        &:hover {
            border-bottom: 2px solid orangered;
        }
    }
}

.add-location-btn {
    background-color: rgba($positive-green, 0.1);
    padding: 0.5rem 2rem;
    outline: none;
    border: 1px solid $positive-green;
    border-radius: 4px;
    color: $positive-green;
    font-size: 1.6rem;
    transition: 150ms;
    font-weight: bold;
    display: inline-block;
    margin: 0 0 0.5rem 0;

    &:hover {
        box-shadow: 0 1px 2px 0px rgba($positive-green, 0.3);
        box-shadow: 0 1px 3px 1px rgba($positive-green, 0.15);
    }

    &:active {
        box-shadow: 0 1px 2px 0px rgba($positive-green, 0.3);
        box-shadow: 0 1px 3px 1px rgba($positive-green, 0.15);
    }

    @include breakpoint(tablet) {
        padding: 2rem;
        font-size: 2.8rem;
    }

    @include breakpoint(mobile) {
        padding: 1.5rem;
        font-size: 2.4rem;
    }
}

.nominee {
    background-color: rgba(orangered, 0.7);
    padding: 2rem;
    border-radius: 5px;
    font-size: 1.8rem;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin: 1.5rem 0;
    color: white;

    &__remove {
        position: absolute;
        top: -5px;
        right: -5px;
        color: crimson;
        background-color: white;
        border-radius: 50%;
        width: 2.2rem;
        height: 2.2rem;
        font-size: 1.8rem;
        text-align: center;
        box-shadow: 0px 0px 10px 2px rgba($black, 0.1);
        cursor: pointer;

        @include breakpoint(mobile) {
            width: 2.8rem;
            height: 2.8rem;
        }
    }

    &__input {
        // border: 1px solid #ccc;
        background-color: #efefef;
        padding: 0 2rem 2rem 2rem;
        margin: 1rem 0 1rem 0;
        border-radius: 5px;
    }
}

.candidate_signature {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    // opacity: 0;
    width: 70rem;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302);
    box-shadow: 0 1px 3px 1px rgba(60, 64, 67, 0.149);
    // animation: animate-in 400ms ease-in forwards;

    &--clear {
        position: absolute;
        top: 1rem;
        left: 1rem;
        font-size: 1.6rem;
        border: none;
        outline: none;
        border: 1px solid #777;
        border-radius: 10px;
        transition: 150ms;

        &:hover {
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302);
        }
    }

    &--cancel {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.6rem;
        border: none;
        outline: none;
        border: 1px solid #777;
        border-radius: 10px;
        transition: 150ms;

        &:hover {
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302);
        }
    }

    & > div {
        background-color: #efefef;
        font-size: 2rem;
        text-align: center;
        padding: 1rem;
    }

    @include breakpoint(tablet) {
        width: 100vw;
    }

    @include breakpoint(mobile) {
        width: 100vw;
    }

    &--animate {
        animation: animate-in 500ms ease-in-out forwards;
    }
}

@keyframes animate-in {
    0% {
        transform: translate(-50%, 100%);
        // opacity: 0;
    }
    80% {
        transform: translate(-50%, 0);
    }
    100% {
        transform: translate(-50%, 0);
        // opacity: 1;
    }
}

.location__success--label {
    font-size: 1.8rem;
    margin: 0.5rem 0;
    color: $positive-green;

    & > i {
        margin-right: 1rem;
    }
}

.aadhaar {
    &__image {
        display: none !important;

        &-label {
            color: #aaa;
            font-weight: 100;
            cursor: pointer;
        }
    }

    &__label {
        cursor: pointer;
        font-weight: normal;
    }

    &__files {
        display: flex;
        justify-content: space-between;

        @include breakpoint(tablet) {
            flex-direction: column;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
        }

        &--file {
            width: 48%;

            @include breakpoint(mobile) {
                width: 100%;
            }
        }
    }

    &__filename {
        word-break: break-word;
    }
}

.modal-container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    z-index: 100;
    padding: 0 10rem;
    overflow-y: auto;

    @include breakpoint(tablet) {
        padding: 0 3rem;
        overflow: scroll;
        padding: 6rem 3rem 4rem 3rem;
    }

    @include breakpoint(mobile) {
        padding: 0 3rem;
        overflow: scroll;
        padding: 8rem 3rem 4rem 3rem;
    }
}

.uploaded {
    &__docs {
        // margin-top: 1rem;
    }

    &__doc {
        padding: 1rem;
        // background-color: rgba(white, 1);
        border-radius: 4px;
        // border: 1px solid rgba($positive-green, 1);
        font-size: 1.6rem;
        margin: 1rem 0;
        color: $positive-green;
        display: flex;
        // justify-content: center;

        @include breakpoint(tablet) {
            font-size: 2.6rem;
        }

        @include breakpoint(mobile) {
            font-size: 2.2rem;
        }

        & > i {
            font-size: 2.4rem;
            margin-right: 1rem;

            @include breakpoint(tablet) {
                font-size: 3.4rem;
            }

            @include breakpoint(mobile) {
                font-size: 3.2rem;
            }
        }
    }
}
