.audio-el{
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 50px 1fr 40px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    button{
        border: none;
        background-color: transparent;
    }
    .fa{
        font-size: 25px;
    }
    
    &-progress{
        position: relative;
        height: 8px;
        &-track, &-slider{
            height: 8px;
            position: absolute;
            z-index: 1;
            border: 1px solid #ccc;
            width: 100%;
            top: 0;
            left: 0;
            cursor: pointer;
            border-radius: 0 5px 5px 0;
        }   
        &-slider{
            transition: width .1s linear;
            z-index: 2;
            background-color: #9fbc59;
        }
        &-timing{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            width: 100%;
            top: 15px;
            font-size: 14px;
        }
    }
    &-volume{
        position: relative;
        &-slider{
           position: absolute;
            display: none;
            background-color: white;
            width: 100px;
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 5px;
            bottom: 220%;
            left: 50%;
            padding: 2px 5px;
            cursor: pointer;
            transform: translateX(-50%) rotate(270deg);
            &:hover{
                display: block;
            }

            &::after{
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                background-color: white;
                z-index: -14;
                border: 1px solid #ccc;
                top: 8px;
                left: -8px;
                transform: rotate(45deg);
                border-top: none;
                border-right: none;
            }
        }
    }
}
.audio-el-volume-btn:hover +  .audio-el-volume-slider{
    display: block;
}


