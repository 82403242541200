.kn-web-container {
    padding: 80px 20px 20px 20px;
    font-size: 1.6rem;
}
.enam-page {
    padding-top: 120px;
    max-width: 400px;
    margin: auto;
    font-family: PT Sans Caption;
}
.enam-logos {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 250px;
    margin: auto;
    img {
        max-width: 70px;
        max-height: 70px;
    }
}
.enam-bid-list-container {
    padding: 30px 0;
}
.enam-bid-card {
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 15px 15px 0 15px;
    &-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        padding-bottom: 8px;
        margin-bottom: 12px;
    }
    &-body {
        padding: 15px 0;
        &-price {
            display: flex;
            justify-content: space-between;
            padding: 15px 0;
        }
        p {
            line-height: 35px;
        }
    }
    &-footer {
        &-images {
            display: flex;
            img {
                height: 80px;
                object-fit: cover;
                width: 80px;
                border-radius: 5px;
                margin-right: 15px;
            }
        }
        a {
            background-color: #9fbc59;
            display: block;
            color: white;
            padding: 10px;
            text-align: center;
            border-radius: 4px;
            margin-top: 30px;
            margin-bottom: 15px;
        }
    }
}

.enam-seller-section {
    font-size: 16px;
    margin: 20px 0 15px 0;
    display: flex;
    // align-items: center;
    &-avatar {
        height: 70px;
        width: 70px;
        margin-right: 25px;
        background-color: #f6f6f6;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
    }
    p {
        line-height: 30px;
    }
}

.enam-bid-success {
    background-color: #f6f6f6;
    padding: 25px;
    border-radius: 5px;
    text-align: center;
}
.bid-create-group {
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 20px 15px;
}

.enam-bid-status {
    padding: 5px 18px;
    border-radius: 15px;
    display: inline-block;
    background-color: lightgoldenrodyellow;
    color: darkgoldenrod;
    font-size: 14px;
    &.accepted {
        background-color: rgba(0, 255, 0, 0.2);
        color: darkgreen;
    }
    &.rejected {
        background-color: rgba(255, 0, 0, 0.2);
        color: darkred;
    }
}
