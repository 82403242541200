@import "../colors";
.candidate-page {
    margin-top: 0;
    font-size: 1.6rem;
    padding: 20px 10px 10px 10px;
    label {
        font-size: 12px;
        margin-bottom: 5px;
    }
    p {
        font-size: 14px;
    }
    .form-control {
        border-color: #ccc;
        height: 40px;
        box-shadow: none;
        color: #333;
    }
}
.candidate-heading {
    color: forestgreen;
    font-family: Aileron;
    margin: 10px 0;
}
.candidate-section {
    padding: 10px;
    margin: 15px 0;
    border: 1px solid #ccc;
}

.cn-page-header {
    text-align: center;
    font-weight: 500;
}

.candidate-btn {
    margin: 15px 0;
    width: 100%;
    padding: 10px 10px;
    font-size: 1.6rem;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #333;
    &.secondary {
        background-color: #836100;
        color: white;
    }
    &.primary {
        background: $primary-green;
        border: 1px solid $primary-green;
        color: $light;
    }
    &.sm {
        margin: 5px 0;
        padding: 5px;
        font-size: 1.6rem;
    }
}
.candidate-doc {
    padding: 10px;
    border-radius: 5px;
    margin: 15px 0;
    border: 1px dashed #ccc;
    &.already-uploaded {
        border: 1px solid #ccc;
    }
    p {
        margin-bottom: 5px;
    }
    label {
        background-color: #f9f9f9;
        padding: 20px;
        margin: 10px 0;
        //border: 1px dashed #ccc;
        border-radius: 5px;
        display: block;
        font-weight: normal;
    }
}
