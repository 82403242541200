body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
/*
*{
    border:1pxc
}
*/
.unlock-page {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    top: 0px;
    background: #0003;
}

@media only screen and (max-width: 600px) {
    .passcode-block {
        width: 80% !important;
    }
}

.passcode-block {
    width: 25%;
    background: #f9f9f9;
    padding: 20px;
    box-shadow: 0px 1px 2px 0px #ccc;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.blur {
    filter: blur(9px);
}

.side-logo {
    width: 20%;
    left: 10px;
    position: absolute;
    top: 20px;
}
.react-tabs{
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    bottom: -1px;
    position: relative;
    cursor: pointer;
    border-width: 1px 1px 1px;
    border-style: solid solid none;
    border-color: transparent transparent transparent;
    border-image: initial;
    border-bottom: none;
    list-style: none;
    padding: 6px 12px;
}

.react-tabs .tab-list {
    border-bottom: 1px solid rgb(170, 170, 170);
    margin: 0px 0px 10px;
    padding: 0px;
}

.react-tabs .active-tab {
    color: white;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background: rgb(159, 188, 89);
}

