.kopia-farmer-listing {
    min-height: calc(100vh);
    padding: 11rem 2rem 3rem 0;
    font-size: 1.6rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: 0.26fr 0.8fr;
    font-family: "PT Sans", sans-serif;
    background-color: #f1f3f6;

    @media (max-width: 767px) {
        padding: 13rem 2rem 2rem 2rem;
        display: block;
    }
    @media (min-width: 767px) and (max-width: 1024px) {
        padding: 13rem 2rem 2rem 0;
    }
}
.kopia-locations-filter {
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
    overflow-x: auto;
    display: flex;
    gap: 4rem;
    button {
        background-color: white;
        border-radius: 40px;
        padding: 1rem 1.5rem;
        border: none;
        i {
            margin-right: 1rem;
        }
    }
    button.selected-infra {
        font-weight: bold;
        background-color: #9fbc59;
        color: white;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    }
}

.kopia-sidebar-filters {
    position: sticky;
    margin-left: 16px;
    top: 0;
    height: 100%;
    &-heading {
        padding: 2rem;
        background-color: white;
        border-radius: 10px;
    }
}
.kopia-sidebar-filter {
    margin: 1rem 0;
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
    p {
        margin-bottom: 1rem;
        opacity: 0.9;
    }
    label {
        font-weight: normal;
        display: block;
    }
    input {
        margin-right: 1rem;
        background-color: #f5f5f5;
        opacity: 0.8;
        border-radius: 4px;
        padding: 4px 4px 4px 8px;
        border: 1px solid #c4c4c4;
    }
    button {
        border: none;
        padding: 0.4rem 1.4rem;
        border-radius: 2px;
        background-color: #9fbc59;
        color: white;
        margin-top: 10px;
        font-size: 1.4rem;
        &:hover {
            opacity: 0.8;
            color: rgb(255, 255, 255);
            background-color: #228b22;
        }
    }
}
.kopia-farmer-listing-container {
    display: grid;
    padding: 2rem 0;
    color: black;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
    @media (max-width: 1024px) {
        padding: 2rem 2rem 2rem 0;
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 767px) {
        padding: 2rem 0;
        grid-template-columns: 1fr;
    }
}
.kopia-farmer-card {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: white;
    .heading-tertiary {
        margin: 2rem 0;
    }
    h3 {
        margin-bottom: 1rem;
        font-family: "Montserrat", sans-serif;
    }
    h2 {
        margin-bottom: 0;
    }
}

.kopia-crop-card-image,
.kopia-farmer-card-image {
    height: 200px;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 1.5rem 1%;
    cursor: pointer;
    width: 48%;
    display: inline-block;
}

.kopia-farmer-card-footer {
    display: flex;
    justify-content: space-between;
    button {
        border: none;
        padding: 0.8rem 2rem;
        border-radius: 4px;
    }
    .kopia-farmer-cta {
        background-color: #9fbc59;
        color: white;
        border-radius: 4px;
        &:hover {
            opacity: 0.8;
            color: rgb(255, 255, 255);
            background-color: #228b22;
        }
    }
}
.kn-ops-theme-btn {
    color: white;
    background-color: #9fbc59;
    border: none;
    padding: 1rem;
    border-radius: 4px;
}
.kopia-farmer-filter-toggler {
    display: none;
    padding: 1rem 2rem;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 4px;
    @media (max-width: 500px) {
        display: inline-block;
    }
}

.kopia-farmer-sidebar {
    @media (max-width: 500px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 200px;
        height: 100vh;
        background-color: white;
        z-index: 4;
        transition: transform 0.3s linear;
        transform: translateX(-100%);
        &.sidebar-open {
            transform: translateX(0);
        }
    }
}
.kopia-sidebar-filters-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 500px) {
        &.overlay-open {
            display: block;
            z-index: 3;
        }
    }
}
.kopia-farmer-listing-header {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
}
.kopia-sidebar-filters-heading {
    display: flex;
    justify-content: space-between;
    button {
        background-color: #f6f6f6;
        border: 1px solid #ccc;
        display: none;
        @media (max-width: 500px) {
            display: inline-block;
        }
    }
}
.kopia-farmer-card-logo {
    height: 200px;
    position: relative;
    margin: 1.5rem auto;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100px;
        width: 100px;
    }
}
