.weather-page {
    min-height: 740px;
    max-width: 100%;
    display: grid;
    height: auto;
    box-sizing: border-box;
}
.app-download-btn {
    outline: none;
    border: none;
    background-color: rgba(56, 142, 60, 1);
    font-size: 1.6rem;
    border-radius: 4px;
    font-weight: bold;
    color: white;
}
.weather {
    background-image: linear-gradient(to bottom, #ffffff 5%, rgba(139, 183, 159, 100), rgba(56, 142, 60, 1));
    padding: 15px 0 15px 0;
    color: white;
    overflow: hidden;
    header {
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        img {
            width: 70%;
        }
        button {
            padding: 5px;
            font-size: 1.6rem;
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0.5s 2 both;
        }
        @media (min-width: 767px) {
            display: block;
            text-align: center;
            img {
                width: 70%;
                margin: auto;
            }
            button {
                display: none;
            }
        }
    }
    .farmer-location {
        margin: 15px 0;
        display: flex;
        color: #333;
        justify-content: center;
        align-items: center;
        img {
            width: 20px;
            height: 20px;
        }
    }
    .weather-day {
        color: #333;
        font-size: 1.6rem;
    }
    .weather-data {
        margin-top: 30px;
        img {
            height: 90px;
            width: 90px;
            display: inline-block;
        }
        .temprature-value {
            h2 {
                font-size: 5rem;
            }
            span {
                font-size: 1.6rem;
            }
        }
        div.rain-info {
            max-width: 250px;
            margin: auto;
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 80px;
                width: 80px;
            }
            span {
                font-size: 1.6rem;
                font-weight: bold;
            }
        }
    }
    .weather-description {
        margin: 10px 0 15px 0;
        .short {
            margin-bottom: 1rem;
        }
        .long {
            font-size: 1.6rem;
            max-width: 90%;
            margin: auto;
        }
    }
    .statics-wrapper {
        display: flex;
        max-width: 60%;
        margin: auto;
        height: 100px;
        justify-content: space-between;
        img {
            height: 50px;
            width: 50px;
        }
        div {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .card-wrapper {
        padding: 0 15px;
        .card {
            display: grid;
            color: black;
            align-items: center;
            grid-template: 70px / 0.6fr 0.2fr 0.2fr;
            box-shadow: 0 10px 5px rgba(0, 0, 0, 0.3);
            background-color: white;
            border-radius: 5px;
            padding: 0 10px;
            box-sizing: border-box;
            margin-bottom: 15px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            img {
                height: 40px;
                width: 40px;
            }
            &:hover {
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
            }
        }
        div.text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 1.6rem;
            padding-left: 25px;
        }
        .temprature,
        .rain {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            font-size: 1.6rem;
        }
        .rain {
            img {
                height: 70px;
                width: 70px;
                margin-top: -10px;
            }
            p {
                margin-top: -15px;
            }
        }
        .card.active {
            box-shadow: none;
            background-image: linear-gradient(to bottom, #ffffff 5%, rgba(139, 183, 159, 0.5), rgba(159, 188, 89, 0.5));
            .text {
                font-weight: bold;
            }
        }
    }
    @media (min-width: 767px) {
        max-height: 100vh;
        position: fixed;
        left: 0;
        height: 100vh;
        width: 400px;
        z-index: 5;
        .card-wrapper {
            padding: 5px 15px;
            max-height: calc(100vh - 380px);
            overflow: scroll;
        }
        .card-wrapper::-webkit-scrollbar {
            width: 0 !important;
        }
    }
}

.crop-prices {
    background-color: white;
    padding: 30px 20px 0 20px;
    .heading-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        button {
            padding: 8px 10px;
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0.5s 2 both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }
        @media (max-width: 767px) {
            button {
                box-shadow: none;
            }
        }
    }
    .crops-name {
        margin: 25px 0;
        display: flex;
        justify-content: space-between;
        font-size: 1.6rem;
        flex-direction: row;
        div {
            text-align: center;
            padding: 8px 24px;
            cursor: pointer;
        }
        div.active {
            background-color: red;
            border-radius: 25px;
            color: white;
            font-weight: bold;
            background-image: linear-gradient(
                to bottom right,
                #ffffff 5%,
                rgba(139, 183, 159, 100),
                rgba(56, 142, 60, 1)
            );
        }
        @media (min-width: 900px) {
            max-width: 500px;
        }
    }
    .mandi-cards {
        display: grid;
        grid-template: auto / 1fr;
        grid-auto-rows: 200px;
        grid-row-gap: 25px;
        & > div {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding: 20px 0;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            background-image: linear-gradient(
                to bottom right,
                white 0% 90%,
                rgba(139, 183, 159, 100),
                rgba(56, 142, 60, 1)
            );
        }
        p {
            font-size: 1.6rem;
        }
        h3 {
            opacity: 0.8;
        }
    }
    @media (min-width: 767px) {
        padding: 30px 40px 20px 40px;
        position: relative;
        left: 400px;
        width: calc(100% - 400px);
        .mandi-cards {
            margin-top: 50px;
            grid-template: 250px / repeat(auto-fit, minmax(300px, 1fr));
            grid-auto-rows: 250px;
            grid-gap: 50px 5%;
        }
    }
}

@media (min-width: 767px) {
    .weather-page {
        max-height: 100vh;
        height: 100vh;
        display: block;
    }
}
.weather-page-error {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .error-icon {
        height: 50px;
        width: 50px;
        margin-bottom: 24px;
    }
    .logo {
        max-width: 80%;
        margin: 10px 0 25px 0;
    }
    p {
        text-align: center;
        font-size: 1.6rem;
    }
    @media (min-width: 767px) {
        .logo {
            margin-top: 0;
            width: 30%;
        }
    }
}

.weather-footer {
    width: 100%;
    bottom: 5px;
    padding: 50px 0 5px 0;

    .img {
        grid-area: button;
        padding: 25px 20px;
        img {
            display: block;
            max-width: 250px;
            height: auto;
            margin: auto;
        }
    }
    .footer-text {
        grid-area: img;
        font-size: 1.8rem;
        text-align: center;
        button {
            outline: none;
            border: none;
            background-color: rgba(56, 142, 60, 1);
            padding: 10px 15px;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
            border-radius: 5px;
            font-weight: bold;
            color: white;
            display: inline-block;
            margin: 15px 0;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
                opacity: 0.9;
                box-shadow: 0 8px 5px rgba(0, 0, 0, 0.2);
            }
        }
        img {
            display: inline;
        }
    }
    @media (min-width: 767px) {
        left: 400px;
        width: calc(100% - 400px);
        display: grid;
        grid-template: auto / 1fr 1fr;
        grid-template-areas: "img button";
        .footer-text {
            grid-area: button;
            // background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            button {
                margin-bottom: 50px;
            }
        }
        .img {
            grid-area: img;
        }
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
.weather-page-error .weather-footer {
    left: 0;
    width: 100%;
    @media (min-width: 767px) {
        max-width: 65%;
    }
}
