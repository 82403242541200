.qna-multimedia {
    &-question {
        margin: 40px auto;
        font-size: 1.6rem;
        & > * {
            margin: 10px 0;
        }
        blockquote {
            margin: 25px 0;
        }
    }
}
.qna-media-videos {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    video {
        width: 100%;
    }
    @media (max-width: 1023px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 500px) {
        display: block;
        video {
            width: 100%;
        }
    }
}
.qna-media-images {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    @media (max-width: 500px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.qna-media-icon {
    font-size: 5rem;
    padding: 1rem;
    cursor: pointer;
}
.qna-media-icon-container {
    display: flex;
    flex-wrap: wrap;
}
