@import "colors";
@import "mixins";

.homepage {
    @include breakpoint(tablet) {
        .heading-special::after {
            display: none;
        }
    }
}
.hero-section {
    background-color: white;
    height: 100vh;
    min-height: 700px;
    background-size: cover;
    margin-top: -18rem;
    background-image: url("../images/banner-img-image.png");
    background-size: cover;
    overflow: hidden !important;
    max-height: 1024px;
    background-position: 0 0;

    @include breakpoint(hdLaptop) {
        min-height: 1400px;
    }
    @include breakpoint(largeDesktop) {
        background-position: 250px 0;
    }
    @include breakpoint(tablet) {
        height: 70vh;
        background-position: 0 0;
    }
    @include breakpoint(mobile) {
        background-size: cover;
        background-position: -350px 0;
    }
    .curve {
        position: absolute;
        top: 80px;
        z-index: 39;
        display: flex;
        align-items: center;
        left: 0;
        background-color: white;
        height: calc(100% - 90px);
        width: 100vw;
        opacity: 0.95;
        // -webkit-clip-path: polygon(0 0, 34% 0, 58% 50%, 55% 57%, 0 91%);
        // clip-path: polygon(0 0, 27% 0, 40% 50%, 37% 54%, 0 94%);
        // clip-path: ellipse(55% 50% at 0% 22%);
        clip-path: ellipse(48% 68% at -6% 29%);
        @include breakpoint(laptop) {
            // background-color: pink;
            -webkit-clip-path: polygon(0 0, 27% 0, 58% 50%, 55% 57%, 0 91%);
            clip-path: ellipse(48% 68% at -6% 29%);
        }
        @include breakpoint(tablet) {
            -webkit-clip-path: ellipse(56% 62% at -6% 29%);
            clip-path: ellipse(56% 62% at -6% 29%);
        }
        @include breakpoint(phablet) {
            background: transparent;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
        @include breakpoint(mobile) {
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

            background: transparent;
        }
    }
    .heading-container {
        height: 290px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 50px;
        max-width: 41%;
        justify-content: space-evenly;
        a {
            width: 150px;
            font-size: 1.5rem;
        }
        @include breakpoint(tablet) {
            padding: 0 15px;
            max-width: 46%;
        }
        @include breakpoint(phablet) {
            padding: 0 15px;
            max-width: 100%;
            height: 250px;
            a {
                width: auto;
                max-width: 120px;
                font-weight: bold;
                text-align: center;
            }
            h1 {
                font-weight: bold;
                color: white;
                text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
            }
        }
    }
    .hero-btn {
        background-color: rgb(34, 139, 34);
        border-color: rgb(34, 139, 34);
        &:hover {
            opacity: 0.8;
            color: white;
        }
    }
    .hero-text-highlight {
        margin: 130px auto 0 auto;
        color: black;
        text-align: center;
        z-index: 200;
        h3 {
            font-size: 18px;
            font-weight: 900;
        }
        .hero-content-box {
            display: inline-block;
            max-width: 80%;
            background: #dcf0dc;
            border-radius: 4px;
            padding: 12px;
            margin-top: 8px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            h1 {
                font-size: 18px;
                font-weight: 500;
                color: #388e3c;
                a {
                    color: rgb(52, 165, 209);
                }
            }
        }
    }
}

//numbers section starts here

.homepage-numbers {
    position: relative;
    z-index: 43;
    height: 250px;
    margin-top: -18rem;
    background-color: #f7f7f7;

    .number-wrapper {
        max-width: 1400px;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        div {
            width: 180px;
            height: 180px;
            background-color: white;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                margin: 25px 0 10px 0;
                width: 30px;
                height: 30px;
            }
            h3 {
                font-size: 18px;
                text-align: center;
                margin-bottom: 15px;
            }
            h1 {
                text-align: center;
                position: relative;
                font-size: 2.5rem;
                font-weight: normal !important;
            }
            .number::after {
                display: block;
                position: absolute;
                content: "";
                right: 15%;
                bottom: -8px;
                height: 5px;
                width: 50%;
                background-color: $positive-green;
            }
            @include breakpoint(tablet) {
                width: 140px;
                height: 140px;
                img {
                    margin: 15px 0 8px 0;
                }
                h3 {
                    font-size: 15px;
                    margin-bottom: 5px;
                }
                h1 {
                    font-size: 2.5rem;
                }
            }
        }
    }
    @include breakpoint(mobile) {
        padding: 40px 0;
        height: auto;
        .number-wrapper {
            flex-direction: column;
            justify-content: space-evenly;
            height: 700px;
            div {
                height: 150px;
                width: 150px;
                align-items: center;
                img {
                    margin-top: 20px;
                }
                h3 {
                    margin-top: 2rem;
                }
            }
        }
    }
}

//feature section
.feature-section {
    position: relative;
    overflow-x: hidden;
    min-height: 700px;
    margin-top: -6rem;
    @include breakpoint(largeDesktop) {
        margin-top: 4rem;
    }
    .feature-wrapper {
        margin-top: 8rem;

        @include breakpoint(largeDesktop) {
            max-width: 85%;
            margin: 8rem auto auto auto;
        }
    }
    .feature-text {
        display: grid;
        grid-template: 150px 150px / 1fr 1fr;
        grid-gap: 20px 30px;
        width: 45%;
        padding: 70px 0 0 10%;
        .heading {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img {
                height: 30px;
                width: 30px;
                margin: 0 5px 0 0;
            }
        }
    }
    .feature-image {
        position: absolute;
        height: 100%;
        top: 0;
        width: auto;
        right: -15vw;
        img {
            height: 120%;
        }
        .mobile-img {
            position: absolute;
            height: 90%;
            width: auto;
            top: 35%;
            left: -5%;
        }
        @include breakpoint(largeDesktop) {
            right: 0;
            width: 50%;
        }
        @include breakpoint(hdLaptop) {
            right: 0;
        }
    }
    @include breakpoint(largeDesktop) {
        right: 0;
    }
    @include breakpoint(desktop) {
        .feature-text {
            padding-left: 10%;
        }
        .feature-image {
            right: -12vw;
            width: 55%;
            img {
                height: 120%;
            }
        }
    }

    //less than 1100px
    @include breakpoint(laptop) {
        .mobile-img {
            display: none;
        }
        .feature-image {
            height: 90%;
            right: -15vw;
            width: 50%;
        }
    }
    //tablet query

    @include breakpoint(tablet) {
        margin-bottom: -2rem;
        .feature-section {
            min-height: auto;
            position: static;
        }
        .feature-wrapper {
            margin-top: 3rem;
        }
        .feature-text {
            padding-left: 0;
            margin: auto;
            justify-content: center;
            grid-template: 150px 150px / 200px 200px;
        }
        .feature-image {
            position: static;
            width: 90%;
            margin: auto;
            img {
                height: auto;
            }
            .mobile-img {
                display: block;
                height: 80%;
                left: 5%;
                height: 30%;
                top: 70%;
            }
        }
    }
    @include breakpoint(mobile) {
        .feature-text {
            width: 80%;
            padding: 40px 0 0 0;
            margin: auto;
            grid-template: repeat(4, auto) / 1fr;
            grid-row-gap: 35px;
            margin-bottom: 35px;
            .heading img {
                height: 15px;
                width: 15px;
                margin-right: 15px;
            }
        }
        .feature-image .mobile-img {
            height: 20%;
            top: 80%;
        }
    }
}

//process section

.process-section {
    margin-top: -12rem;
    @include breakpoint(largeDesktop) {
        margin-top: -2rem;
    }
    p.text-lg {
        max-width: 60%;
        margin: 20px auto 0 auto;
    }

    //stepper for desktop viewport
    .process-stepper {
        display: grid;
        grid-template: auto / repeat(6, 1fr);
        grid-column-gap: 3%;
        max-width: 90%;
        margin: auto;
        padding-top: 100px;
    }
    .step-wrapper {
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px; //space for hover description content
        p.text-lg {
            margin-top: 5px;
        }
        .icon-wrapper {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f7f7f7;
            transition: all 0.3s ease-in-out;

            img {
                width: 40px;
                height: 40px;
            }
        }
        &::after,
        &::before {
            content: "";
            width: 15px;
            height: 15px;
            position: absolute;
            background-color: #ccc;
            opacity: 0.9;
            top: 50%;
            border-radius: 50%;
            right: -20%;
        }
        &::before {
            right: 0%;
        }
        &:last-child::after,
        &:last-child::before {
            display: none;
        }
        //hover effect
        &:hover .icon-wrapper {
            box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
        // &:hover .icon-wrapper .step-description{
        //     opacity: 1;
        // }
        .step-description {
            pointer-events: none;
            position: absolute;
            top: -120%;
            box-shadow: 0 15px 10px -8px rgba(0, 0, 0, 0.2);
            left: 50%;
            opacity: 0;
            transform: translateX(-50%);
            width: 250px;
            border-radius: 5px;
            z-index: 50;
            background-color: $positive-green;
            padding: 10px;
            transition: all 0.3s ease-in-out;
            p {
                color: white;
                font-weight: bold;
                text-align: center;
            }
            &::after {
                position: absolute;
                bottom: -15px;
                content: "";
                left: 50%;
                transform: rotate(45deg) translateX(-50%);
                height: 20px;
                width: 20px;
                border-radius: 5px;
                background-color: $positive-green;
            }
        }
    }
    @include breakpoint(tablet) {
        margin-top: inherit;
        p.text-lg {
            max-width: 75%;
        }
        .process-stepper {
            align-items: center;
            grid-template: auto auto / repeat(3, 2fr);
            grid-row-gap: 80px;
            padding-top: 50px;
            grid-template-areas: "sourcing  sorting grading" "delivering dispatching packaging";
            .sourcing-step {
                grid-area: sourcing;
            }
            .sorting-step {
                grid-area: sorting;
            }
            .grading-step {
                grid-area: grading;
            }
            .packaging-step {
                grid-area: packaging;
            }
            .dispatch-step {
                grid-area: dispatching;
            }
            .delivery-step {
                grid-area: delivering;
            }
        }
        .step-wrapper {
            padding: 0;

            &::after {
                top: 35%;
                right: 10%;
            }
            &::before {
                top: 35%;
                right: -10%;
            }
            &:nth-child(3)::after,
            &:nth-child(3)::before {
                display: none;
            }
            &:last-child::after,
            &:last-child::before {
                display: block;
            }
            &:nth-child(4)::after {
                top: -45%;
                right: 45%;
            }
            &:nth-child(4)::before {
                top: -20%;
                right: 45%;
            }
            .step-description {
                top: -75%;
                width: 200px;
                padding-bottom: 15px;
                &::after {
                    position: absolute;
                    bottom: -10px;
                    z-index: 4;
                    transform: rotate(45deg) translateX(-50%);
                }
            }
        }
    }
    @include breakpoint(mobile) {
        h1.heading-secondary {
            max-width: 90%;
            margin: auto;
            &::after {
                display: none;
            }
        }
        p.text-lg {
            max-width: 90%;
        }
        .process-stepper {
            display: block;
        }

        .step-wrapper {
            margin-bottom: 55px;
            &::after,
            &::before {
                display: none !important; //clearing all connecting dots
            }
            .icon-wrapper {
                width: 200px;
                height: 200px;
                img {
                    height: 75px;
                    width: 75px;
                }
            }
            p.text-lg {
                font-size: 3rem;
                margin-top: 20px;
            }
            .step-description {
                top: -65%;
                width: 300px;
                p {
                    font-size: 16px;
                }
            }
        }
    }
    .read-more-link {
        margin-top: 30px;
    }
}
a.read-more-link {
    font-size: 1.5rem;
    display: inline-block;
    margin-top: 30px;
    font-size: 1.5rem;
    i {
        color: $positive-green;
        display: inline-block;
        margin-left: 5px;
        transition: all 0.3s ease-in-out;
    }
    &:hover i {
        margin-left: 10px;
    }
}
.customer-section {
    margin-top: -6rem;
    @include breakpoint(largeDesktop) {
        margin-top: 1rem;
    }
    .wrapper {
        max-width: 80%;
        margin: auto;
        margin-top: 5rem;
        display: grid;
        grid-template: auto / 0.5fr 0.5fr;
        align-items: center;
        grid-column-gap: 5%;
        @include breakpoint(largeDesktop) {
            width: 70%;
        }
        @include breakpoint(tablet) {
            max-width: 90%;
        }
        @include breakpoint(laptop) {
            max-width: 90%;
        }
        @include breakpoint(mobile) {
            max-width: 90%;
            grid-template: auto auto / 1fr;
            grid-row-gap: 50px;
        }
    }
    .farmer-section {
        margin-bottom: 6rem;
    }
    .farmer-section .wrapper {
        grid-template-areas: "text image";
        @include breakpoint(mobile) {
            grid-template-areas: "image" "text";
        }
    }
    .buyer-section .wrapper {
        grid-template-areas: "image text";
        @include breakpoint(mobile) {
            grid-template-areas: "image" "text";
        }
    }
    .reason-wrapper {
        grid-area: text;
        .reason-text {
            display: grid;
            grid-template: auto/ 70px auto;
            align-items: center;
            padding: 15px;
            margin-bottom: 25px;
            max-width: 80%;
            grid-column-gap: 15px;
            transition: box-shadow 0.2s ease-in-out;
            border-radius: 5px;
            &:hover {
                box-shadow: 0 3px 15px -2px rgba(0, 0, 0, 0.2);
            }
            @include breakpoint(tablet) {
                max-width: 90%;
                grid-template: auto/ 50px auto;
                grid-column-gap: 8px;
            }
            @include breakpoint(laptop) {
                max-width: 90%;
            }
            @include breakpoint(mobile) {
                max-width: 100%;
            }
        }
        .icon-wrapper {
            display: flex;
            height: 60px;
            width: 60px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #f7f7f7;
            img {
                height: 40px;
                width: 40px;
            }
            @include breakpoint(tablet) {
                height: 45px;
                width: 45px;
                img {
                    height: 35px;
                    width: 35px;
                }
            }
        }
        .heading h4 {
            margin-bottom: 10px;
            @include breakpoint(tablet) {
                margin-bottom: 7px;
            }
        }
    }
    .illustration-wrapper {
        grid-area: image;
        h3 {
            align-self: center;
            max-width: 80%;
        }
        img {
            height: 70%;
            width: 70%;
        }
        @include breakpoint(mobile) {
            align-items: center;
            h3 {
                font-size: 2.5rem;
                max-width: 100%;
            }
            img {
                margin: 0 auto;
            }
        }
    }
    .buyer-section {
        .illustration-wrapper img {
            align-self: flex-start;
            @include breakpoint(mobile) {
                align-self: center;
            }
        }
        .reason-wrapper {
            align-self: end;
        }
    }
}

//testimonial section

.testimonial-section {
    margin-top: -4rem;
    @include breakpoint(largeDesktop) {
        margin-top: 4rem;
    }
    @include breakpoint(tablet) {
        margin-top: 0rem;
    }
    .testimonial-wrapper {
        margin: auto auto auto auto;
        @include breakpoint(hdLaptop) {
            width: 60%;
            margin: auto auto auto auto;
        }
        @include breakpoint(largeDesktop) {
            width: 75%;
        }
    }
    .testimonial-container {
        position: relative;
        width: 90%;
        height: 450px;
        margin: auto;
        margin-top: 4rem;

        @include breakpoint(mobile) {
            position: static;
            height: auto;
            min-height: 320px;
            max-height: auto;
        }
        @include breakpoint(tablet) {
            height: auto;
            min-height: 300px;
        }
        .first-card,
        .second-card,
        .third-card {
            position: absolute;
            background-color: white;
            border-radius: 8px;
            z-index: 5;
        }
        .first-card,
        .third-card {
            height: 350px;
            top: 25px;
            width: 24%;
            opacity: 0.5;
            padding: 25px 15px 15px 15px;
            img.quoteIcon {
                width: 30px;
                height: 30px;
            }
            .testimonial-text {
                margin-top: 50px;
                max-height: 40%;
                overflow: hidden;
            }
            .info-wrapper {
                margin-top: 40px;
                display: grid;
                grid-template: 50px / 40px auto;
                grid-column-gap: 20px;
                img {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                }
            }
            @include breakpoint(laptop) {
                height: auto;
                .testimonial-text {
                    margin-top: 15px;
                    max-height: 130px;
                    overflow: hidden;
                }
                .info-wrapper {
                    margin-top: 15px;
                }
            }
            @include breakpoint(phablet) {
                display: none;
            }
            @include breakpoint(tablet) {
                height: auto;
                .testimonial-text {
                    max-height: 80px;
                    overflow: hidden;
                }
                .info-wrapper {
                    grid-column-gap: 0;
                    img {
                        height: 20px;
                        width: 20px;
                    }
                    .name p {
                        font-size: 8px;
                    }
                }
            }
        }
        .third-card {
            right: 0;
        }
        .active-card {
            height: 380px;
            width: 60%;
            left: 50%;
            z-index: 6;
            transform: translateX(-50%);
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2);
            padding: 0 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            .quoteIcon {
                height: 50px;
                width: 50px;
                margin-bottom: 2rem;
            }
            p.testimonial-text {
                font-size: 1.8rem;
            }
            .info-wrapper {
                display: grid;
                margin-top: 0px;
                grid-template: 50px / 65px auto;
                align-items: center;
                grid-column-gap: 3%;
            }
            img {
                border-radius: 50%;
                height: 65px;
                width: 65px;
            }
            div.name {
                h5 {
                    font-size: 2rem;
                }
                p {
                    font-size: 1.5rem;
                }
            }

            @include breakpoint(laptop) {
                padding: 20px 30px;
                height: 330px;
                .quoteIcon {
                    height: 40px;
                    width: 40px;
                }
                .info-wrapper {
                    margin-top: 40px;
                    img {
                        height: 45px;
                        width: 45px;
                    }
                }
            }
            @include breakpoint(phablet) {
                width: 95%;
                margin: auto;
                height: auto;
                padding: 15px;
                .quoteIcon {
                    height: 30px;
                    width: 30px;
                    display: inline-block;
                    margin-bottom: 15px;
                }
                .info-wrapper {
                    margin-top: 0;
                }
                div.name h5 {
                    font-size: 1.2rem;
                }
            }
            @include breakpoint(tablet) {
                height: 290px;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            }
        }
    }
    .testimonial-btns {
        .wrapper {
            margin: auto;
            width: 100px;
            display: flex;
            justify-content: space-between;
            .arrow-btn {
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                height: 40px;
                width: 40px;
                background-color: $positive-green;
                border-radius: 50%;
                box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.3);
                transition: all 0.2s linear;
                font-size: 16px;
                cursor: pointer;
                &:active {
                    box-shadow: none;
                }
            }
            @include breakpoint(mobile) {
                width: 100px;
            }
        }
    }
    @include breakpoint(tablet) {
        .background--1 {
            height: 70rem;
            top: 0rem;
        }
    }
    @include breakpoint(mobile) {
        .background--1 {
            display: none;
        }
    }
}

.animate-card {
    & * {
        animation: fadeInCard 0.6s ease-in-out 0s 1 normal forwards;
    }
}

@keyframes fadeInCard {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

//in the news section

.news-section {
    overflow: hidden;
    margin-top: -4rem;
    @include breakpoint(largeDesktop) {
        margin-top: 4rem;
    }
    @include breakpoint(tablet) {
        margin-top: 0rem;
    }
    .wrapper {
        margin-top: 5rem;
    }
    .news-content {
        cursor: pointer;
        width: 60%;
        margin: auto;
        display: grid;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 15px 35px;
        min-height: 350px;
        grid-template: 90px 50px 70px auto / 1fr;
        .logo {
            height: 100%;
            justify-self: center;
        }
        .quote-icon {
            height: 40px;
            width: 40px;
        }
        a.read-more-content {
            color: $positive-green;
            font-weight: bold;
        }
        @include breakpoint(hdLaptop) {
            width: 75%;
        }
        @include breakpoint(largeDesktop) {
            width: 75%;
        }
        @include breakpoint(laptop) {
            grid-template: 90px auto auto auto / 1fr;
            width: 75%;
        }
        @include breakpoint(tablet) {
            min-height: auto;
            width: 95%;
            margin: auto;
            grid-template: 90px 40px 60px auto / 1fr;
            grid-column-gap: 0;

            .quote-icon {
                width: 30px;
                height: 30px;
            }
            .article-heading {
                font-size: 3rem;
            }
        }
        @include breakpoint(mobile) {
            grid-template: 40px auto auto auto / 1fr;
            width: 95%;
            padding: 15px;
            min-height: 300px;
            grid-row-gap: 15px;
            .quote-icon {
                width: 25px;
                height: 25px;
            }
            h3.article-heading {
                font-size: 18px;
            }
            p.article-content {
                font-size: 14px;
            }
        }
    }
    .animate-in {
        animation: fadeIn 0.4s linear 0s 1 normal forwards;
    }
    .logo-container {
        margin-top: 10rem;
        display: grid;
        grid-template: 80px / repeat(5, 1fr);
        grid-column-gap: 5%;
        align-items: center;
        transition: all 0.6s ease-in-out;
        img {
            cursor: pointer;
            position: relative;
            transition: all 0.3s ease-in-out;
        }
        .active {
            padding-bottom: 15px;
            border-bottom: 5px solid $positive-green;
        }
        @include breakpoint(mobile) {
            grid-template: 100px / repeat(5, 25%);
            overflow: hide;
            position: relative;
            margin-top: 4rem;
            grid-column-gap: 10%;
        }
    }
    .as-seen-on-container {
        margin-top: 10rem;
        .wrapper {
            display: grid;
            grid-template: 70px / repeat(5, 1fr);
            justify-content: space-around;
            grid-column-gap: 40px;
            align-items: center;
        }
        @include breakpoint(mobile) {
            margin-top: 4rem;
            .wrapper {
                grid-template: 60px 60px /1fr 1fr;
            }
        }
        @include breakpoint(mobile) {
            .wrapper {
                grid-template: 70px / repeat(5, 25%);
                animation: scrolling 5s linear 0s infinite alternate forwards;
                &:hover {
                    animation-play-state: paused;
                }
            }
        }
    }
}
@keyframes fadeIn {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }
    100% {
        transform: translateX(00px);
        opacity: 1;
    }
}
@keyframes scrolling {
    0% {
        transform: translateX(200px);
    }
    100% {
        transform: translateX(-50%);
    }
}
.image-grid {
    background-color: rgba(245, 240, 245, 0.6);
    padding: 100px 0;
    margin-bottom: -9rem;
    margin-top: -6rem;
    @include breakpoint(largeDesktop) {
        margin-top: 4rem;
    }
    .wrapper {
        display: grid;
        max-width: 80%;
        margin: auto;
        grid-gap: 5px;
        grid-template: 190px / repeat(auto-fit, minmax(300px, 1fr));
        grid-auto-rows: 290px;
        grid-auto-flow: dense;
        img {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
        .vertical {
            grid-row: span 2;
        }
        .landscape {
            grid-column: span 2;
        }
        .hero {
            grid-row: span 2;
            grid-column: span 2;

            @include breakpoint(laptop) {
                grid-row: span 1;
            }
            @include breakpoint(tablet) {
                grid-row: span 1;
            }
            @include breakpoint(mobile) {
                grid-row: span 1;
            }
        }
        @include breakpoint(largeDesktop) {
            grid-template: 230px / repeat(auto-fit, minmax(500px, 1fr));
            grid-auto-rows: 290px;
        }
        @include breakpoint(hdLaptop) {
            grid-template: 270px / repeat(auto-fit, minmax(500px, 1fr));
            grid-auto-rows: 270px;
        }
        @include breakpoint(mobile) {
            grid-template: auto / 1fr;
            grid-auto-rows: auto;

            .vertical,
            .hero {
                grid-row: auto;
            }
            .hero {
                grid-column: span 1;
            }
        }
    }
    .image-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 4000;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 80%;
            height: 80%;
            @include breakpoint(mobile) {
                width: 100%;
                height: auto;
            }
        }
        .fa {
            position: fixed;
            top: 3%;
            font-size: 45px;
            color: white;
            right: 5%;
            cursor: pointer;
        }
    }
}
