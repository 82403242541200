@import 'colors';
@import 'mixins';

.theme-btn {
    padding: 10px 30px 10px 30px;
    border-radius: 50px 50px;
    i {
        opacity: 0;
        position: absolute;
    }
    i.right-align {
        margin-left: 5px
    }
    i.left-align {
        margin-right: 5px;
    }
    @include easing(0.2s, ease, 0s);
    &:hover {
        i {
            position: relative;
            opacity: 1;
        }
    }
}

.theme-btn--green {
    background: $primary-green;
    border: 1px solid $primary-green;
    color: $light;
    &:hover {
        background: $light;
        color: $dark;
    }
}

.theme-btn--brown {
    background: $primary-brown;
    color: $light;
    border: 1px solid $primary-brown;
    &:hover {
        background: $light;
        color: $dark;
        //@include shadow(inset 150px 0 0 $light)
    }
}

.kn-btn {
    font-size: 1.2rem;   
    //border-radius: 4px;
    padding: 1rem 5rem;
    // box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
    transition: all 200ms;
    // max-width: 20rem;
    @include breakpoint(tablet) {
        font-size: 1.8rem;   
        //border-radius: 4px;
        padding: 1.5rem 6rem;
    }
    @include breakpoint(phablet) {
        font-size: 1.8rem;   
        //border-radius: 4px;
        padding: 1.5rem 6rem;
    }
    @include breakpoint(mobile) {
        font-size: 2rem;   
        //border-radius: 4px;
        padding: 2rem 7rem;
    }
    &--brown {
        border-radius: 4px;
        // background-color: transparent;
        // color: $light;
        color: $theme-brown;
        background-color: $light;
        text-transform: uppercase;
        border: 1px solid transparent;


        // & > a {
        //     padding: 1rem 5rem;
        // }
    }
    &:hover {
        box-shadow: 5px 8px 25px 0 rgba(46,61,73,.2);
        border-radius: 0;
        transform: translateY(-1px);
        border: 1px solid $light;
        color: $theme-brown;
    }
}

@media (max-width:445px) {
    .theme-btn {
        padding: 5px 10px;
        border-radius: 2px;
    }
}