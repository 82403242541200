.modal-wrapper {
    background: #b1c155;
    max-width: 400px;
    margin: auto;
    transition: all 0.5s ease;
    z-index: 1;
    position: fixed;
    overflow: hidden;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-height: 80vh;
    overflow: auto;
    width: 95%;
}
.Gallerymodal__wrapper {
    background: #b1c155;
    max-width: 95vw;
    margin: auto;
    transition: all 0.5s ease;
    border-radius: 10px;
    z-index: 1;
    position: fixed;
    overflow: hidden;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-height: 90vh;
    @media only screen and (max-width: 640px) {
        overflow-y: auto;
        width: 95%;
    }
}
.modal__header {
    background: #b1c155;
    color: #e9e9e9;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
}
body.modal-open {
    overflow: hidden;
    height: 100vh;
}

.modal__header h4 {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
}

.modal__header span {
    font-size: 2rem;
    cursor: pointer;
    color: #e9e9e9;
}
.modal--content {
    padding: 0rem;
    background-color: #fff;
    width: 100%;
   
}
.modal-body {
    margin-bottom: 1rem;
    padding: 0 !important;
}
.Gallerymodal-body {
    margin-bottom: 1rem;
    padding: 0 !important;
    display: flex;
}

.modal-body h4 {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
}

.modal-body h4 span {
    font-weight: normal;
}

.modal-body p {
    font-size: 10px;
    color: #333;
    padding: 1rem;
    margin: 1rem 0 2rem 0;
}

.modal-footer button {
    display: block;
    font-size: 1.2vw;
    margin-left: auto;
    padding: 0.5rem 1.8rem;
    background: #da6840;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.back-drop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #101010e8;
    z-index: 9999;
}

/**************form css************/

.modal-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.modal-list li {
    padding: 0.3em;
}

.required-star,
.close-modal-btn {
    font-weight: 700;
    color: #102a43;
    line-height: 35px;
    font-size: 12px;
    text-transform: uppercase;
}

.modal-form input[type="submit"] {
    background: #fc4366;
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 5px;
    margin-top: 1.3em;
}

.modal-form {
    background-color: #ffffff;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
}

.required-star {
    color: #fc4366;
    font-size: small;
}

.modal-form input,
.modal-form textarea {
    width: 100%;
    padding: 9px 20px;
    border: 1px solid #e1e2eb;
    background-color: #fff;
    color: #102a43;
    box-sizing: border-box;
    font-size: 1.5rem;
    line-height: 2rem;
    box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
    border-radius: 3px;
    line-height: 2rem;
}

.b-detail {
    padding: 8px;
    text-align: center;
    h2 {
        font-size: 22px;
        text-align: left;
    }
    @media only screen and (min-width: 640px) {
        max-height: 80vh;
        overflow-y: auto;
    }
}
.kopia-report-card{
    padding: 1rem 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    min-height: 500px;
    background-color: white;
    img{
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }
    .kopia-report-card-data{
        display: flex;
        align-items: center;
        
    }
    @media (max-width:767px) {
            display: block;
            padding: 0;
    }
}
.kopia-non-dimension-container{
    min-height: 200px;
    min-width: 300px;
    max-width: 800px;
    .control-arrow{
       background-color: rgba(0,0,0,0.2) !important;
    }
}