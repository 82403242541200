@import "colors";
@import "mixins";

.page-footer {
    border-top: 1px solid rgb(243, 243, 243);
    background: $white;
    padding: 5rem 10rem;
    font-weight: 300;
    @include breakpoint(tablet) {
        padding: 5rem 5rem;
    }
    @include breakpoint(mobile) {
        padding: 3rem 3rem;
    }
    img {
        //width: 5rem;
        height: 5rem;
        margin: 0 0 1rem -0.5rem;
    }
    .kn-heading {
        margin-bottom: 1rem;
    }
    table {
        tr {
            td {
                font-size: 1.6rem;
                padding: 0.5rem 0;
            }
        }
    }
    ul {
        list-style-type: none;
        li {
            font-size: 1.6rem;
            padding: 0.5rem 0;
            cursor: pointer;
            a {
                text-decoration: none;
                &:hover {
                    color: $primary-green;
                }
            }
            &.bold {
                font-weight: 700;
            }
        }
    }
}

.copyright-text {
    display: inline-block;
}

.page-post-footer {
    padding: 2rem 10rem;
    background: rgb(247, 246, 246);
    p {
        vertical-align: middle;
    }

    .follow-icons-container {
        float: right;
        @include breakpoint(mobile) {
            float: left;
        }
        i {
            font-size: 2.4rem;
            cursor: pointer;
            margin-right: 5px;
        }
    }
}

.careers {
    &::after {
        content: "We are hiring :)";
        display: inline-block;
        background-color: $primary-brown;
        color: $white;
        padding: 2px 8px;
        margin-left: 1rem;
        border-radius: 4px;
        font-style: italic;
    }
}
