@font-face {
    font-family: Aileron;
    src: url(../fonts/Aileron-Heavy.otf);
}

@import "mixins";

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // border: 1px solid red;
}

html,
body {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;

    //overflow-x: hidden;

    @include breakpoint(laptop) {
        font-size: 8px;
    }

    @include breakpoint(tablet) {
        font-size: 6px;
    }

    @include breakpoint(mobile) {
        font-size: 7px;
    }
}

html {
    //   height: 100%;
}

body.ReactModal__Body--open {
    overflow: hidden hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: none;
    }
    &:focus {
        text-decoration: none;
    }
}

div,
header,
footer,
section,
nav,
aside {
    position: relative;
}

img {
    display: block;
    max-width: 100%;
}

button {
    position: relative;
    &:focus {
        outline: none;
    }
}

button:focus,
input:focus,
select:focus {
    outline: none;
}

ul {
    margin: 0;
    padding: 0;
}

input[type="radio"] {
    -webkit-appearance: radio;
}
