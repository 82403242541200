@import "colors";
@import "mixins";

.treasure-chest {
    position: absolute;
    left: 67%;
    top: 47vh;
    transform: translate(-50%, -50%);
    height: 20rem;
    z-index: 10;
    @include breakpoint(laptop) {
        // top: 21vh;
        top: 280px;
    }
    @include breakpoint(mobile) {
        display: none;
    }
    @include breakpoint(tablet) {
        top: 25vh;
    }
}

.map-image {
    position: absolute;
    left: 73%;
    top: 48vh;
    transform: translate(-50%, -50%);
    height: 65rem;
    z-index: 5;
    @include breakpoint(laptop) {
        top: 23vh;
        top: 300px;
    }
    @include breakpoint(tablet) {
        top: 25vh;
        // top: 400px;
    }
    @include breakpoint(mobile) {
        top: 35rem;
        // top: 250px;
        left: 50%;
        opacity: 0.3;
        z-index: 1;
    }
}

.treasure-animation {
    animation: animate-treasure 1000ms ease-out 1 forwards;
}

.move-treasure {
    &:hover {
        animation: move-treasure 1400ms ease-in infinite alternate;
    }
}

@keyframes move-treasure {
    0% {
        top: 44%;
    }
    50% {
        top: 45%;
    }
    100% {
        top: 46%;
    }
}

@keyframes animate-treasure {
    0% {
        height: 200px;
        opacity: 1;
    }
    // 20% {
    //     height: 150px;
    //     opacity: 0.8;
    // }
    // 50% {
    //     height: 380px;
    //     opacity: 0.4;
    // }
    100% {
        height: 1400px;
        opacity: 0;
        z-index: -1;
    }
}

.map-overlay {
    height: 100vh;
    width: 50%;
    z-index: 0;
    position: absolute;
    top: 0;
    // margin-top: 20rem;

    &-left {
        background: url("../../src/images/joot2.jpg");
        left: 0%;
        // margin-left: -12vw;
        transform-origin: left center;
        // @include breakpoint(largeDesktop) {
        //     margin-left: -22vw;
        // }
    }
    &-right {
        background: url("../../src/images/joot2.jpg");
        left: 50%;
        transform-origin: right center;
    }
    @include breakpoint(tablet) {
        max-height: 500px;
    }

    @include breakpoint(mobile) {
        min-height: 700px;
    }
}

.animate-panel-right {
    animation: animate-right-panel 2000ms linear 1 forwards;
}

.animate-panel-left {
    animation: animate-left-panel 2000ms linear 1 forwards;
}

@keyframes animate-right-panel {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(145deg);
        visibility: hidden;
    }
}

@keyframes animate-left-panel {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(-145deg);
        visibility: hidden;
    }
}

.treasure-text {
    position: absolute;
    display: inline-block;
    top: 20vh;
    left: 10%;
    width: auto;
    max-width: 40rem;
    line-height: 6rem;

    & > .heading-special {
        font-size: 4rem;
    }
    @include breakpoint(largeDesktop) {
        font-size: 5rem;
    }
    @include breakpoint(laptop) {
        // top: 8vh
    }

    @include breakpoint(tablet) {
        top: 10vh;
        z-index: 4;
        text-align: center;
        display: block;
        width: 40rem;
        font-size: 2rem;
    }
    @include breakpoint(mobile) {
        top: 20rem;
        z-index: 12;
        text-align: center;
        display: block;
        width: 40rem;
        left: 50%;
        transform: translateX(-50%);
        font-size: 2rem;
    }
}

.open-card-button {
    z-index: 100;
    // position: absolute;
    // top: calc(100vh - 9rem);
    // left: 50%;
    // transform: translate(-50%, -50%);
    color: $positive-green;
    transition: 200ms;
    // cursor: pointer;
    // padding: .5rem 3rem;
    border: none;
    // background-color: $positive-green;
    background-color: transparent;
    font-weight: bolder;
    border-bottom: 1px solid transparent;
    font-size: 2rem;

    &:hover {
        top: calc(100vh - 8.1rem);
        border-radius: 5px;
        border-bottom: 1px solid $positive-green;
    }
}

.curl {
    width: 6rem;
    height: 6rem;
    position: absolute;
    top: calc(100vh - 6rem);
    left: 50%;
    cursor: pointer;
    background: linear-gradient(
        45deg,
        #fff,
        #f3f3f3 45%,
        #ddd 50%,
        #aaa 50%,
        #bbb 56%,
        #ccc 62%,
        #f3f3f3 80%,
        #fff 100%
    );
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;

    &:hover {
        width: 140px;
        height: 140px;
        top: calc(100vh - 140px);
    }
}

.buyer-page {
    min-height: 92vh;
    height: auto;
    margin-top: 80px;
    @include breakpoint(laptop) {
        min-height: 650px;
    }
    @include breakpoint(tablet) {
        max-height: 500px;
    }
    @include breakpoint(mobile) {
        height: auto;
        min-height: 700px;
    }
    // background-image: linear-gradient(bottom left, rgba(0, 255, 0, 0.1), rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1));
    // z-index: -1;
}

.map-wrapper.map-uninteractive {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    justify-content: flex-end;
}

.district-group {
    animation: svgfadein 0.5s ease-in-out 0s 1 normal forwards;
}
@keyframes svgfadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.map-wrapper.map-interactive {
    display: flex;
    justify-content: center;
    transition: 200ms;
}
.state {
    fill-opacity: 1;
    stroke: black;
    stroke-opacity: 1;
    stroke-width: 1;

    &-selected {
        fill: rgba($positive-green, 0.6);
    }

    &:hover {
        cursor: pointer;
        fill: rgba($positive-green, 0.8);
    }

    // &:hover &:parent {
    //     display: inline-block;
    // }

    &-title-wrapper {
        display: none;
        background-color: white;
        font-size: 16px;
        border-radius: 4px;
        color: #333;
        box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0px;
        left: 0px;
        // display: inline-block;
        padding: 4px 4rem;
        z-index: 1;
    }

    &-title {
        z-index: 10;
    }

    &-title-wrapper::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 90%;
        left: 50%;
        background-color: white;
        z-index: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(45deg);
        // box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, .2);
    }
}
.district-path {
    stroke: rgba(0, 0, 0, 0.3);
    // fill: #FFFFFF;
    fill-opacity: 1;
    stroke-opacity: 1;
    stroke-width: 0.2;
    &:hover {
        cursor: pointer;
        fill: rgba($positive-green, 0.8);
    }
}
.buyer-form-wrapper {
    padding: 3rem 2rem;
    margin-top: 10rem;
}

.buyer {
    &-form {
        width: 40rem;
        // padding: 2rem 4rem;

        &-control {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background-color: transparent;
            border: none;
            font-size: 14px;
            transition: 200ms;
            transform-origin: center;

            &:hover {
                transform: scale(1.1);
            }
        }
        .verify-options {
            display: flex;
            margin-top: 0.8rem;
            align-items: center;
            justify-content: space-between;
            a {
                font-weight: bold;
                font-size: 1.6rem;
                text-decoration: underline;
            }
        }
    }

    &-input {
        margin: 1rem 0 0 0;
        font-size: 1.6rem;
        padding: 1rem 1.5rem;
        border: none;
        outline: none;
        border-radius: 4px;
        width: 100%;
        border: 1px solid transparent;
        background-color: #fafafa;

        &:placeholder-shown > label {
            opacity: 0;
        }

        &::placeholder {
            color: #aaa;
        }

        &:focus > label {
            opacity: 0;
        }

        &:focus {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        }

        &-error {
            font-size: 1.6rem;
            color: crimson;
            margin: 0;
        }
    }

    &-verify-btn {
        padding: 0.5rem 2rem;
        background-color: $positive-green;
        color: $white;
        font-size: 1.6rem;
        text-transform: uppercase;
        transition: 200ms;
        border: none;
        outline: none;

        &:hover {
            border-radius: 4px;
            transform: translateY(-2px);
            box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.2);
        }
    }

    .slick-track {
        display: flex;
    }

    .slick-track .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
    }

    &-form-details {
        position: absolute;
        top: 30vh;
        left: 10%;
        // background-color: rgba(255, 255, 255, .8);
        border-radius: 5px;
        // box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, .2);
        z-index: 100;

        &-show {
            margin-top: 1rem;
        }

        &-show-enter {
            opacity: 0.01;
            @include trans(translate(-50%, -40%));
        }

        &-show-enter-active {
            opacity: 1;
            @include trans(translate(-50%, -50%));
            transition: all 300ms ease-out;
        }

        &-show-exit {
            opacity: 1;
            @include trans(translate(-50%, -50%));
        }

        &-show-exit-active {
            opacity: 0;
            @include trans(translate(-50%, -40%));
            transition: all 300ms ease-out;
        }
        @include breakpoint(laptop) {
            // top: 13vh;
        }
        @include breakpoint(tablet) {
            top: 18vh;
        }
        @include breakpoint(mobile) {
            top: 28rem;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.phone-number-wrapper {
    display: grid;
    grid-template: auto / 100px auto;
    grid-column-gap: 5px;
    select {
        height: 50px;
    }
    @include breakpoint(mobile) {
        select {
            height: 35px;
        }
    }
}

.buyer-enquiry {
    font-size: 1.6rem;
}
.crop-list-dropdown {
    position: absolute;
    padding: 10px;
    background-color: white;
    top: 10%;
    font-size: 1.6rem;
    right: 15%;
    z-index: 99;
    select {
        margin-top: 10px;
        height: 30px;
        outline: none;
        min-width: 150px;
        border: 1px solid #333;
        font-size: 1.6rem;
        background-color: #fafafa;
    }
}

div[role="toolbar"] {
    background-color: transparent !important;
    border: 1px solid #aaa;
    top: 20px !important;

    & > button:hover {
        color: #999 !important;
    }

    & > button[title="Zoom in"] {
        display: none !important;
    }

    & > button[title="Zoom out"] {
        display: none !important;
    }

    & > button[title="Fit to viewer"] {
        display: none !important;
    }
    @include breakpoint(mobile) {
        transform: translate(0, 0) !important;
        left: 20% !important;
    }
}

.crop-image {
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}
.buyer-page {
    @include breakpoint(tablet) {
        min-height: 500px;
        max-height: 500px;
    }
    @include breakpoint(laptop) {
        max-height: 500px;
    }
    .sidebar {
        height: calc(100vh - 60px);
        padding: 2rem;
        overflow: scroll;
        width: 35%;
        border-right: 1px solid #eee;
        background-color: whitesmoke;
        transition: all 0.4s ease-in-out;
        transform: translateX(0);
        @include breakpoint(laptop) {
            padding: 15px;
            form > div > input {
                border: 1px solid #ccc;
            }
            width: 45%;
            height: 700px;
        }

        @include breakpoint(tablet) {
            height: 500px;
            width: 45%;
        }
        @include breakpoint(mobile) {
            z-index: 400;
            max-height: 610px;
            overflow: scroll;
            padding-bottom: 100px;
            position: absolute;
            width: 85%;
        }
    }

    .sidebar::-webkit-scrollbar {
        width: 0 !important;
    }
    .crop-region-wrapper {
        padding: 10px 0;
        font-size: 1.6rem;
        position: relative;
        .user-details {
            position: fixed;
        }

        label {
            font-weight: normal;
            display: block;
        }
        & > div {
            margin-bottom: 15px;
        }
        select {
            height: 35px;
            background-color: white;
            width: 100%;
            border-radius: 0;
            border: none;
        }
        label.map-select {
            padding: 7px 5px;
            width: 100%;
            border-radius: 4px;
            background-color: #ffffff;
            cursor: pointer;
        }
        .cart-items {
            margin-top: 3.5rem;
            &-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                button {
                    background-color: transparent;
                    padding: 0.3rem 0.7rem;
                    font-size: 1.6rem;
                    border: 1px solid black;
                    .fa {
                        position: static;
                    }
                }
            }
            .cart-item-card {
                position: relative;
                margin-top: 2rem;
                background-color: white;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
                padding: 15px 10px 15px 20px;
                border-radius: 3px;
                display: grid;
                grid-template: auto / 80% 10%;
                grid-gap: 10px 1%;
                p {
                    font-size: 1.6rem;
                }

                &--info > div {
                    margin: 0.5rem 0;
                    font-size: 1.6rem;
                }
            }
            label {
                margin-bottom: 01rem;
                font-weight: bold;
            }

            input[type="text"] {
                width: 70%;
                font-size: 1.6rem;
                padding: 0.5rem;
                margin-top: 0.5rem;
                border: 1px solid #cccccc;
            }
            .no-borders {
                border: none !important;
                border: 1px solid #cccccc;
                border-radius: 5px;
            }
            .date {
                input {
                    width: 100% !important;
                }
            }
            span {
                font-size: 1.6rem;
            }
            .fa {
                position: absolute;
                top: 7%;
                font-size: 1.8rem;
                cursor: pointer;
                &.fa-trash {
                    color: #ff3366;
                    right: 5%;
                }
                &.fa-edit {
                    color: $positive-green;
                    right: 10%;
                    cursor: pointer;
                }
            }
            .save-edits {
                z-index: 5;
                position: absolute;
                right: 5%;
                top: 7%;
                background-color: transparent;
                border: 1px solid $positive-green;
                color: $positive-green;
                font-size: 1.6rem;
                padding: 3px 8px;
                border-radius: 5px;

                &:hover {
                    box-shadow: 0px 0px 5px 0px rgba($positive-green, 0.6);
                }
            }
            .react-date-picker {
                width: 75%;
                padding: 5px 0;
                .react-date-picker__inputGroup__year {
                    width: 2rem;
                }
                @include breakpoint(mobile) {
                    font-size: 1.6rem;
                    .react-date-picker__button {
                        svg {
                            height: 10px;
                            width: 10px;
                        }
                    }
                }
                input.react-date-picker__inputGroup__input {
                    width: auto !important;
                    min-width: 0.75rem !important;
                }
            }
        }
    }
}

.carousel.carousel-slider {
    height: inherit;
}

.buyer-enquiry-data-wrapper {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;

    .images {
        height: 40%;
        position: relative;
        // background-color: red;
        height: 35rem;
        & > div {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        .carousel-image {
            height: 30rem;
            max-height: auto;
        }
        .carousel .slider-wrapper {
            height: 100%;
            background-color: whitesmoke;
        }
    }
    .control-dots {
        background-color: whitesmoke;
        margin-bottom: 0;
    }
    .carousel .slide {
        background-color: whitesmoke;
        img {
            width: auto;
            height: 35rem;
        }
    }
    .carousel .slider-wrapper {
        cursor: pointer;
        background-color: whitesmoke;
    }
    .carousel .slider-wrapper.axis-horizontal .slider .slide {
        height: 100%;
    }
    .info-wrapper {
        padding: 2rem 4rem;
    }
    .carousel.carousel-slider .control-arrow {
        background-color: transparent;
        color: black;
        &::before {
            border-right: 8px solid #000;
        }
        &.control-next::before {
            border-right: none;
            border-left: 8px solid #000;
        }
    }
    .info {
        // padding-bottom: 5rem;
        font-size: 1.6rem;
        & h2 {
            margin: 1rem 0;
        }
        &-table {
            margin-top: 1rem;
        }
        &-table,
        &-details {
            margin: 1rem 0;

            h3 {
                margin-bottom: 1rem;
                color: $positive-green;
            }
        }
    }

    button.close-btn {
        z-index: 500;
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 3rem;
        height: 3rem;
        background-color: whitesmoke;
        border-radius: 50%;
        border: none;
        box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
        transition: 150ms;
        font-size: 1.6rem;
        &:hover {
            transform: translateY(-1px);
        }
    }

    button.cart-interested-btn {
        padding: 0.5rem 2rem;
        font-size: 1.6rem;
        border: 1px solid transparent;
        background-color: $positive-green;
        transition: 200ms;
        color: white;
        &:hover {
            border: 1px solid $positive-green;
            transform: translateY(-1px);
            box-shadow: 1px 1px 10px 0px rgba($positive-green, 0.5);
        }
        @include breakpoint(laptop) {
            padding: 5px 10px;
        }
    }
}

.buyer-enquiry-form-wrapper {
    padding: 15px 0;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-top: 3rem;
    padding: 3rem 2rem;
    position: relative;
    .react-date-picker > div {
        border: 1px solid #ccc;
    }
    .more-info {
        overflow: hidden;
        font-size: 1.6rem;
        height: 0;
        transition: all 0.3s ease-in-out;
        &.expand {
            overflow: visible;
            height: 12rem;
        }
    }
    .extra-crop {
        margin-top: 20px;
        label {
            margin-bottom: 4px;
        }
    }
    .extra-crop-list {
        & > div {
            font-weight: bold;
            font-style: italic;
            display: inline-block;
            padding: 5px 0;
            // background-color: white;
            // border: 1px solid #333;
            margin-right: 15px;
        }
    }
    form {
        box-sizing: border-box;
        font-size: 1.6rem;
        border-radius: 5px;
        margin-top: 1rem;
        background-color: white;
        & > div {
            margin-bottom: 15px;
        }

        .react-date-picker__wrapper,
        .react-date-picker {
            width: 100%;
        }
        label {
            font-size: 1.6rem;
            display: block;
            margin-bottom: 8px;
            span {
                color: red;
            }
        }
        p {
            margin-bottom: 15px;
        }
        & > div > input {
            padding: 5px;
            height: 35px;
            width: 100%;
            border: 1px solid #ccc;
        }
    }
    button.cart-add-btn {
        font-size: 1.6rem;
        border: none;
        background-color: $positive-green;
        transition: 200ms;
        color: white;

        &:hover {
            transform: translateY(-2px);
            color: white;
            box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
        }
        @include breakpoint(laptop) {
            padding: 5px 10px;
        }
    }

    button.cart-remove-btn {
        font-size: 1.6rem;
        border: 1px solid crimson;
        background-color: white;
        transition: 200ms;
        color: crimson;
        margin-left: 2rem;

        &:hover {
            transform: translateY(-2px);
            color: red;
            border: 1px solid red;
            box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
        }
        @include breakpoint(laptop) {
            padding: 5px 10px;
        }
    }
}

.cart-conatainer {
    display: flex;
    justify-content: space-between;
    p {
        font-size: 2rem;
        max-width: 220px;
        font-weight: bold;
        @include breakpoint(tablet) {
            font-size: 1.5rem;
        }
    }
    .sidebar-menu-icon {
        font-size: 2.2rem;
        cursor: pointer;
        @include breakpoint(tablet) {
            margin-left: 0;
        }
    }
    .sidebar-menu-container {
        position: relative;

        div {
            position: absolute;
            background-color: white;
            left: 1rem;
            z-index: 5;
            padding: 0.5rem;
            width: 15rem;
            top: 3rem;
            font-size: 1.6rem;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            border-radius: 5px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            button {
                outline: none;
                border: none;
                background-color: transparent;
                margin-top: 5px;
                transition: 150ms;
                width: 100%;
                text-align: left;
                border-radius: 4px;
                padding: 0.5rem 1rem;

                &:hover {
                    background-color: rgb(245, 245, 245);
                }
            }
        }
    }
}

.date-picker > div {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1px;
    width: 100%;
    background-color: transparent !important;
}

.date-picker--special > div {
    border: none;
    background-color: white;
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    margin-top: 0.5rem;
    // background-color: transparent !important;
}

.react-date-picker--disabled {
    background-color: transparent !important;
}

.cart-badge {
    font-size: 1.6rem;
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: crimson;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.react-svg-zoom-animation {
    animation: svg-animation 600ms linear 1 forwards;
}

.react-svg-zoom-in {
    left: 50%;
    transform-origin: center;
    animation: svg-animation-in 1200ms linear 1 forwards;
}

@keyframes svg-animation {
    0% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes svg-animation-in {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.checkout-btn {
    font-size: 1.6rem;
    border: none;
    background-color: $positive-green;
    transition: 200ms;
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 2.5rem;
    margin-right: 2rem;

    &:hover {
        transform: translateY(-1px);
        color: white;
        box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
    }
    @include breakpoint(tablet) {
        font-size: 1.6rem;
        padding: 5px;
    }
    @include breakpoint(mobile) {
        padding: 0.5rem 3rem;
    }
}
.submit-otp-btn {
    background-color: $positive-green;
    &:hover {
        background-color: $positive-green;
        opacity: 0.8;
    }
}
.otp-modal {
    input {
        margin: 1rem 0 0 0;
        font-size: 1.6rem;
        padding: 1rem 1.5rem;
        border: none;
        outline: none;
        border-radius: 4px;
        width: 100%;
        border: 1px solid transparent;
        background-color: #fafafa;
        &:focus {
            outline: none;
        }
    }
}
.menu-btn {
    border: none;
    background-color: transparent;
}

.sweet-alert {
    h2 {
        font-size: 2.8rem;
    }

    & .text-muted {
        padding: 1rem;
        font-size: 1.8rem;
    }
}
.map-description {
    position: absolute;
    right: 9%;
    top: 82vh;
    font-size: 1.6rem;
    max-width: 400px;
    font-weight: bold;
}
.OTPmodal {
    position: fixed;
    width: 40%;
    min-width: 400px;
    height: 40%;
    left: 50%;
    min-height: 350px;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 2500;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 5rem;
    justify-content: space-between;
    font-size: 1.6rem;
    & > div {
        // width: 50%;
        display: flex;
        justify-content: center;
        font-weight: bold;
    }
    .resent-otp {
        margin-right: 2rem;
    }
    .fa {
        position: relative;
        right: -55%;
        top: 0%;
        font-size: 2rem;
        cursor: pointer;
    }
    @include breakpoint(laptop) {
        width: 50%;
        height: 280px;
    }
    @include breakpoint(tablet) {
        height: 280px;
        width: 60%;
        & > div {
            width: 75%;
        }
    }
    @include breakpoint(mobile) {
        min-height: 280px;
        height: auto;
        min-width: auto;
        width: 90%;
        padding: 10px 15px 30px 15px;
        & > div {
            width: 80%;
        }
        .fa {
            right: -45%;
            top: 5%;
            font-size: 3rem;
        }
    }
}
.otp-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 120;
}
.mapWrapper {
    overflow: hidden;
    position: relative;
    width: 65%;
    height: calc(100vh - 60px);
    @include breakpoint(laptop) {
        width: 100%;
        margin: auto;
        // height: 120vh;
        height: 500px;
    }

    @include breakpoint(tablet) {
        width: 100%;
        margin: auto;
        // height: 120vh;
        height: 500px;
    }

    .sidebar-toggle-btn {
        display: none;
        position: absolute;
        transition: all 0.4s ease-in-out;

        @include breakpoint(mobile) {
            display: flex;
            height: 60px;
            border-radius: 0 5px 5px 0;
            z-index: 400;
            left: 0;
            width: 20px;
            background-color: $positive-green;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            cursor: pointer;
        }
    }
}
p.buyer-register-error {
    font-size: 1.6rem !important;
    padding: 4px 0;
    margin: 1rem 0;
    font-weight: bold;
    @include breakpoint(mobile) {
        font-weight: normal;
        margin: 0;
        padding: 0;
    }
}

.crop-select {
    background-color: white;
    // height: 40rem;
    overflow: auto;
    max-height: 70rem;
    width: 95%;
    position: absolute;
    z-index: 1;
    box-shadow: 1px 1px 10px 2px rgba(black, 0.2);
    // border-bottom-left-radius: 1rem;
    // border-bottom-right-radius: 1rem;
    // padding: 0 2rem;
}

.styled-checkbox {
    position: absolute;
    opacity: 0;

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        //   margin: 10px 0;
        //   background-color: red;
        margin-bottom: 0;
        padding: 1rem;
        border-bottom: 1px solid #eee;
    }

    // Box
    & + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
        border: 2px solid #ccc;
    }

    // Box hover
    &:hover + label:before {
        background: rgba($positive-green, 0.6);
    }

    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

    // Box checked
    &:checked + label:before {
        background: rgba($positive-green, 1);
    }

    // Disabled state label
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark
    &:checked + label:after {
        content: "";
        position: absolute;
        left: 15px;
        top: 19px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
    }
}

.state-info-tooltip {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 234567;
    width: 350px;
    min-height: 200px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: ghostwhite;
    @include breakpoint(mobile) {
        height: 180px;
        width: 200px;
    }
    & > div {
        position: relative;
        padding: 2rem;
        z-index: 1;
    }
    .population {
        margin: 1rem 0;
    }
    label {
        display: block;
    }
    .major-crops {
        word-break: break-all;
        font-weight: normal;
        letter-spacing: 0.09rem;
        font-size: 1.2rem;
    }
    .offer2Sale-crops {
        margin-top: 1rem;
        font-size: 1.6rem;
        letter-spacing: 0.03rem;
    }
    .arrow {
        position: absolute;
        height: 30px;
        width: 30px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        background-color: ghostwhite;
        z-index: 0;
    }
}

//   .table {

//       & tr th {
//           margin:
//       }
//   }

.theme-table {
    width: 100%;
    & tr th {
        margin: 15px;
    }
}

.stylish-loader {
    // box-shadow: 0px 2px 7px 0px #ccc;
    padding: 20px;
}

.loader-effect {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 20px;
    position: relative;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}
.buyer-loader {
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 2500;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 100px;
        animation: loadingAnim 1s linear 0s infinite normal forwards;
    }
}

@keyframes loadingAnim {
    0%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}
.instruction-container {
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: white;
    border-radius: 3px;
    ul {
        margin: 2rem 0;
        padding-left: 1.5rem;
        letter-spacing: 0.08rem;
        line-height: 3rem;
    }
    form {
        display: flex;
        align-items: center;
        input,
        label {
            margin: 0;
        }
        input {
            margin-right: 1rem;
        }
    }
}
.buyer-page-image-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.95);
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 90%;
        max-height: 90%;
    }
    .fa.fa-times {
        position: absolute;
        font-size: 4rem;
        right: 5%;
        top: 5%;
        cursor: pointer;
        color: white;
    }
}
.email-agree {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    input,
    label {
        margin: 0;
    }
    input {
        margin-right: 1rem;
    }
}
//mandi integration css changes
.crop-region-wrapper {
    .toggle-mode {
        height: 60px;
        display: flex;
        padding: 1rem 2rem;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        input {
            margin-right: 0.5rem;
        }
    }
}

.offer-label {
    display: flex;
    align-items: center;
    color: #388e3c;
    span {
        cursor: pointer;
    }
    img {
        cursor: pointer;
        height: 40px;
        margin-right: 8px;
        @include breakpoint(mobile) {
            height: 15px;
        }
    }
}
.offer-info {
    display: flex;
    align-items: center;
    font-weight: bold;

    img {
        height: 45px;
        margin-right: 3rem;
    }
}
.offer-text-span,
.state-title span {
    background-color: #388e3c;
    color: white;
    padding: 3px 10px;
    cursor: pointer;
    display: inline-block;
    border-radius: 0.5rem;
}
.state-title span {
    font-size: 1.2rem;
    font-weight: normal;
    padding: 2px 5px;
}
.offer-text {
    color: white;
    font-size: 14px;
}
@keyframes offerBlink {
    0%,
    100% {
        border-color: red;
        color: red;
    }
    50% {
        border-color: black;
        color: yellow;
    }
}
.offer-crop-tooltip {
    position: absolute;
}
.mandi-tooltip {
    height: 250px;
    width: 250px;
    background-color: red;
    position: absolute;
    left: 50%;
    top: 10%;
    display: none;
    z-index: 400;
}

.mandi-info-tooltip {
    position: fixed;
    left: 50%;
    top: 50%;
    padding: 2rem 1rem;
    padding-bottom: 3rem;
    z-index: 234567;
    width: 350px;
    font-size: 1.6rem;
    min-height: 200px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: white;
    @include breakpoint(mobile) {
        height: 180px;
        width: 200px;
    }
    h3 {
        letter-spacing: 0.02rem;
        margin-bottom: 1rem;
    }
    .price-container {
        padding: 2rem 2rem 1rem 2rem;
        display: flex;
        justify-content: space-between;
        label {
            text-align: center;
            font-size: 1.6rem;
            display: block;
            font-weight: normal;
        }
        p {
            text-align: center;
            font-weight: bold;
            font-size: 2rem;
        }
    }
    .unitofprice {
        font-size: 1rem;
    }
    .arrow {
        position: absolute;
        height: 30px;
        width: 30px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        background-color: white;
        z-index: 0;
    }
}
