@import "colors";
@import "mixins";

/******* Farmer HomePage Slider CSS ************/
//// @Author Suman kumar, 24th May -31st May, 2021.
html.modal-open {
    overflow: hidden;
    body.modal-open {
        height: unset;
    }
}

.farmer_language {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 4px 6px 80px;
}
.farmer_language_button {
    border: none;
    padding: 6px 22px;
    background-color: #e5e5e5;
    font-size: 16px;
    font-family: montserrat;
    font-weight: bold;
}
@include breakpoint(tablet) {
    .farmer_language_button {
        padding: 4px 20px;
    }
    .farmer_language {
        margin-top: 85px;
        padding: 0px 0px 6px 80px;
    }
}

@include breakpoint(mobile) {
    .farmer_language_button {
        padding: 4px 16px;
    }
    .farmer_language {
        margin-top: 85px;
        padding: 0px 0px 6px 80px;
    }
}
.Farmer-HomePage-hero {
    top: 90px;

    /*****Farmer page carousal modified css of carousal package*******/

    .carousel .thumbs-wrapper {
        top: -215px !important;
        padding: 60px 100px !important;
        margin: 0 !important;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #040404 98%) !important;
    }
    .carousel .thumb {
        border: none !important;
        width: 180px !important;
        border-radius: 16px !important;
    }
    .carousel.carousel-slider .control-arrow {
        opacity: 1 !important;
        margin: 12px !important;
    }
    .carousel.carousel-slider .control-arrow:hover {
        background: rgba(0, 0, 0, 0) !important;
    }
    @include breakpoint(tablet) {
        .carousel .thumbs-wrapper {
            top: -188px !important;
            padding: 60px 60px !important;
            margin: 0 !important;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #040404 98%) !important;
        }
        .carousel .thumb {
            width: 130px !important;
        }
    }
    @include breakpoint(mobile) {
        .carousel .thumbs-wrapper {
            top: -60px !important;
            padding: 10px 35px !important;
            margin: 0 !important;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #040404 98%) !important;
        }
        .carousel .thumb {
            width: 70px !important;
            border-radius: 14px !important;
        }
        .carousel.carousel-slider .control-arrow {
            margin: -2px !important;
        }
    }
}
@media (min-width: 769px) and (max-width: 992px) {
    .Farmer-HomePage-hero {
        top: 76px;
    }
}
.carousal-content {
    //top: 90px;
    h1 {
        position: absolute;
        //display: flex;
        width: 100%;
        color: #9cc75a;
        justify-content: space-around;
        font-size: 48px;
        font-family: montserrat;
        font-weight: bold;
        padding: 40px;
    }
    p {
        position: absolute;
        display: flex;
        width: 100%;
        color: white;
        justify-content: space-around;
        font-size: 28px;
        font-family: montserrat;
        font-weight: bold;
        padding: 116px;
    }
}

@media (max-width: 768px) {
    .carousal-content > h1 {
        font-size: 32px;
        padding: 26px;
    }
    .carousal-content > p {
        font-size: 24px;
        padding: 80px;
    }
    .Farmer-HomePage-hero {
        top: 76px;
    }
}

@media (max-width: 500px) {
    .carousal-content > h1 {
        font-size: 24px;
        padding: 16px;
    }
    .carousal-content > p {
        font-size: 12px;
        padding: 53px;
    }
}

/******************* Laughing Farmer section ************/

.laughingfarmer-section {
    padding: 20px 0px 0px 0px;
    margin-top: -110px;

    h1 {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 44px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding: 60px;
    }
}

@include breakpoint(tablet) {
    .laughingfarmer-section > h1 {
        font-size: 32px;
        padding: 48px;
    }
}
@include breakpoint(mobile) {
    .laughingfarmer-section {
        padding: 0px 0px 0px 0px;
        margin-top: 48px;
    }
    .laughingfarmer-section > h1 {
        font-size: 28px;
        padding: 10px;
    }
}
.laughingfarmer-background {
    border-radius: 4px;
    height: 84rem;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -8rem;
    @include trans(translateX(-50%));
    // clip-path: polygon(0 12%, 100% 0, 100% 85%, 0% 100%);
    background-color: rgba(245, 240, 245, 0.6);

    @include breakpoint(tablet) {
        height: 108rem;
        margin-top: -18rem;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        height: 130rem;
        margin-top: -16rem;
    }

    @include breakpoint(mobile) {
        height: 115rem;
        margin-top: -5rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }

    @media (max-width: 400px) {
        height: 118rem;
        margin-top: -5rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}

.laughing-farmer-img {
    height: 380px;
    width: 600px;
    background: url(../images/farmer/laugh_farmer.png);
    background-position: center;
    background-size: cover;
    margin-right: -320px;

    @include breakpoint(tablet) {
        height: 220px;
        width: 400px;
        margin-right: -40px;
    }
    @media (min-width: 800px) and (max-width: 1024px) {
        height: 330px;
        width: 480px;
        margin-right: -40px;
    }
    @include breakpoint(mobile) {
        height: 280px;
        width: 96vw;
        margin: 2rem 0 0 0;
    }
}

/****** Crop-section *****/

.crop-section {
    padding: 50px 0px 0px 0px;

    h1 {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 44px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding: 60px;
    }
}
.crop-section-video {
    width: 600px;
    height: 380px;
}
@media (min-width: 900px) and (max-width: 1280px) {
    .crop-section-video {
        width: 420px;
        height: 300px;
    }
    .crop-section {
        padding: 0px 0px 0px 0px;
    }
    .journey-farmer-info--text {
        font-size: 26px !important;
    }
}
@include breakpoint(tablet) {
    .crop-section-video {
        width: 360px;
        height: 240px;
    }
    .crop-section > h1 {
        font-size: 32px;
        padding: 40px;
    }
    .crop-section {
        padding: 5px 0px 0px 0px;
    }
}
@include breakpoint(mobile) {
    .crop-section-video {
        width: 95vw;
        height: 220px;
    }
    .crop-section {
        padding: 8px 0px 0px 0px;
    }
    .crop-section > h1 {
        font-size: 28px;
        padding: 10px;
    }
}

/**********Journey *******/

.journey-section {
    padding: 60px 0px 0px 0px;
}
.journey-section-quote {
    background-color: #38853b;
    height: 300px;
    padding: 86px 60px;
    h1 {
        color: white;
        font-weight: bold;
        font-size: 42px;
        font-family: montserrat;
        font-style: normal;
        // opacity: 0.8;
        line-height: 1.3;
    }
}
@media (max-width: 768px) {
    .journey-section-quote {
        height: 240px;
        padding: 55px 70px;
    }
    .journey-section-quote > h1 {
        font-size: 30px;
    }
    .journey-section {
        padding: 50px 0px 0px 0px;
    }
}
@media (max-width: 500px) {
    .journey-section-quote {
        height: 212px;
        padding: 22px 30px;
    }
    .journey-section-quote > h1 {
        font-size: 26px;
    }
    .journey-section {
        padding: 20px 0px 0px 0px;
    }
}
@media (max-width: 400px) {
    .journey-section-quote {
        height: 242px;
        padding: 20px 30px;
    }
    .journey-section-quote > h1 {
        font-size: 26px;
    }
    .journey-section {
        padding: 4px 0px 0px 0px;
    }
}

@media (min-width: 800px) and (max-width: 1024px) {
    .journey-section-quote {
        padding: 46px 60px;
    }
    .journey-section {
        padding: 50px 0px 0px 0px;
    }
}

.journey-farmer {
    padding: 0 96px 96px 96px;
    display: flex;
    align-items: center;

    @include breakpoint(tablet) {
        padding: 0 55px 96px 36px;
    }

    @include breakpoint(phablet) {
        flex-direction: column;
        padding: 64px 0 64px 0;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        padding: 0 0 64px 0;
    }
    &-info {
        text-align: left;
        width: 50%;
        padding-right: 64px;
        float: left;

        &--text {
            margin-top: 16px;
            font-size: 26px;
            color: #888;
            font-weight: 400;
            line-height: 1.5;
        }

        @include breakpoint(tablet) {
            .journey-farmer-info--text {
                font-size: 18px !important;
            }
            width: 60%;
            clear: both;
            text-align: center;
            font-size: 16px;
            padding-right: 30px;
            line-height: 26px;
        }
        @include breakpoint(mobile) {
            .journey-farmer-info--text {
                font-size: 18px;
            }
            width: 100%;
            padding: 14px;
            clear: both;
            text-align: center;
            font-size: 18px;
            top: -24px;
        }
    }

    &-img {
        height: 600px;
        width: 520px;
        background: url(../images/farmer/mappic.jpg);
        background-position: center;
        background-size: cover;
        margin-right: -320px;

        @include breakpoint(tablet) {
            height: 390px;
            width: 400px;
            margin-right: -40px;
        }
        @include breakpoint(mobile) {
            height: 400px;
            width: 100%;
            margin: 2rem 0 0 0;
        }
    }
}

.distance-numbers {
    position: relative;
    //z-index: 43;
    height: 250px;
    //margin-top: -128px;
    background-color: white;

    .distance-number-wrapper {
        max-width: 100vw;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #f9f6f9;
        div {
            width: 200px;
            height: 200px;
            background-color: white;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                margin: 25px 0 10px 0;
                width: 30px;
                height: 30px;
            }
            h3 {
                font-size: 18px;
                text-align: center;
                margin-bottom: 15px;
            }
            h1 {
                text-align: center;
                position: relative;
                font-size: 2.5rem;
                font-weight: normal !important;
            }
            .number::after {
                display: block;
                position: absolute;
                content: "";
                right: 15%;
                bottom: -8px;
                height: 5px;
                width: 50%;
                background-color: $positive-green;
            }
            @include breakpoint(tablet) {
                width: 160px;
                height: 160px;
                img {
                    margin: 15px 0 8px 0;
                }
                h3 {
                    font-size: 15px;
                    margin-bottom: 5px;
                }
                h1 {
                    font-size: 2.5rem;
                }
            }
        }
    }
    @include breakpoint(mobile) {
        // padding: 30px 0;
        height: auto;
        .distance-number-wrapper {
            flex-direction: column;
            justify-content: space-evenly;
            height: 700px;
            div {
                height: 160px;
                width: 160px;
                align-items: center;
                img {
                    margin-top: 20px;
                }
                h3 {
                    margin-top: 0rem;
                }
            }
        }
    }
}

/********Farmer Mandi section**********/

.farmermandi-section {
    padding: 30px 0px 0px 0px;

    h1 {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 44px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding: 60px;
    }
}

@include breakpoint(tablet) {
    .farmermandi-section > h1 {
        font-size: 32px;
        padding: 40px;
    }
    .farmermandi-section {
        padding: 5px 0px 0px 0px;
    }
}
@include breakpoint(mobile) {
    .farmermandi-section {
        padding: 60px 0px 0px 0px;
    }
    .farmermandi-section > h1 {
        font-size: 28px;
        padding: 10px;
    }
}

.farmermandi_background {
    border-radius: 4px;
    height: 78rem;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -6rem;
    @include trans(translateX(-50%));
    background-color: rgba(245, 240, 245, 0.6);

    @include breakpoint(tablet) {
        height: 82rem;
        margin-top: -4rem;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        height: 90rem;
        margin-top: -4rem;
    }

    @include breakpoint(mobile) {
        height: 100rem;
        margin-top: -5rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}

.farmermandi-mandi-img {
    height: 380px;
    width: 600px;
    background: url(../images/farmer/mandi3.png);
    background-position: center;
    background-size: cover;
    margin-right: -320px;

    @include breakpoint(tablet) {
        height: 220px;
        width: 400px;
        margin-right: -40px;
    }
    @media (min-width: 800px) and (max-width: 1024px) {
        height: 330px;
        width: 480px;
        margin-right: -40px;
    }
    @include breakpoint(mobile) {
        height: 280px;
        width: 96vw;
        margin: 2rem 0 0 0;
    }
}

/******* farmer page हमसे जुड़ने के फायदे ! *********/

.benefit-join {
    padding: 40px 0px 75px 0px;
}
.benefit-join-us {
    background-color: #7f6127;
    height: 610px;
    padding: 40px 20px;
    h1 {
        color: white;
        text-align: center;
        font-weight: bold;
        font-size: 42px;
        font-family: montserrat;
        font-style: normal;
        p {
            text-align: center;
            font-weight: 300;
            font-family: montserrat;
            font-size: 20px;
            color: white;
            padding: 12px;
        }
    }
}
.joining_us_audio-form_section {
    //display: flex;
    justify-content: space-between;
}
.joining_us_registration_form {
    //display: flex;
    //justify-content: space-between;
    text-align: left;
    padding: 20px 20px 10px 20px;
    button {
        border: none;
        padding: 0.6rem 1rem;
        border-radius: 8px;
    }
    .registration-form-cta {
        background-color: #b72626ed;
        color: white;
        font-size: 18px;
        font-weight: 700;
    }
}

@media (min-width: 800px) and (max-width: 1024px) {
    .benefit-join-us {
        height: 540px;
    }
    .benefit-join {
        padding: 20px 0px 75px 0px;
    }
    .joining_us_audio-form_section {
        padding: 50px 60px 2px 60px;
    }
}

@media (min-width: 800px) and (max-width: 900px) {
    .joining_us_registration_form {
        text-align: center;
    }
}
@media (max-width: 768px) {
    .benefit-join-us {
        height: 750px;
        //padding: 30px 30px;
    }
    .benefit-join-us > h1 {
        font-size: 32px;
        p {
            font-size: 16px;
        }
    }
    .benefit-join {
        padding: 0px;
    }
    .joining_us_audio-form_section {
        padding: 16px 32px;
    }
    .joining_us_registration_form {
        text-align: center;
    }
}
@media (max-width: 500px) {
    .benefit-join-us {
        height: 830px;
        padding: 20px 14px;
    }
    .benefit-join-us > h1 {
        font-size: 28px;
        p {
            font-size: 14px;
            padding: 10px 2px;
        }
    }
    .benefit-join {
        padding: 0px;
        top: -10px;
    }
    .joining_us_audio-form_section {
        display: block;
        justify-content: space-between;
        padding: 16px 44px;
    }
    .joining_us_registration_form {
        text-align: center;
    }
}
@media (max-width: 400px) {
    .benefit-join-us {
        height: 780px;
        padding: 20px 14px;
    }
}

.benefit-join_grid {
    //margin: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    align-items: center;
    width: 90vw;
    height: 400px;
    margin-left: 55px;
}
.benefit-join_grid > article {
    background-color: #9cc75a;
    border: none;
    box-shadow: 2px 2px 6px 0px;
    border-radius: 20px;
    text-align: center;

    transition: transform 0.3s;
}
.benefit-join_grid > article:hover {
    transform: translateY(5px);
    box-shadow: 2px 2px 26px 0px;
}
.benefit-join_grid > article img {
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 80%;
}
.benefit-join_grid_text {
    padding: 20px 20px 20px;
}
.benefit-join_grid_text h3 {
    //text-transform: uppercase;
    color: white;
    font-size: 20px;
    font-family: montserrat;
    font-weight: 700;
}
@media (min-width: 800px) and (max-width: 1024px) {
    .benefit-join_grid {
        margin-left: 32px;
        height: 330px;
    }
}
@media (max-width: 768px) {
    .benefit-join_grid {
        grid-template-columns: repeat(2, 1fr);
        width: 88vw;
        margin-left: 28px;
    }
}
@media (max-width: 500px) {
    .benefit-join_grid {
        grid-template-columns: repeat(1, 1fr);
        width: 65vw;
        margin-left: 52px;
    }
    .benefit-join_grid > article {
        text-align: center;
    }
    .benefit-join_grid_text {
        padding: 8px 8px 8px;
    }
    .benefit-join_grid_text > h3 {
        font-size: 18px;
    }
}

/********playstore section *******/

.playstore-section {
    padding: 40px 0px 0px 0px;
    margin-top: -100px;
    h1 {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 44px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding: 60px;
    }
}

@include breakpoint(tablet) {
    .playstore-section {
        padding: 90px 0px 0px 0px;
        h1 {
            font-size: 32px;
        }
    }
}
@include breakpoint(mobile) {
    .playstore-section {
        padding: 1px 0px 0px 0px;
        top: 80px;
        h1 {
            font-size: 28px;
            padding: 60px 18px 10px 18px;
        }
    }
}
.playstore-app-img {
    height: 530px;
    width: 580px;
    background: url(../images/farmer/playstore.png);
    background-position: center;
    background-size: cover;
    margin-right: -320px;

    @include breakpoint(tablet) {
        height: 280px;
        width: 310px;
        margin-right: -40px;
    }
    @media (min-width: 800px) and (max-width: 1024px) {
        height: 400px;
        width: 440px;
        margin-right: -40px;
    }
    @include breakpoint(mobile) {
        height: 360px;
        width: 92vw;
        margin: 2rem 0 0 0;
    }
}

/**********farmer image grid *******/

.farmergrid {
    top: -50px;
    //padding: 40px 20px;
    h1 {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 42px;
        font-family: montserrat;
        font-style: normal;
    }
}
@media (min-width: 800px) and (max-width: 1024px) {
    .farmergrid {
        //padding: 50px 0px 30px 0px;
        top: -60px;
    }
}
@media (max-width: 768px) {
    .farmergrid > h1 {
        font-size: 32px;
    }
    .farmergrid {
        //padding: 40px 0px 20px 0px;
        top: -48px;
        padding: 0px 15px;
    }
}
@media (max-width: 500px) {
    .farmergrid > h1 {
        font-size: 28px;
    }
}
.farmer_image-grid {
    background-color: rgba(245, 240, 245, 0.6);
    padding: 100px 0;
    margin-bottom: -9rem;
    margin-top: -10rem;
    @include breakpoint(largeDesktop) {
        margin-top: 4rem;
    }
    @include breakpoint(mobile) {
        margin-top: 0rem;
    }
    .farmer_image_wrapper {
        display: grid;
        max-width: 80%;
        margin: auto;
        grid-gap: 5px;
        grid-template: 190px / repeat(auto-fit, minmax(300px, 1fr));
        grid-auto-rows: 290px;
        grid-auto-flow: dense;
        img {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
        .vertical {
            grid-row: span 2;
        }
        .landscape {
            grid-column: span 2;
        }
        .hero {
            grid-row: span 2;
            grid-column: span 2;

            @include breakpoint(laptop) {
                grid-row: span 1;
            }
            @include breakpoint(tablet) {
                grid-row: span 1;
            }
            @include breakpoint(mobile) {
                grid-row: span 1;
            }
        }
        @include breakpoint(largeDesktop) {
            grid-template: 230px / repeat(auto-fit, minmax(500px, 1fr));
            grid-auto-rows: 290px;
        }
        @include breakpoint(hdLaptop) {
            grid-template: 270px / repeat(auto-fit, minmax(500px, 1fr));
            grid-auto-rows: 270px;
        }
        @include breakpoint(mobile) {
            grid-template: auto / 1fr;
            grid-auto-rows: auto;

            .vertical,
            .hero {
                grid-row: auto;
            }
            .hero {
                grid-column: span 1;
            }
        }
    }
    .farmer_image-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 4000;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 80%;
            height: 80%;
            @include breakpoint(mobile) {
                width: 100%;
                height: auto;
            }
        }
        .fa {
            position: fixed;
            top: 3%;
            font-size: 45px;
            color: white;
            right: 5%;
            cursor: pointer;
        }
    }
}
/******KN logo on homepage carousel **********/

.carousal-content-logo {
    img {
        width: 90px !important;
        height: 82px;
        position: absolute;
        justify-content: space-around;
        margin: 26px 50px 0px -254px;
    }

    @media (min-width: 769px) and (max-width: 899px) {
        img {
            width: 70px !important;
            height: 60px;
            position: absolute;
            justify-content: space-around;
            margin: 34px 50px 0px -220px;
        }
    }
    @media (max-width: 768px) {
        img {
            width: 70px !important;
            height: 60px;
            position: absolute;
            justify-content: space-around;
            margin: 15px 50px 0px -180px;
        }
    }

    @include breakpoint(mobile) {
        img {
            width: 50px !important;
            height: 45px;
            position: absolute;
            justify-content: space-around;
            margin: 6px 0px 0px -132px;
        }
    }
}
/******************video grid ***********/
.video_grid {
    background-color: #f9f6f9;
    //padding: 1px;
}
.KN_video_grid {
    margin: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    height: 220px;
}

.KN_video_grid > article {
    border-radius: 18px;
    text-align: center;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
}

.KN_video_grid > article:hover {
    transition: transform 0.1s ease-out;
    transform: translateY(5px);
}

.KN_video_section {
    height: 100%;
    width: 100%;
    padding: 10px;
    border-radius: 20px;
}
@media (max-width: 768px) {
    .KN_video_grid {
        grid-template-columns: repeat(2, 1fr);
        margin: 0;
        height: 440px;
    }
    .video_grid {
        height: 460px;
    }
}

@media (max-width: 500px) {
    .KN_video_grid {
        grid-template-columns: repeat(1, 1fr);
        margin: 0;
        height: 720px;
    }

    .KN_video_grid > article {
        text-align: center;
    }
    .video_grid {
        height: 738px;
        top: -40px;
    }
}
/***********.playstore-section social media button *****************/

/**************facebook***********/

.farmer_page_facebook-button {
    height: 34px;
    padding: 4px;
    display: inline-block;
    margin-left: 12px;
}

@media (max-width: 768px) {
    .farmer_page_facebook-button {
        margin-left: -100px;
    }
}
@media (max-width: 500px) {
    .farmer_page_facebook-button {
        padding: 2px;
        margin-left: 0px;
    }
}
/**************youtube***********/
.farmer_page_youtube-button {
    height: 34px;
    padding: 4px;
    display: inline-block;
}

@media (max-width: 768px) {
    .farmer_page_youtube-button {
        padding: 4px;
    }
}
@media (max-width: 500px) {
    .farmer_page_youtube-button {
        padding: 2px;
    }
}
/*********** playstore *****/

.farmer_page_playstore-button {
    height: 34px;
    padding: 3px;
    display: inline-block;
}

@media (max-width: 768px) {
    .farmer_page_playstore-button {
        padding: 4px;
    }
}
@media (max-width: 500px) {
    .farmer_page_playstore-button {
        padding: 2px;
        height: 36px;
    }
}

/******* farmer registration form Modal **********/
.farmer_registration_modal-wrapper {
    background: #b1c155;
    max-width: 400px;
    margin: auto;
    transition: all 0.5s ease;
    z-index: 1;
    position: fixed;
    overflow: hidden;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-height: 80vh;
    overflow: auto;
    width: 95%;
    border-radius: 10px;
    line-height: 26px;
    font-size: 16px;
}
.farmer_registration_modal__header {
    background: #b1c155;
    color: #e9e9e9;
    padding: 0.6rem 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
    h4 {
        font-size: 20px;
        color: #fff;
        font-weight: bold;
    }
    span {
        font-size: 2rem;
        cursor: pointer;
        color: #e9e9e9;
    }
}

.farmer_registration_close-modal-btn {
    font-weight: 700;
    color: #102a43;
    line-height: 35px;
    font-size: 12px;
    text-transform: uppercase;
}

.farmer_registration_modal--content {
    padding: 0rem 0rem 10px 0rem;
    background-color: #fff;
    width: 100%;
}
.farmer_registration_modal-body {
    //margin-bottom: 1rem;
    padding: 0 !important;
    text-align: left !important;
    h4 {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
    }
    span {
        font-weight: normal;
    }
    p {
        font-size: 10px;
        color: #333;
        padding: 1rem;
        margin: 1rem 0 2rem 0;
    }
}

.farmer_registration_back-drop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000e6;
    z-index: 9999;
}

/**********farmer Registration form css*************/

.farmer_registration_modal-form {
    background-color: #ffffff;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
}

.farmer_registration_modal-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        padding: 0.2em;
        button {
            background: #b72626ed;
            color: white;
            font-weight: 700;
            font-size: 13px;
            border-radius: 5px;
            margin-top: 8px;
            width: 136px;
            padding: 5px;
            border: none;
        }
    }
    label {
        font-size: 10px;
        margin-bottom: 0px;
    }
}

.required-star {
    color: #fc4366;
    font-size: small;
}

.farmer_registration_modal-form input,
.farmer_registration_modal-form textarea {
    width: 100%;
    padding: 9px 12px;
    border: 1px solid #e1e2eb;
    background-color: #fff;
    color: #102a43;
    box-sizing: border-box;
    font-size: 1.2rem;
    line-height: 1.3rem;
    box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
    border-radius: 3px;
}
