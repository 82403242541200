.text-center {
    text-align: center;
}

.block-center {
    display: block;
    margin: 0 auto;
}

.center-text {
    text-align: center;
}