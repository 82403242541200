@import "colors";
@import "mixins";

// .border-container {

//     &:not(:nth-child(1)):not(:nth-child(5)):not(:nth-child(6)):not(:last-child) {
//         border-bottom: 1px solid #eee;
//     }

// }

.main-container {
    // padding-top: 6rem;
    &--heading {
        margin: 0 auto;
        width: 65rem;
        text-align: center;
        font-weight: bold;

        @include breakpoint(laptop) {
            width: 100%;
        }

        @include breakpoint(tablet) {
            width: 100%;
        }

        @include breakpoint(phablet) {
            width: 100%;
        }

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    &--text {
        margin: 3rem auto 0 auto;
        width: 100rem;
        text-align: center;
        line-height: 2.8rem;

        @include breakpoint(tablet) {
            width: 100%;
        }

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    @include breakpoint(mobile) {
        &--heading {
            margin-top: 2rem;
        }
    }
}

.vision-section {
    padding: 0 5rem;

    @include breakpoint(tablet) {
        padding: 0 7rem;
        text-align: left;
    }

    @include breakpoint(phablet) {
        padding: 0 5rem;
        text-align: left;
    }

    @include breakpoint(mobile) {
        padding: 0;
        text-align: left;
    }
}

.main-heading {
    @include breakpoint(tablet) {
        padding: 0 7rem;
        text-align: left;
    }
    @include breakpoint(phablet) {
        padding: 0;
        text-align: left;
    }

    @include breakpoint(mobile) {
        padding: 0;
        text-align: left;
    }
}

.main-text {
    @include breakpoint(tablet) {
        padding: 0 7rem;
        text-align: left;
    }

    @include breakpoint(phablet) {
        padding: 0;
        text-align: left;
    }

    @include breakpoint(mobile) {
        padding: 0;
        text-align: left;
    }
}

.numbers-container {
    .numbers {
        &--img {
            &-1 {
                z-index: 10;
                width: 100vw;
                margin-top: -22rem;
            }

            &-2 {
                z-index: 10;
                width: 200%;
                height: 200%;
                margin-top: -20rem;
                // transform: scale(1.4);
            }

            @include breakpoint(tablet) {
                &-1 {
                    z-index: 10;
                    width: 200%;
                    height: 200%;
                    margin-top: 0rem;
                }

                &-2 {
                    z-index: 10;
                    width: 200%;
                    height: 200%;
                    margin-top: -15rem;
                }
            }

            @include breakpoint(mobile) {
                &-1 {
                    z-index: 10;
                    width: 200%;
                    height: 200%;
                    margin-top: 0rem;
                }

                &-2 {
                    z-index: 10;
                    width: 200%;
                    height: 200%;
                    margin-top: -10rem;
                }
            }
        }

        &-wrapper {
            display: flex;
            margin-top: 5rem;
            justify-content: space-around;
            color: $primary-green;

            &--item {
                // margin-right: 6rem;
                text-align: center;

                & > h1 {
                    font-size: 7rem;

                    @include breakpoint(laptop) {
                        font-size: 5rem;
                    }

                    @include breakpoint(tablet) {
                        font-size: 5rem;
                    }

                    @include breakpoint(phablet) {
                        font-size: 4rem;
                    }

                    @include breakpoint(mobile) {
                        font-size: 7rem;
                    }
                }

                & > p {
                    font-size: 2rem;
                }

                @include breakpoint(mobile) {
                    margin-bottom: 3rem;
                }
            }

            @include breakpoint(mobile) {
                flex-direction: column;
            }
        }
    }
}
.numbers-container--img-2 {
    z-index: 5;
    margin-top: -10rem;
    width: 200%;
    // margin-left: -40rem;
    transform: scale(2);
}

.farmer {
    padding: 0 6rem 6rem 6rem;
    display: flex;
    align-items: center;

    @include breakpoint(tablet) {
        padding: 4rem;
    }

    @include breakpoint(phablet) {
        flex-direction: column;
        padding: 4rem 0 4rem 0;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        padding: 0 0 4rem 0;
    }
    &-info {
        text-align: left;
        width: 50%;
        padding-right: 6rem;
        float: left;

        &--text {
            margin-top: 1rem;
            line-height: 2.4rem;
        }

        @include breakpoint(tablet) {
            width: 100%;
            clear: both;
            text-align: left;
        }
        @include breakpoint(mobile) {
            width: 100%;
            padding-right: 0;
            clear: both;
            text-align: left;
        }
    }

    &-images {
        width: 50%;
        float: right;
        position: relative;
        padding-left: 6rem;

        @include breakpoint(tablet) {
            width: 100%;
            clear: both;
            display: flex;
            padding-left: 0;
            margin-top: 3rem;
        }
        @include breakpoint(mobile) {
            width: 100%;
            clear: both;
            display: flex;
            padding-left: 0;
        }
    }

    &-img {
        height: 55rem;
        width: 60rem;
        background: url(../images/FarmerAppUse2.jpg);
        background-position: center;
        background-size: cover;
        margin-right: -20rem;

        @include breakpoint(tablet) {
            height: 25rem;
            width: 40rem;
            margin-right: 0;
        }
        @include breakpoint(phablet) {
            height: 35rem;
            width: 100%;
            margin: 2rem 0 0 0;
        }
        @include breakpoint(mobile) {
            height: 25rem;
            width: 100%;
            margin: 2rem 0 0 0;
        }

        &-1 {
            height: 46rem;
            margin-left: auto;
            margin-right: -20rem;
        }
    }
}

.buyer {
    padding: 6rem 6rem 0 6rem;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;

    @include breakpoint(tablet) {
        padding: 4rem;
    }

    @include breakpoint(phablet) {
        flex-direction: column;
        padding: 0;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        padding: 0;
        margin-bottom: 3rem;
    }
    &-info {
        width: 50%;
        padding-left: 6rem;
        float: right;

        &--text {
            margin-top: 1rem;
            line-height: 2.4rem;
        }

        @include breakpoint(tablet) {
            float: left;
            width: 100%;
            clear: both;
        }

        @include breakpoint(phablet) {
            padding-left: 0;
        }

        @include breakpoint(mobile) {
            padding-left: 0;
        }
    }

    &-images {
        width: 50%;
        float: left;
        position: relative;
        padding-right: 6rem;

        @include breakpoint(tablet) {
            width: 100%;
            clear: both;
            display: flex;
            padding-right: 0;
            margin-top: 3rem;
        }
        @include breakpoint(mobile) {
            width: 100%;
            clear: both;
            display: flex;
            padding-right: 0;
        }
    }

    &-img {
        transition: all 200ms;
        height: 55rem;
        width: 60rem;
        background: url(../images/buyer_7.jpeg);
        background-position: center;
        background-size: cover;
        margin-left: -12rem;

        @include breakpoint(tablet) {
            height: 25rem;
            width: 40rem;
            margin-left: 0;
        }
        @include breakpoint(phablet) {
            height: 35rem;
            width: 100%;
            margin: 2rem 0 0 0;
        }
        @include breakpoint(mobile) {
            height: 25rem;
            width: 100%;
            margin: 2rem 0 0 0;
        }

        // &-4 {
        //     height: 46rem;
        //     margin-right: auto;
        //     margin-left: 0;
        // }

        // &:hover {
        //     box-shadow: 2px 2px 2rem 2px rgba(0, 0, 0, 0.4);
        // }
    }
}

.investors {
    display: flex;
    justify-content: space-around;

    @include breakpoint(mobile) {
        flex-direction: column;
    }

    &-logo {
        width: 16rem;
        height: 16rem;
        filter: grayscale(2);
        transition: 200ms;

        &:hover {
            // transform: scale(1.02);
            filter: none;
        }
    }

    &-card {
        width: 24rem;
        background-color: $white;
        border-radius: 8px;
        @include shadow(2px 4px 8px 0 rgba(46, 61, 73, 0.2));
        overflow: hidden;
        transition: all 150ms;
        cursor: pointer;

        @include breakpoint(phablet) {
            width: 20rem;
        }

        @include breakpoint(mobile) {
            margin: 2rem auto;
        }

        &-logo {
            width: 24rem;
            height: 22rem;
            transition: 200ms;

            &:hover {
                filter: none;
            }
        }

        &-name {
            padding: 1rem 2rem;
        }

        &:hover {
            @include shadow(5px 5px 25px 0 rgba(46, 61, 73, 0.2));
        }
    }

    &-link {
        transition: all 200ms;
        color: #008bed;

        &:hover {
            color: #003bff;
        }
    }
}

.media {
    .slick-track {
        display: flex;
    }

    .slick-track .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
    }

    &-container {
        display: flex;
        // background-color: red;
        justify-content: space-around;
        align-items: center;

        @include breakpoint(phablet) {
            flex-direction: column;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
        }

        &-others-up {
            margin-top: 7rem;
            animation: showMoreMedia 400ms ease-in;
        }

        &-others-down {
            margin-top: 7rem;
            animation: hideMoreMedia 200ms ease-in;
        }

        &-showMore {
            margin-top: 7rem;
        }

        &-showMore-enter {
            margin-top: 7rem;
            opacity: 0.01;
            @include trans(translateY(-50%));
        }

        &-showMore-enter-active {
            margin-top: 7rem;
            opacity: 1;
            @include trans(translateY(0%));
            transition: all 300ms ease-out;
        }

        &-showMore-exit {
            margin-top: 7rem;
            opacity: 1;
            @include trans(translateY(0%));
        }

        &-showMore-exit-active {
            margin-top: 7rem;
            opacity: 0;
            @include trans(translateY(-50%));
            transition: all 300ms ease-out;
        }
    }

    &-logo {
        width: 16rem;
        margin: 0 auto;

        @include breakpoint(tablet) {
            width: 14rem;
        }

        @include breakpoint(phablet) {
            width: 22rem;
            margin: 2rem auto;
        }

        @include breakpoint(mobile) {
            margin: 2rem auto;
            width: 18rem;
        }
    }

    &-more-btn {
        transition: all 200ms;
        color: $theme-green;
        background-color: transparent;
        border: none;

        &:hover {
            color: $primary-green;
        }
    }
}

.more-media {
    margin-top: 7rem;
}

.arrow-more {
    margin-left: 0.5rem;
}

.background--2 {
    border-radius: 4px;
    height: 130rem;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: 5rem;
    @include trans(translateX(-50%));
    clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
    -webkit-clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
    -moz-clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
    background-color: rgba(245, 240, 245, 0.6);

    @include breakpoint(tablet) {
        height: 88rem;
        margin-top: -7rem;
        clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
        -webkit-clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
        -moz-clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
    }

    @include breakpoint(phablet) {
        height: 130rem;
        margin-top: -5rem;
        clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
        -webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
        -moz-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
    }

    @include breakpoint(mobile) {
        height: 115rem;
        margin-top: -5rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}

.background--1 {
    border-radius: 4px;
    height: 75rem;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -15rem;
    @include trans(translateX(-50%));
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
    -webkit-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
    -moz-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
    background-color: rgba(245, 240, 245, 0.6);

    @include breakpoint(tablet) {
        height: 55rem;
        margin-top: -4rem;
        clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
        -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
        -moz-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
    }

    @include breakpoint(phablet) {
        height: 50rem;
        margin-top: -3rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }

    @include breakpoint(mobile) {
        height: 120rem;
        margin-top: 0rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}

.hiring-banner {
    // background: linear-gradient(to bottom right, $theme-green, $theme-brown);
    background: $theme-brown;
    padding: 12rem 0;
    font-family: Roboto;

    & > h1 {
        font-family: serif;
        font-size: 4.5rem;
    }

    .careers-text {
        // font-style: italic;
        padding: 0 45rem;
        color: $theme-brown-text;
        font-weight: lighter;
        @include breakpoint(desktop) {
            padding: 0 15rem;
        }
        @include breakpoint(mobile) {
            padding: 0 5rem;
        }
    }
    button {
        margin: 2rem auto 0 auto;
        border: none;
        background-color: transparent;
    }
}

.heading-special {
    display: inline-block;
    position: relative;

    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 6rem;
        height: 0.4rem;
        color: $white;
        background-color: $theme-green;
        right: 0;
        bottom: 0;
        z-index: -10;
    }
}

.culture {
    &-img {
        height: 30rem;

        &-container {
            padding: 1rem;
        }

        &-1 {
            background: url(../images/oc_1.jpeg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-2 {
            background: url(../images/oc_2.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-3 {
            background: url(../images/FarmerMeet.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-4 {
            background: url(../images/carousel1.jpeg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-5 {
            background: url(../images/oc_3.jpeg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-6 {
            background: url(../images/oc_6.jpeg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}

.core-values-box {
    // background: linear-gradient(to bottom right, $theme-green, $theme-brown);
    background: $theme-green;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: $light;
    height: 50rem;
    padding: 0 50rem;
    text-align: center;
    h1 {
        font-size: 10rem;
        font-family: serif;
        font-weight: 300;
        letter-spacing: 0.1rem;
    }
    p {
        margin: 2rem 0 0 0;
        font-size: 1.8rem;
    }
    .carousel-btn {
        position: absolute;
        cursor: pointer;
        top: 50%;
        font-size: 2.4rem;
        &--left {
            left: 2rem;
        }
        &--right {
            right: 2rem;
        }
    }
    @include breakpoint(desktop) {
        height: 35rem;
        padding: 0 30rem;
        h1 {
            font-size: 8rem;
        }
    }
    @include breakpoint(laptop) {
    }
    @include breakpoint(phablet) {
        padding: 0 20rem;
        h1 {
            font-size: 6rem;
        }
    }
    @include breakpoint(mobile) {
        padding: 0 5rem;
        h1 {
            font-size: 4rem;
        }
    }
}

.core-value-list {
    display: table;
    margin: 0 auto 0 auto;
    @include breakpoint(mobile) {
        display: none;
    }
    li {
        display: table-cell;
        font-size: 1.5rem;
        padding: 0.4rem 1rem;
        cursor: pointer;
        color: $text-tertiary;
        &.active {
            border-bottom: 2px solid $theme-green;
            font-weight: 700;
        }
    }
}

.animate-on-enter {
    opacity: 0;
    animation: animate-on-enter 3000ms ease-out forwards;
}

@keyframes animate-on-enter {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.core-values {
    &--animation-enter {
        margin-top: 7rem;
        opacity: 0.01;
        @include trans(translateY(-50%));
    }

    &--animation-enter-active {
        margin-top: 7rem;
        opacity: 1;
        @include trans(translateY(0%));
        transition: all 300ms ease-out;
    }

    &--animation-exit {
        margin-top: 7rem;
        opacity: 1;
        @include trans(translateY(0%));
    }

    &--animation-exit-active {
        margin-top: 7rem;
        opacity: 0;
        @include trans(translateY(-50%));
        transition: all 300ms ease-out;
    }
}

.mapouter {
    text-align: right;
    height: 20rem;
    width: 40rem;
    @include breakpoint(mobile) {
        margin: 1rem 0;
    }
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 20rem;
    width: 40rem;
}

.careers-footer {
    & > li {
        width: 20rem;
        @include breakpoint(mobile) {
            width: 40rem;
        }
    }
    & > li:nth-child(1)::after {
        content: "New";
        display: inline-block;
        background-color: $primary-brown;
        color: $white;
        padding: 2px 8px;
        margin-left: 1rem;
        border-radius: 4px;
    }
}
